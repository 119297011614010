import React from 'react';
import { useNavigate } from 'react-router';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import { useDispatchClientDocumentGetList } from '../../../Redux/Actions/Client/Documents';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import moment from 'moment';
import FinishedDocumentsComp from '../../../Components/Document/FinishedDocuments';

/**
 * Finished()
 * @constructor
 */
export default function Finished() {
  const navigate = useNavigate();
  const { user, activeClient } = useAppSelector((state) => state.auth);
  const [documents, setDocuments] = React.useState<DocumentType[] | null>(null);
  const [t] = useTranslation();
  const [selectedYear, setSelectedYear] = React.useState<number>(parseInt(moment().format('YYYY')));
  const [loading, setLoading] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);

  const dispatchGetList = useDispatchClientDocumentGetList();

  const handleUpdate = React.useCallback(() => {
    const startDate = moment(selectedYear, 'YYYY').startOf('year').format('YYYY-MM-DD');
    const endDate = moment(selectedYear, 'YYYY').endOf('year').format('YYYY-MM-DD');

    if (activeClient) {
      setLoading(true);
      dispatchGetList(activeClient.clientId, startDate, endDate)
        .then(setDocuments)
        .finally(() => setLoading(false));
    }
  }, [activeClient, dispatchGetList, selectedYear]);

  React.useEffect(() => {
    if (user.isCustomerUser) {
      navigate('/dashboard');
    }
  }, [navigate, user.isCustomerUser]);

  React.useEffect(() => {
    navContext.setHeadline(t('invoicesDone'));
    if (user.isCustomerUser) {
      navContext.setShowBack(true);
    }
  }, [navContext, t, user.isCustomerUser]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (selectedYear) {
      timer = setTimeout(() => handleUpdate(), 200);
    }

    return () => clearTimeout(timer);
  }, [handleUpdate, selectedYear]);

  if (activeClient) {
    return (
      <FinishedDocumentsComp
        documents={documents || []}
        client={activeClient}
        loading={loading}
        selectedYear={selectedYear}
        onChangeYear={setSelectedYear}
      />
    );
  }
  return null;
}
