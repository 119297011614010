import React from 'react';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { useDispatchCustomerGenerateApiKey } from '../../Redux/Actions/CustomerActions';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import { useCopyClipboard } from '../../Library/Hooks/GlobalHooks';
import Config from '../../Library/Config';

/**
 * ApiKey()
 * @constructor
 */
export default function ApiKey() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const [show, setShow] = React.useState<boolean>(false);
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();

  const dispatchGenerate = useDispatchCustomerGenerateApiKey();

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!customer.settings.api || !customer.settings.api.token) {
      timer = setTimeout(() => dispatchGenerate(), 500);
    }

    return () => clearTimeout(timer);
  }, [customer.settings.api, dispatchGenerate]);

  const renderKeyText = () => {
    if (customer.settings.api.token) {
      return show
        ? customer.settings.api.token
        : `... ${customer.settings.api.token.substring(customer.settings.api.token.length - 50)}`;
    }
    return '';
  };

  const renderShowAll = () => {
    if (!show) {
      return (
        <>
          <i
            className={classNames('fas fa-eye text-gray-400 hover:text-black pl-6 cursor-pointer', 'ApiKeyShow')}
            onClick={() => setShow(true)}
          />
          <Tooltip anchorSelect={'.ApiKeyShow'}>{t('apiKeyShow')}</Tooltip>
        </>
      );
    }
    return null;
  };

  if (customer.settings.api && customer.settings.api.token) {
    return (
      <Container headline={t('apiKey')} closable initialOpen={false} identifier={'CustomerApiKey'}>
        <div className={'text-gray-500'}>{t('apiKeyDescription')}</div>
        <div className={'mt-10 mb-1 font-light'}>{t('secretKey')}</div>
        <div className={'flex flex-1 flex-wrap px-4 py-3 border border-gray-200 rounded items-center'}>
          <div className={'flex- flex-1 break-all'}>{renderKeyText()}</div>
          <div>
            {renderShowAll()}
            <i
              className={'far fa-copy text-gray-400 hover:text-black pl-6 cursor-pointer ApiKeyCopy'}
              onClick={() => copyToClipBoard(customer.settings.api.token || '')}
            />
            <Tooltip anchorSelect={'.ApiKeyCopy'}>{t('copyToClipboard')}</Tooltip>
          </div>
        </div>

        <div className={'mt-6'}>
          <a
            className={'text-blue-700 hover:underline cursor-pointer'}
            target={'_blank'}
            href={`${Config.domain}/apidocumentation`}
            rel="noreferrer"
          >
            <i className={'fas fa-globe mr-3'} />
            {t('apiShowDocumentation')}
          </a>
        </div>

        {clipboardCallout}
      </Container>
    );
  }
  return null;
}
