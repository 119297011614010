import React from 'react';
import AbstractModal from '../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import classNames from 'classnames';
import Button from '../Forms/Button';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSelect: (folder: string) => void;
}

/**
 * MoveToFolderModal()
 * @param props
 * @constructor
 */
export default function MoveToFolderModal(props: Props) {
  const { visible, onClose, onSelect } = props;
  const { t } = useTranslation();
  const { folders } = useAppSelector((state) => state.storage);

  const renderFolders = () => {
    const copy = [...folders];
    return copy
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((item) => (
        <div
          key={item}
          onClick={() => onSelect(item)}
          className={classNames('py-2 px-3 rounded-md bg-gray-100 mb-2 cursor-pointer', 'hover:bg-gray-300')}
        >
          <i className={'fas fa-folder-closed mr-4 text-sky-500'} />
          {item}
        </div>
      ));
  };

  return (
    <AbstractModal
      visible={visible}
      onBackdrop={onClose}
      type={'success'}
      headline={t('moveFile')}
      buttons={
        <Button onPress={onClose} colorStyle={'cancel'}>
          {t('close')}
        </Button>
      }
      icon={'far fa-folder-arrow-up'}
    >
      <div className={'flex flex-1 flex-col mt-6'}>{renderFolders()}</div>
    </AbstractModal>
  );
}
