import { DocumentState, DocumentType, InvoiceType } from '../Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useAppSelector } from './ReduxHooks';
import { useDispatchDocumentGetInvoiceInformation } from '../../Redux/Actions/DocumentActions';
import { Client } from '../Types/ClientTypes';
import { SubscriptionType } from '../Types/CustomerTypes';

export interface StateMapping {
  state: DocumentState;
  textColorClass: string;
  bgColorClass: string;
  icon: string;
  text: string;
}

/**
 * useDocumentStatusMappings()
 */
export const useDocumentStatusMappings = (): StateMapping[] => {
  const [t] = useTranslation();
  const [mappings] = React.useState<StateMapping[]>([
    {
      state: DocumentState.uploaded,
      textColorClass: 'text-gray-400',
      bgColorClass: 'bg-gray-400',
      icon: 'fas fa-money-check-pen',
      text: t(`modules.documents.statusText.${DocumentState.uploaded}`),
    },
    {
      state: DocumentState.open,
      textColorClass: 'text-orange-500',
      bgColorClass: 'bg-orange-500 hover:bg-orange-800',
      icon: 'far fa-folder-open',
      text: t(`modules.documents.statusText.${DocumentState.open}`),
    },
    {
      state: DocumentState.done,
      textColorClass: 'text-green-700',
      bgColorClass: 'bg-green-600 hover:bg-green-800',
      icon: 'fas fa-check-circle',
      text: t(`modules.documents.statusText.${DocumentState.done}`),
    },
    {
      state: DocumentState.question,
      textColorClass: 'text-blue-700',
      bgColorClass: 'bg-blue-500 hover:bg-blue-800',
      icon: 'fas fa-question-circle',
      text: t(`modules.documents.statusText.${DocumentState.question}`),
    },
    {
      state: DocumentState.unreadable,
      textColorClass: 'text-purple-700',
      bgColorClass: 'bg-purple-500 hover:bg-purple-800',
      icon: 'fa-kit fa-regular-glasses-slash',
      text: t(`modules.documents.statusText.${DocumentState.unreadable}`),
    },
    {
      state: DocumentState.notBookable,
      textColorClass: 'text-red-600',
      bgColorClass: 'bg-red-500 hover:bg-red-800',
      icon: 'fa-kit fa-regular-file-invoice-slash',
      text: t(`modules.documents.statusText.${DocumentState.notBookable}`),
    },
    {
      state: DocumentState.notDeductible,
      textColorClass: 'text-red-600',
      bgColorClass: 'bg-red-500 hover:bg-red-800',
      icon: 'fa-kit fa-regular-circle-euro-slash',
      text: t(`modules.documents.statusText.${DocumentState.notDeductible}`),
    },
  ]);

  return mappings;
};

/**
 * useDocumentGetMapping()
 */
export const useDocumentGetMapping = () => {
  const mappings = useDocumentStatusMappings();

  return React.useCallback(
    (state: DocumentState) => {
      if (state) {
        const found = mappings.find((item) => item.state === state);
        if (found) {
          return found;
        }
      }
      return mappings[0];
    },
    [mappings],
  );
};

/**
 * useDocumentAppendInvoiceData()
 */
export const useDocumentAppendInvoiceData = () => {
  const { customers } = useAppSelector((state) => state.client);
  const dispatchGetInvoiceInformation = useDispatchDocumentGetInvoiceInformation();

  return React.useCallback(
    (document: DocumentType, client: Client): Promise<DocumentType> => {
      const customer = customers.find((item) => item.customerId === client.customerId);

      if (customer && customer.subscriptionActive && customer.subscriptionType !== SubscriptionType.smallBusiness) {
        return dispatchGetInvoiceInformation(document, client).then((invoiceData: any) => {
          if (invoiceData && invoiceData.creditor) {
            if (!invoiceData.incoming) {
              return Promise.resolve({
                ...document,
                type: InvoiceType.outgoing,
                invoiceNumber: invoiceData.invoiceNumber || '',
                invoiceDate: invoiceData.invoiceDate || '',
              });
            }
            if (invoiceData.incoming) {
              return Promise.resolve({
                ...document,
                type: InvoiceType.incoming,
                creditor: invoiceData.creditor || '',
                invoiceNumber: invoiceData.invoiceNumber || '',
                invoiceDate: invoiceData.invoiceDate || '',
              });
            }
            return Promise.resolve(document);
          } else {
            return Promise.resolve(document);
          }
        });
      }
      return Promise.resolve(document);
    },
    [customers, dispatchGetInvoiceInformation],
  );
};
