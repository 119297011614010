import React from 'react';
import ClientCreate, { ClientCreateEndpoint } from './Endpoints/ClientCreate';
import Salutation, { SalutationType } from './Types/Salutation';
import Language, { LanguageType } from './Types/Language';
import MenuItem from './Components/MenuItem';
import classNames from 'classnames';
import Client, { ClientType } from './Types/Client';
import ApiHeadline from './Components/ApiHeadline';
import OAuthGet, { OAuthGetEndpoint } from './Endpoints/OAuthGet';
import ClientGetList, { ClientGetListEndpoint } from './Endpoints/ClientGetList';
import Document, { DocumentType } from './Types/Document';
import DocTypes, { DocumentTypeType } from './Types/DocumentType';
import DocumentState, { DocumentStateType } from './Types/DocumentState';
import DocumentGetOpen, { DocumentGetOpenEndpoint } from './Endpoints/DocumentGetOpen';

/**
 * ApiDocumentation()
 * @constructor
 */
export default function ApiDocumentation() {
  return (
    <div className={'flex flex-1 flex-row relative'}>
      <div className={'bg-gray-200 min-w-96'}>
        <div>
          <div className={'text-center font-bold text-3xl mt-6'}>SotoDocs API</div>
          <div className={'text-center text font-light'}>Documentation</div>
        </div>

        <div className={'mt-10 p-2'}>
          <div className={'bg-gray-300 uppercase -mx-2 p-3 mb-2 font-bold'}>Types</div>
          <MenuItem item={ClientType} />
          <MenuItem item={DocumentType} />
          <MenuItem item={DocumentStateType} />
          <MenuItem item={DocumentTypeType} />
          <MenuItem item={LanguageType} />
          <MenuItem item={SalutationType} />

          <div className={'bg-gray-300 uppercase -mx-2 p-3 mb-2 mt-2 font-bold'}>Endpoints</div>
          <MenuItem item={OAuthGetEndpoint} />
          <MenuItem item={ClientCreateEndpoint} />
          <MenuItem item={ClientGetListEndpoint} />
          <MenuItem item={DocumentGetOpenEndpoint} />
        </div>
      </div>
      <div className={'p-6 w-full'}>
        <ApiHeadline />

        <div className={classNames('text-4xl mb-6 font-bold text-purple-700')}>Types</div>
        <Client />
        <Document />
        <DocumentState />
        <DocTypes />
        <Language />
        <Salutation />

        <div className={classNames('text-4xl mb-6 mt-10 font-bold text-purple-700')}>Endpoints</div>
        <OAuthGet />
        <ClientCreate />
        <ClientGetList />
        <DocumentGetOpen />
      </div>
    </div>
  );
}
