import React from 'react';
import Table from '../../Table';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import Container from '../../Container/Container';
import Row from './Row';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SortableHeader from './SortableHeader';
import sort, { SortKey } from './SortHelper';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { STORAGE_DOCUMENT_LAST_SORT_DIRECTION, STORAGE_DOCUMENT_LAST_SORT_KEY } from '../../../Library/Types/Constants';

interface Props {
  documents: DocumentType[];
  onPress: (doc: DocumentType) => void;
  showClient: boolean;
  showFileName: boolean;
  showCreditor: boolean;
  withContainer: boolean;
  withoutTableHeader: boolean;
  emptyText?: string;
  containerHeadline?: string;
  loading: boolean;
  showActions: boolean;
  allowSelect: boolean;
  onSelectionChange?: (items: DocumentType[]) => void;
}

/**
 * ListView()
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const {
    documents,
    onPress,
    showClient,
    showFileName,
    showActions,
    withContainer,
    withoutTableHeader,
    emptyText,
    containerHeadline,
    loading,
    showCreditor,
    allowSelect,
    onSelectionChange,
  } = props;
  const [t] = useTranslation();
  const { clients } = useAppSelector((state) => state.auth);
  const [selectedDocuments, setSelectedDocuments] = React.useState<DocumentType[]>([]);
  const [sortedColumn, setSortedColumn] = React.useState<SortKey | null>(SortKey.invoiceNumber);
  const [sortDirection, setSortDirection] = React.useState<'ASC' | 'DESC'>('ASC');
  const [sortedDocs, setSortedDocs] = React.useState<DocumentType[]>([]);

  React.useEffect(() => {
    setSortedColumn((localStorage.getItem(STORAGE_DOCUMENT_LAST_SORT_KEY) as SortKey) || SortKey.invoiceDate);
    setSortDirection((localStorage.getItem(STORAGE_DOCUMENT_LAST_SORT_DIRECTION) as any) || 'ASC');
  }, []);

  React.useEffect(() => {
    if (sortedColumn) {
      setSortedDocs(sort(documents, sortedColumn, sortDirection, clients));
    } else {
      setSortedDocs(documents);
    }
  }, [clients, documents, sortDirection, sortedColumn]);

  React.useEffect(() => {
    if (!allowSelect) {
      setSelectedDocuments([]);
    }
  }, [allowSelect]);

  React.useEffect(() => {
    if (!allowSelect) {
      setSelectedDocuments([]);
    }
  }, [allowSelect]);

  React.useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedDocuments);
    }
  }, [onSelectionChange, selectedDocuments]);

  const handleSetSortColumn = (value: SortKey) => {
    localStorage.setItem(STORAGE_DOCUMENT_LAST_SORT_KEY, value);

    if (value === sortedColumn) {
      if (sortDirection === 'ASC') {
        setSortDirection('DESC');
        localStorage.setItem(STORAGE_DOCUMENT_LAST_SORT_DIRECTION, 'DESC');
      } else if (sortDirection === 'DESC') {
        setSortDirection('ASC');
        localStorage.removeItem(STORAGE_DOCUMENT_LAST_SORT_DIRECTION);
        localStorage.removeItem(STORAGE_DOCUMENT_LAST_SORT_KEY);
        setSortedColumn(null);
      } else {
        setSortDirection('ASC');
        localStorage.setItem(STORAGE_DOCUMENT_LAST_SORT_DIRECTION, 'ASC');
      }
    } else {
      setSortedColumn(value);
      setSortDirection('ASC');
      localStorage.setItem(STORAGE_DOCUMENT_LAST_SORT_DIRECTION, 'ASC');
    }
  };

  const renderDocs = sortedDocs.map((doc: DocumentType) => (
    <Row
      document={doc}
      key={doc.documentId}
      onPress={onPress}
      showClient={showClient}
      showActions={showActions}
      showFileName={showFileName}
      showCreditor={showCreditor}
      selectActive={allowSelect}
      onSelect={(item) => setSelectedDocuments([...selectedDocuments, item])}
      onDeselect={(item) =>
        setSelectedDocuments(selectedDocuments.filter((param) => param.documentId !== item.documentId))
      }
      selected={!!selectedDocuments.find((param) => param.documentId === doc.documentId)}
    />
  ));

  const renderActionButton = () => {
    if (showActions) {
      return <th style={{ width: 20 }} />;
    }
    return null;
  };

  const renderSelect = () => {
    if (allowSelect) {
      return (
        <i
          className={classNames(
            { 'far fa-square text-black': selectedDocuments.length !== documents.length },
            { 'far fa-square-check text-black': selectedDocuments.length === documents.length },
            'cursor-pointer text-2xl',
          )}
          onClick={() => setSelectedDocuments(selectedDocuments.length === documents.length ? [] : documents)}
        />
      );
    }
    return null;
  };

  const renderClientHeader = () => {
    if (showClient) {
      return (
        <SortableHeader
          caption={t('client')}
          onPress={() => handleSetSortColumn(SortKey.client)}
          active={sortedColumn === SortKey.client}
          sortDirection={sortDirection}
        />
      );
    }
    return null;
  };

  const renderFileName = () =>
    showFileName ? (
      <SortableHeader
        caption={t('name')}
        onPress={() => handleSetSortColumn(SortKey.fileName)}
        active={sortedColumn === SortKey.fileName}
        sortDirection={sortDirection}
      />
    ) : null;

  const renderCreditor = () =>
    showCreditor ? (
      <SortableHeader
        caption={t('creditor')}
        onPress={() => handleSetSortColumn(SortKey.creditor)}
        active={sortedColumn === SortKey.creditor}
        sortDirection={sortDirection}
      />
    ) : null;

  const renderTableHeader = () => {
    if (!withoutTableHeader) {
      return (
        <thead>
          <tr>
            <th>{renderSelect()}</th>
            <th></th>
            {renderFileName()}
            {renderClientHeader()}
            {renderCreditor()}
            <SortableHeader
              caption={t('invoiceNumber')}
              onPress={() => handleSetSortColumn(SortKey.invoiceNumber)}
              active={sortedColumn === SortKey.invoiceNumber}
              sortDirection={sortDirection}
            />
            <SortableHeader
              caption={t('invoiceDate')}
              onPress={() => handleSetSortColumn(SortKey.invoiceDate)}
              active={sortedColumn === SortKey.invoiceDate}
              sortDirection={sortDirection}
            />
            {renderActionButton()}
          </tr>
        </thead>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className={'text-gray-500 text-center p-10'}>
          <i className={'fas fa-spin fa-spinner text-2xl'} />
        </div>
      );
    }

    if (!sortedDocs || sortedDocs.length === 0) {
      return (
        <div className={'text-center text-gray-500 p-10'}>{emptyText || t('modules.documents.noOpenDocuments')}</div>
      );
    }
    return (
      <Table showVerticalBorder={true}>
        {renderTableHeader()}
        <tbody>{renderDocs}</tbody>
      </Table>
    );
  };

  if (withContainer) {
    return (
      <Container noPadding headline={containerHeadline}>
        {renderContent()}
      </Container>
    );
  }
  return renderContent();
}

ListView.defaultProps = {
  withContainer: true,
  showFileName: true,
  withoutTableHeader: false,
  loading: false,
  showActions: false,
  showCreditor: false,
  allowSelect: false,
};
