import React from 'react';
import classNames from 'classnames';

interface Props {
  caption: string;
  onPress: () => void;
  active: boolean;
  sortDirection?: 'ASC' | 'DESC';
}

/**
 * SortableHeader()
 * @param props
 * @constructor
 */
export default function SortableHeader(props: Props) {
  const { caption, onPress, active, sortDirection } = props;

  return (
    <th>
      <div className={'flex flex-1 flex-row cursor-pointer'} onClick={onPress}>
        <div className={'flex-grow'}>{caption}</div>
        <div>
          <i
            className={classNames(
              { 'fa-light fa-arrow-up-arrow-down': !active },
              { 'fas fa-arrow-down-a-z': active && (!sortDirection || sortDirection === 'ASC') },
              { 'fas fa-arrow-up-z-a': active && sortDirection && sortDirection === 'DESC' },
              { 'text-gray-400': !active },
              { 'text-purple-700': active },
            )}
          />
        </div>
      </div>
    </th>
  );
}
