import React from 'react';
import Headline from '../../Components/Headline';
import Input, { InputType } from '../../Components/Forms/Input';
import Button from '../../Components/Forms/Button';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../../Library/Firebase';
import { Customer } from '../../Library/Types/CustomerTypes';
import { useDispatchUserRegisterInternal } from '../../Redux/Actions/UserActions';
import AuthenticationError from '../../Components/AuthenticationError';
import validateRegisterInternalUser, {
  ValidateRegisterUserErrors,
} from '../../Library/Validators/RegisterInternalUser';
import { LOCALSTORAGE_PATH_CUSTOMER_LOGO } from '../../Library/Types/GeneralTypes';
import RegisterAndLoginFooter from '../../Components/RegisterAndLoginFooter';
import classNames from 'classnames';
import Index from '../../Components/Website/Navigation';
const sotoLogo = require('../../Assets/Images/icon2.png');

const Box = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  ${(props) => props.theme.templates.borderRadius};
  ${(props) => props.theme.templates.border};
`;

const LeftContainer = styled.div`
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #d1d5db;

  @media (max-width: 768px) {
    width: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1.5;
  padding: 30px 40px;
`;

/**
 * RegisterUser()
 * @constructor
 */
export default function RegisterUser() {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState<Customer>();
  const [mail, setMail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [authErrorCode, setAuthErrorCode] = React.useState<string | null>(null);
  const [validateResult, setValidateResult] = React.useState<ValidateRegisterUserErrors | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [registrationActive, setRegistrationActive] = React.useState<boolean>(false);

  const dispatchUserInternalCreate = useDispatchUserRegisterInternal();

  const handleCreate = React.useCallback(() => {
    setValidateResult(null);

    if (customer) {
      const validation = validateRegisterInternalUser(firstName, lastName, mail, password, passwordRepeat, customer);

      if (customerId && !validation.hasErrors) {
        setLoading(true);
        dispatchUserInternalCreate(firstName, lastName, mail, password, customerId)
          .then(() => {
            setLoading(false);
            navigate('/dashboard');
          })
          .catch((error) => {
            setLoading(false);
            setAuthErrorCode(error.code);
          });
      } else {
        setValidateResult(validation);
        setAuthErrorCode(validation.errorCode);
      }
    }
  }, [customer, customerId, dispatchUserInternalCreate, firstName, lastName, mail, navigate, password, passwordRepeat]);

  React.useEffect(() => {
    const customerGet = httpsCallable<any, Customer>(getFunctions(), FirebaseFunctions.customerGet);
    customerGet({ customerId })
      .then((response) => {
        setCustomer(response.data);
        setRegistrationActive(response.data.settings.register.isActive);

        if (response.data.logo) {
          localStorage.setItem(LOCALSTORAGE_PATH_CUSTOMER_LOGO, response.data.logo);
        }
      })
      .catch(() => {
        navigate('/login');
      });
  }, [customerId, navigate]);

  React.useEffect(() => {
    if (!registrationActive && !loading) {
      setAuthErrorCode('registration-inactive');
    } else {
      setAuthErrorCode('');
    }
  }, [loading, registrationActive]);

  if (!isLoggedIn) {
    return (
      <div className={classNames('flex flex-1 flex-col')}>
        <Index showPrice={false} showStartPage={false} showRegister={false} showLogin={false} />

        <div className={'flex flex-1 flex-row'}>
          <div className={'basis-0 sm:basis-0 md:basis-1/6 lg:basis-1/6 xl:basis-1/6 2xl:basis-3/12'} />
          <div className={'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-6/12 p-4'}>
            <Box className={'shadow-2xl bg-white mt-10'}>
              <LeftContainer className={'hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex sm:w-0 md:w-0'}>
                <div className={'text-center mt-6'}>
                  <div className={'font-bold text-2xl'}>{customer ? customer.displayName || customer.name : ''}</div>
                </div>
                <div className={'flex flex-grow items-center text-center text-5xl font-bold text-gray-400 p-10'}>
                  <img
                    src={customer && customer.logo ? customer.logo : sotoLogo}
                    alt={'Logo'}
                    style={{ maxHeight: 300 }}
                  />
                </div>
              </LeftContainer>
              <RightContainer>
                <Headline size={'large'} className={'mb-4'}>
                  {t('registerUser')}
                </Headline>

                <AuthenticationError errorCode={authErrorCode} />

                <div className={'flex flex-row flex-grow'}>
                  <div className={'mr-2 flex-1'}>
                    <Input
                      onChange={setFirstName}
                      value={firstName}
                      label={t('firstName')}
                      disabled={loading || !registrationActive}
                      autoFocus
                      hasError={!!validateResult && validateResult.firstName}
                    />
                  </div>
                  <div className={'ml-2 flex-1 '}>
                    <Input
                      onChange={setLastName}
                      value={lastName}
                      label={t('lastName')}
                      disabled={loading || !registrationActive}
                      hasError={!!validateResult && validateResult.lastName}
                    />
                  </div>
                </div>

                <Input
                  onChange={setMail}
                  value={mail}
                  label={t('eMailAddress')}
                  disabled={loading || !registrationActive}
                  hasError={!!validateResult && validateResult.mail}
                  type={InputType.email}
                />
                <Input
                  onChange={setPassword}
                  value={password}
                  label={t('password')}
                  type={InputType.password}
                  disabled={loading || !registrationActive}
                  hasError={!!validateResult && validateResult.password}
                />
                <Input
                  onChange={setPasswordRepeat}
                  value={passwordRepeat}
                  label={t('passwordRepeat')}
                  type={InputType.password}
                  disabled={loading || !registrationActive}
                  hasError={!!validateResult && validateResult.passwordRepeat}
                />
                <div className={'flex flex-1'}>
                  <Button
                    className={'h-12 justify-center flex-1'}
                    onPress={handleCreate}
                    disabled={loading || !registrationActive}
                  >
                    {loading ? <i className={'fas fa-spin fa-spinner'} /> : t('registerUser')}
                  </Button>
                </div>
              </RightContainer>
            </Box>

            <RegisterAndLoginFooter />
          </div>
          <div className={'basis-0 sm:basis-0 md:basis-1/6 lg:basis-1/6 xl:basis-1/6 2xl:basis-3/12'} />
        </div>
      </div>
    );
  }
  return <Navigate to={'/dashboard'} replace />;
}
