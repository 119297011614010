import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Headline()
 * @constructor
 */
export default function Headline() {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);

  const getHeadline = () => {
    const hour = parseInt(moment().format('H'));

    if (hour > 0 && hour < 13) {
      return t('dashboardHeadline.morning', { name: user.firstName });
    } else if (hour >= 13 && hour < 18) {
      return t('dashboardHeadline.noon', { name: user.firstName });
    } else {
      return t('dashboardHeadline.evening', { name: user.firstName });
    }
  };

  return (
    <div className={'flex flex-1 flex-col mb-10'}>
      <div className={'text-4xl font-light'}>{getHeadline()}</div>
      <div className={'text-gray-600 text font-light'}>
        {t('dashboardHeadline.date', { date: moment().format('LLLL') })}
      </div>
    </div>
  );
}
