import { Customer } from '../Types/CustomerTypes';

export interface ValidateRegisterUserErrors {
  hasErrors: boolean;
  firstName: boolean;
  lastName: boolean;
  mail: boolean;
  password: boolean;
  passwordRepeat: boolean;
  errorCode: string;
}

const validateRegisterInternalUser = (
  firstName: string,
  lastName: string,
  mail: string,
  password: string,
  passwordRepeat: string,
  customer: Customer,
): ValidateRegisterUserErrors => {
  let response: ValidateRegisterUserErrors = {
    hasErrors: false,
    firstName: false,
    lastName: false,
    mail: false,
    password: false,
    passwordRepeat: false,
    errorCode: '',
  };

  if (!lastName || lastName.length < 2) {
    response = { ...response, hasErrors: true, lastName: true };
  }

  if (!firstName || firstName.length < 2) {
    response = { ...response, hasErrors: true, firstName: true };
  }

  if (!mail || mail.indexOf('@') < 0 || mail.indexOf('.') < 0) {
    response = { ...response, hasErrors: true, mail: true };
  } else {
    if (customer.settings.register.allowedDomains && customer.settings.register.allowedDomains.length > 0) {
      const domain = mail.split('@').pop();
      if (domain) {
        if (customer.settings.register.allowedDomains.indexOf(domain.toLowerCase()) < 0) {
          response = { ...response, hasErrors: true, mail: true, errorCode: 'domain-invalid' };
        }
      }
    }
  }

  if (!password) {
    response = { ...response, hasErrors: true, password: true };
  }

  if (!passwordRepeat) {
    response = { ...response, hasErrors: true, passwordRepeat: true };
  }

  if (passwordRepeat !== password) {
    response = { ...response, hasErrors: true, passwordRepeat: true, password: true };
  }

  return response;
};

export default validateRegisterInternalUser;
