import React from 'react';
import { Client } from '../../../Library/Types/ClientTypes';
import Table from '../../Table';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';
import { Salutation } from '../../../Library/Types/GeneralTypes';
import Button from '../../Forms/Button';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import Row from './Row';

interface Props {
  clients: Client[];
  onPress: (client: Client) => void;
}

/**
 * ClientTable()
 * @param props
 * @constructor
 */
export default function ClientTable(props: Props) {
  const { clients, onPress } = props;
  const { customer } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sorted, setSorted] = React.useState<Client[]>();

  React.useEffect(() => {
    const data = [...clients];
    const sortedValue = data.sort((a, b) => {
      const aName = (a.salutation === Salutation.company ? a.companyName : a.lastName).toLowerCase().trim();
      const bName = (b.salutation === Salutation.company ? b.companyName : b.lastName).toLowerCase().trim();

      return aName.localeCompare(bName);
    });
    setSorted(sortedValue);
  }, [clients]);

  const renderRows = () => {
    if (sorted && sorted.length > 0) {
      return sorted.map((client: Client) => <Row client={client} onPress={onPress} key={client.clientId} />);
    }
    return null;
  };

  const renderGroupHeader = () => {
    if (customer.settings.client.groups && customer.settings.client.groups.length > 1) {
      return <th>{t('group')}</th>;
    }
    return null;
  };

  if (clients && clients.length > 0) {
    return (
      <Container noPadding>
        <Table showVerticalBorder>
          <thead>
            <tr>
              <th style={{ width: 50 }} />
              <th>{t('name')}</th>
              <th>{t('taxNumber')}</th>
              {renderGroupHeader()}
              <th style={{ width: 50 }}></th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </Table>
      </Container>
    );
  }
  return (
    <div className={'flex flex-1 flex-col items-center'}>
      <div
        className={'text-gray-400 rounded text-center p-6 mb-6'}
        dangerouslySetInnerHTML={{ __html: t('modules.client.noClientsHint').replace('\n', '<br/>') }}
      />

      <Button onPress={() => navigate('/client/create')} colorStyle={'primary'} icon={'fas fa-plus'}>
        {t('clientCreate')}
      </Button>
    </div>
  );
}
