import { DocumentType } from '../Types/DocumentTypes';
import moment from 'moment';

/**
 * Download base64 PDF
 * @param base64
 * @param fileName
 */
export const downloadBase64Pdf = (base64: string, fileName: string): void => {
  let linkSource = base64;

  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.target = '_blank';
  downloadLink.download = fileName;
  downloadLink.click();
};

/**
 * openDocument
 * @param item
 */
export const openDocument = (item: DocumentType) => {
  const a = document.createElement('a');
  a.href = item.downloadUrl;
  a.target = '_blank';
  a.click();
};

/**
 * isValidEmail()
 * @param email
 * @returns {boolean}
 */
export function isValidateEmail(email: string): boolean {
  const re =
    // eslint-disable-next-line max-len, no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    return !(
      email.indexOf('à') > -1 ||
      email.indexOf('è') > -1 ||
      email.indexOf('ù') > -1 ||
      email.indexOf('ò') > -1 ||
      email.indexOf('ì') > -1 ||
      email.toLowerCase().indexOf('ü') > -1 ||
      email.toLowerCase().indexOf('ä') > -1 ||
      email.toLowerCase().indexOf('ö') > -1 ||
      email.toLowerCase().indexOf('ß') > -1
    );
  }
  return false;
}

/**
 * isValidateUrl()
 * @param url
 * @returns {boolean}
 */
export function isValidateUrl(url: string): boolean {
  const re =
    // eslint-disable-next-line no-useless-escape
    /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/;
  return re.test(String(url).toLowerCase());
}

/**
 * isValidPhoneNumber
 * @param number
 * @returns {*}
 */
export const isValidPhoneNumber = (number: string): boolean => {
  const phoneNo = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
  const matcher = number.match(phoneNo) && number.match(phoneNo);

  if (matcher) {
    return matcher.length > 0;
  }
  return false;
};

/**
 * generateRandomString
 * @param length
 */
export const generateRandomString = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

/**
 * stripePriceToPrice()
 * @param price
 */
export const stripePriceToPrice = (price: string): string => {
  const back = price.substring(price.length - 2);
  const front = price.substring(0, price.length - 2);

  return `${front},${back}€`;
};

/**
 * displayIban()
 * @param iban
 */
export const displayIban = (iban: string): string => {
  return `...${iban.substring(iban.length - 4)}`;
};

/**
 * secondsToMinuteString()
 * @param seconds
 */
export const secondsToMinuteString = (seconds: number): string => {
  if (seconds > 0) {
    const minutes = Math.floor(seconds / 60);
    const extraSeconds = Math.round(seconds % 60);

    return `${minutes}:${extraSeconds < 10 ? `0${extraSeconds}` : extraSeconds}`;
  }
  return '0:00';
};

/**
 * formatSize()
 * @param bytes
 * @param fractionDigits
 */
export const formatSize = (bytes: number, fractionDigits: number = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(fractionDigits)) + ' ' + sizes[i];
};

/**
 * shortenFileName()
 * @param fileName
 * @param maxLength
 */
export const shortenFileName = (fileName: string, maxLength: number): string => {
  const { name } = splitFileToNameAndExtension(fileName);

  if (name.length <= maxLength) {
    return name;
  } else {
    if (name.indexOf('.') > 0) {
      const split = name.split('.');
      const prefixLength = split[1].length;

      const short = split[0].substring(0, maxLength - prefixLength - 3);
      return `${short}...${split[1]}`;
    }
    return `${name.substring(0, maxLength - 3)}...`;
  }
};

/**
 * isValidIban()
 * @param iban
 */
export const isValidIban = (iban: string): boolean => {
  const regex =
    // eslint-disable-next-line max-len
    /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i;
  const match1 = iban.match(regex);
  return match1 !== null;
};

/**
 * splitFileToNameAndExtension()
 * @param filePath
 */
export const splitFileToNameAndExtension = (filePath: string): { name: string; extension: string } => {
  if (filePath.indexOf('.') >= 0) {
    const index = filePath.lastIndexOf('.');
    const name = filePath.substring(0, index);
    const extension = filePath.substring(index + 1);

    return { name, extension };
  }
  return { name: filePath, extension: '' };
};

/**
 * generates a filename for a document
 * @param clientId
 */
export const generateDocumentIdentifier = (clientId: string): string => {
  const random = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
  return `${moment().format('YYYYMMDDHHmmss')}_${clientId}_${random}`;
};

/**
 * isValidDateString()
 * @param value
 */
export const isValidDateString = (value: string): { result: boolean; format?: string } => {
  const regex1 = /^(0[1-9]|[12][0-9]|3[01])(\/)(0[1-9]|1[1,2])(\/)(19|20)\d{2}/gm;
  const regex2 = /^(0[1-9]|[12][0-9]|3[01])(-)(0[1-9]|1[1,2])(-)(19|20)\d{2}/gm;
  const regex3 = /^(0[1-9]|[12][0-9]|3[01])(.)(0[1-9]|1[1,2])(.)(19|20)\d{2}/gm;
  const regex4 = /^(0[1-9]|[12][0-9]|3[01])(.)(0[1-9]|1[1,2])(.)\d{2}$/gm;

  const match1 = value.match(regex1);
  if (match1 !== null) {
    return { result: true, format: 'DD/MM/YYYY' };
  }

  const match2 = value.match(regex2);
  if (match2 !== null) {
    return { result: true, format: 'DD-MM-YYYY' };
  }

  const match3 = value.match(regex3);
  if (match3 !== null) {
    return { result: true, format: 'DD.MM.YYYY' };
  }

  const match4 = value.match(regex4);
  if (match4 !== null) {
    return { result: true, format: 'DD.MM.YY' };
  }

  return { result: false };
};

/**
 * isValidDate()
 * @param value
 */
export const isValidDate = (value: string): boolean => {
  if (value && value.length > 0) {
    try {
      moment(value).format('LL');
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

/**
 * buildTooltipClass()
 * Class names must start with an alpha numerical letter!
 * @param identifier
 */
export const buildTooltipClass = (identifier: string): string => {
  return `A${identifier}`;
};
