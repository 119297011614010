import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
const image = require('../../Assets/Images/conference.jpeg');

/**
 * Presentation()
 * @constructor
 */
export default function Presentation() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={classNames('flex flex-1 flex-col items-center p-8 bg-white pb-20', 'lg:pt-20')}>
      <div className={classNames('flex flex-1 text-3xl text-center', 'lg:text-5xl')}>
        {t('modules.website.presentation.headline')}
      </div>
      <div
        className={classNames('flex flex-1 text-2xl text-center font-light mt-4 px-0', 'md:px-32 lg:px-80 lg:mt-10')}
      >
        {t('modules.website.presentation.text')}
      </div>

      <div className={classNames('py-20')}>
        <img alt={'Conference'} src={image} className={classNames('max-w-80', 'lg:w-96')} />
      </div>

      <div
        className={classNames(
          'px-8 py-4 bg-purple-700 text-white rounded-lg font-bold text-xl cursor-pointer',
          'hover:bg-purple-900 hover:drop-shadow-2xl text-center',
        )}
        onClick={() => navigate('/contact')}
      >
        {t('modules.website.presentation.button')}
      </div>
    </div>
  );
}
