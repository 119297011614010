import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${(props) => props.theme.templates.borderRadius};
  cursor: pointer;
  color: #4d5562;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:hover {
    background-color: #e2e4e7;
    color: #000000;

    > i {
      color: #000000;
    }
  }
`;

interface Props {
  icon: string;
  onPress: () => void;
  className?: string;
  visible: boolean;
}

/**
 * TopNavbarItem()
 * @constructor
 */
export default function TopNavbarItem(props: Props) {
  const { onPress, icon, className, visible } = props;

  if (visible) {
    return (
      <Container onClick={onPress} className={className}>
        <i className={icon} />
      </Container>
    );
  }
  return null;
}

TopNavbarItem.defaultProps = {
  visible: true,
};
