import {
  AUTH_LOGIN_AUTO,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  CLIENT_CREATE_SUCCESS,
  CLIENT_DELETE_SUCCESS,
  CLIENT_GETLIST_BY_IDS_SUCCESS,
  CLIENT_GETLIST_SUCCESS,
  CLIENT_SET_ACTIVE,
  CLIENT_UPDATE_SUCCESS,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_UPDATE_SUCCESS,
  USER_GETLIST_SUCCESS,
  USER_UPDATE_SUCCESS,
} from '../ActionTypes';
import { Client } from '../../Library/Types/ClientTypes';
import { User } from '../../Library/Types/UserTypes';
import { createReducer } from '@reduxjs/toolkit';
import { Customer } from '../../Library/Types/CustomerTypes';

export interface AuthReducerInterface {
  customer: Customer;
  clients: Client[];
  activeClient: Client | null;
  user: User; // Current authed user
  users: User[]; // List of all customer users
  assignedClientIds: string[];
  isLoggedIn: boolean;
}

const defaultValues: AuthReducerInterface = {
  customer: {} as Customer,
  clients: [],
  activeClient: null, // Only used by clients not by customers
  user: {} as User,
  users: [],
  assignedClientIds: [],
  isLoggedIn: false,
};

export default createReducer(defaultValues, (builder) => {
  builder.addCase(AUTH_LOGIN_SUCCESS, (state, action) => {
    state.isLoggedIn = true;
    state.user = action.payload.user;
  });
  builder.addCase(CUSTOMER_REGISTER_SUCCESS, (state, action) => {
    state.customer = action.payload.customer;
    state.user = action.payload.user;
    state.isLoggedIn = true;
  });
  builder.addCase(AUTH_LOGIN_AUTO, (state, action) => {
    state.isLoggedIn = true;
    state.user = action.payload.user;
  });
  builder.addCase(CUSTOMER_GET_SUCCESS, (state, action) => {
    state.customer = action.payload;
  });
  builder.addCase(CLIENT_SET_ACTIVE, (state, action) => {
    state.activeClient = action.payload;
  });
  builder.addCase(CLIENT_CREATE_SUCCESS, (state, action) => {
    const clients = [...state.clients, action.payload];
    state.clients = clients;
    state.assignedClientIds = clients
      .filter((item) => item.assignedUserId === state.user.userId)
      .map((item) => item.clientId);
  });
  builder.addCase(CLIENT_UPDATE_SUCCESS, (state, action) => {
    state.clients = state.clients.map((client) =>
      client.clientId === action.payload.clientId ? action.payload : client,
    );
  });
  builder.addCase(CLIENT_DELETE_SUCCESS, (state, action) => {
    state.clients = state.clients.filter((item) => item.clientId !== action.payload.clientId);
  });
  builder.addCase(CLIENT_GETLIST_SUCCESS, (state, action) => {
    state.clients = action.payload;
    state.assignedClientIds = action.payload
      .filter((item) => item.assignedUserId === state.user.userId)
      .map((item) => item.clientId);
  });
  builder.addCase(CLIENT_GETLIST_BY_IDS_SUCCESS, (state, action) => {
    state.clients = action.payload;
    state.assignedClientIds = action.payload.map((item) => item.clientId);
    state.activeClient = !state.activeClient
      ? !state.user.isCustomerUser
        ? action.payload[0]
        : null
      : state.activeClient;
  });
  builder.addCase(USER_GETLIST_SUCCESS, (state, action) => {
    state.users = action.payload;
  });
  builder.addCase(USER_UPDATE_SUCCESS, (state, action) => {
    state.users = state.users.map((user) => (user.userId === action.payload.userId ? action.payload : user));
    state.user = action.payload.userId === state.user.userId ? action.payload : state.user;
  });
  builder.addCase(CUSTOMER_UPDATE_SUCCESS, (state, action) => {
    state.customer = action.payload;
  });
  builder.addCase(AUTH_LOGOUT, (state) => {
    state.isLoggedIn = false;
    state.users = [];
    state.clients = [];
    state.user = {} as User;
    state.customer = {} as Customer;
    state.assignedClientIds = [];
    state.activeClient = null;
  });
});
