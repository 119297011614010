import React from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS, isAndroid, isBrowser } from 'react-device-detect';
import ShowHideContainer from '../ShowHideContainer';
import Button from '../Forms/Button';
import { useNavigate } from 'react-router';

const app = require('../../Assets/Images/app_login.png');
const apple = require('../../Assets/Images/app_store.png');
const google = require('../../Assets/Images/play_store.png');

/**
 * UseAppHint()
 * @constructor
 */
export default function UseAppHint() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={'flex flex-1 flex-col bg-white items-center justify-center p-8'}>
      <div>
        <Button onPress={() => navigate('/')} colorStyle={'purple'}>
          <div className={'p-3'}>{t('modules.website.backToWebsite')}</div>
        </Button>
      </div>

      <div className={'text-purple-700 font-bold text-center mt-10 text-4xl mb-10'}>{t('useAppHintHeader')}</div>
      <img src={app} alt={'App'} className={'px-10 mb-10'} />
      <div className={'text-black text-xl text-justify'}>{t('useAppHintDescription')}</div>

      <div className={'flex flex-col items-center mt-8 lg:flex-row'}>
        <ShowHideContainer visible={isIOS || isBrowser}>
          <a href={'https://apps.apple.com/de/app/sotodocs/id6503434976'} target={'_blank'} rel="noreferrer">
            <img src={apple} alt="Apple" className={'w-48 lg:mr-3 mb-4'} />
          </a>
        </ShowHideContainer>
        <ShowHideContainer visible={isAndroid || isBrowser}>
          <a href={'https://play.google.com/store/apps/details?id=com.sotodocs'} target={'_blank'} rel="noreferrer">
            <img src={google} alt="Google" className={'w-48 lg:ml-3 mb-4'} />
          </a>
        </ShowHideContainer>
      </div>
    </div>
  );
}
