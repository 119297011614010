import React from 'react';
import { User } from '../../../../../Library/Types/UserTypes';
import Container from '../../../../Container/Container';
import MandantAccess from './MandantAccess';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
}

/**
 * AccessControl()
 * @param props
 * @constructor
 */
export default function AccessControl(props: Props) {
  const { user } = props;
  const { t } = useTranslation();

  if (user.isCustomerUser && !user.isCustomerAdmin) {
    return (
      <Container headline={t('userAccessControl')} closable initialOpen={false}>
        <MandantAccess user={user} />
      </Container>
    );
  }
  return null;
}
