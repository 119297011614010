import React from 'react';
import { Document, Page } from 'react-pdf';
import { TextContent } from 'pdfjs-dist/types/src/display/api';
import { pdfPrepareTexts } from '../../Library/Functions/PdfFunctions';
import Container from '../Container/Container';
import styled from 'styled-components';
import { DocumentType } from '../../Library/Types/DocumentTypes';
import { useDispatchDocumentDownloadBase64 } from '../../Redux/Actions/DocumentActions';
import classNames from 'classnames';
import ActionButton from '../Container/ActionButton';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { downloadBase64Pdf } from '../../Library/Functions/HelperFunctions';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useCopyClipboard } from '../../Library/Hooks/GlobalHooks';
import PdfContextMenu from './PdfContextMenu';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { STORAGE_PDF_COPY_HELPER_ACTIVE } from '../../Library/Types/Constants';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 30px;
`;

interface Props {
  document: DocumentType;
  onTexts: (texts: string[]) => void;
  width: number;
  onStartLoading: () => void;
  onFinishedLoading: () => void;
  onFullScreen: (full: boolean) => void;
  highlightWords?: string[];
  onSetCreditor: (value: string) => void;
  onSetInvoiceNumber: (value: string) => void;
  onSetInvoiceDate: (value: string) => void;
}

/**
 * PdfPreview()
 * @param props
 * @constructor
 */
export default function PdfPreview(props: Props) {
  const {
    document,
    onTexts,
    width,
    onStartLoading,
    onFinishedLoading,
    onFullScreen,
    highlightWords,
    onSetCreditor,
    onSetInvoiceDate,
    onSetInvoiceNumber,
  } = props;
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [texts] = React.useState<TextContent>();
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [base64, setBase64] = React.useState<string>('');
  const [fullScreen, setFullScreen] = React.useState<boolean>(false);
  const [downloaded, setDownloaded] = React.useState<boolean>(false);
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();

  const [contextX, setContextX] = React.useState<number>(0);
  const [contextY, setContextY] = React.useState<number>(0);
  const [contextHeadline, setContextHeadline] = React.useState<string>('');
  const [showContextMenu, setShowContextMenu] = React.useState<boolean>(false);
  const [copyHelperActive, setCopyHelperActive] = React.useState<boolean>(true);

  const dispatchDownload = useDispatchDocumentDownloadBase64();

  const downloadFile = React.useCallback(() => {
    downloadBase64Pdf(base64, document.originalName);
  }, [base64, document.originalName]);

  React.useEffect(() => {
    const value = localStorage.getItem(STORAGE_PDF_COPY_HELPER_ACTIVE);
    setCopyHelperActive(!value || value === 'true');
  }, []);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!base64 && !downloaded && document && document.fileType.indexOf('pdf') >= 0) {
      onStartLoading();
      timer = setTimeout(() => {
        setDownloaded(true);

        dispatchDownload(document)
          .then((response) => {
            setBase64(response);
            onFinishedLoading();
            return Promise.resolve();
          })
          .catch(() => {
            setBase64('');
            return Promise.resolve();
          });
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [base64, dispatchDownload, document, downloaded, onFinishedLoading, onStartLoading]);

  React.useEffect(() => {
    if (texts && texts.items.length > 0) {
      onTexts(pdfPrepareTexts(texts));
    }
  }, [onTexts, texts]);

  const renderPager = () => {
    if (pageCount > 1) {
      return (
        <PageContainer>
          <i
            className={'fas fa-circle-arrow-left text-3xl cursor-pointer text-gray-400 hover:text-black'}
            onClick={currentPage > 1 ? () => setCurrentPage(currentPage - 1) : () => {}}
          />
          <div className={'ml-3 mr-3'}>{`${currentPage} / ${pageCount}`}</div>
          <i
            className={'fas fa-circle-arrow-right text-3xl cursor-pointer text-gray-400 hover:text-black'}
            onClick={currentPage < pageCount ? () => setCurrentPage(currentPage + 1) : () => {}}
          />
        </PageContainer>
      );
    }
    return null;
  };

  const onClick = (e: any) => {
    setShowContextMenu(false);
    if (e.target && e.target.className && e.target.className.indexOf('clickable') > -1 && e.target.innerHTML) {
      copyToClipBoard(e.target.innerHTML);
    }
  };

  const renderText = (textItem: any) => {
    if (highlightWords && highlightWords.length > 0) {
      let returnValue = textItem.str;
      let found = false;

      highlightWords.forEach((word) => {
        if (returnValue.indexOf(word) > -1) {
          const preparedClassNames = classNames({ 'clickable cursor-pointer hover:bg-amber-700': copyHelperActive });
          found = true;
          returnValue = returnValue.replace(
            word,
            // eslint-disable-next-line max-len
            `<div class="inline-block bg-amber-500 text-white ${preparedClassNames}" style="padding: 2px;">${word}</div>`,
          );
        }
      });

      if (!found) {
        if (copyHelperActive) {
          // eslint-disable-next-line max-len
          return `<div class="clickable hover:bg-gray-600 cursor-pointer hover:text-white" style="padding: 2px;">${returnValue}</div>`;
        } else {
          return returnValue;
        }
      } else {
        return returnValue;
      }
    }
    if (copyHelperActive) {
      // eslint-disable-next-line max-len
      return `<div class="clickable hover:bg-gray-600 cursor-pointer hover:text-white" style="padding: 2px;">${textItem.str}</div>`;
    } else {
      return textItem.str;
    }
  };

  const handleOnContextMenu = (e: any) => {
    setShowContextMenu(false);

    if (!user.isCustomerUser) {
      e.preventDefault();
      if (e.target && e.target.className && e.target.className.indexOf('clickable') > -1 && e.target.innerHTML) {
        setContextX(e.pageX);
        setContextY(e.pageY);
        setContextHeadline(e.target.innerHTML);
        setShowContextMenu(true);
      }
    }
  };

  const toggleCopyHelper = () => {
    localStorage.setItem(STORAGE_PDF_COPY_HELPER_ACTIVE, !copyHelperActive ? 'true' : 'false');
    setCopyHelperActive(!copyHelperActive);
  };

  if (base64 && document && document.fileType.indexOf('pdf') > -1) {
    return (
      <Container
        headline={t('document')}
        closable
        initialOpen={true}
        actionButtons={
          <>
            <ActionButton
              icon={classNames(
                { 'fas fa-clipboard-check': copyHelperActive },
                { 'far fa-clipboard': !copyHelperActive },
                'hover:text-white',
              )}
              className={classNames('hover:bg-blue-500 hover:text-white', 'autoCopyPaste mr-0', {
                'bg-green-600 text-white hover:bg-green-500': copyHelperActive,
              })}
              onPress={toggleCopyHelper}
            />
            <ActionButton
              icon={classNames('fas fa-cloud-download')}
              className={classNames('hover:bg-blue-500 hover:text-white mr-0')}
              onPress={downloadFile}
            />
            <ActionButton
              icon={classNames({ 'fat fa-maximize': !fullScreen }, { 'fat fa-minimize': fullScreen })}
              className={classNames('hover:bg-blue-500 hover:text-white mr-0', 'resizePdf')}
              onPress={() => {
                setFullScreen(!fullScreen);
                onFullScreen(!fullScreen);
              }}
            />
          </>
        }
      >
        <Document
          file={base64}
          onLoadSuccess={(pdf) => {
            setPageCount(pdf.numPages);
          }}
          onContextMenu={handleOnContextMenu}
        >
          <Page
            height={window.innerHeight - 60}
            key={`page_${currentPage}`}
            pageNumber={currentPage}
            width={width}
            renderTextLayer={true}
            renderAnnotationLayer={true}
            customTextRenderer={(data) => renderText(data)}
            className={'d-none'}
            onClick={onClick}
          />
        </Document>
        {renderPager()}
        {clipboardCallout}

        <PdfContextMenu
          visible={showContextMenu}
          onClose={() => setShowContextMenu(false)}
          x={contextX}
          y={contextY}
          text={contextHeadline}
          onSetCreditor={onSetCreditor}
          onSetInvoiceDate={onSetInvoiceDate}
          onSetInvoiceNumber={onSetInvoiceNumber}
        />

        <Tooltip anchorSelect={'.autoCopyPaste'} style={{ maxWidth: 400 }}>
          {t('pdfCopyHelperHint')}
        </Tooltip>
        <Tooltip place={'left'} anchorSelect={'.resizePdf'}>
          {fullScreen ? t('minimize') : t('maximize')}
        </Tooltip>
      </Container>
    );
  }
  return null;
}
