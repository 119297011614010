import React from 'react';
import moment from 'moment/moment';
import DatePicker from '../../Forms/DatePicker';
import Input from '../../Forms/Input';
import { useTranslation } from 'react-i18next';
import { InvoiceType } from '../../../Library/Types/DocumentTypes';
import ShowHideContainer from '../../ShowHideContainer';
import { DocumentUpdateActions, DocumentUpdateState } from '../DocumentUpdateReducer';

interface Props {
  dispatch: React.Dispatch<DocumentUpdateActions>;
  state: DocumentUpdateState;
  disabled: boolean;
  showErrors: boolean;
  loadingAi: boolean;
}

/**
 * InvoiceInformationForm()
 * @param props
 * @constructor
 */
export default function InvoiceInformationForm(props: Props) {
  const { dispatch, state, disabled, showErrors, loadingAi } = props;
  const { t } = useTranslation();

  if (state.data.type !== InvoiceType.bank) {
    return (
      <>
        <ShowHideContainer visible={state.data.type === InvoiceType.incoming}>
          <Input
            onChange={(payload) => dispatch({ type: 'creditor', payload })}
            value={state.data.creditor}
            label={t('creditor')}
            hasError={showErrors && !state.data.creditor}
            disabled={disabled}
            showCopy
            loading={loadingAi}
          />
        </ShowHideContainer>
        <Input
          onChange={(payload) => dispatch({ type: 'invoiceNumber', payload })}
          value={state.data.invoiceNumber}
          label={t('invoiceNumber')}
          hasError={showErrors && !state.data.invoiceNumber}
          disabled={disabled}
          showCopy
          loading={loadingAi}
        />
        <DatePicker
          onChange={(payload) => dispatch({ type: 'invoiceDate', payload })}
          label={t('invoiceDate')}
          initialValue={state.data.invoiceDate ? moment(state.data.invoiceDate).toDate() : undefined}
          hasError={showErrors && !state.data.invoiceDate}
          disabled={disabled}
          maxDate={moment().toDate()}
          showCopy
        />
      </>
    );
  }
  return null;
}
