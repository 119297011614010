import React from 'react';
import { Customer } from '../../Library/Types/CustomerTypes';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { useTranslation } from 'react-i18next';
import { NavbarContext } from '../Layout/AuthedLayout';
import { useCheckAdminAccess } from '../../Library/Hooks/AccessHooks';
import { useDispatchCustomerUpdate } from '../../Redux/Actions/CustomerActions';
import Container from '../Container/Container';
import InputRow from '../Forms/InputRow';
import validateUpdateCustomer, { ValidateUpdateCustomerErrors } from '../../Library/Validators/UpdateCustomer';
import { useLoadingModal } from '../../Library/Hooks/GlobalHooks';
import Button from '../Forms/Button';
import { useNavigate } from 'react-router';

export type State = {
  customer: Customer;
  errors: { [K in keyof Customer]?: string };
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: Customer }
  | { type: 'name'; payload: string }
  | { type: 'displayName'; payload: string }
  | { type: 'zip'; payload: string }
  | { type: 'city'; payload: string }
  | { type: 'street'; payload: string }
  | { type: 'mail'; payload: string }
  | { type: 'taxNumber'; payload: string }
  | { type: 'phone'; payload: string }
  | { type: 'web'; payload: string };

export const initialState: State = {
  customer: {} as Customer,
  errors: {},
  hasChanges: false,
  initialized: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'name':
      return { ...state, customer: { ...state.customer, name: action.payload }, hasChanges: true };
    case 'displayName':
      return { ...state, customer: { ...state.customer, displayName: action.payload }, hasChanges: true };
    case 'zip':
      return { ...state, customer: { ...state.customer, zip: action.payload }, hasChanges: true };
    case 'city':
      return { ...state, customer: { ...state.customer, city: action.payload }, hasChanges: true };
    case 'street':
      return { ...state, customer: { ...state.customer, street: action.payload }, hasChanges: true };
    case 'phone':
      return { ...state, customer: { ...state.customer, phone: action.payload }, hasChanges: true };
    case 'mail':
      return { ...state, customer: { ...state.customer, mail: action.payload }, hasChanges: true };
    case 'taxNumber':
      return { ...state, customer: { ...state.customer, taxNumber: action.payload }, hasChanges: true };
    case 'web':
      return { ...state, customer: { ...state.customer, web: action.payload }, hasChanges: true };
    case 'init':
      return { ...initialState, customer: action.payload, initialized: true, hasChanges: false };
    default:
      throw new Error('Invalid type!');
  }
};

/**
 * DetailsForm()
 * @constructor
 */
export default function DetailsForm() {
  const navigate = useNavigate();
  const { customer } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const navContext = React.useContext(NavbarContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { setLoading, isLoading } = useLoadingModal({ headline: t('updatingData') });
  const [errors, setErrors] = React.useState<ValidateUpdateCustomerErrors | null>(null);
  useCheckAdminAccess();

  const dispatchUpdate = useDispatchCustomerUpdate();

  const handleUpdate = React.useCallback(() => {
    setErrors(null);
    const validationResult = validateUpdateCustomer(state.customer);

    if (validationResult.isValid) {
      setLoading(true);
      dispatchUpdate(state.customer)
        .then((response) => dispatch({ type: 'init', payload: response }))
        .finally(() => {
          setLoading(false);
          navigate(-1);
        });
    } else {
      setErrors(validationResult);
    }
  }, [dispatchUpdate, navigate, setLoading, state.customer]);

  React.useEffect(() => {
    if (!state.initialized) {
      dispatch({ type: 'init', payload: customer });
    }
  }, [customer, state.initialized]);

  React.useEffect(() => {
    navContext.setHeadline(`${t('settings')} / ${customer.displayName || customer.name}`);
  }, [customer.displayName, customer.name, navContext, t]);

  return (
    <>
      <Container showLoading={isLoading}>
        <InputRow
          onChange={(payload) => dispatch({ type: 'name', payload })}
          value={state.customer.name}
          placeholder={''}
          label={t('name')}
          hasError={!!errors && !!errors.errors.name}
          disabled={isLoading}
        />
        <InputRow
          onChange={(payload) => dispatch({ type: 'displayName', payload })}
          value={state.customer.displayName}
          placeholder={''}
          label={t('displayName')}
          hasError={!!errors && !!errors.errors.displayName}
          disabled={isLoading}
        />

        <InputRow
          onChange={(payload) => dispatch({ type: 'street', payload })}
          value={state.customer.street}
          placeholder={t('streetAndNumber')}
          label={t('address')}
          disabled={isLoading}
        />
        <div className={'flex flex-row'}>
          <div className={'w-3/12'}></div>
          <div className={'flex flex-1 flex-row'}>
            <div className={'mr-4'}>
              <InputRow
                onChange={(payload) => dispatch({ type: 'zip', payload })}
                value={state.customer.zip}
                placeholder={t('zip')}
                disabled={isLoading}
              />
            </div>
            <div className={'flex-grow'}>
              <InputRow
                onChange={(payload) => dispatch({ type: 'city', payload })}
                value={state.customer.city}
                placeholder={t('city')}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <InputRow
          onChange={(payload) => dispatch({ type: 'phone', payload })}
          value={state.customer.phone}
          label={t('phone')}
          hasError={!!errors && !!errors.errors.phone}
          disabled={isLoading}
        />
        <InputRow
          onChange={(payload) => dispatch({ type: 'mail', payload })}
          value={state.customer.mail}
          label={t('mail')}
          hasError={!!errors && !!errors.errors.mail}
          disabled={isLoading}
        />
        <InputRow
          onChange={(payload) => dispatch({ type: 'web', payload })}
          value={state.customer.web}
          label={t('website')}
          hasError={!!errors && !!errors.errors.web}
          disabled={isLoading}
        />
        <InputRow
          onChange={(payload) => dispatch({ type: 'taxNumber', payload })}
          value={state.customer.taxNumber}
          label={t('taxNumber')}
          disabled={isLoading}
        />
      </Container>

      <Container>
        <div className={'flex justify-end'}>
          <Button onPress={() => navigate(-1)} colorStyle={'cancel'} disabled={isLoading}>
            {t('cancel')}
          </Button>
          <Button onPress={handleUpdate} colorStyle={'success'} disabled={isLoading}>
            <>
              {isLoading ? <i className={'fas fa-spin fa-spinner mr-3'} /> : null}
              {t('save')}
            </>
          </Button>
        </div>
      </Container>
    </>
  );
}
