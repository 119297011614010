import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isValidDateString } from '../../Library/Functions/HelperFunctions';
import moment from 'moment';

const Container = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: ${(props) => props.y + 20}px;
  left: ${(props) => props.x}px;
  z-index: 1000;
  overflow: hidden;
`;

interface Props {
  text: string;
  visible: boolean;
  x: number;
  y: number;
  onSetCreditor: (value: string) => void;
  onSetInvoiceNumber: (value: string) => void;
  onSetInvoiceDate: (value: string) => void;
  onClose: () => void;
}

const itemClassName = classNames(
  'px-4 py-3 text-black cursor-pointer',
  'hover:bg-purple-200',
  'border-b border-b-gray-300',
  'last:border-b-0',
);

/**
 * PdfContextMenu()
 * @param props
 * @constructor
 */
export default function PdfContextMenu(props: Props) {
  const { visible, x, y, text, onSetInvoiceNumber, onSetInvoiceDate, onSetCreditor, onClose } = props;
  const [isValidDate, setIsValidDate] = React.useState<boolean>(false);
  const [dateFormat, setDateFormat] = React.useState<string | undefined>('');
  const { t } = useTranslation();

  React.useEffect(() => {
    if (text) {
      const { result, format } = isValidDateString(text);
      setIsValidDate(result);
      setDateFormat(format);
    }
  }, [text]);

  const handleSetInvoiceData = () => {
    if (isValidDate && dateFormat) {
      onSetInvoiceDate(moment(text, dateFormat).format('YYYY-MM-DD'));
      onClose();
    }
  };

  const renderDate = () => {
    if (isValidDate) {
      return (
        <div className={itemClassName} onClick={handleSetInvoiceData}>
          {t('contextMenuUseAsInvoiceDate')}
        </div>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <Container x={x || 100} y={y || 100} className={classNames('bg-white rounded-md drop-shadow-2xl shadow-2xl')}>
        <div className={classNames('bg-purple-500 px-4 py-3 text-white', 'font-bold')}>{text}</div>
        <div
          className={itemClassName}
          onClick={() => {
            onSetCreditor(text);
            onClose();
          }}
        >
          {t('contextMenuUseAsCreditor')}
        </div>
        <div
          className={itemClassName}
          onClick={() => {
            onSetInvoiceNumber(text);
            onClose();
          }}
        >
          {t('contextMenuUseAsInvoiceNumber')}
        </div>
        {renderDate()}
      </Container>
    );
  }
  return null;
}
