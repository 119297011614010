import React from 'react';
import { getSelectedLanguage } from '../../Library/Functions/LocalizationFunctions';
import Es from './Conditions/Es';
import classNames from 'classnames';
import Navigation from '../../Components/Website/Navigation';
import Footer from '../../Components/Website/Footer';

/**
 * Conditions()
 * @constructor
 */
export default function Conditions() {
  const getPage = React.useCallback(() => {
    const language = getSelectedLanguage(false);

    switch (language.toLowerCase()) {
      case 'es':
        return <Es />;
      default:
        return <Es />;
    }
  }, []);

  return (
    <div className={classNames('flex flex-1 flex-col')}>
      <Navigation showPrice={false} showStartPage={true} showRegister={true} showLogin={true} />

      <div className={classNames('flex flex-1 flex-row justify-center', 'py-10', 'text-justify')}>
        <div className={classNames('w-full px-7', 'lg:w-2/3 lg:px-0')}>{getPage()}</div>
      </div>

      <Footer />
    </div>
  );
}
