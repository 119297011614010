import React from 'react';
import classNames from 'classnames';

interface Props {
  label?: string;
  children: React.ReactElement;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
  required: boolean;
}

/**
 * AbstractFormContainer()
 * @param props
 * @constructor
 */
export default function AbstractFormContainer(props: Props) {
  const { children, required, direction, containerClass, hint, label } = props;

  const renderLabel = () => {
    if (label) {
      return (
        <div
          className={classNames(
            { 'w-3/12 text-gray-400': direction === 'row' },
            { 'mb-2 text-gray-800': direction === 'column' },
          )}
        >
          {`${label}${required ? '*' : ''}`}
        </div>
      );
    }
    return null;
  };

  const renderHintColumn = () => {
    if (hint && direction === 'column') {
      return <div className={classNames('text-gray-500 text-sm font-light mb-4 -mt-2')}>{hint}</div>;
    }
    return null;
  };

  const renderHintRow = () => {
    if (hint && direction === 'row') {
      return (
        <div className={'flex flex-1'}>
          <div className={classNames('w-3/12')}></div>
          <div className={classNames('text-gray-500 font-light text-sm flex-1')}>{hint}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classNames('mb-4', containerClass)}>
      <div
        className={classNames(
          'flex flex-1',
          { 'flex-row items-center': direction === 'row' },
          { 'flex-col justify-center': direction === 'column' },
          { 'mb-4': !hint },
          { 'mb-1': hint },
        )}
      >
        {renderLabel()}
        {renderHintColumn()}
        {children}
      </div>
      {renderHintRow()}
    </div>
  );
}

AbstractFormContainer.defaultProps = {
  required: false,
};
