import { createPromise } from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import Config from '../Library/Config';
import { Environment } from '../Library/Types/GeneralTypes';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import AuthReducer from './Reducers/AuthReducer';
import DocumentReducer from './Reducers/DocumentReducer';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, persistStore } from 'redux-persist';
import UserReducer from './Reducers/UserReducer';
import ClientReducer from './Reducers/ClientReducer';
import StorageReducer from './Reducers/StorageReducer';

let middleware: any;

const persistConfig = {
  version: 1.0,
  key: 'sotodocs',
  storage: storageSession,
};

/**
 * Add logger only on dev or staging environment.
 * On production environment no console output should be generated
 */
if (Config.env !== Environment.production) {
  const logger = createLogger({ collapsed: true, duration: true });
  middleware = [createPromise(), logger];
} else {
  middleware = [createPromise()];
}

const rootReducer = combineReducers({
  auth: AuthReducer,
  document: DocumentReducer,
  user: UserReducer,
  client: ClientReducer,
  storage: StorageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable: () => true,
      },
    }).concat(middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
