import React from 'react';
import AbstractProductBox from './AbstractProductBox';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Config from '../../../Library/Config';

interface Props {
  onPress: () => void;
  isMonthly: boolean;
  showBuyButton: boolean;
  headerClass?: string;
}

/**
 * ProductBoxSmallBusiness()
 * @param props
 * @constructor
 */
export default function ProductBoxSmallBusiness(props: Props) {
  const { isMonthly, showBuyButton, headerClass } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [priceId, setPriceId] = React.useState<string>('');

  React.useEffect(() => {
    if (isMonthly) {
      setPriceId(Config.stripe.pricePoints.smallBusiness.monthly);
    } else {
      setPriceId(Config.stripe.pricePoints.smallBusiness.yearly);
    }
  }, [isMonthly]);

  return (
    <AbstractProductBox
      headerClass={headerClass}
      showBuyButton={showBuyButton}
      caption={'Small Business'}
      priceString={t('xPricePerMonth', { price: isMonthly ? '49,90€' : '39,90€' })}
      rebateString={
        !isMonthly ? (
          <div className={'line-through text-gray-400'}>{t('xPricePerMonth', { price: '49,90€' })}</div>
        ) : (
          <>&nbsp;</>
        )
      }
      yearlyFull={
        !isMonthly ? <div className={'text-gray-400 text-sm'}>{t('xYearlyPrice', { price: '479,00€' })}*</div> : null
      }
      onPress={() => navigate(`/checkout/${priceId}`)}
      isMonthly={isMonthly}
      buttonColor={'cancel'}
    >
      <div className={'text-gray-500'}>
        <div className={'my-2'}>{t('subscription.smallBusiness.option1')}</div>
        <div className={'my-2'}>{t('subscription.smallBusiness.option2')}</div>
        <div className={'my-2'}>{t('subscription.smallBusiness.option3')}</div>
        <div className={'my-2'}>{t('subscription.smallBusiness.option4')}</div>
        <div className={'my-2'}>{t('subscription.smallBusiness.option5')}</div>
        <div className={'my-2'}>{t('subscription.smallBusiness.option6')}</div>
      </div>
    </AbstractProductBox>
  );
}
