import React from 'react';
import Container from '../Container/Container';
import { DocumentType } from '../../Library/Types/DocumentTypes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatchDocumentDownloadBase64 } from '../../Redux/Actions/DocumentActions';
import { documentGetTextFromImage } from '../../Library/Functions/DocumentFunctions';
import classNames from 'classnames';
import ActionButton from '../Container/ActionButton';
import { downloadBase64Pdf } from '../../Library/Functions/HelperFunctions';

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

interface Props {
  document: DocumentType;
  onStartLoading: () => void;
  onFinishedLoading: () => void;
  onTexts: (texts: string[]) => void;
}

/**
 * ImagePreview()
 * @param props
 * @constructor
 */
export default function ImagePreview(props: Props) {
  const { document, onTexts, onStartLoading, onFinishedLoading } = props;
  const [base64, setBase64] = React.useState<string>('');
  const { t } = useTranslation();
  const [downloaded, setDownloaded] = React.useState<boolean>(false);

  const downloadImage = useDispatchDocumentDownloadBase64(false);

  const downloadFile = React.useCallback(() => {
    downloadBase64Pdf(base64, document.originalName);
  }, [base64, document.originalName]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!base64 && !downloaded && document.fileType.indexOf('image') >= 0) {
      timer = setTimeout(() => {
        onStartLoading();
        setDownloaded(true);

        downloadImage(document)
          .then((data) => {
            setBase64(data);

            return documentGetTextFromImage(data).then((ocrResult) => {
              onTexts(ocrResult);
              return Promise.resolve();
            });
          })
          .catch((error) => {
            setBase64('');
            return Promise.resolve();
          })
          .finally(() => onFinishedLoading());
      }, 250);
    }

    return () => clearTimeout(timer);
  }, [base64, document, downloadImage, downloaded, onFinishedLoading, onStartLoading, onTexts]);

  if (document && document.fileType.indexOf('image') > -1) {
    return (
      <Container
        headline={t('document')}
        actionButtons={
          <ActionButton
            icon={classNames('fas fa-cloud-download')}
            className={classNames('hover:bg-blue-500 hover:text-white mr-0')}
            onPress={downloadFile}
          />
        }
      >
        <Inner>
          <img src={document.downloadUrl} alt={document.originalName} />
        </Inner>
      </Container>
    );
  }
  return null;
}
