import React from 'react';
import classNames from 'classnames';

const downloadLink =
  // eslint-disable-next-line max-len
  'https://firebasestorage.googleapis.com/v0/b/sotodocs-production.appspot.com/o/internals%2Flegal_advice_es.pdf?alt=media&token=cd4a290e-e129-444a-b481-e792188c5c40';

/**
 * Es()
 * @constructor
 */
export default function Es() {
  return (
    <div className={'text-justify'}>
      <h1 className={'text-4xl font-bold'}>AVISO LEGAL Y CONDICIONES GENERALES DE USO</h1>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>WWW.SOTODOCS.COM</h2>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>I. INFORMACIÓN GENERAL</h2>
      <p className={'text mb-6 md:text-lg'}>
        En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la
        Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos
        de información general de este sitio web:
      </p>
      <p className={'text mb-6 md:text-lg'}>
        La titularidad de este sitio web, WWW.SOTODOCS.COM, (en adelante, Sitio Web) la ostenta: SOTOSOFT S.L., provista
        de NIF: B72570732 e inscrita en: REGISTRO MERCANTIL DE CADIZ Nº2 con los siguientes datos registrales: TOMO
        2459, FOLIO 24, HOJA CA-61226, cuyo representante es: Thorsten Rutte, y cuyos datos de contacto son:
      </p>
      <p className={'text md:text-lg'}>Dirección: SENDA CHICA, 7, 1B, 11310 SAN ROQUE (CADIZ)</p>
      <p className={'text mb-6 md:text-lg'}>Email de contacto: info@sotodocs.com</p>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>II. TÉRMINOS Y CONDICIONES GENERALES DE USO</h2>
      <h3 className={'text-xl mb-4 font-medium'}>El objeto de las condiciones: El Sitio Web</h3>
      <p className={'text mb-6 md:text-lg'}>
        El objeto de las presentes Condiciones Generales de Uso (en adelante, Condiciones) es regular el acceso y la
        utilización del Sitio Web. A los efectos de las presentes Condiciones se entenderá como Sitio Web: la apariencia
        externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de
        navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación
        (en adelante, Contenidos) y todos aquellos servicios o recursos en línea que en su caso ofrezca a los Usuarios
        (en adelante, Servicios).
      </p>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS se reserva la facultad de modificar, en cualquier momento, y sin aviso previo, la presentación y
        configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario
        reconoce y acepta que en cualquier momento SOTODOCS pueda interrumpir, desactivar y/o cancelar cualquiera de
        estos elementos que se integran en el Sitio Web o el acceso a los mismos.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El acceso al Sitio Web por el Usuario tiene carácter libre y, por regla general, es gratuito sin que el Usuario
        tenga que proporcionar una contraprestación para poder disfrutar de ello, salvo en lo relativo al coste de
        conexión a través de la red de telecomunicaciones suministrada por el proveedor de acceso que hubiere contratado
        el Usuario.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        La utilización de alguno de los Contenidos o Servicios del Sitio Web podrá hacerse mediante la suscripción o
        registro previo del Usuario.
      </p>
      <h3 className={'text-xl mb-4 font-medium'}>El Usuario</h3>
      <p className={'text mb-6 md:text-lg'}>
        El acceso, la navegación y uso del Sitio Web, así como por los espacios habilitados para interactuar entre los
        Usuarios, y el Usuario y SOTODOCS, como los comentarios y/o espacios de blogging, confiere la condición de
        Usuario, por lo que se aceptan, desde que se inicia la navegación por el Sitio Web, todas las Condiciones aquí
        establecidas, así como sus ulteriores modificaciones, sin perjuicio de la aplicación de la correspondiente
        normativa legal de obligado cumplimiento según el caso. Dada la relevancia de lo anterior, se recomienda al
        Usuario leerlas cada vez que visite el Sitio Web.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El Sitio Web de SOTODOCS proporciona gran diversidad de información, servicios y datos. El Usuario asume su
        responsabilidad para realizar un uso correcto del Sitio Web. Esta responsabilidad se extenderá a:
      </p>
      <ul className={'list-disc text mb-6 ml-20  md:text-lg'}>
        <li>
          Un uso de la información, Contenidos y/o Servicios y datos ofrecidos por SOTODOCS sin que sea contrario a lo
          dispuesto por las presentes Condiciones, la Ley, la moral o el orden público, o que de cualquier otro modo
          puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del Sitio Web.
        </li>
        <li>
          La veracidad y licitud de las informaciones aportadas por el Usuario en los formularios extendidos por
          SOTODOCS para el acceso a ciertos Contenidos o Servicios ofrecidos por el Sitio Web. En todo caso, el Usuario
          notificará de forma inmediata a SOTODOCS acerca de cualquier hecho que permita el uso indebido de la
          información registrada en dichos formularios, tales como, pero no solo, el robo, extravío, o el acceso no
          autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación.
        </li>
      </ul>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el
        respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, spamming,
        que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran
        adecuados para su publicación.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        En cualquier caso, SOTODOCS no será responsable de las opiniones vertidas por los Usuarios a través de
        comentarios u otras herramientas de blogging o de participación que pueda haber.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El mero acceso a este Sitio Web no supone entablar ningún tipo de relación de carácter comercial entre SOTODOCS
        y el Usuario.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        Siempre en el respeto de la legislación vigente, este Sitio Web de SOTODOCS se dirige a todas las personas, sin
        importar su edad, que puedan acceder y/o navegar por las páginas del Sitio Web.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El Sitio Web está dirigido principalmente a Usuarios residentes en España. SOTODOCS no asegura que el Sitio Web
        cumpla con legislaciones de otros países, ya sea total o parcialmente. Si el Usuario reside o tiene su
        domiciliado en otro lugar y decide acceder y/o navegar en el Sitio Web lo hará bajo su propia responsabilidad,
        deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no
        asumiendo SOTODOCS responsabilidad alguna que se pueda derivar de dicho acceso.
      </p>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>
        III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB: EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD
      </h2>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS no garantiza la continuidad, disponibilidad y utilidad del Sitio Web, ni de los Contenidos o Servicios.
        SOTODOCS hará todo lo posible por el buen funcionamiento del Sitio Web, sin embargo, no se responsabiliza ni
        garantiza que el acceso a este Sitio Web no vaya a ser ininterrumpido o que esté libre de error.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        Tampoco se responsabiliza o garantiza que el contenido o software al que pueda accederse a través de este Sitio
        Web, esté libre de error o cause un daño al sistema informático (software y hardware) del Usuario. En ningún
        caso SOTODOCS será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el acceso,
        navegación y el uso del Sitio Web, incluyéndose, pero no limitándose, a los ocasionados a los sistemas
        informáticos o los provocados por la introducción de virus.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS tampoco se hace responsable de los daños que pudiesen ocasionarse a los usuarios por un uso inadecuado
        de este Sitio Web. En particular, no se hace responsable en modo alguno de las caídas, interrupciones, falta o
        defecto de las telecomunicaciones que pudieran ocurrir.
      </p>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>IV. POLÍTICA DE ENLACES</h2>
      <p className={'text mb-6 md:text-lg'}>
        Se informa que el Sitio Web de SOTODOCS pone o puede poner a disposición de los Usuarios medios de enlace (como,
        entre otros, links, banners, botones), directorios y motores de búsqueda que permiten a los Usuarios acceder a
        sitios web pertenecientes y/o gestionados por terceros.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        La instalación de estos enlaces, directorios y motores de búsqueda en el Sitio Web tiene por objeto facilitar a
        los Usuarios la búsqueda de y acceso a la información disponible en Internet, sin que pueda considerarse una
        sugerencia, recomendación o invitación para la visita de los mismos.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS no ofrece ni comercializa por sí ni por medio de terceros los productos y/o servicios disponibles en
        dichos sitios enlazados.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        Asimismo, tampoco garantizará la disponibilidad técnica, exactitud, veracidad, validez o legalidad de sitios
        ajenos a su propiedad a los que se pueda acceder por medio de los enlaces.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS en ningún caso revisará o controlará el contenido de otros sitios web, así como tampoco aprueba,
        examina ni hace propios los productos y servicios, contenidos, archivos y cualquier otro material existente en
        los referidos sitios enlazados.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso, uso,
        calidad o licitud de los contenidos, comunicaciones, opiniones, productos y servicios de los sitios web no
        gestionados por SOTODOCS y que sean enlazados en este Sitio Web.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El Usuario o tercero que realice un hipervínculo desde una página web de otro, distinto, sitio web al Sitio Web
        de SOTODOCS deberá saber que:
      </p>
      <p className={'text mb-6 md:text-lg'}>
        No se permite la reproducción —total o parcialmente— de ninguno de los Contenidos y/o Servicios del Sitio Web
        sin autorización expresa de SOTODOCS.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        No se permite tampoco ninguna manifestación falsa, inexacta o incorrecta sobre el Sitio Web de SOTODOCS, ni
        sobre los Contenidos y/o Servicios del mismo.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        A excepción del hipervínculo, el sitio web en el que se establezca dicho hiperenlace no contendrá ningún
        elemento, de este Sitio Web, protegido como propiedad intelectual por el ordenamiento jurídico español, salvo
        autorización expresa de SOTODOCS.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El establecimiento del hipervínculo no implicará la existencia de relaciones entre SOTODOCS y el titular del
        sitio web desde el cual se realice, ni el conocimiento y aceptación de SOTODOCS de los contenidos, servicios y/o
        actividades ofrecidas en dicho sitio web, y viceversa.
      </p>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>V. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial
        del Sitio Web, así como de los elementos contenidos en el mismo (a título enunciativo y no exhaustivo, imágenes,
        sonido, audio, vídeo, software o textos, marcas o logotipos, combinaciones de colores, estructura y diseño,
        selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).
        Serán, por consiguiente, obras protegidas como propiedad intelectual por el ordenamiento jurídico español,
        siéndoles aplicables tanto la normativa española y comunitaria en este campo, como los tratados internacionales
        relativos a la materia y suscritos por España.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente
        prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a
        disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier
        soporte y por cualquier medio técnico, sin la autorización de SOTODOCS.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de SOTODOCS. Podrá
        visualizar los elementos del Sitio Web o incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su
        ordenador o en cualquier otro soporte físico siempre y cuando sea, exclusivamente, para su uso personal. El
        Usuario, sin embargo, no podrá suprimir, alterar, o manipular cualquier dispositivo de protección o sistema de
        seguridad que estuviera instalado en el Sitio Web.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        En caso de que el Usuario o tercero considere que cualquiera de los Contenidos del Sitio Web suponga una
        violación de los derechos de protección de la propiedad intelectual, deberá comunicarlo inmediatamente a
        SOTODOCS a través de los datos de contacto del apartado de INFORMACIÓN GENERAL de este Aviso Legal y Condiciones
        Generales de Uso.
      </p>
      <h2 className={'text-2xl mb-4 mt-10 font-medium'}>VI. ACCIONES LEGALES, LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h2>
      <p className={'text mb-6 md:text-lg'}>
        SOTODOCS se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la
        utilización indebida del Sitio Web y Contenidos, o por el incumplimiento de las presentes Condiciones.
      </p>
      <p className={'text mb-6 md:text-lg'}>
        La relación entre el Usuario y SOTODOCS se regirá por la normativa vigente y de aplicación en el territorio
        español. De surgir cualquier controversia en relación con la interpretación y/o a la aplicación de estas
        Condiciones las partes someterán sus conflictos a la jurisdicción ordinaria sometiéndose a los jueces y
        tribunales que correspondan conforme a derecho.
      </p>

      <div className={'inline-flex mt-10 mb-10'}>
        <a
          className={classNames(
            'cursor-pointer text-xl bg-purple-700 px-4 py-3',
            'hover:bg-purple-900 rounded-md text-white',
          )}
          href={downloadLink}
          target="_blank"
          rel="noreferrer"
        >
          <i className={'far fa-file-pdf mr-4'} />
          Download PDF
        </a>
      </div>
    </div>
  );
}
