import { useTranslation } from 'react-i18next';
import { useLoadUserDashboard } from './UserDashboardLoader';
import React from 'react';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import ListView from '../../../Components/Document/ListView';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import { useNavigate } from 'react-router';
import UserOverviewYear from '../../../Components/Dashboard/UserOverviewYear';
import Headline from '../../../Components/Dashboard/Headline';
import NewMailButton from '../../../Components/Dashboard/NewMailButton/NewMailButton';

export default function UserDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { activeClient } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const navContext = React.useContext(NavbarContext);
  useLoadUserDashboard();

  const setNavigationButton = React.useMemo(() => {
    return <NewMailButton />;
  }, []);

  React.useEffect(() => {
    navContext.setHeadline(t('userDashboard'));
    navContext.setButton(setNavigationButton);
  }, [navContext, setNavigationButton, t]);

  const handleOnPress = (document: DocumentType) => {
    navigate(`/documents/${document.documentId}`);
  };

  if (activeClient) {
    return (
      <div>
        <Headline />

        <UserOverviewYear />

        <ListView
          containerHeadline={t('documentsNeedAttention')}
          documents={documents.filter(
            (item) => item.state !== DocumentState.open && item.clientId === activeClient.clientId,
          )}
          onPress={handleOnPress}
          showClient={false}
        />
      </div>
    );
  }
  return null;
}
