import React, { HTMLInputTypeAttribute } from 'react';
import classNames from 'classnames';
import AbstractFormContainer from './AbstractFormContainer';
import ShowHideContainer from '../ShowHideContainer';

interface Props {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  hasError: boolean;
  disabled?: boolean;
  required?: boolean;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
  autoFocus: boolean;
  showClear: boolean;
  type?: HTMLInputTypeAttribute;
  rightButtonCaption?: string;
  rightButtonIcon?: string;
  onRightButtonPress?: () => void;
}

/**
 * InputRow()
 * @param props
 * @constructor
 */
export default function InputRow(props: Props) {
  const {
    placeholder,
    autoFocus,
    direction,
    label,
    onChange,
    value,
    hasError,
    disabled,
    required,
    hint,
    containerClass,
    showClear,
    type,
    rightButtonCaption,
    rightButtonIcon,
    onRightButtonPress,
  } = props;

  const renderClear = () => {
    if (showClear && value && value.length > 0) {
      return (
        <div
          className={classNames(
            'absolute text-gray-300 cursor-pointer right-3 top-0 bottom-0',
            'flex justify-center items-center hover:text-black',
          )}
          onClick={() => onChange('')}
        >
          <i className={'fas fa-times-circle px-3'} />
        </div>
      );
    }
    return null;
  };

  const renderRightButton = () => {
    if (rightButtonCaption || rightButtonIcon) {
      return (
        <div className={'relative flex items-center right-0 top-0 -bottom-0'}>
          <div
            className={classNames(
              'flex py-3 px-4 bg-white rounded-r-md border-gray-300 border cursor-pointer',
              'hover:bg-blue-500 hover:text-white',
              'items-center',
            )}
            onClick={onRightButtonPress}
          >
            <ShowHideContainer visible={!!rightButtonIcon}>
              <i className={classNames(rightButtonIcon, { 'mr-3': !!rightButtonCaption })} />
            </ShowHideContainer>
            <ShowHideContainer visible={!!rightButtonCaption}>{rightButtonCaption}</ShowHideContainer>
          </div>
        </div>
      );
    }
    return null;
  };

  const getPlaceholder = () => {
    if (label || placeholder) {
      if (required) {
        return `${placeholder || label}*`;
      }
      return placeholder || label;
    }
    return '';
  };

  return (
    <AbstractFormContainer
      direction={direction}
      label={label}
      hint={hint}
      containerClass={containerClass}
      required={required}
    >
      <div className={'flex flex-1 relative'}>
        <input
          className={classNames([
            'py-3 px-4 flex-grow border-gray-300 border focus:outline-0 placeholder:font-light',
            { 'border-red-500 border-2': hasError },
            { 'rounded-md': !rightButtonIcon && !rightButtonCaption },
            { 'rounded-l-md border-r-0': rightButtonIcon || rightButtonCaption },
          ])}
          placeholder={getPlaceholder()}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          autoFocus={autoFocus}
          type={type || 'text'}
        />
        {renderRightButton()}
        {renderClear()}
      </div>
    </AbstractFormContainer>
  );
}

InputRow.defaultProps = {
  hasError: false,
  direction: 'row',
  autoFocus: false,
  showClear: false,
};
