import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { FirebasePath } from '../../Library/Firebase';
import { getStorage, uploadBytes, ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { useDispatchCustomerUpdate } from '../../Redux/Actions/CustomerActions';
import CalloutSuccessfullySaved from '../Callouts/CalloutSuccessfullySaved';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import ActionButton from '../Container/ActionButton';
import classNames from 'classnames';

/**
 * CompanyLogo()
 * @constructor
 */
export default function CompanyLogo() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [callout, setCallout] = React.useState<boolean>(false);

  const dispatchUpdateCustomer = useDispatchCustomerUpdate();

  const upload = React.useCallback(
    (file: File) => {
      if (file) {
        const storageFileName = `${FirebasePath.customerLogo}/${customer.customerId}`;
        uploadBytes(ref(getStorage(), storageFileName), file)
          .then(() => {
            return getDownloadURL(ref(getStorage(), storageFileName)).then((logoUrl) => {
              return dispatchUpdateCustomer({ ...customer, logo: logoUrl });
            });
          })
          .finally(() => setCallout(true));
      }
    },
    [customer, dispatchUpdateCustomer],
  );

  const deleteLogo = React.useCallback(() => {
    if (customer.logo) {
      const storageFileName = `${FirebasePath.customerLogo}/${customer.customerId}`;
      deleteObject(ref(getStorage(), storageFileName)).then(() => {
        return dispatchUpdateCustomer({ ...customer, logo: null });
      });
    }
  }, [customer, dispatchUpdateCustomer]);

  const renderLogo = () => {
    if (customer.logo) {
      return (
        <div
          className={'flex flex-1 flex-wrap items-center justify-center content-center'}
          onClick={() => inputRef.current?.click()}
        >
          <img src={customer.logo} alt={'Customer-Logo'} className={'object-cover'} style={{ maxHeight: 280 }} />
        </div>
      );
    }
    return (
      <div className={'flex flex-1 flex-wrap content-center items-center justify-center'} style={{ height: 120 }}>
        <i className={'fal fa-image-slash text-gray-300'} style={{ fontSize: 80 }} />
      </div>
    );
  };

  const getActionButtons = () => {
    return (
      <div>
        <ActionButton
          onPress={() => inputRef.current?.click()}
          icon={'fas fa-cloud-upload'}
          className={classNames('hover:bg-blue-500 hover:text-white')}
          tooltip={t('uploadCompanyLogo')}
        />
        <ActionButton
          onPress={deleteLogo}
          icon={'fas fa-trash-alt'}
          className={classNames('hover:bg-red-600 hover:text-white')}
          visible={!!customer.logo}
          tooltip={t('delete')}
        />
      </div>
    );
  };

  return (
    <Container
      headline={t('companyLogo')}
      actionButtons={getActionButtons()}
      contentContainerClass={'items-center justify-center'}
    >
      <CalloutSuccessfullySaved visible={callout} onChange={setCallout} />

      {renderLogo()}

      <input
        style={{ display: 'none' }}
        type={'file'}
        ref={inputRef}
        accept={'image/png,image/jpg,image/jpeg,image/bmp'}
        onChange={(e) => (e.target.files ? upload(e.target.files[0]) : () => {})}
      />
    </Container>
  );
}
