import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import React from 'react';
import {
  CUSTOMER_GET_ERROR,
  CUSTOMER_GET_START,
  CUSTOMER_GET_SUCCESS,
  CUSTOMER_GETLIST_ERROR,
  CUSTOMER_GETLIST_START,
  CUSTOMER_GETLIST_SUCCESS,
  CUSTOMER_REGISTER_ERROR,
  CUSTOMER_REGISTER_START,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_UPDATE_START,
  CUSTOMER_UPDATE_SUCCESS,
} from '../ActionTypes';
import {
  DocumentReference,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  collection,
  CollectionReference,
  addDoc,
  query,
  where,
  getDocs,
  documentId,
} from 'firebase/firestore';
import { FirebaseFunctions, FirebasePath } from '../../Library/Firebase';
import { Customer } from '../../Library/Types/CustomerTypes';
import { RegisterCustomerState } from '../../Modules/Register/RegisterCustomer';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { User } from '../../Library/Types/UserTypes';
import { customerBuildFromRegistration } from '../../Library/Functions/CustomerFunctions';
import { getMappedLanguageFromBrowser } from '../../Library/Functions/LocalizationFunctions';
import { Language } from '../../Library/Types/GeneralTypes';
import moment from 'moment/moment';
import { getFunctions, httpsCallable } from 'firebase/functions';

/**
 * useDispatchCustomerGet()
 */
export const useDispatchCustomerGet = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (customerId: string) => {
      dispatch(CUSTOMER_GET_START(customerId));

      const document = doc(getFirestore(), FirebasePath.customers, customerId) as DocumentReference<Customer>;

      return getDoc(document)
        .then((snapShot) => {
          if (snapShot.exists()) {
            const customer = { ...(snapShot.data() as Customer), customerId: snapShot.id };
            dispatch(CUSTOMER_GET_SUCCESS(customer));
            return Promise.resolve(customer);
          }

          return Promise.reject();
        })
        .catch((error) => {
          dispatch(CUSTOMER_GET_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchCustomerGetList()
 */
export const useDispatchCustomerGetList = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (customerIds: string[]) => {
      dispatch(CUSTOMER_GETLIST_START(customerIds));

      const documentCollection = collection(getFirestore(), FirebasePath.customers) as CollectionReference<Customer>;
      const queryRef = query(documentCollection, where(documentId(), 'in', customerIds));

      return getDocs(queryRef)
        .then((snapShot) => {
          const customers: Customer[] = [];
          if (!snapShot.empty) {
            snapShot.forEach((item) => {
              customers.push({ ...item.data(), customerId: item.id });
            });
          }

          dispatch(CUSTOMER_GETLIST_SUCCESS(customers));
          return Promise.resolve(customers);
        })
        .catch((error) => {
          dispatch(CUSTOMER_GETLIST_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchCustomerUpdate()
 */
export const useDispatchCustomerUpdate = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (customer: Customer) => {
      dispatch(CUSTOMER_UPDATE_START(customer));

      const documentRef = doc(
        getFirestore(),
        FirebasePath.customers,
        customer.customerId,
      ) as DocumentReference<Customer>;

      return setDoc(documentRef, customer)
        .then((snapShot) => {
          dispatch(CUSTOMER_UPDATE_SUCCESS(customer));
          return Promise.resolve(customer);
        })
        .catch((error) => {
          dispatch(CUSTOMER_UPDATE_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchCustomerRegister()
 */
export const useDispatchCustomerRegister = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (state: RegisterCustomerState) => {
      dispatch(CUSTOMER_REGISTER_START(state));

      return createUserWithEmailAndPassword(getAuth(), state.data.mail, state.data.password)
        .then((authResponse) => {
          return updateProfile(authResponse.user, {
            displayName: `${state.data.firstName} ${state.data.lastName}`,
          }).then(() => {
            const user: User = {
              customerId: '',
              userId: '',
              clients: [],
              mail: state.data.mail,
              lastName: state.data.lastName,
              firstName: state.data.firstName,
              uuid: authResponse.user.uid,
              isCustomerAdmin: true,
              isCustomerUser: true,
              isActive: true,
              language: getMappedLanguageFromBrowser() as Language,
              createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
              isBeta: false,
            };
            const customer = customerBuildFromRegistration(
              state.data.companyName,
              state.data.mail,
              state.data.taxNumber,
              state.data.country,
            );

            const userCollectionRef = collection(getFirestore(), FirebasePath.users) as CollectionReference<User>;
            const customerCollectionRef = collection(
              getFirestore(),
              FirebasePath.customers,
            ) as CollectionReference<Customer>;

            return addDoc(userCollectionRef, user).then((userSnapShot) => {
              return addDoc(customerCollectionRef, customer).then((customerSnapShot) => {
                const userDocRef = doc(getFirestore(), FirebasePath.users, userSnapShot.id) as DocumentReference<User>;
                return setDoc(userDocRef, { ...user, customerId: customerSnapShot.id }).then(() => {
                  const userResponse: User = { ...user, userId: userSnapShot.id, customerId: customerSnapShot.id };
                  const customerResponse: Customer = { ...customer, customerId: customerSnapShot.id };

                  dispatch(CUSTOMER_REGISTER_SUCCESS({ customer: customerResponse, user: userResponse }));
                  return Promise.resolve({ customer: customerResponse, user: userResponse });
                });
              });
            });
          });
        })
        .catch((error) => {
          dispatch(CUSTOMER_REGISTER_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchCustomerGenerateApiKey()
 */
export const useDispatchCustomerGenerateApiKey = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.auth);

  return React.useCallback(() => {
    const callable = httpsCallable<{ customerId: string }, Customer>(
      getFunctions(),
      FirebaseFunctions.customerGenerateApiKey,
    );

    return callable({ customerId: customer.customerId }).then((response) => {
      dispatch(CUSTOMER_UPDATE_SUCCESS(response.data));
      return Promise.resolve(response.data);
    });
  }, [customer.customerId, dispatch]);
};
