import React from 'react';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import AbstractModal from '../../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import Row from './Row';
import Button from '../../Forms/Button';

interface Props {
  document: DocumentType;
  visible: boolean;
  onClose: () => void;
}

/**
 * MessageModal()
 * @param props
 * @constructor
 */
export default function MessageModal(props: Props) {
  const { visible, onClose, document } = props;
  const { t } = useTranslation();

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'}>
        {t('close')}
      </Button>
    </div>
  );

  const renderMessages = () => document.messages.map((message) => <Row message={message} key={message.message} />);

  return (
    <AbstractModal
      visible={visible}
      onBackdrop={onClose}
      headline={t('messages', { count: document.messages.length })}
      type={'primary'}
      buttons={renderButtons()}
      icon={'far fa-messages'}
    >
      <div className={'flex flex-1 flex-col mt-10'}>{renderMessages()}</div>
    </AbstractModal>
  );
}
