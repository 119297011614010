import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { generateRandomString } from '../../Library/Functions/HelperFunctions';

interface Props {
  onPress?: () => void;
  icon?: string;
  className?: string;
  visible?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

/**
 * ActionButton()
 * @param props
 * @constructor
 */
export default function ActionButton(props: Props) {
  const { onPress, icon, className, visible, disabled, tooltip } = props;
  const [tooltipClass] = React.useState<string>(generateRandomString(20).toLowerCase());

  const renderTooltip = () => {
    if (tooltip) {
      return (
        <Tooltip anchorSelect={`.${tooltipClass}`} place={'bottom'} style={{ borderRadius: 6 }}>
          {tooltip}
        </Tooltip>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <>
        {renderTooltip()}
        <div
          className={classNames(
            tooltipClass,
            'fas',
            { 'cursor-pointer': !disabled },
            'w-10',
            'text-center',
            'py-3',
            'last:mr-0',
            'mr-2',
            'rounded-md',
            { 'opacity-40 cursor-not-allowed': disabled },
            className,
            { 'text-gray-400': !className || className.indexOf('-700') < 0 },
          )}
          data-tooltip-target="tooltip-default"
          onClick={onPress && !disabled ? () => onPress() : () => {}}
        >
          <i className={classNames(icon)} />
        </div>
      </>
    );
  }
  return null;
}

ActionButton.defaultProps = {
  visible: true,
  disabled: false,
};
