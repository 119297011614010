import React from 'react';
import { useTranslation } from 'react-i18next';
import AbstractCallout from './AbstractCallout';

interface Props {
  visible: boolean;
  onChange: (value: boolean) => void;
  text?: string;
}

/**
 * CalloutSuccessfullySaved()
 * @param props
 * @constructor
 */
export default function CalloutSuccessfullySaved(props: Props) {
  const { visible, onChange, text } = props;
  const { t } = useTranslation();

  return (
    <AbstractCallout
      visible={visible}
      onChange={onChange}
      icon={'fas fa-check-circle'}
      text={text || t('changesSavedSuccessfully')}
      className={'bg-green-700 text-white'}
      duration={5000}
      transitionTime={750}
      showClose
      closeBgClass={'hover:bg-green-500'}
    />
  );
}
