import React from 'react';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { useDispatchClientGetList } from '../../../Redux/Actions/ClientActions';
import { useDispatchUserGetByCustomer } from '../../../Redux/Actions/UserActions';

/**
 * useLoadUserDashboard()
 */
export const useLoadCustomerDashboard = () => {
  const { customer } = useAppSelector((state) => state.auth);

  const dispatchGetClients = useDispatchClientGetList();
  const dispatchGetUsers = useDispatchUserGetByCustomer();

  return React.useCallback(() => {
    return dispatchGetClients(customer.customerId).then((clients) => {
      return dispatchGetUsers(customer.customerId).then(() => {});
    });
  }, [customer.customerId, dispatchGetClients, dispatchGetUsers]);
};
