import React from 'react';
import classNames from 'classnames';
import MobileMenu from './MobileMenu';

const logo = require('../../../Assets/Images/sotologo_white.png');

interface Props {
  showPrice: boolean;
  showStartPage: boolean;
  showRegister: boolean;
  showLogin: boolean;
}

/**
 * Mobile()
 * @constructor
 */
export default function Mobile(props: Props) {
  const { showPrice, showStartPage, showRegister, showLogin } = props;

  return (
    <div className={classNames('flex flex-1 flex-row bg-purple-700 p-4', 'lg:hidden')}>
      <div className={'flex flex-1 flex-col'}>
        <div className={'flex flex-row items-center'}>
          <img src={logo} alt={'Sotosoft Logo'} className={'h-10 mr-4'} />
          <div className={classNames('text-3xl font-bold text-white')}>SotoDocs</div>
        </div>
      </div>
      <div>
        <MobileMenu
          showPrice={showPrice}
          showStartPage={showStartPage}
          showLogin={showLogin}
          showRegister={showRegister}
        />
      </div>
    </div>
  );
}
