import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getSelectedLanguage } from '../../Library/Functions/LocalizationFunctions';
import { useCopyClipboard } from '../../Library/Hooks/GlobalHooks';

interface DatePickerProps {
  onChange: (date: string) => void;
  label: string;
  initialValue?: Date;
  visible: boolean;
  required: boolean;
  hasError?: boolean;
  errorMessage?: string | null;
  disabled: boolean;
  contextHelpKey: string;
  showSkeleton: boolean;
  minDate: Date;
  maxDate: Date;
  showCopy: boolean;
}

interface ContainerProps {
  $disabled: boolean;
  $focused: boolean;
  $hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 15px;

  .TextContainer {
    border-radius: ${(props) => props.theme.borderRadius};
    height: 58px;
    padding-left: 18px;
    padding-right: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.borderColor};
    background-color: ${(props) => (props.$disabled ? lighten(0.32, props.theme.color.textLight) : '#ffffff')};
    overflow: hidden;
  }

  .Label {
    position: absolute;
    left: 19px;
    top: ${(props) => (props.$focused ? '6px' : '17px')};
    font-size: ${(props) => (props.$focused ? '14px' : '16px')};
    font-weight: 300;
    color: ${(props) => (props.$hasError ? lighten(0.15, props.theme.colors.red) : props.theme.colors.textLight)};
    transition:
      top 200ms,
      font-size 200ms,
      font-weight 200ms;
  }

  .RightIconContainer {
    position: absolute;
    right: 15px;
    top: 17px;

    i {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ErrorIcon {
      color: ${(props) => lighten(0.15, props.theme.colors.red)};
    }
    .DisabledIcon {
      color: ${(props) => lighten(0.15, props.theme.colors.textLight)};
    }
  }

  .react-datepicker-wrapper {
    display: flex;
    z-index: -1;

    .react-datepicker__input-container {
      display: flex;
      flex: 1;
      outline: 0;
      background: transparent;

      input {
        cursor: pointer;
        outline: 0;
        display: flex;
        flex: 1;
        padding-left: 0;
        line-height: 20px;
        border: none;
        height: 56px;
        padding-bottom: 6px;
        padding-top: 22px;
        background-color: transparent;
        caret-color: transparent;
      }
    }
  }
`;

/**
 * DatePicker()
 * @param props
 * @constructor
 */
export default function DatePicker(props: DatePickerProps) {
  const { showSkeleton, minDate, maxDate, initialValue, disabled, showCopy } = props;
  const [value, setValue] = React.useState<Date | undefined>(initialValue);
  const [initializedValue, setInitializedValue] = React.useState<Date | undefined>();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();

  React.useEffect(() => {
    if (initializedValue !== initialValue) {
      setValue(initialValue);
      setInitializedValue(initialValue);
      setInitialized(true);
    }
  }, [initialValue, initialized, initializedValue]);

  const renderLabel = () => {
    const label = `${props.label}${props.required ? '*' : ''}`;
    return <div className="Label">{label}</div>;
  };

  const renderCopyIcon = () => {
    if (showCopy && value) {
      return (
        <i
          className="far fa-copy text-gray-300 hover:text-black cursor-pointer"
          onClick={() => copyToClipBoard(moment(value).format('LL'))}
        />
      );
    }
    return null;
  };

  const renderErrorIcon = () => {
    if (props.hasError) {
      return <i className="fas fa-exclamation-circle ErrorIcon" />;
    }
    return null;
  };

  const renderDisabledIcon = () => {
    if (disabled) {
      return <i className="fas fa-lock DisabledIcon" />;
    }
    return null;
  };

  const renderRightIcons = () => {
    const errorIcon = renderErrorIcon();
    const disabledIcon = renderDisabledIcon();
    const copyIcon = renderCopyIcon();

    if (errorIcon || disabledIcon || copyIcon) {
      return (
        <div className="RightIconContainer z-10">
          {copyIcon}
          {errorIcon}
          {disabledIcon}
        </div>
      );
    }
    return null;
  };

  const handleSetValue = (param: any) => {
    if (props.onChange && param) {
      setValue(param);
      const date = moment(param).format('YYYY-MM-DD');
      props.onChange(date);
    }
  };

  const renderContent = () => {
    if (!showSkeleton) {
      return (
        <div className="TextContainer">
          {renderLabel()}
          {renderRightIcons()}

          <ReactDatePicker
            onChange={handleSetValue}
            locale={getSelectedLanguage(true)}
            selected={value}
            dateFormat={'dd.MM.yyyy'}
            onChangeRaw={(e) => e.preventDefault()}
            minDate={minDate}
            maxDate={maxDate}
            showWeekNumbers
            disabled={props.disabled}
            showTimeSelect={false}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
          />
        </div>
      );
    }
    return null;
  };

  if (props.visible) {
    return (
      <Container $focused={!!value} $hasError={props.hasError || false} $disabled={disabled}>
        {renderContent()}
        {clipboardCallout}
      </Container>
    );
  }
  return null;
}

DatePicker.defaultProps = {
  visible: true,
  value: '',
  required: false,
  hasError: false,
  errorMessage: null,
  disabled: false,
  contextHelpKey: null,
  showSkeleton: false,
  minDate: null,
  maxDate: null,
  initialStartDate: null,
  showCopy: false,
};
