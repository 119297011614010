import React from 'react';
import ProductBoxSmallBusiness from './ProductBox/ProductBoxSmallBusiness';
import ProductBoxProfessional from './ProductBox/ProductBoxProfessional';
import ProductBoxUnlimited from './ProductBox/ProductBoxUnlimited';
import Container from '../Container/Container';
import Toggle from '../Forms/Toggle';
import { useTranslation } from 'react-i18next';
import RegisterAndLoginFooter from '../RegisterAndLoginFooter';

interface Props {
  hasSubscription: boolean;
}

/**
 * ChooseProduct()
 * @param props
 * @constructor
 */
export default function ChooseProduct(props: Props) {
  const { hasSubscription } = props;
  const { t } = useTranslation();
  const [monthly, setMonthly] = React.useState<boolean>(true);

  if (!hasSubscription) {
    return (
      <>
        <Container>
          <div className={'flex flex-1 flex-row items-center cursor-pointer'}>
            <Toggle onChange={() => setMonthly(!monthly)} value={!monthly} />
            <div className={'ml-4 text-xl'} onClick={() => setMonthly(!monthly)}>
              {t('saveWithYearlySubscription')}
            </div>
          </div>
        </Container>

        <div className={'flex flex-1'}>
          <div className={'basis-1/3 mr-4'}>
            <ProductBoxSmallBusiness
              onPress={() => {}}
              isMonthly={monthly}
              showBuyButton
              headerClass={'bg-purple-400 text-white py-6'}
            />
          </div>
          <div className={'basis-1/3 ml-4 mr-4'}>
            <ProductBoxProfessional
              onPress={() => {}}
              isMonthly={monthly}
              showBuyButton
              headerClass={'bg-orange-400 text-white py-6'}
            />
          </div>
          <div className={'basis-1/3 ml-4'}>
            <ProductBoxUnlimited
              onPress={() => {}}
              isMonthly={monthly}
              showBuyButton
              headerClass={'bg-purple-400 text-white py-6'}
            />
          </div>
        </div>

        <RegisterAndLoginFooter />
      </>
    );
  }
  return null;
}
