import React from 'react';
import { ClientGroup } from '../../../../Library/Types/GeneralTypes';
import classNames from 'classnames';
import AbstractModal from '../../../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../../../Forms/Button';
import { useAppSelector } from '../../../../Library/Hooks/ReduxHooks';
import { getNextFreeClientGroupId } from '../../../../Library/Functions/CustomerFunctions';
import { useDispatchCustomerUpdate } from '../../../../Redux/Actions/CustomerActions';

interface Props {
  initialValue: ClientGroup | null;
  visible: boolean;
  onClose: () => void;
}

/**
 * CreateOrUpdateModal()
 * @param props
 * @constructor
 */
export default function CreateOrUpdateModal(props: Props) {
  const { initialValue, visible, onClose } = props;
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const [name, setName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [existing, setExisting] = React.useState<boolean>(false);

  const dispatchUpdateCustomer = useDispatchCustomerUpdate();

  const handleSave = React.useCallback(() => {
    setExisting(false);
    const found =
      customer.settings.client && customer.settings.client.groups
        ? customer.settings.client.groups.find((item) => item.name.toLowerCase() === name.toString())
        : undefined;

    if (!found) {
      setLoading(true);

      let groups: ClientGroup[] = [];
      if (initialValue) {
        groups = customer.settings.client.groups.map((item) =>
          item.id === initialValue.id ? { ...initialValue, name } : item,
        );
      } else {
        if (customer.settings.client && customer.settings.client.groups) {
          groups = [
            ...customer.settings.client.groups,
            { id: getNextFreeClientGroupId(customer.settings.client.groups), name },
          ];
        } else {
          groups = [{ id: 1, name }];
        }
      }

      dispatchUpdateCustomer({
        ...customer,
        settings: { ...customer.settings, client: { ...customer.settings.client, groups } },
      }).finally(() => {
        setLoading(false);
        onClose();
      });
    } else {
      setExisting(true);
    }
  }, [customer, dispatchUpdateCustomer, initialValue, name, onClose]);

  React.useEffect(() => {
    if (!visible) {
      setTimeout(() => setName(''), 500);
    }
  }, [visible]);

  React.useEffect(() => {
    if (initialValue) {
      setName(initialValue.name);
    }
  }, [initialValue]);

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'} disabled={loading}>
        {t('cancel')}
      </Button>
      <Button onPress={handleSave} colorStyle={'success'} disabled={!name || loading}>
        <div className={'flex justify-center items-center'}>
          {loading ? <i className={'fas fa-spin fa-spinner mr-2'} /> : null}
          {t('save')}
        </div>
      </Button>
    </div>
  );

  return (
    <AbstractModal
      visible={visible}
      type={'success'}
      headline={!initialValue ? t('addClientGroup') : t('editClientGroup')}
      buttons={renderButtons()}
      onBackdrop={onClose}
      icon={'far fa-layer-group'}
    >
      <div className={'flex flex-1 mt-6 flex-col'}>
        <input
          className={classNames(
            'py-3 px-4 flex-grow rounded-md border-gray-300 border focus:outline-0 placeholder:font-extralight',
            { 'border-red-500 border-2': existing },
          )}
          autoFocus
          placeholder={t('enterGroupName')}
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={loading}
        />
      </div>
    </AbstractModal>
  );
}
