import React from 'react';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { useCopyClipboard } from '../../Library/Hooks/GlobalHooks';
import Config from '../../Library/Config';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';

/**
 * ExternalLogin()
 * @constructor
 */
export default function ExternalLogin() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();
  const url = `${Config.domain}/external/login/${customer.customerId}`;

  return (
    <Container headline={t('externalLogin')} closable initialOpen={false} identifier={'CustomerExternalLogin'}>
      <div className={'text-gray-500'}>{t('modules.customer.externalLoginDescription')}</div>

      <div className={'mt-10 mb-1 font-light'}>{t('externalLoginUrl')}</div>
      <div className={'flex flex-1 flex-wrap px-4 py-3 border border-gray-200 rounded items-center'}>
        <div className={'flex- flex-1 break-all'}>{url}</div>
        <div>
          <i
            className={'far fa-copy text-gray-400 hover:text-black pl-6 cursor-pointer ApiKeyCopy'}
            onClick={() => copyToClipBoard(url)}
          />
          <Tooltip anchorSelect={'.ApiKeyCopy'}>{t('copyToClipboard')}</Tooltip>
        </div>
      </div>

      {clipboardCallout}
    </Container>
  );
}
