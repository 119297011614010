import React from 'react';
import { Client } from '../../../Library/Types/ClientTypes';
import classNames from 'classnames';
import { clientBuildDisplayName } from '../../../Library/Functions/ClientFunctions';
import { Tooltip } from 'react-tooltip';
import { Salutation } from '../../../Library/Types/GeneralTypes';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { buildTooltipClass } from '../../../Library/Functions/HelperFunctions';

interface Props {
  client: Client;
  onPress: (client: Client) => void;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { client, onPress } = props;
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const [groupName, setGroupName] = React.useState<string>('Unknown');
  const [toolTipClassName] = React.useState<string>(buildTooltipClass(client.clientId));

  React.useEffect(() => {
    const found = customer.settings.client.groups.find((item) => item.id === client.groupId);
    if (found) {
      setGroupName(found.name);
    } else {
      setGroupName('Unknown');
    }
  }, [client.groupId, customer.settings.client.groups]);

  const getIcon = (client: Client) => {
    if (client.salutation === Salutation.company) {
      return <i className={'far fa-building'} />;
    }
    if (client.salutation === Salutation.male) {
      return <i className={'far fa-male'} />;
    }
    return <i className={'far fa-female'} />;
  };

  const renderGroup = () => {
    if (customer.settings.client.groups && customer.settings.client.groups.length > 1) {
      return <td>{groupName}</td>;
    }
    return null;
  };

  return (
    <tr
      key={client.clientId}
      onClick={() => onPress(client)}
      className={classNames({ 'text-gray-400': !client.isActive })}
    >
      <td width={50}>{getIcon(client)}</td>
      <td>{clientBuildDisplayName(client)}</td>
      <td>{client.taxNumber}</td>
      {renderGroup()}
      <td>
        <i
          className={classNames(
            `${toolTipClassName}`,
            { 'far fa-plug-circle-check text-green-800': client.isConnected },
            { 'fa-kit fa-regular-plug-slash text-gray-300': !client.isConnected },
          )}
        />
        <Tooltip anchorSelect={`.${toolTipClassName}`}>
          {client.isConnected ? t('userAccountConnected') : t('userAccountNotConnected')}
        </Tooltip>
      </td>
    </tr>
  );
}
