import React from 'react';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  useDispatchCustomerMailTemplateGet,
  useDispatchCustomerMailTemplateSet,
} from '../../../Redux/Actions/Customer/MailTemplateActions';
import { MailTemplateType, Salutation } from '../../../Library/Types/GeneralTypes';
import { MailTemplate } from '../../../Library/Types/CustomerTypes';
import Input from '../../Forms/Input';
import validateClientRegistrationMailTemplate from '../../../Library/Validators/ClientRegistrationMailTemplate';
import AlertModal from '../../Modals/AlertModal';
import ConfirmModal from '../../Modals/ConfirmModal';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { useDispatchClientSendRegistrationMail } from '../../../Redux/Actions/Client/MailTemplateActions';
import { Client } from '../../../Library/Types/ClientTypes';
import { useProgressCallout, useSuccessCallout } from '../../../Library/Hooks/CalloutHooks';
import DisplayRow from '../../Display/DisplayRow';
import ActionButton from '../../Container/ActionButton';
import classNames from 'classnames';

/**
 * ClientRegisterSettings()
 * @constructor
 */
export default function ClientRegisterSettings() {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [value, setValue] = React.useState<string>('');
  const ref = React.useRef<ReactQuill | null>(null);
  const [template, setTemplate] = React.useState<MailTemplate | null>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [subject, setSubject] = React.useState<string>('');
  const [showError, setShowError] = React.useState<boolean>(false);
  const [askSendTestmail, setAskSendTestmail] = React.useState<boolean>(false);
  const [testMailEnabled, setTestMailEnabled] = React.useState<boolean>(true);
  const { callout, showCallout } = useSuccessCallout();
  const { calloutProgress, showProgressCallout, hideProgressCallout } = useProgressCallout();

  const dispatchSet = useDispatchCustomerMailTemplateSet();
  const dispatchGet = useDispatchCustomerMailTemplateGet();
  const dispatchSend = useDispatchClientSendRegistrationMail(true);

  const handleSendTestmail = React.useCallback(() => {
    if (template) {
      setAskSendTestmail(false);
      setTestMailEnabled(false);
      showProgressCallout();

      dispatchSend(template, {
        firstName: user.firstName,
        salutation: Salutation.male,
        lastName: user.lastName,
        mail: user.mail,
        customerId: user.customerId,
      } as Client).then(() => {
        hideProgressCallout();
        showCallout(t('testMailSendSuccessfully'));
        setTestMailEnabled(false);
        setTimeout(() => setTestMailEnabled(true), 60000);
      });
    }
  }, [
    dispatchSend,
    hideProgressCallout,
    showCallout,
    showProgressCallout,
    t,
    template,
    user.customerId,
    user.firstName,
    user.lastName,
    user.mail,
  ]);

  const handleSet = React.useCallback(() => {
    if (ref && ref.current && ref.current.editor) {
      const isValid = validateClientRegistrationMailTemplate(value, subject);

      if (isValid) {
        showProgressCallout();
        const preparedHtml = value.replaceAll('<p>', '<div>').replaceAll('</p>', '<div>');

        dispatchSet(MailTemplateType.clientRegistration, subject, preparedHtml, ref.current.editor.getText()).then(
          (newTemplate) => {
            hideProgressCallout();
            showCallout();
            setHasChanges(false);
            setTemplate(newTemplate);
          },
        );
      } else {
        setShowError(true);
      }
    }
  }, [dispatchSet, hideProgressCallout, showCallout, showProgressCallout, subject, value]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!template && !loaded) {
      timer = setTimeout(
        () =>
          dispatchGet(MailTemplateType.clientRegistration)
            .then(setTemplate)
            .catch(() => {})
            .finally(() => setLoaded(true)),
        500,
      );
    }

    return () => clearTimeout(timer);
  }, [dispatchGet, loaded, template]);

  React.useEffect(() => {
    if (template) {
      setValue(template.html);
      setSubject(template.subject);
    }
  }, [template]);

  const renderButtons = () => {
    return (
      <>
        <ActionButton
          onPress={() => setAskSendTestmail(true)}
          disabled={!testMailEnabled}
          icon={'fas fa-envelope-circle-check'}
          className={classNames(
            { 'text-blue-700': testMailEnabled },
            { 'hover:bg-blue-700 hover:text-white': testMailEnabled },
            { 'cursor-not-allowed': !testMailEnabled },
          )}
          tooltip={t('sendTestmail')}
        />
        <ActionButton
          onPress={handleSet}
          disabled={!hasChanges}
          icon={'fa fa-check-circle'}
          className={classNames(
            'text-green-700',
            { 'hover:bg-green-900 hover:text-white': hasChanges },
            { 'cursor-not-allowed': !hasChanges },
          )}
          tooltip={t('saveChanges')}
        />
      </>
    );
  };

  return (
    <>
      <Container
        headline={t('templateRegistrationMail')}
        actionButtons={renderButtons()}
        closable
        identifier={'ClientRegistrationMailTemplate'}
      >
        <p className={'text-gray-500 mb-6'}>{t('modules.settings.registerMandantDescription')}</p>

        <DisplayRow label={t('name')} value={`{{name}}`} showCopyIcon />
        <DisplayRow label={t('registrationUrl')} value={`{{registrationUrl}}`} showCopyIcon />

        <div className={'mt-6'} />
        <Input onChange={setSubject} value={subject} label={t('subject')} />
        <div style={{ minHeight: 300, paddingBottom: 50 }} className={'flex flex-1 mt-6 overflow-hidden'}>
          <ReactQuill
            placeholder={t('insertTemplateHere')}
            ref={ref}
            theme="snow"
            value={value}
            onChange={(param) => setValue(param)}
            onKeyPress={() => setHasChanges(true)}
            style={{ flexGrow: 1 }}
          />
        </div>
      </Container>

      {callout}
      {calloutProgress}

      <AlertModal
        headline={t('alert.invalidClientRegistrationTemplate.headline')}
        text={t('alert.invalidClientRegistrationTemplate.text')}
        visible={showError}
        onClose={() => setShowError(false)}
        type={'error'}
      />

      <ConfirmModal
        headline={t('alert.askSendTestMail.headline')}
        text={t('alert.askSendTestMail.text', { mail: user.mail })}
        visible={askSendTestmail}
        onClose={() => setAskSendTestmail(false)}
        onConfirm={handleSendTestmail}
        type={'primary'}
      />
    </>
  );
}
