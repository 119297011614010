import React from 'react';
import { ClientGroup } from '../../../../Library/Types/GeneralTypes';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';

interface Props {
  group: ClientGroup;
  onEdit: (group: ClientGroup) => void;
  onDelete: (group: ClientGroup) => void;
  showDelete: boolean;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { group, onEdit, onDelete, showDelete } = props;
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(false);

  const renderDelete = () => {
    if (showDelete) {
      return (
        <div>
          <i
            className={'fas fa-trash-alt text-gray-300 hover:text-red-700 cursor-pointer px-4'}
            onClick={() => setShow(true)}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={
        'py-3 pl-4 bg-gray-100 rounded mb-3 first:mt-6 hover:bg-gray-200 cursor-pointer flex-row flex items-center'
      }
    >
      <i className={'fas fa-layer-group mr-3 text-gray-400'} />
      <div className={'flex-grow'} onClick={() => onEdit(group)}>
        {group.name}
      </div>
      {renderDelete()}

      <AskDeleteModal
        headline={t('alert.askDeleteClientGroup.headline')}
        text={t('alert.askDeleteClientGroup.text', { name: group.name })}
        visible={show}
        onClose={() => setShow(false)}
        onDelete={() => onDelete(group)}
      />
    </div>
  );
}
