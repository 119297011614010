import React from 'react';
import AbstractModal from '../../../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import InputRow from '../../../Forms/InputRow';
import Button from '../../../Forms/Button';
import { BankAccount, Client } from '../../../../Library/Types/ClientTypes';
import { useDispatchClientUpdate } from '../../../../Redux/Actions/ClientActions';

interface Props {
  visible: boolean;
  onClose: () => void;
  client: Client;
}

/**
 * AddBankAccount()
 * @param props
 * @constructor
 */
export default function AddBankAccount(props: Props) {
  const { visible, onClose, client } = props;
  const [t] = useTranslation();
  const [iban, setIban] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatchUpdateClient = useDispatchClientUpdate();

  const addBankAccount = React.useCallback(() => {
    if (name && iban) {
      setLoading(true);
      const bankAccounts: BankAccount[] =
        client.settings && client.settings.bankAccounts
          ? [...client.settings.bankAccounts, { bankIban: iban.replaceAll(' ', ''), bankName: name }]
          : [{ bankIban: iban, bankName: name }];

      dispatchUpdateClient({ ...client, settings: { ...client.settings, bankAccounts } }).finally(() => {
        setLoading(false);
        onClose();
      });
    }
  }, [client, dispatchUpdateClient, iban, name, onClose]);

  React.useEffect(() => {
    if (!visible) {
      setLoading(false);
      setIban('');
      setName('');
    }
  }, [visible]);

  const renderButtons = () => {
    return (
      <div>
        <Button onPress={() => onClose()} colorStyle={'cancel'}>
          {t('cancel')}
        </Button>
        <Button onPress={addBankAccount} colorStyle={'success'} disabled={!name || !iban || loading}>
          <div className={'flex items-center'}>
            {loading ? <i className={'fa fa-spin fa-spinner mr-2'} /> : null}
            {t('save')}
          </div>
        </Button>
      </div>
    );
  };

  return (
    <AbstractModal
      headline={t('addBankAccount')}
      visible={visible}
      type={'primary'}
      buttons={renderButtons()}
      icon={'fas fa-building-columns text-green-700'}
    >
      <div className={'flex flex-1 flex-col'}>
        <div className={'text-gray-500'}>{t('addBankAccountDescription')}</div>
        <div className={'flex flex-1 flex-col mt-6'}>
          <InputRow value={name} onChange={setName} placeholder={t('bankName')} autoFocus />
          <InputRow containerClass={'-mt-4 -mb-6'} value={iban} onChange={setIban} placeholder={t('bankIban')} />
        </div>
      </div>
    </AbstractModal>
  );
}
