import React, { useCallback } from 'react';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Config from '../../Library/Config';
import Button from '../Forms/Button';
import RegisterAndLoginFooter from '../RegisterAndLoginFooter';
import Table from '../Table';
import classNames from 'classnames';
import { stripePriceToPrice } from '../../Library/Functions/HelperFunctions';
import { useDispatchStripeGetCustomerPortalUrl } from '../../Redux/Actions/StripeActions';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import ConfirmModal from '../Modals/ConfirmModal';

interface Props {
  invoices: any[];
  subscription: any;
  hasSubscription: boolean;
}

/**
 * Subscription()
 * @constructor
 */
export default function Subscription(props: Props) {
  const { invoices, subscription, hasSubscription } = props;
  const { customer } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showHint, setShowHint] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const dispatchGetPortal = useDispatchStripeGetCustomerPortalUrl();

  const handleOpenPortal = useCallback(() => {
    setShowHint(false);

    if (customer.stripeCustomerId) {
      setLoading(true);
      dispatchGetPortal(customer.stripeCustomerId as string).then((url) => {
        window.location.href = url;
        setTimeout(() => setLoading(false), 2000);
      });
    }
  }, [customer.stripeCustomerId, dispatchGetPortal]);

  const getNameByPriceId = () => {
    switch (subscription.items.data[0].plan.id) {
      case Config.stripe.pricePoints.smallBusiness.monthly:
      case Config.stripe.pricePoints.smallBusiness.yearly:
        return 'Small Business';
      case Config.stripe.pricePoints.professional.monthly:
      case Config.stripe.pricePoints.professional.yearly:
        return 'Professional';
      case Config.stripe.pricePoints.unlimited.monthly:
      case Config.stripe.pricePoints.unlimited.yearly:
        return 'Unlimited';
    }
  };

  const getSubscriptionHint = () => {
    if (!subscription.cancel_at_period_end) {
      return t('subscriptionRenewalHintActive', {
        date: moment.unix(subscription.current_period_end).format('LL'),
      });
    }
    return t('subscriptionRenewalHintInactive', {
      date: moment.unix(subscription.current_period_end).format('LL'),
    });
  };

  if (hasSubscription && subscription) {
    return (
      <>
        <div className={'flex flex-1'}>
          <div className={'flex-1 lg:basis-2/6 mr-4'}>
            <Container headline={t('yourSubscription')}>
              <div className={'flex flex1- flex-col items-center'}>
                <i
                  className={classNames(
                    { 'fas fa-check-circle text-green-700': !subscription.cancel_at_period_end },
                    { 'fas fa-power-off text-orange-400': subscription.cancel_at_period_end },
                    'text-5xl mb-8',
                  )}
                />
                <div className={'text-sm text-gray-400'}>{t('currentPlan')}</div>
                <div className={'text-3xl mb-10'}>{getNameByPriceId()}</div>
              </div>

              <div className={'flex flex-1 flex-row mb-2'}>
                <div className={'flex-1'}>{t('periodStartDate')}</div>
                <div>{moment.unix(subscription.current_period_start).format('LL')}</div>
              </div>
              <div className={'flex flex-1 flex-row mb-2'}>
                <div className={'flex-1'}>{subscription.cancel_at_period_end ? t('periodEndDate') : t('renewAt')}</div>
                <div>{moment.unix(subscription.current_period_end).format('LL')}</div>
              </div>
              <div className={'flex flex-1 flex-row mb-2'}>
                <div className={'flex-1'}>{t('subscriptionInterval')}</div>
                <div>{subscription.items.data[0].plan.interval === 'month' ? t('monthly') : t('yearly')}</div>
              </div>
              <div className={'flex flex-1 flex-row mb-2'}>
                <div className={'flex-1'}>{t('priceNetto')}</div>
                <div>{stripePriceToPrice(subscription.items.data[0].plan.amount.toString())}</div>
              </div>

              <div className={'mt-14'}>
                <Button onPress={() => setShowHint(true)} className={'w-full h-12 justify-center'}>
                  <div className={'text-center'}>
                    {loading ? <i className={'fas fa-spin fa-spinner'} /> : t('manageSubscription')}
                  </div>
                </Button>
              </div>
              <div className={'mt-6 px-4 text text-gray-700 text-center'}>{getSubscriptionHint()}</div>
              <div className={'mt-6 text-sm text-gray-400 text-center'}>{t('debitHint')}</div>
            </Container>
          </div>
          <div className={'flex-1 lg:basis-4/6'}>
            <Container headline={t('yourInvoices')}>
              <Table>
                <tbody>
                  {invoices.map((item) => (
                    <tr key={item.id}>
                      <td width={30}>
                        {item.status === 'paid' ? (
                          <i className={'fas fa-check-circle text-green-700'} />
                        ) : (
                          <i className={'fas fa-hourglass-half text-orange-500'} />
                        )}
                      </td>
                      <td>{`${moment.unix(item.period_start).format('LL')}`}</td>
                      <td>{item.status === 'paid' ? t('paid') : t('paymentWaiting')}</td>
                      <td>{stripePriceToPrice(item.amount_paid.toString())}</td>
                      <td width={30}>
                        <i
                          className={'fas fa-file-arrow-down text-gray-300 hover:text-blue-700'}
                          onClick={() => (window.location.href = item.invoice_pdf)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
          </div>
        </div>

        <RegisterAndLoginFooter />

        <ConfirmModal
          headline={t('alert.redirectManageSubscription.headline')}
          text={t('alert.redirectManageSubscription.text')}
          visible={showHint}
          onClose={() => setShowHint(false)}
          onConfirm={handleOpenPortal}
          type={'primary'}
        />
      </>
    );
  }
  return null;
}
