const validateClientRegistrationMailTemplate = (html?: string, subject?: string): boolean => {
  if (!html) {
    return false;
  }
  if (!subject) {
    return false;
  }
  if (html.indexOf('{{registrationUrl}}') < 0) {
    return false;
  }

  return true;
};

export default validateClientRegistrationMailTemplate;
