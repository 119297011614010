import { useAppDispatch, useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import React from 'react';
import { MailTemplateType } from '../../../Library/Types/GeneralTypes';
import { doc, DocumentReference, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { FirebasePath } from '../../../Library/Firebase';
import { MailTemplate } from '../../../Library/Types/CustomerTypes';
import {
  CUSTOMER_MAILTEMPLATE_GET_ERROR,
  CUSTOMER_MAILTEMPLATE_GET_START,
  CUSTOMER_MAILTEMPLATE_GET_SUCCESS,
  CUSTOMER_MAILTEMPLATE_SET_ERROR,
  CUSTOMER_MAILTEMPLATE_SET_START,
  CUSTOMER_MAILTEMPLATE_SET_SUCCESS,
} from '../../ActionTypes';

/**
 * useDispatchCustomerMailTemplateSet()
 */
export const useDispatchCustomerMailTemplateSet = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (template: MailTemplateType, subject: string, html: string, text: string) => {
      dispatch(CUSTOMER_MAILTEMPLATE_SET_START(template));

      const documentRef = doc(
        getFirestore(),
        FirebasePath.customers,
        customer.customerId,
        FirebasePath.mailTemplates,
        template,
      ) as DocumentReference<MailTemplate>;

      const templateItem: MailTemplate = {
        type: template,
        subject,
        text,
        html,
      };

      return setDoc(documentRef, templateItem)
        .then(() => {
          dispatch(CUSTOMER_MAILTEMPLATE_SET_SUCCESS(templateItem));
          return Promise.resolve(templateItem);
        })
        .catch((error) => {
          dispatch(CUSTOMER_MAILTEMPLATE_SET_ERROR(error));
          return Promise.reject(error);
        });
    },
    [customer.customerId, dispatch],
  );
};

/**
 * useDispatchCustomerMailTemplateGet()
 */
export const useDispatchCustomerMailTemplateGet = () => {
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (template: MailTemplateType) => {
      dispatch(CUSTOMER_MAILTEMPLATE_GET_START(template));

      const documentRef = doc(
        getFirestore(),
        FirebasePath.customers,
        customer.customerId,
        FirebasePath.mailTemplates,
        template,
      ) as DocumentReference<MailTemplate>;

      return getDoc(documentRef)
        .then((snapShot) => {
          if (snapShot.exists()) {
            dispatch(CUSTOMER_MAILTEMPLATE_GET_SUCCESS(snapShot.data()));
            return Promise.resolve(snapShot.data());
          }

          return Promise.reject('');
        })
        .catch((error) => {
          dispatch(CUSTOMER_MAILTEMPLATE_GET_ERROR(error));
          return Promise.reject(error);
        });
    },
    [customer.customerId, dispatch],
  );
};
