import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { buttonClass } from './Item';
import { useTranslation } from 'react-i18next';
import { STORAGE_SELECTED_LANGUAGE } from '../../../Library/Types/Constants';
import moment from 'moment';
import { getSelectedLanguage, momentConfiguration } from '../../../Library/Functions/LocalizationFunctions';
import i18n from 'i18next';
import { HashLink } from 'react-router-hash-link';

const de = require('../../../Assets/Images/de-DE.png');
const en = require('../../../Assets/Images/en-US.png');
const es = require('../../../Assets/Images/es-ES.png');

interface Props {
  showPrice: boolean;
  showStartPage: boolean;
  showRegister: boolean;
  showLogin: boolean;
}

const renderMenuItem = (caption: string, visible: boolean, navigateTo: string, image?: any, onPress?: () => void) => {
  const renderImage = () => {
    if (image) {
      return <img src={image} alt={'Country'} width={24} className={'mr-4'} />;
    }
    return null;
  };

  const renderContainer = (content: any, active: boolean) => {
    if (onPress) {
      return (
        <div
          onClick={onPress}
          className={classNames(
            { 'bg-gray-100 text-gray-900': active },
            { 'text-gray-700': !active },
            'block px-4 py-4 cursor-pointer',
            'border-b border-b-gray-100 rounded-md',
          )}
        >
          {content}
        </div>
      );
    } else {
      return (
        <HashLink
          to={navigateTo}
          className={classNames(
            { 'bg-gray-100 text-gray-900': active },
            { 'text-gray-700': !active },
            'block px-4 py-4 cursor-pointer',
            'border-b border-b-gray-100 rounded-md',
          )}
        >
          {content}
        </HashLink>
      );
    }
  };

  if (visible) {
    return (
      <Menu.Item key={caption}>
        {({ active }) =>
          renderContainer(
            <div className={'flex flex-1 flex-row'}>
              {renderImage()}
              <div className={classNames('flex flex-row items-center')}>{caption}</div>
            </div>,
            active,
          )
        }
      </Menu.Item>
    );
  }
  return null;
};

/**
 * MobileMenu()
 * @param props
 * @constructor
 */
export default function MobileMenu(props: Props) {
  const { showPrice, showStartPage, showRegister } = props;
  const { t } = useTranslation();

  const setLanguage = React.useCallback((lng: string) => {
    // Change language
    localStorage.setItem(STORAGE_SELECTED_LANGUAGE, lng);
    moment().locale(getSelectedLanguage(false));
    moment.localeData(getSelectedLanguage(false)).set(momentConfiguration(getSelectedLanguage(true)));
    i18n.changeLanguage(getSelectedLanguage(true)).then(() => {});
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={classNames(buttonClass)}>
          <i className={'fas fa-bars text-xl'} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="
            absolute right-0 z-20 mt-6 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1
            ring-black ring-opacity-5 focus:outline-none
          "
        >
          <div>
            <div className={classNames('uppercase text-gray-500 bg-gray-200 px-4 py-4 rounded-md')}>{t('menu')}</div>
            {renderMenuItem(t('startPage'), showStartPage, '/')}
            {renderMenuItem(t('modules.website.prices'), showPrice, '/#price')}
            {renderMenuItem(t('modules.website.contact'), true, '/contact')}
            {renderMenuItem(t('register'), showRegister, '/register')}

            <div className={classNames('uppercase text-gray-500 bg-gray-200 px-4 py-4')}>{t('language')}</div>

            {renderMenuItem(t('languages.de-DE'), true, '', de, () => setLanguage('de-DE'))}
            {renderMenuItem(t('languages.es-ES'), true, '', es, () => setLanguage('es-ES'))}
            {renderMenuItem(t('languages.en-EN'), true, '', en, () => setLanguage('en-EN'))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
