import styled from 'styled-components';
import LeftMenu from '../Navigation/LeftMenu';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';
import TopNavbar from '../Navigation/TopNavbar';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import React, { ReactElement } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Unsubscribe } from 'firebase/firestore';
import {
  useDispatchDocumentsOnListenClientChanges,
  useDispatchDocumentsOnListenCustomerChanges,
} from '../../Redux/Actions/DocumentActions';
import { isMobile } from 'react-device-detect';
import UseAppHint from './UseAppHint';

interface Context {
  setHeadline: (value: string) => void;
  headline: string;
  setButton: (value: ReactElement | null) => void;
  button: React.ReactElement | null;
  setShowLoading: (value: boolean) => void;
  showLoading: boolean;
  showBack: boolean;
  setShowBack: (value: boolean) => void;
}

export const NavbarContext = React.createContext<Context>({
  setHeadline: () => {},
  headline: '',
  setButton: () => {},
  button: null,
  showLoading: false,
  setShowLoading: () => {},
  showBack: false,
  setShowBack: () => {},
});

export const menuWidthLarge = 300;
export const menuWidthSmall = 80;

const Container = styled.div<{ $marginLeft: number }>`
  display: flex;
  flex: 1;

  .Content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: ${(props) => props.$marginLeft}px;
  }
`;

const ContentContainer = styled.div`
  background-color: #f3f4f6;
  flex-direction: column;
  padding: 30px 30px;
  height: 100%;
`;

/**
 * AuthedLayout()
 * @constructor
 */
export default function AuthedLayout() {
  const route = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, user, customer, assignedClientIds } = useAppSelector((state) => state.auth);
  const [headline, setHeadline] = React.useState<string>('');
  const [navButton, setNavButton] = React.useState<React.ReactElement | null>(null);
  const [showNavLoading, setShowNavLoading] = React.useState<boolean>(false);
  const [showBackButton, setShowBackButton] = React.useState<boolean>(false);
  const [smallMenu, setSmallMenu] = React.useState<boolean>(false);

  const dispatchOnListenCustomer = useDispatchDocumentsOnListenCustomerChanges();
  const dispatchOnListenClient = useDispatchDocumentsOnListenClientChanges();

  const handleAuthStateChange = React.useCallback(
    (state: any) => {
      if (!state) {
        navigate('/login');
      }
    },
    [navigate],
  );

  function handleWindowSizeChange() {
    setSmallMenu(window.innerWidth < 1280);
  }

  React.useEffect(() => {
    setSmallMenu(window.innerWidth < 1280);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  React.useEffect(() => {
    let unsubscribeCustomer: Unsubscribe;
    let unsubscribeClient: Unsubscribe;

    if (user.isCustomerUser && customer) {
      unsubscribeCustomer = dispatchOnListenCustomer(customer.customerId);
    }
    if (!user.isCustomerUser && assignedClientIds && assignedClientIds.length > 0) {
      unsubscribeClient = dispatchOnListenClient(assignedClientIds);
    }

    return () => {
      if (unsubscribeCustomer) {
        unsubscribeCustomer();
      }
      if (unsubscribeClient) {
        unsubscribeClient();
      }
    };
  }, [assignedClientIds, customer, dispatchOnListenClient, dispatchOnListenCustomer, user.isCustomerUser]);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  const handleSetHeadline = React.useCallback(
    (value: string) => {
      if (value !== headline) {
        setHeadline(value);
      }
    },
    [headline],
  );

  const handleSetButton = React.useCallback((value?: any) => {
    setNavButton(value);
  }, []);

  React.useEffect(() => {
    setNavButton(null);
    setShowBackButton(false);
    setShowNavLoading(false);
  }, [route.key]);

  if (isLoggedIn) {
    if (!isMobile) {
      return (
        <NavbarContext.Provider
          value={{
            setHeadline: handleSetHeadline,
            headline,
            button: navButton,
            setButton: handleSetButton,
            showLoading: showNavLoading,
            setShowLoading: setShowNavLoading,
            showBack: showBackButton,
            setShowBack: setShowBackButton,
          }}
        >
          <Container $marginLeft={smallMenu ? menuWidthSmall : menuWidthLarge}>
            <LeftMenu smallMenu={smallMenu} />

            <div className="Content">
              <TopNavbar />
              <ContentContainer>
                <Outlet />
              </ContentContainer>
            </div>
          </Container>
        </NavbarContext.Provider>
      );
    }
    return <UseAppHint />;
  }
  return <Navigate replace to={'/login'} />;
}
