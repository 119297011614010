import React from 'react';
import ActionButton from '../../Container/ActionButton';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import { useDispatchDocumentsDownloadAllOpen } from '../../../Redux/Actions/DocumentActions';
import moment from 'moment/moment';
import { downloadBase64Pdf } from '../../../Library/Functions/HelperFunctions';
import classNames from 'classnames';

interface Props {
  openDocuments: DocumentType[];
  clientId: string;
}

/**
 * DownloadOpenDocumentsButton()
 * @param props
 * @constructor
 */
export default function DownloadOpenDocumentsButton(props: Props) {
  const { openDocuments, clientId } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatchDownloadList = useDispatchDocumentsDownloadAllOpen();

  const handleDownload = React.useCallback(() => {
    setLoading(true);

    dispatchDownloadList(clientId)
      .then((base64Zip) => {
        openDocuments.sort((a, b) =>
          moment(a.firebaseInvoiceDate.toDate()).isAfter(moment(b.firebaseInvoiceDate.toDate())) ? 1 : -1,
        );
        const minDate = moment(openDocuments[0].firebaseInvoiceDate.toDate()).format('DDMMYYYY');
        const maxDate = moment(openDocuments[openDocuments.length - 1].firebaseInvoiceDate.toDate()).format('DDMMYYYY');
        // eslint-disable-next-line max-len
        const fileName = `${clientId}_${minDate}_${maxDate}.zip`;
        downloadBase64Pdf(base64Zip.data, fileName);
        return Promise.resolve();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId, dispatchDownloadList, openDocuments]);

  return (
    <div style={{ display: 'flex' }}>
      <ActionButton
        icon={classNames({ 'fas fa-cloud-download': !loading }, { 'fas fa-spin fa-spinner': loading })}
        onPress={handleDownload}
        className={'hover:bg-blue-500 hover:text-white'}
        tooltip={t('downloadAllOpen')}
      />
    </div>
  );
}
