import React from 'react';
import styled from 'styled-components';
import Headline from '../Headline';
import classNames from 'classnames';
import ActionButton from './ActionButton';

const ContentContainer = styled.div`
  border: 1px solid ${(props) => props.theme.borderColor};
  ${(props) => props.theme.templates.borderRadius};
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 15px;
  display: flex;
`;

const InnerContainer = styled.div`
  flex: 1;
`;

const HeadlineContainer = styled.div<{ open: boolean }>`
  height: 70px;
  border-top-left-radius: ${(props) => props.theme.borderRadius};
  border-top-right-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => (!props.open ? props.theme.borderRadius : '0')};
  border-bottom-left-radius: ${(props) => (!props.open ? props.theme.borderRadius : '0')};
`;

interface Props {
  headline?: string;
  children: React.ReactNode;
  containerStyle?: object;
  boxShadow?: boolean;
  closable: boolean;
  initialOpen: boolean;
  identifier?: string;
  noPadding: boolean;
  actionButtons?: React.ReactElement;
  showLoading: boolean;
  containerClassNames?: string;
  contentContainerClass?: string;
}

/**
 * Container()
 * @param props
 * @constructor
 */
export default function Container(props: Props) {
  const {
    children,
    headline,
    containerStyle,
    boxShadow,
    closable,
    initialOpen,
    identifier,
    noPadding,
    actionButtons,
    showLoading,
    containerClassNames,
    contentContainerClass,
  } = props;
  const [open, setOpen] = React.useState<boolean>(initialOpen);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (closable && !loaded && identifier) {
      const state = localStorage.getItem(`@ContainerState_${identifier}`);
      if (state !== null) {
        if (state === 'closed') {
          setOpen(false);
        } else {
          setOpen(true);
        }
      }
      setLoaded(true);
    }
  }, [closable, identifier, loaded]);

  const handleToggleState = () => {
    if (open) {
      if (identifier) {
        localStorage.setItem(`@ContainerState_${identifier}`, 'closed');
      }
      setOpen(false);
    } else {
      if (identifier) {
        localStorage.setItem(`@ContainerState_${identifier}`, 'open');
      }
      setOpen(true);
    }
  };

  const renderLoading = () => {
    if (showLoading) {
      return <i className={classNames('fas', 'fa fa-spin fa-spinner', 'cursor-pointer', 'p-3', 'text-blue-500')} />;
    }
    return null;
  };

  const renderClosable = () => {
    if (closable && !showLoading) {
      return (
        <div>
          <ActionButton
            onPress={handleToggleState}
            className={classNames(
              'fas',
              { 'fa-chevron-down': !open },
              { 'fa-chevron-up': open },
              'cursor-pointer',
              'p-3',
              'text-gray-400',
              'hover:bg-gray-200 rounded-md hover:text-gray-700',
            )}
          />
        </div>
      );
    }
    return null;
  };

  const renderActionButtons = () => {
    if (actionButtons && open && !showLoading) {
      return actionButtons;
    }
    return null;
  };

  const renderHeadline = () => {
    if (headline) {
      return (
        <HeadlineContainer
          open={open}
          className={classNames('flex', 'flex-row', 'items-center', 'px-4', 'py-4', { 'border-b': open }, 'bg-white')}
        >
          <Headline size={'medium'} className={'flex-grow p-1'}>
            {headline}
          </Headline>
          {renderActionButtons()}
          {renderClosable()}
          {renderLoading()}
        </HeadlineContainer>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (open) {
      return (
        <InnerContainer className={classNames({ 'p-8': !noPadding }, contentContainerClass)}>{children}</InnerContainer>
      );
    }
    return null;
  };

  return (
    <ContentContainer
      style={containerStyle}
      className={classNames({ 'shadow-2xl': boxShadow }, { 'cursor-pointer': !open }, containerClassNames)}
      onClick={!open ? () => handleToggleState() : () => {}}
    >
      {renderHeadline()}
      {renderContent()}
    </ContentContainer>
  );
}

Container.defaultProps = {
  closable: false,
  initialOpen: true,
  noPadding: false,
  showLoading: false,
};
