import React, { useCallback } from 'react';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import Container from '../../Container/Container';
import InputRow from '../../Forms/InputRow';
import { useTranslation } from 'react-i18next';
import ListBoxSelectLanguage from '../../Forms/PredefinedListBox/ListBoxSelectLanguage';
import { User as UserType } from '../../../Library/Types/UserTypes';
import { Language } from '../../../Library/Types/GeneralTypes';
import ActionButton from '../../Container/ActionButton';
import classNames from 'classnames';
import { useDispatchUserUpdate } from '../../../Redux/Actions/UserActions';
import { useSuccessCallout } from '../../../Library/Hooks/CalloutHooks';
import { STORAGE_SELECTED_LANGUAGE } from '../../../Library/Types/Constants';
import moment from 'moment/moment';
import { getSelectedLanguage, momentConfiguration } from '../../../Library/Functions/LocalizationFunctions';
import i18n from 'i18next';
import ChangePasswordModal from './ChangePasswordModal';

export type State = {
  user: UserType;
  errors: { [K in keyof UserType]?: boolean };
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: UserType }
  | { type: 'firstName'; payload: string }
  | { type: 'lastName'; payload: string }
  | { type: 'resetChanges' }
  | { type: 'errors'; payload: { [K in keyof UserType]?: boolean } }
  | { type: 'language'; payload: Language };

export const initialState: State = {
  user: {} as UserType,
  errors: {},
  hasChanges: false,
  initialized: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'firstName':
      return { ...state, user: { ...state.user, firstName: action.payload }, hasChanges: true };
    case 'lastName':
      return { ...state, user: { ...state.user, lastName: action.payload }, hasChanges: true };
    case 'language':
      return { ...state, user: { ...state.user, language: action.payload }, hasChanges: true };
    case 'errors':
      return { ...state, errors: action.payload, hasChanges: true };
    case 'resetChanges':
      return { ...state, hasChanges: false, errors: {} };
    case 'init':
      return { ...initialState, user: action.payload, initialized: true };
    default:
      throw new Error('Invalid type!');
  }
};

/**
 * User()
 * @constructor
 */
export default function User() {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { callout, showCallout } = useSuccessCallout();

  const dispatchUserUpdate = useDispatchUserUpdate();

  const handleUpdate = useCallback(() => {
    dispatch({ type: 'errors', payload: {} });

    if (state.user.firstName && state.user.lastName) {
      setLoading(true);
      dispatchUserUpdate(state.user)
        .then(() => {
          dispatch({ type: 'resetChanges' });
          showCallout();

          // Change language
          localStorage.setItem(STORAGE_SELECTED_LANGUAGE, state.user.language);
          moment.locale(getSelectedLanguage(false));
          moment.localeData(getSelectedLanguage(false)).set(momentConfiguration(getSelectedLanguage(true)));
          i18n.changeLanguage(getSelectedLanguage(true)).then(() => {});

          return Promise.resolve();
        })
        .finally(() => setLoading(false));
    } else {
      dispatch({ type: 'errors', payload: { firstName: !state.user.firstName, lastName: !state.user.lastName } });
    }
  }, [dispatchUserUpdate, showCallout, state.user]);

  React.useEffect(() => {
    if (!state.initialized && user) {
      dispatch({ type: 'init', payload: user });
    }
  }, [state.initialized, user]);

  const handlePasswordSuccess = () => {
    setShowPassword(false);
    showCallout(t('passwordChangedSuccessfully'));
  };

  const renderActionButtons = () => {
    if (state.hasChanges) {
      if (!loading) {
        return (
          <>
            <ActionButton
              onPress={() => dispatch({ type: 'init', payload: user })}
              icon={'fas fa-times-circle hover:bg-gray-400 hover:text-white'}
              className={classNames()}
              tooltip={t('cancel')}
            />
            <ActionButton
              onPress={handleUpdate}
              icon={'fas fa-check-circle'}
              className={classNames('text-green-700 hover:bg-green-700 hover:text-white')}
              tooltip={t('saveChanges')}
            />
          </>
        );
      }
      return (
        <ActionButton
          onPress={() => {}}
          icon={'fas fa-spin fa-spinner'}
          className={classNames()}
          tooltip={t('saving')}
        />
      );
    }
    return undefined;
  };

  return (
    <Container headline={t('personalData')} actionButtons={renderActionButtons()}>
      <InputRow
        onChange={(payload) => dispatch({ type: 'firstName', payload })}
        value={state.user.firstName}
        label={t('firstName')}
        direction={'row'}
        hasError={state.errors && state.errors.firstName}
      />
      <InputRow
        onChange={(payload) => dispatch({ type: 'lastName', payload })}
        value={state.user.lastName}
        label={t('lastName')}
        direction={'row'}
        hasError={state.errors && state.errors.lastName}
      />
      <ListBoxSelectLanguage
        onChange={(payload) => dispatch({ type: 'language', payload })}
        selected={state.user.language}
        direction={'row'}
      />
      <InputRow onChange={() => {}} value={user.mail} label={t('mail')} direction={'row'} disabled />

      <InputRow
        onChange={() => {}}
        value={'DummyPassword'}
        label={t('password')}
        direction={'row'}
        type={'password'}
        disabled
        rightButtonCaption={t('changePassword')}
        rightButtonIcon={'fas fa-key'}
        onRightButtonPress={() => setShowPassword(true)}
      />

      <ChangePasswordModal
        visible={showPassword}
        onClose={() => setShowPassword(false)}
        onSuccess={handlePasswordSuccess}
      />

      {callout}
    </Container>
  );
}
