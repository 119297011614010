import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../../Container/Container';
import Button from '../../Forms/Button';
import classNames from 'classnames';
import ShowHideContainer from '../../ShowHideContainer';

interface Props {
  onPress: () => void;
  priceString: string | React.ReactElement;
  rebateString?: string | React.ReactElement;
  yearlyFull?: null | React.ReactElement;
  isMonthly: boolean;
  caption: string;
  children: React.ReactElement;
  buttonColor?: 'primary' | 'success' | 'cancel' | 'error';
  shadow?: boolean;
  showBuyButton: boolean;
  headerClass?: string;
}

/**
 * AbstractProductBox()
 * @param props
 * @constructor
 */
export default function AbstractProductBox(props: Props) {
  const {
    onPress,
    caption,
    children,
    showBuyButton,
    isMonthly,
    priceString,
    buttonColor,
    rebateString,
    shadow,
    yearlyFull,
    headerClass,
  } = props;
  const { t } = useTranslation();

  return (
    <Container
      containerStyle={{ textAlign: 'center', overflow: 'hidden' }}
      noPadding
      containerClassNames={classNames({ 'drop-shadow-2xl': shadow }, 'flex flex-1 h-full')}
    >
      <div className={'flex flex-1 flex-col h-full'}>
        <div className={'flex flex-1 flex-col'}>
          <div
            className={classNames(
              'rounded-t-md -mt-1 text-black border-t flex flex-row justify-center py-4 ' +
                'border-b border-b-gray-200',
              headerClass,
            )}
          >
            <div className={'text-2xl text-center'}>{caption}</div>
          </div>

          <div className={'my-10'}>
            {rebateString || null}
            <div className={'text-3xl'}>{`${priceString}*`}</div>
            <div className={'text-gray-800 mt-10'}>
              {isMonthly ? t('subscriptionMonthly') : t('subscriptionYearly')}
            </div>
            {yearlyFull || null}
            <div className={'text-gray-400 text-sm'}>{`*${t('subscriptionHinNetto')}`}</div>
          </div>

          <div className={'flex flex-row justify-center mb-10 text-yellow-400'}>
            <i className={'fas fa-star text-center mx-1'} />
            <i className={'fas fa-star text-center mx-1'} />
            <i className={'fas fa-star text-center mx-1'} />
          </div>

          <div className={classNames('px-8', { 'pb-20': !showBuyButton })}>{children}</div>
        </div>

        <div className={classNames('text-xs px-16 text-gray-400 mt-10', { 'mb-10': !showBuyButton })}>
          {t('subscriptionCancelHint')}
        </div>

        <ShowHideContainer visible={showBuyButton}>
          <div>
            <Button
              onPress={onPress}
              colorStyle={buttonColor || 'purple'}
              className={'mt-6 mb-10 w-1/2 justify-center'}
            >
              <div className={'text-lg py-2'}>{t('buyNow')}</div>
            </Button>
          </div>
        </ShowHideContainer>
      </div>
    </Container>
  );
}
