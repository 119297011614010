import React from 'react';
import { User } from '../../../Library/Types/UserTypes';
import Table from '../../Table';
import { useTranslation } from 'react-i18next';
import Row from './Row';

interface Props {
  users: User[];
  onPress: (user: User) => void;
}

/**
 * ListView()
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const { users, onPress } = props;
  const { t } = useTranslation();
  const [sorted, setSorted] = React.useState<User[]>([...users]);

  React.useEffect(() => {
    if (users) {
      setSorted([...users]);
    }
  }, [users]);

  const renderUsers = () => {
    if (sorted) {
      return sorted
        .sort((a, b) => a.lastName.localeCompare(b.lastName))
        .map((user) => <Row user={user} key={user.userId} onPress={onPress} />);
    }
  };

  if (sorted) {
    return (
      <Table showVerticalBorder>
        <thead>
          <tr>
            <th style={{ width: 50 }}></th>
            <th>{t('name')}</th>
            <th>{t('mail')}</th>
          </tr>
        </thead>
        <tbody>{renderUsers()}</tbody>
      </Table>
    );
  }
  return null;
}
