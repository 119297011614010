import React from 'react';
import styled from 'styled-components';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  > .Sub {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const TextBox = styled.div`
  flex-grow: 1;
  padding: 10px;
  ${(props) => props.theme.templates.borderRadius};
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;

  &:hover {
  }
`;

interface Props {
  description?: string;
  texts: string[];
  onPress: (text: string) => void;
}

/**
 * TextList()
 * @param props
 * @constructor
 */
export default function TextList(props: Props) {
  const { texts, onPress, description } = props;
  const { t } = useTranslation();

  const renderDescription = () => {
    if (description) {
      return <div className={'text-gray-500 mb-6 -mt-3 font-light'}>{description}</div>;
    }
    return null;
  };

  if (texts && texts.length > 0) {
    return (
      <Container headline={t('textBlocks')} closable initialOpen={false} identifier={'textBlocks'}>
        <InnerContainer>
          {renderDescription()}
          <div className={'Sub'}>
            {texts.map((text, i) => (
              <TextBox
                className={
                  'shadow-md hover:shadow-sm bg-gray-50 border-2 border-gray-200 text-gray-500 hover:text-black'
                }
                key={text}
                onClick={() => onPress(text)}
              >
                {text}
              </TextBox>
            ))}
          </div>
        </InnerContainer>
      </Container>
    );
  }
  return null;
}
