import React from 'react';
import styled from 'styled-components';

const TableContainer = styled.table`
  tr {
    border-bottom: 1px solid lightgray;

    &:last-child {
      border: 0;
    }
  }

  td {
    padding: 14px;
    font-weight: 400;

    &.mandatory {
      font-weight: 600;
      &:after {
        content: '*';
      }
    }

    &:first-child {
      font-size: 16px;
      width: 400px;
    }

    &:last-child {
      color: gray;
    }
  }
`;

interface Props {
  children: React.ReactElement[] | React.ReactElement;
}

/**
 * ApiTable()
 * @param props
 * @constructor
 */
export default function ApiTable(props: Props) {
  const { children } = props;

  return (
    <div className={'border border-gray-300 rounded-xl mt-6'}>
      <TableContainer className={'w-full'}>
        <tbody>{children}</tbody>
      </TableContainer>
    </div>
  );
}
