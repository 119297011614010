import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  errorCode: string | null;
}

/**
 * AuthenticationError()
 * @param props
 * @constructor
 */
export default function AuthenticationError(props: Props) {
  const { errorCode } = props;
  const { t } = useTranslation();

  if (errorCode) {
    return (
      <div className={'p-3 bg-red-400 border-l-8 border-l-red-800 mb-6 text-white font-bold'}>
        {t(`authErrors.${errorCode.replace('auth/', '')}`)}
      </div>
    );
  }
  return null;
}
