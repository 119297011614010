import React from 'react';
import { Endpoint } from '../ApiTypes';
import AbstractEndpoint from '../AbstractEndpoint';

export const OAuthGetEndpoint: Endpoint = {
  name: 'OAuth Get',
  description:
    'For this endpoint no authorization header is needed! This endpoint generates and returns an OAuth URL ' +
    'to identify the user and to return the current api key to the provided callback function. The OAuth Url expires ' +
    'automatically after 10 minutes. Once received the url, just redirect the user to the given website. ' +
    'If the authentication of the user was successfully, we will callback the token to your callback url as a POST ' +
    'request with JSON-Data in the body. The body looks like "{token: \'...123ergdfgdf\'}". If you provide a ' +
    'redirectUrl, we will redirect the user to the given url after successfully authenticated.',
  method: 'post',
  path: '/oauth',
  requestBody: {
    type: 'JSON-Object',
    data: [
      {
        type: 'string',
        key: 'platform',
        mandatory: true,
        description:
          'The name of your platform requesting the OAuth URL. This will be shown to the customer to verify ' +
          'that he will grand access to the correct participant',
      },
      {
        type: 'string',
        key: 'callbackUrl',
        mandatory: true,
        description:
          'The url wich will be called to send the new api token. The call will be a post request with JSON-Data in ' +
          'body. Example: { token: "...123ergdfgdf" } The url must have https protokoll.',
      },
      {
        type: 'string',
        key: 'redirectUrl',
        mandatory: false,
        description:
          'The url the user will be redirected after successfully authenticated. If no url is provided the user ' +
          'just be informed to close the window',
      },
    ],
  },
  responseBody: {
    type: 'JSON-Object',
    data: [
      {
        key: 'token',
        mandatory: true,
        type: 'string',
        description:
          'The token to be used for all requests in the request authorization header. The response to the callback ' +
          'url will be an JSON-Object like "{token: "thetokentobeused..."}"',
      },
    ],
  },
};

/**
 * ClientCreate()
 * @constructor
 */
export default function OAuthGet() {
  return <AbstractEndpoint endpoint={OAuthGetEndpoint} />;
}
