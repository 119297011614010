import React from 'react';
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { FirebasePath } from '../../Library/Firebase';
import { User } from '../../Library/Types/UserTypes';
import { useAppDispatch } from '../../Library/Hooks/ReduxHooks';
import {
  USER_GETLIST_ERROR,
  USER_GETLIST_START,
  USER_GETLIST_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
} from '../ActionTypes';
import { Client } from '../../Library/Types/ClientTypes';
import { Language } from '../../Library/Types/GeneralTypes';
import { getMappedLanguageFromBrowser } from '../../Library/Functions/LocalizationFunctions';
import moment from 'moment';

/**
 * useDispatchUserRegisterInternal()
 */
export const useDispatchUserRegisterInternal = () => {
  return React.useCallback(
    (firstName: string, lastName: string, mail: string, password: string, customerId: string) => {
      return createUserWithEmailAndPassword(getAuth(), mail.toLowerCase(), password).then((authResponse) => {
        const auth = getAuth();
        if (auth && auth.currentUser) {
          return updateProfile(auth.currentUser, { displayName: `${firstName} ${lastName}` }).then(() => {
            const collectionRef = collection(getFirestore(), FirebasePath.users);
            const user: User = {
              userId: '',
              isCustomerUser: true,
              lastName,
              firstName,
              mail: mail.toLowerCase(),
              uuid: authResponse.user.uid,
              isCustomerAdmin: false,
              customerId,
              isActive: false,
              clients: [],
              language: getMappedLanguageFromBrowser() as Language,
              createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
              isBeta: false,
            };
            return addDoc(collectionRef, user);
          });
        } else {
          return Promise.reject();
        }
      });
    },
    [],
  );
};

/**
 * useDispatchUserRegisterClient()
 */
export const useDispatchUserRegisterClient = () => {
  return React.useCallback((firstName: string, lastName: string, mail: string, password: string, clientId: string) => {
    return createUserWithEmailAndPassword(getAuth(), mail.toLowerCase(), password).then((authResponse) => {
      const auth = getAuth();
      if (auth && auth.currentUser) {
        return updateProfile(auth.currentUser, { displayName: `${firstName} ${lastName}` }).then(() => {
          const collectionRef = collection(getFirestore(), FirebasePath.users);
          const user: User = {
            userId: '',
            isCustomerUser: false,
            lastName,
            firstName,
            mail: mail.toLowerCase(),
            uuid: authResponse.user.uid,
            isCustomerAdmin: false,
            customerId: null,
            isActive: true,
            clients: [clientId],
            language: getMappedLanguageFromBrowser() as Language,
            createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            isBeta: false,
          };
          return addDoc(collectionRef, user).then(() => {
            const docRefClient = doc(getFirestore(), FirebasePath.clients, clientId) as DocumentReference<Client>;
            return setDoc(docRefClient, { isConnected: true }, { merge: true });
          });
        });
      } else {
        return Promise.reject();
      }
    });
  }, []);
};

/**
 * useDispatchUserGetByCustomer()
 */
export const useDispatchUserGetByCustomer = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (customerId: string) => {
      dispatch(USER_GETLIST_START(customerId));

      const collectionRef = collection(getFirestore(), FirebasePath.users) as CollectionReference<User>;
      const queryRef = query(collectionRef, where('customerId', '==', customerId));

      return getDocs(queryRef)
        .then((snapShot) => {
          let users: User[] = [];
          if (!snapShot.empty) {
            snapShot.forEach((item) => {
              users.push({ ...item.data(), userId: item.id });
            });
          }
          dispatch(USER_GETLIST_SUCCESS(users));
          return Promise.resolve(users);
        })
        .catch((error) => {
          dispatch(USER_GETLIST_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchUserUpdate()
 */
export const useDispatchUserUpdate = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (user: User) => {
      dispatch(USER_UPDATE_START(user));

      const docRef = doc(getFirestore(), FirebasePath.users, user.userId) as DocumentReference<User>;

      return setDoc(docRef, user)
        .then(() => {
          dispatch(USER_UPDATE_SUCCESS(user));
          return Promise.resolve(user);
        })
        .catch((error) => {
          dispatch(USER_UPDATE_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
