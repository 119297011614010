import React from 'react';
import { Storage } from '../../Library/Types/StorageTypes';
import AbstractModal from '../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';
import { storageFileTypeToName, storageGetFileTypeIcon } from '../../Library/Functions/StorageFunctions';
import classNames from 'classnames';
import Input, { InputType } from '../Forms/Input';
import { formatSize } from '../../Library/Functions/HelperFunctions';
import ShowHideContainer from '../ShowHideContainer';
import { useDispatchStorageUpdate } from '../../Redux/Actions/StorageActions';

interface Props {
  document: Storage;
  visible: boolean;
  onClose: () => void;
}

export type State = {
  name: string;
  description: string;
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: Storage }
  | { type: 'name'; payload: string }
  | { type: 'description'; payload: string };

export const initialState: State = {
  name: '',
  description: '',
  hasChanges: false,
  initialized: false,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'name':
      return { ...state, name: action.payload, hasChanges: true };
    case 'description':
      return { ...state, description: action.payload, hasChanges: true };
    case 'clear':
      return { ...initialState };
    case 'init':
      return {
        ...initialState,
        name: action.payload.name,
        description: action.payload.description || '',
        initialized: true,
        hasChanges: false,
      };
    default:
      throw new Error('Invalid type!');
  }
};

/**
 * InformationModal()
 * @param props
 * @constructor
 */
export default function InformationModal(props: Props) {
  const { visible, onClose, document } = props;
  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatchUpdate = useDispatchStorageUpdate();

  const handleUpdate = React.useCallback(() => {
    if (state.name && state.name.length > 1) {
      setLoading(true);
      dispatchUpdate({ ...document, name: state.name, description: state.description }).finally(() => {
        setLoading(false);
        onClose();
      });
    }
  }, [dispatchUpdate, document, onClose, state.description, state.name]);

  React.useEffect(() => {
    if (visible && !state.initialized) {
      dispatch({ type: 'init', payload: document });
    }
  }, [document, state.initialized, visible]);

  React.useEffect(() => {
    if (!visible) {
      dispatch({ type: 'clear' });
    }
  }, [visible]);

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'} disabled={loading}>
        {t('cancel')}
      </Button>
      <Button onPress={handleUpdate} colorStyle={'success'} disabled={!state.hasChanges || loading}>
        <div className={'flex justify-center items-center'}>
          <ShowHideContainer visible={loading}>
            <i className={'fas fa-spin fa-spinner mr-2'} />
          </ShowHideContainer>
          {t('save')}
        </div>
      </Button>
    </div>
  );

  return (
    <AbstractModal visible={visible} buttons={renderButtons()} type={'success'} headline={''} hideIcon={true}>
      <div className={classNames('flex flex-1 items-start flex-row')}>
        <div className={'mr-10'}>
          <div className={classNames('p-5 bg-gray-200 rounded-xl shadow-2xl')}>
            <i style={{ fontSize: 70 }} className={storageGetFileTypeIcon(document)} />
          </div>
          <div className={'text-center text-gray-500 text-sm mt-5'}>
            <div>{storageFileTypeToName(document)}</div>
            <div>{formatSize(document.size)}</div>
          </div>
        </div>
        <div className={'flex w-full flex-col'}>
          <Input
            onChange={(payload) => dispatch({ type: 'name', payload })}
            value={state.name}
            label={t('fileName')}
            rightText={document.extension}
          />
          <Input
            type={InputType.textarea}
            value={state.description}
            onChange={(payload) => dispatch({ type: 'description', payload })}
            label={t('information')}
          />
        </div>
      </div>
    </AbstractModal>
  );
}
