import React, { Fragment } from 'react';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { Menu, Transition } from '@headlessui/react';
import MailButtonItem from './MailButtonItem';

/**
 * NewMailButton()
 * @constructor
 */
export default function NewMailButton() {
  const { documents } = useAppSelector((state) => state.document);
  const { assignedClientIds, user } = useAppSelector((state) => state.auth);
  const [newMessages, setNewMessages] = React.useState<DocumentType[]>();

  React.useEffect(() => {
    if (documents && documents.length > 0) {
      const filtered = documents.filter((item) => assignedClientIds.indexOf(item.clientId) >= 0);
      if (filtered) {
        const havingNewMessages = filtered.filter((document) => {
          let newMessages = false;
          if (document.messages && document.messages.length > 0) {
            document.messages.forEach((message) => {
              if (user.isCustomerUser && !message.isCustomerMessage && !message.isRead) {
                newMessages = true;
              }
              if (!user.isCustomerUser && message.isCustomerMessage && !message.isRead) {
                newMessages = true;
              }
            });
          }
          return newMessages;
        });
        setNewMessages(havingNewMessages);
      }
    }
  }, [assignedClientIds, documents, user.isCustomerUser]);

  const renderItems = () => {
    if (newMessages && newMessages.length > 0) {
      return newMessages.map((item) => <MailButtonItem document={item} key={item.documentId} />);
    }
    return null;
  };

  if (newMessages && newMessages.length > 0) {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="
              inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-2xl font-semibold
              hover:bg-purple-100 text-purple-700
            "
          >
            {<i className={`fad fa-comments fa-beat py-1 px-1`} aria-hidden="true" />}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="
              absolute flex flex-grow right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1
              ring-black ring-opacity-5 focus:outline-none
            "
          >
            <div className="py-1">{renderItems()}</div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
  return null;
}
