import React from 'react';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import DetailsComponent from '../../../Components/Customer/Details';
import CompanyLogo from '../../../Components/Customer/CompanyLogo';
import ApiKey from '../../../Components/Customer/ApiKey';
import ExternalLogin from '../../../Components/Customer/ExternalLogin';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useTranslation } from 'react-i18next';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const { customer } = useAppSelector((state) => state.auth);
  const navContext = React.useContext(NavbarContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    navContext.setHeadline(`${t('settings')} / ${customer.displayName || customer.name}`);
  }, [customer.displayName, customer.name, navContext, t]);

  return (
    <>
      <div className={'flex flex-row'}>
        <div className={'w-1/3'}>
          <CompanyLogo />
        </div>

        <div className={'w-2/3 ml-6'}>
          <DetailsComponent />
        </div>
      </div>

      <ExternalLogin />
      <ApiKey />

      <div className={'mt-4 text-xs text-gray-400 text-center'}>{`${customer.customerId}`}</div>
    </>
  );
}
