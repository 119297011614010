import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import { useDispatchClientDocumentGetList } from '../../../Redux/Actions/Client/Documents';
import { useTranslation } from 'react-i18next';
import { Client } from '../../../Library/Types/ClientTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { clientBuildDisplayName } from '../../../Library/Functions/ClientFunctions';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useCheckHasClientAccess } from '../../../Library/Hooks/AccessHooks';
import moment from 'moment';
import FinishedDocumentsComp from '../../../Components/Document/FinishedDocuments';

/**
 * FinishedDocuments()
 * @constructor
 */
export default function FinishedDocuments() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { clients, user } = useAppSelector((state) => state.auth);
  const [documents, setDocuments] = React.useState<DocumentType[] | null>(null);
  const [t] = useTranslation();
  const [client, setClient] = React.useState<Client>();
  const [selectedYear, setSelectedYear] = React.useState<number>(parseInt(moment().format('YYYY')));
  const [loading, setLoading] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);
  useCheckHasClientAccess(clientId);

  const dispatchGetList = useDispatchClientDocumentGetList();

  const handleUpdate = React.useCallback(() => {
    const startDate = moment(selectedYear, 'YYYY').startOf('year').format('YYYY-MM-DD');
    const endDate = moment(selectedYear, 'YYYY').endOf('year').format('YYYY-MM-DD');

    if (clientId) {
      setLoading(true);
      dispatchGetList(clientId, startDate, endDate)
        .then(setDocuments)
        .finally(() => setLoading(false));
    }
  }, [clientId, dispatchGetList, selectedYear]);

  React.useEffect(() => {
    if (!user.isCustomerUser) {
      navigate('/dashboard');
    }
  }, [navigate, user.isCustomerUser]);

  React.useEffect(() => {
    navContext.setHeadline(t('invoicesDoneWithName', { clientName: client ? clientBuildDisplayName(client) : '' }));
    if (user.isCustomerUser) {
      navContext.setShowBack(true);
    }
  }, [client, navContext, t, user.isCustomerUser]);

  React.useEffect(() => {
    if (clients) {
      const found = clients.find((c) => c.clientId === clientId);
      if (found) {
        setClient(found);
      }
    }
  }, [client, clientId, clients, navContext, t]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (selectedYear) {
      timer = setTimeout(() => handleUpdate(), 200);
    }

    return () => clearTimeout(timer);
  }, [handleUpdate, selectedYear]);

  if (client) {
    return (
      <FinishedDocumentsComp
        documents={documents || []}
        client={client}
        loading={loading}
        selectedYear={selectedYear}
        onChangeYear={setSelectedYear}
      />
    );
  }
  return null;
}
