import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavbarContext } from '../../Components/Layout/AuthedLayout';
import User from '../../Components/Settings/Profile/User';
import BankAccounts from '../../Components/Settings/Profile/BankAccounts/BankAccounts';
import ConfirmModal from '../../Components/Modals/ConfirmModal';
import { useDispatchLogOut } from '../../Redux/Actions/AuthActions';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';

/**
 * Profile()
 * @constructor
 */
export default function Profile() {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [showAskLogout, setShowAskLogout] = React.useState<boolean>(false);
  const navContext = useContext(NavbarContext);

  const dispatchLogout = useDispatchLogOut();

  const handleLogout = () => {
    dispatchLogout().then(() => {});
  };

  React.useEffect(() => {
    navContext.setHeadline(t('myProfile'));
  }, [navContext, t]);

  return (
    <>
      <User />
      <BankAccounts />

      <div className={'flex flex-1 items-center flex-col mt-20'}>
        <div
          onClick={() => setShowAskLogout(true)}
          className={'text-sm py-2 px-4 cursor-pointer text-red-700 rounded hover:bg-red-700 hover:text-white'}
        >
          <i className={'fas fa-sign-out mr-2'} />
          {t('logout')}
        </div>
        <div className={'mt-4 text-xs text-gray-400'}>{`${user.userId} / ${user.uuid}`}</div>
      </div>

      <ConfirmModal
        headline={t('alert.askLogout.headline')}
        text={t('alert.askLogout.text')}
        visible={showAskLogout}
        onClose={() => setShowAskLogout(false)}
        onConfirm={handleLogout}
        type={'error'}
        icon={'far fa-right-from-bracket'}
      />
    </>
  );
}
