import styled, { useTheme } from 'styled-components';
import { darken } from 'polished';
import React from 'react';

const Container = styled.div<{
  $bgColor: string;
  $textColor: string;
  $borderColor: string;
  size: 'small' | 'medium' | 'large';
  disabled: boolean;
}>`
  display: inline-flex;
  padding: ${(props) => (props.size === 'small' ? '5px 12px' : props.size === 'medium' ? '6px 14px' : '10px 20px')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => props.$bgColor};
  ${(props) => props.theme.templates.borderRadius};
  text-align: center;
  align-items: center;
  color: ${(props) => props.$textColor};
  margin-left: 10px;
  border: 1px solid ${(props) => props.$borderColor || props.$bgColor};
  font-size: ${(props) => (props.size === 'small' ? '14px' : props.size === 'medium' ? '16px' : '20px')};
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};

  &:first-child {
    margin-left: 0;
  }

  > i {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${(props) => (!props.disabled ? darken(0.1, props.$bgColor) : 'inherited')};
  }
`;

export enum ButtonColors {
  primary = '',
}

interface Props {
  className?: string;
  containerStyle?: object;
  children: string | React.ReactElement;
  onPress: () => void;
  disabled: boolean;
  icon?: string;
  colorStyle: 'primary' | 'success' | 'cancel' | 'error' | 'purple';
  size: 'small' | 'medium' | 'large';
}

export default function Button(props: Props) {
  const { children, onPress, disabled, icon, colorStyle, size, className, containerStyle } = props;
  const theme = useTheme();

  const getColorStyles = () => {
    switch (colorStyle) {
      case 'success':
        return { $bgColor: theme.colors.green, $textColor: '#ffffff', $borderColor: '' };
      case 'cancel':
        return { $bgColor: '#ffffff', $textColor: theme.colors.text, $borderColor: theme.borderColor };
      case 'error':
        return { $bgColor: theme.colors.red, $textColor: '#ffffff', $borderColor: '' };
      case 'purple':
        return { $bgColor: '#7e22ce', $textColor: '#ffffff', $borderColor: '' };
      default:
        return { $bgColor: theme.colors.primary, $textColor: '#ffffff', $borderColor: '' };
    }
  };

  const renderIcon = () => {
    if (icon) {
      return <i className={icon} />;
    }
    return null;
  };

  return (
    <Container
      className={`SotoButton ${className}`}
      onClick={!disabled ? () => onPress() : () => {}}
      {...getColorStyles()}
      size={size}
      disabled={disabled}
      style={containerStyle}
    >
      {renderIcon()}
      {children}
    </Container>
  );
}

Button.defaultProps = {
  disabled: false,
  colorStyle: 'primary',
  size: 'medium',
};
