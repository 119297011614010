import DropDownButton, { DropDownItem } from '../../Forms/DropDownButton';
import { useTranslation } from 'react-i18next';
import { DocumentState, DocumentType, InvoiceType } from '../../../Library/Types/DocumentTypes';
import React from 'react';
import { StateHistoryModal } from '../StateHistoryModal';
import { openDocument } from '../../../Library/Functions/HelperFunctions';
import PreviewModal from '../PreviewModal';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { useDispatchDocumentReopen } from '../../../Redux/Actions/DocumentActions';
import ConfirmModal from '../../Modals/ConfirmModal';
import { useNavigate } from 'react-router';
import MessageModal from '../Messages/MessageModal';

interface Props {
  document: DocumentType;
}

/**
 * ActionDropDown()
 * @constructor
 */
export function ActionDropDown(props: Props) {
  const { document } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [showHistory, setShowHistory] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [showAskReopen, setShowAskReopen] = React.useState<boolean>(false);
  const [showMessages, setShowMessages] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatchDocumentReopen = useDispatchDocumentReopen();

  const handleReopen = React.useCallback(() => {
    if (!loading) {
      setLoading(true);
      dispatchDocumentReopen({ ...document, state: DocumentState.open }).then(() => {
        setTimeout(() => {
          navigate(`/documents/${document.documentId}`);
        }, 500);
      });
    }
  }, [dispatchDocumentReopen, document, loading, navigate]);

  const getItems = (): DropDownItem[] => {
    const items: DropDownItem[] = [];

    if (document.type !== InvoiceType.bank) {
      items.push({
        caption: t('preview'),
        onPress: () => setShow(true),
        icon: 'fas fa-eye text-gray-500',
      });
    }

    if (document.messages.length > 0) {
      items.push({
        caption: t('messages', { count: document.messages.length }),
        onPress: () => setShowMessages(true),
        icon: 'far fa-messages text-gray-500',
      });
    }

    items.push({
      caption: t('changeHistory'),
      onPress: () => setShowHistory(true),
      icon: 'fa-kit fa-regular-file-clock text-gray-500',
    });
    items.push({
      caption: t('download'),
      onPress: () => openDocument(document),
      icon: 'fa fa-cloud-download text-gray-500',
    });

    if (user.isCustomerUser && user.isCustomerAdmin) {
      items.push({
        caption: t('reopen'),
        onPress: () => setShowAskReopen(true),
        icon: 'fa fa-folder-open text-gray-500',
      });
    }

    return items;
  };

  return (
    <>
      <DropDownButton
        items={getItems()}
        icon={'fa fa-ellipsis'}
        buttonClass={'ring-0 shadow-none hover:shadow-sm hover:ring-1 ring-gray-300'}
      />

      <StateHistoryModal document={document} visible={showHistory} onClose={() => setShowHistory(false)} />
      <PreviewModal document={show ? document : null} onClose={() => setShow(false)} />

      <ConfirmModal
        visible={showAskReopen}
        onClose={() => setShowAskReopen(false)}
        onConfirm={handleReopen}
        confirmText={t('reopen')}
        type={'error'}
        headline={t('alert.confirmReopenDocument.headline')}
        text={t('alert.confirmReopenDocument.text')}
      />

      <MessageModal document={document} visible={showMessages} onClose={() => setShowMessages(false)} />
    </>
  );
}
