import React from 'react';
import classNames from 'classnames';
import { HashLink } from 'react-router-hash-link';

interface Props {
  caption?: string;
  icon?: string;
  navigateTo: string;
  visible: boolean;
  containerClass?: string;
  children?: React.ReactElement;
}

export const buttonClass = classNames(
  'px-5 py-2 text-white text cursor-pointer rounded-md mr-2 last:mr-0',
  'hover:bg-orange-400 flex items-center md:py-3 md:text-md',
);

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { caption, icon, navigateTo, visible, containerClass, children } = props;

  const renderIcon = () => {
    if (icon) {
      return (
        <div>
          <i className={classNames(icon, 'mr-2')} />
        </div>
      );
    }
    return null;
  };

  if (visible) {
    return (
      <HashLink
        className={classNames(buttonClass, containerClass, 'flex-row items-center hidden', 'md:flex')}
        to={navigateTo}
      >
        {renderIcon()}
        <div>{caption || children}</div>
      </HashLink>
    );
  }
  return null;
}
