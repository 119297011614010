import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const banner = require('../../Assets/Images/header_banner_1.png');

/**
 * Header()
 * @constructor
 */
export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-1 flex-col items-center', 'bg-purple-700', 'xl:flex-row')}>
      <div className={classNames('w-full flex justify-center p-16', 'xl:justify-end')}>
        <img src={banner} alt={'HeaderLogo'} style={{}} />
      </div>
      <div className={classNames('flex flex-col items-center w-full text-white px-5 pt-14 pb-14', 'xl:items-start')}>
        <div className={classNames('text-6xl font-bold font-mono', 'xl:text-8xl')}>SotoDocs</div>
        <div className={classNames('text-4xl text-center', 'xl:text-5xl xl:text-left xl:pr-52')}>
          {t('modules.website.header.line1')}
        </div>
        <div className={classNames('text-2xl font-light text-center pt-6', 'xl:text-left xl:pr-52')}>
          {t('modules.website.header.line2')}
        </div>

        <div className={classNames('mt-10')}>
          <div
            className={classNames(
              'px-8 py-4 bg-orange-400 text-white rounded-lg font-bold text-xl cursor-pointer',
              'hover:bg-orange-500 hover:drop-shadow-2xl',
            )}
            onClick={() => navigate('/register')}
          >
            {t('modules.website.header.startNow')}
          </div>
        </div>
      </div>
    </div>
  );
}
