import { useAppDispatch, useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import React from 'react';
import { collection, CollectionReference, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { FirebasePath } from '../../../Library/Firebase';
import { BookingStatistic } from '../../../Library/Types/StatisticTypes';
import {
  CLIENT_STATISTICS_GETLIST_ERROR,
  CLIENT_STATISTICS_GETLIST_START,
  CLIENT_STATISTICS_GETLIST_SUCCESS,
} from '../../ActionTypes';
import moment from 'moment/moment';

/**
 * useDispatchClientStatisticsGet()
 */
export const useDispatchClientStatisticsGet = () => {
  const dispatch = useAppDispatch();
  const { lastStatisticRefresh, statistics } = useAppSelector((state) => state.client);

  return React.useCallback(
    (clientId: string) => {
      // Load only new every 10 minutes
      if (
        !lastStatisticRefresh ||
        moment(lastStatisticRefresh).isBefore(moment().subtract(10, 'm')) ||
        statistics.lastUsedClientId !== clientId
      ) {
        dispatch(CLIENT_STATISTICS_GETLIST_START(clientId));

        const collectionRef = collection(
          getFirestore(),
          FirebasePath.clients,
          clientId,
          FirebasePath.statistics,
        ) as CollectionReference<BookingStatistic>;
        const queryRef = query(collectionRef, where('timestamp', '>', moment().subtract(13, 'month').toDate()));

        return getDocs(queryRef)
          .then((snapShot) => {
            const docs: BookingStatistic[] = [];
            if (!snapShot.empty) {
              snapShot.forEach((item) => {
                docs.push({ ...item.data() });
              });
            }

            dispatch(CLIENT_STATISTICS_GETLIST_SUCCESS(docs));
            return Promise.resolve(docs);
          })
          .catch((error) => {
            dispatch(CLIENT_STATISTICS_GETLIST_ERROR(error));
            return Promise.reject(error);
          });
      }
      return new Promise((resolve) => resolve(statistics.all));
    },
    [dispatch, lastStatisticRefresh, statistics.all, statistics.lastUsedClientId],
  );
};
