import { AUTH_LOGOUT, USER_STATISTICS_GETLIST_SUCCESS } from '../ActionTypes';
import { createReducer } from '@reduxjs/toolkit';
import { BookingStatistic, StatisticsGroupedByMonth } from '../../Library/Types/StatisticTypes';
import moment from 'moment';
import { statisticsGroupByMonth, statisticsPrepareWeeklyItems } from '../../Library/Functions/StatisticFunctions';

export interface UserReducerInterface {
  statistics: {
    all: BookingStatistic[];
    weekly: BookingStatistic[];
    lastYear: StatisticsGroupedByMonth[];
  };
  lastStatisticRefresh: string;
}

const defaultValues: UserReducerInterface = {
  statistics: {
    all: [],
    weekly: [],
    lastYear: [],
  },
  lastStatisticRefresh: '',
};

export default createReducer(defaultValues, (builder) => {
  builder.addCase(USER_STATISTICS_GETLIST_SUCCESS, (state, action) => {
    state.statistics.all = action.payload;
    state.statistics.weekly = statisticsPrepareWeeklyItems(action.payload);
    state.statistics.lastYear = statisticsGroupByMonth(action.payload);
    state.lastStatisticRefresh = moment().format('YYYY-MM-DD HH:mm:ss');
  });

  builder.addCase(AUTH_LOGOUT, (state) => {
    state.statistics = defaultValues.statistics;
    state.lastStatisticRefresh = '';
  });
});
