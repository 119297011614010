import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-google-charts';

/**
 * GestorOverallDocumentStatistics()
 * @constructor
 */
export default function GestorOverallDocumentStatistics() {
  const { t } = useTranslation();
  const { documents } = useAppSelector((state) => state.document);
  const { user, assignedClientIds } = useAppSelector((state) => state.auth);
  const [myDocs, setMyDocs] = React.useState<DocumentType[]>();
  const [uploaded, setUploaded] = React.useState<DocumentType[]>([]);
  const [open, setOpen] = React.useState<DocumentType[]>([]);
  const [question, setQuestion] = React.useState<DocumentType[]>([]);
  const [notBookable, setNotBookable] = React.useState<DocumentType[]>([]);
  const [notDeductible, setNotDeductible] = React.useState<DocumentType[]>([]);
  const [notReadable, setNotReadable] = React.useState<DocumentType[]>([]);
  const [chartData, setChartData] = React.useState<any>();

  React.useEffect(() => {
    if (documents && documents.length > 0) {
      const docs = documents.filter((item) => assignedClientIds.indexOf(item.clientId) >= 0);
      setMyDocs(docs);
    }
  }, [assignedClientIds, documents]);

  React.useEffect(() => {
    if (myDocs) {
      setOpen(myDocs.filter((item) => item.state === DocumentState.uploaded));
      setOpen(myDocs.filter((item) => item.state === DocumentState.open));
      setQuestion(myDocs.filter((item) => item.state === DocumentState.question));
      setNotBookable(myDocs.filter((item) => item.state === DocumentState.notBookable));
      setNotDeductible(myDocs.filter((item) => item.state === DocumentState.notDeductible));
      setNotReadable(myDocs.filter((item) => item.state === DocumentState.unreadable));
      setUploaded(myDocs.filter((item) => item.state === DocumentState.uploaded));
    }
  }, [myDocs]);

  React.useEffect(() => {
    let data = [[t('documents') as string, t('amount')]];

    data.push([t('modules.documents.statusText.uploaded'), uploaded.length as any]);
    data.push([t('modules.documents.statusText.open'), open.length as any]);
    data.push([t('modules.documents.statusText.question'), question.length as any]);
    data.push([t('modules.documents.statusText.notBookable'), notBookable.length as any]);
    data.push([t('modules.documents.statusText.notDeductible'), notDeductible.length as any]);
    data.push([t('modules.documents.statusText.unreadable'), notReadable.length as any]);

    setChartData(data);
  }, [notBookable.length, notDeductible.length, notReadable.length, open.length, question.length, t, uploaded.length]);

  const options = {
    pieHole: 0.5,
    is3D: false,
    legend: { position: 'right' },
    chartArea: { left: 0, top: 20, right: 0, bottom: 20 },
    colors: ['gray', 'orange', 'blue', 'red', 'red', 'purple'],
  };

  if (
    user.isCustomerUser &&
    chartData &&
    (open.length > 0 ||
      question.length > 0 ||
      notBookable.length > 0 ||
      notDeductible.length > 0 ||
      notReadable.length > 0 ||
      uploaded.length > 0)
  ) {
    return (
      <Container headline={t('documentOverviewStatistics')}>
        <Chart chartType="PieChart" width="100%" height="400px" data={chartData} options={options} />
      </Container>
    );
  }
  return null;
}
