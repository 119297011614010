import { useTranslation } from 'react-i18next';
import { ListBoxItem } from '../../Components/Forms/ListBox';
import { Salutation } from '../Types/GeneralTypes';

/**
 * useListBoxGetSalutationOptions()
 */
export const useListBoxGetSalutationOptions = (): ListBoxItem<Salutation>[] => {
  const { t } = useTranslation();

  return [
    { value: Salutation.female, label: t('salutationType.female'), disabled: false },
    { value: Salutation.male, label: t('salutationType.male'), disabled: false },
    { value: Salutation.company, label: t('salutationType.company'), disabled: false },
  ];
};
