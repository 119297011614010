import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from '../../Container/Container';
import ActionButton from '../../Container/ActionButton';
import ShowHideContainer from '../../ShowHideContainer';
import DocumentFilter from '../../Document/DocumentFilter';
import ListView from '../../Document/ListView';
import { DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import DownloadOpenDocumentsButton from './DownloadOpenDocumentsButton';
import UploadDocumentButton from './UploadDocumentButton';

interface Props {
  documents: DocumentType[];
  clientId?: string;
}

/**
 * OpenDocuments()
 * @constructor
 */
export default function OpenDocuments(props: Props) {
  const { documents, clientId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filtered, setFiltered] = React.useState<DocumentType[]>();

  if (clientId && documents) {
    return (
      <Container
        headline={t('documentsOpen', { count: documents.length })}
        closable
        identifier={'MandantDetailsOpenDocuments'}
        actionButtons={
          <>
            <UploadDocumentButton clientId={clientId} />
            <DownloadOpenDocumentsButton
              openDocuments={documents.filter((item) => item.state === DocumentState.open)}
              clientId={clientId}
            />
            <ActionButton
              icon={'far fa-memo-circle-check'}
              onPress={() => navigate(`/client/${clientId}/invoices`)}
              className={'hover:bg-blue-500 hover:text-white'}
              tooltip={t('invoicesDoneShow')}
            />
          </>
        }
      >
        <ShowHideContainer visible={!!documents && documents.length > 0}>
          <div className={'mb-6'}>
            <DocumentFilter documents={documents || []} onChange={setFiltered} hideOwnerOnly showOpenOnly />
          </div>
        </ShowHideContainer>

        <ListView
          documents={filtered || []}
          onPress={(doc) => navigate(`/documents/${doc.documentId}`)}
          showClient={false}
          withContainer={false}
        />
      </Container>
    );
  }
  return null;
}
