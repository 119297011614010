import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import Table from '../Table';
import { formatSize, secondsToMinuteString } from '../../Library/Functions/HelperFunctions';
import { DocumentState } from '../../Library/Types/DocumentTypes';
import { Client } from '../../Library/Types/ClientTypes';

/**
 * AdminOverview()
 * @constructor
 */
export default function AdminOverview() {
  const { t } = useTranslation();
  const { user, clients } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const [documentCount, setDocumentCount] = React.useState<number>(0);
  const [fileSize, setFileSize] = React.useState<number>(0);
  const [openDocumentsCount, setOpenDocumentsCount] = React.useState<number>(0);
  const [activeClients, setActiveClients] = React.useState<Client[]>([]);
  const [averageBookingTime, setAverageBookingTime] = React.useState<number>(0);

  React.useEffect(() => {
    if (user.isCustomerAdmin) {
      let count = 0;
      let size = 0;
      let overallTime = 0;

      clients.forEach((client) => {
        count += client.documentCount;
        size += client.storageSize;
        overallTime += client.bookingTime || 0;
      });

      setFileSize(size);
      setDocumentCount(count);
      setAverageBookingTime(overallTime / count);
    }
  }, [clients, user.isCustomerAdmin]);

  React.useEffect(() => {
    if (user.isCustomerAdmin) {
      const count = documents.filter((item) => item.state === DocumentState.open).length;
      setOpenDocumentsCount(count);

      const activeClients = clients.filter((item) => item.isActive);
      setActiveClients(activeClients);
    }
  }, [clients, documents, user.isCustomerAdmin]);

  if (user.isCustomerAdmin) {
    return (
      <Container headline={t('shortOverview')}>
        <Table>
          <tbody>
            <tr>
              <td>{t('documentsDoneOverall')}</td>
              <td align={'right'}>{documentCount}</td>
            </tr>
            <tr>
              <td>{t('averageBookingTime')}</td>
              <td align={'right'}>{t('xMinutes', { time: secondsToMinuteString(averageBookingTime) })}</td>
            </tr>
            <tr>
              <td>{t('storageOverall')}</td>
              <td align={'right'}>{formatSize(fileSize)}</td>
            </tr>
            <tr>
              <td>{t('clientsAndOpenDocuments')}</td>
              <td align={'right'}>{`${activeClients.length} / ${openDocumentsCount}`}</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    );
  }
  return null;
}
