import { DocumentType } from '../../../Library/Types/DocumentTypes';
import { Client } from '../../../Library/Types/ClientTypes';
import { clientBuildDisplayName } from '../../../Library/Functions/ClientFunctions';
import Lodash from 'lodash';

export enum SortKey {
  creditor = 'creditor',
  invoiceNumber = 'invoiceNumber',
  invoiceDate = 'invoiceDate',
  fileName = 'fileName',
  client = 'client',
}

/**
 * sort()
 * @param documents
 * @param key
 * @param sortOrder
 * @param clients
 */
const sort = (
  documents: DocumentType[],
  key: SortKey,
  sortOrder: 'ASC' | 'DESC',
  clients: Client[],
): DocumentType[] => {
  if (key === SortKey.invoiceNumber) {
    if (sortOrder === 'ASC') {
      return Lodash.sortBy(documents, 'invoiceNumber');
    } else {
      return Lodash.sortBy(documents, 'invoiceNumber').reverse();
    }
  }

  if (key === SortKey.creditor) {
    if (sortOrder === 'ASC') {
      return Lodash.sortBy(documents, 'creditor');
    } else {
      return Lodash.sortBy(documents, 'creditor').reverse();
    }
  }

  if (key === SortKey.fileName) {
    if (sortOrder === 'ASC') {
      return Lodash.sortBy(documents, 'originalName');
    } else {
      return Lodash.sortBy(documents, 'originalName').reverse();
    }
  }

  if (key === SortKey.invoiceDate) {
    if (sortOrder === 'ASC') {
      return Lodash.sortBy(documents, 'invoiceDate');
    } else {
      return Lodash.sortBy(documents, 'invoiceDate').reverse();
    }
  }

  if (key === SortKey.client) {
    return documents.sort((itemA, itemB) => {
      const clientA = clients.find((item) => item.clientId === itemA.clientId);
      const clientB = clients.find((item) => item.clientId === itemB.clientId);

      if (clientA && clientB) {
        const nameA = clientBuildDisplayName(clientA);
        const nameB = clientBuildDisplayName(clientB);

        if (sortOrder === 'ASC') {
          return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
        } else {
          return nameB.toLowerCase().localeCompare(nameA.toLowerCase());
        }
      }
      return -1;
    });
  }

  return documents;
};

export default sort;
