import React from 'react';
import Type from './Components/Type';
import { Type as TypeEntity } from './ApiTypes';
import ApiTable from './Components/ApiTable';
import classNames from 'classnames';

interface Props {
  itemType: TypeEntity;
}

/**
 * AbstractType()
 * @param props
 * @constructor
 */
export default function AbstractType(props: Props) {
  const { itemType } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const renderFields = () => {
    if (open) {
      const sorted = itemType.fields.sort((a, b) => a.key.localeCompare(b.key));

      const mapped = sorted.map((item) => (
        <tr key={item.key}>
          <td className={item.mandatory ? 'mandatory' : ''}>{item.key}</td>
          <td>
            <Type type={item.type} />
          </td>
          <td>{item.description}</td>
        </tr>
      ));

      return (
        <div className={'p-5'}>
          <ApiTable>{mapped}</ApiTable>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a id={itemType.name} content={''} href={'/#'} />
      <div className={'flex flex-1 flex-col mb-6 border border-gray-200 rounded-xl'}>
        <div
          className={classNames(
            'p-4 bg-gray-200 flex-row flex items-center',
            { 'rounded-t-xl': open },
            { 'rounded-xl': !open },
          )}
        >
          <div className={'flex flex-row flex-1'}>
            <span className={'bg-gray-400 text-white py-1 px-2 rounded-md mr-4'}>{itemType.type}</span>
            <div className={classNames('text-2xl font-bold')}>{itemType.name}</div>
          </div>
          <div>
            <div
              className={classNames(
                'bg-white rounded px-3 py-2 border border-gray-300',
                'cursor-pointer',
                'hover:bg-gray-100',
              )}
              onClick={() => setOpen(!open)}
            >
              <i
                className={classNames('fas', { 'fa-chevron-down': !open }, { 'fa-chevron-up': open }, 'text-gray-400')}
              />
            </div>
          </div>
        </div>

        {renderFields()}
      </div>
    </>
  );
}
