import React from 'react';
import { DocumentMessage, DocumentType } from '../../../Library/Types/DocumentTypes';
import classNames from 'classnames';
import { shortenFileName } from '../../../Library/Functions/HelperFunctions';
import { Menu } from '@headlessui/react';
import { useDispatchDocumentUpdate } from '../../../Redux/Actions/DocumentActions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  document: DocumentType;
}

/**
 * MailButtonItem()
 * @param props
 * @constructor
 */
export default function MailButtonItem(props: Props) {
  const { document } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState<DocumentMessage>();

  const dispatchUpdateDocument = useDispatchDocumentUpdate();

  const handleNavigate = React.useCallback(
    (document: DocumentType) => {
      const messages: DocumentMessage[] = document.messages.map((message) => ({ ...message, isRead: true }));
      dispatchUpdateDocument({ ...document, messages }).then(() => {
        navigate(`/documents/${document.documentId}`);
      });
    },
    [dispatchUpdateDocument, navigate],
  );

  React.useEffect(() => {
    if (document) {
      const found = document.messages.find((item) => !item.isRead);
      setMessage(found);
    }
  }, [document]);

  if (message) {
    return (
      <Menu.Item key={document.documentId}>
        {({ active }) => (
          <div
            onClick={() => handleNavigate(document)}
            className={classNames(
              'text-gray-700',
              'px-5 py-3',
              'cursor-pointer',
              'text-md',
              'border-b',
              'last:border-b-0',
            )}
            style={{ width: 550 }}
          >
            <div className={'flex flex-1 flex-grow items-center'}>
              <div>
                <i className={'fas fa-comment-plus text-2xl text-purple-300 mr-4'} />
              </div>
              <div className={'flex-grow mr-4'}>
                {t('newMessageForDocument', {
                  name: shortenFileName(document.originalName, 30),
                  client: message?.createdDisplayName,
                })}
              </div>
              <div>
                <i className={'fas fa-arrow-up-right-from-square font-light text-sm text-gray-400'} />
              </div>
            </div>
          </div>
        )}
      </Menu.Item>
    );
  }
  return null;
}
