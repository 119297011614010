import React from 'react';
import AbstractType from '../AbstractType';
import { Type } from '../ApiTypes';

export const DocumentTypeType: Type = {
  type: 'enum',
  name: 'DocumentType',
  fields: [
    { type: 'string', key: 'incoming', description: 'Incoming invoices', mandatory: false },
    { type: 'string', key: 'outgoing', description: 'Outgoing invoices', mandatory: false },
    { type: 'string', key: 'bank', description: 'Bank statements', mandatory: false },
  ],
};

/**
 * DocumentType()
 * @constructor
 */
export default function DocumentType() {
  return <AbstractType itemType={DocumentTypeType} />;
}
