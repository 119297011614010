export const LOCALSTORAGE_PATH_CUSTOMER_LOGO = '@customerLogoPath';

export enum Environment {
  production = 'production',
  staging = 'staging',
  dev = 'dev',
}

export enum Salutation {
  male = 'm',
  female = 'f',
  company = 'c',
}

export enum MailTemplateType {
  clientRegistration = 'clientRegistration',
}

export enum Language {
  de = 'de-DE',
  es = 'es-ES',
  en = 'en-EN',
}

export interface ClientGroup {
  id: number;
  name: string;
}

export interface OAuth {
  id?: string;
  platform: string;
  callbackUrl: string;
  redirectUrl?: string;
  timestamp: any;
}
