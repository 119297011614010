import React, { ReactElement } from 'react';
import Config from '../Config';
import { Environment } from '../Types/GeneralTypes';
import LoadingModal from '../../Components/LoadingModal';
import CalloutCopiedToClipboard from '../../Components/Callouts/CalloutCopiedToClipboard';
import Lodash from 'lodash';
import { useAppSelector } from './ReduxHooks';

type LoadingModalOptions = {
  headline?: string;
  textLine1?: string;
  textLine2?: string;
  progress?: number;
  delayStart?: number;
  delayEnd?: number;
  initialValue?: boolean;
};
type LoadingModalResult = {
  loadingModal: any;
  setLoading: (value: boolean) => void;
  isLoading: boolean;
  setHeadline: (headline: string) => void;
  setTextLine1: (line: string) => void;
};
type LoadingModalFn = (options?: LoadingModalOptions) => LoadingModalResult;
/**
 * Generate a Loading Component and returns it if loading is set. The returned loadingElement has to be placed into
 * the Code and if loading is set the default loading modal will appear!
 * useLoadingModal()
 * @param options
 */
export const useLoadingModal: LoadingModalFn = (
  options: LoadingModalOptions = { initialValue: false },
): LoadingModalResult => {
  const [loading, setLoading] = React.useState<boolean>(options.initialValue || false);
  const [delayedLoading, setDelayedLoading] = React.useState<boolean>(false);
  const [loadingElement, setLoadingElement] = React.useState<ReactElement | null>(null);
  const [headline, setHeadline] = React.useState<string>(options.headline || '');
  const [textLine1, setTextLine1] = React.useState<string>(options.textLine1 || '');
  const timerRef = React.useRef<any>();

  const setLoadingFnc = React.useCallback(
    (value: boolean) => {
      clearTimeout(timerRef.current);
      if (!value) {
        if (Config.env === Environment.dev) {
          setTimeout(() => {
            setLoading(false);
          }, Math.random() * 3000);
        } else {
          setLoading(false);
        }
        timerRef.current = setTimeout(() => setDelayedLoading(false), options.delayEnd || 0);
      } else {
        setLoading(true);
        timerRef.current = setTimeout(() => setDelayedLoading(true), options.delayStart || 0);
      }
    },
    [options.delayEnd, options.delayStart],
  );

  React.useEffect(() => {
    if (delayedLoading) {
      setLoadingElement(
        <LoadingModal
          visible={true}
          headline={headline}
          line1={textLine1}
          line2={options.textLine2 || ''}
          progress={options.progress}
        />,
      );
    } else {
      if (Config.env === Environment.dev) {
        setTimeout(() => {
          setLoadingElement(null);
        }, Math.random() * 4000);
      } else {
        setLoadingElement(null);
      }
    }
  }, [delayedLoading, headline, options.progress, options.textLine2, textLine1]);

  return { loadingModal: loadingElement, setLoading: setLoadingFnc, isLoading: loading, setHeadline, setTextLine1 };
};

/**
 * useCopyClipboard()
 */
export const useCopyClipboard = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [hide, setHide] = React.useState<boolean>(true);
  const [component, setComponent] = React.useState<React.ReactElement | null>();
  const [value, setValue] = React.useState<string>();

  React.useEffect(() => {
    if (!visible) {
      setHide(true);
    }
  }, [visible]);

  const copyToClipBoard = React.useCallback((param: string) => {
    setHide(false);
    setValue(param);
    navigator.clipboard.writeText(param);
    setTimeout(() => setVisible(true), 100);
  }, []);

  React.useEffect(() => {
    if (hide) {
      setComponent(null);
    } else {
      setComponent(<CalloutCopiedToClipboard visible={visible} onChange={setVisible} value={value} />);
    }
  }, [hide, value, visible]);

  return { clipboardCallout: component, copyToClipBoard };
};

/**
 * useContainerDimensions()
 * @param myRef
 * @param trigger
 */
export const useContainerDimensions = (myRef: React.MutableRefObject<HTMLDivElement>, trigger: any) => {
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  const getDimensions = React.useCallback(() => {
    if (myRef && myRef.current) {
      return {
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
      };
    }
    return {
      width: 0,
      height: 0,
    };
  }, [myRef]);

  const handleResize = React.useCallback(() => {
    if (!Lodash.isEqual(getDimensions(), dimensions) && trigger !== undefined) {
      setDimensions(getDimensions());
    }
  }, [dimensions, getDimensions, trigger]);

  React.useEffect(() => {
    setTimeout(() => handleResize(), 500);
  }, [handleResize, myRef]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getDimensions, handleResize, myRef]);

  return dimensions;
};

/**
 * useIsDemo()
 */
export const useIsDemo = () => {
  const { customer, user } = useAppSelector((state) => state.auth);
  const [isDemo, setIsDemo] = React.useState<boolean>();

  React.useEffect(() => {
    if (isDemo === undefined) {
      setIsDemo(user.isCustomerUser && !customer.subscriptionActive);
    }
  }, [customer, customer.subscriptionType, isDemo, user.isCustomerUser]);

  return isDemo;
};
