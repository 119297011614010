import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from '../../../../Library/Hooks/ReduxHooks';
import { User } from '../../../../Library/Types/UserTypes';
import { NavbarContext } from '../../../../Components/Layout/AuthedLayout';
import { useTranslation } from 'react-i18next';
import Container from '../../../../Components/Container/Container';
import DisplayRow from '../../../../Components/Display/DisplayRow';
import moment from 'moment';
import AccessControl from '../../../../Components/Settings/Customer/Users/AccessControl';
import ActionButton from '../../../../Components/Container/ActionButton';
import classNames from 'classnames';
import { useDispatchUserUpdate } from '../../../../Redux/Actions/UserActions';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const { users, customer } = useAppSelector((state) => state.auth);
  const [user, setUser] = React.useState<User>();
  const context = React.useContext(NavbarContext);

  const dispatchUserUpdate = useDispatchUserUpdate();

  const handleUpdateActive = React.useCallback(
    (value: boolean) => {
      if (user) {
        dispatchUserUpdate({ ...user, isActive: value }).finally(() => {});
      }
    },
    [dispatchUserUpdate, user],
  );

  React.useEffect(() => {
    if (userId) {
      const found = users.find((user) => user.userId === userId);
      if (found && found.customerId === customer.customerId) {
        setUser(found);
      } else {
        navigate(-1);
      }
    }
  }, [customer.customerId, navigate, userId, users]);

  React.useEffect(() => {
    if (user) {
      context.setShowBack(true);
      context.setHeadline(`${t('settings')} / ${t('userAccounts')} / ${user?.firstName} ${user?.lastName}`);
    }
  }, [context, t, user]);

  const getStateElement = (state: boolean) => {
    return (
      <div className={'items-center'}>
        <i className={`fas fa-circle mr-2 ${state ? 'text-green-600' : 'text-red-600'}`} />
        {t(`${state ? 'active' : 'inactive'}`)}
      </div>
    );
  };

  const renderActivateDeactivateButton = () => {
    if (!user?.isCustomerAdmin) {
      return (
        <ActionButton
          icon={classNames({ 'fas fa-check-circle': !user?.isActive }, { 'fas fa-ban': user?.isActive })}
          onPress={() => handleUpdateActive(!user?.isActive)}
          className={classNames(
            { 'hover:bg-red-500 hover:text-white text-red-600': user?.isActive },
            { 'hover:bg-green-600 hover:text-white text-green-600': !user?.isActive },
          )}
          tooltip={user?.isActive ? t('deactivate') : t('activate')}
        />
      );
    }
  };

  if (user) {
    return (
      <>
        <Container headline={t('details')} actionButtons={renderActivateDeactivateButton()}>
          <DisplayRow label={t('state')} value={getStateElement(user.isActive)} />
          <DisplayRow label={t('firstName')} value={user.firstName} />
          <DisplayRow label={t('lastName')} value={user.lastName} />
          <DisplayRow label={t('mail')} value={user.mail} />
          <DisplayRow label={t('createdAt')} value={moment(user.createdDate).format('LL')} />
        </Container>

        <AccessControl user={user} />
      </>
    );
  }
  return null;
}
