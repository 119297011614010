import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const de = require('../../Assets/Images/de-DE.png');
const en = require('../../Assets/Images/en-US.png');
const es = require('../../Assets/Images/es-ES.png');

/**
 * LanguageRow()
 * @constructor
 */
export default function LanguageRow() {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-1 flex-col px-10 py-10 bg-gray-200 text-center', 'lg:p-20')}>
      <div className={classNames('text-3xl text-center', 'lg:text-4xl')}>{t('modules.website.language.headline1')}</div>
      <div className={classNames('mb-14 text-2xl pt-4 font-light')}>{t('modules.website.language.headline2')}</div>

      <div className={classNames('flex flex-1 flex-row justify-center', 'lg:flex-row lg:justify-center lg:mt-10')}>
        <img src={es} alt={'Spanisch'} className={classNames('max-w-20 mb-10 mr-6', 'lg:mb-0 lg:mr-20 lg:max-w-48')} />
        <img src={de} alt={'Deutsch'} className={classNames('max-w-20 mb-10 mr-6', 'lg:mb-0 lg:mr-20 lg:max-w-48')} />
        <img src={en} alt={'Englisch'} className={classNames('max-w-20 mb-10', 'lg:mb-0 lg:max-w-48')} />
      </div>
    </div>
  );
}
