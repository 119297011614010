import React from 'react';
import styled from 'styled-components';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';
import { StateMapping, useDocumentStatusMappings } from '../../Library/Hooks/DocumentHooks';
import classNames from 'classnames';
import { generateRandomString } from '../../Library/Functions/HelperFunctions';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const Container = styled.div``;

interface Props {
  document: DocumentType;
}

/**
 * StatusIcon()
 * @param props
 * @constructor
 */
export default function StatusIcon(props: Props) {
  const { document } = props;
  const { t } = useTranslation();
  const statusMappings = useDocumentStatusMappings();
  const [mapping, setMapping] = React.useState<StateMapping>();
  const [tooltipClass] = React.useState<string>(generateRandomString(20));

  React.useEffect(() => {
    const found = statusMappings.find((item) => item.state === document.state);
    setMapping(found);
  }, [document.state, statusMappings]);

  if (mapping) {
    return (
      <Container className={mapping.textColorClass}>
        <i
          className={classNames(
            mapping.icon,
            { 'fa-beat': document.state === DocumentState.uploaded },
            'text-2xl',
            tooltipClass,
          )}
        />
        <Tooltip className={'z-20'} anchorSelect={`.${tooltipClass}`}>
          {t(`modules.documents.statusText.${document.state}`)}
        </Tooltip>
      </Container>
    );
  }
  return null;
}
