import { BookingStatistic, StatisticsGroupedByMonth } from '../Types/StatisticTypes';
import moment from 'moment';

/**
 * statisticsPrepareWeeklyItems()
 * @param bookings
 */
export const statisticsPrepareWeeklyItems = (bookings: BookingStatistic[]) => {
  const startDate = moment().subtract(7, 'day');
  const endDate = moment();

  const items: BookingStatistic[] = [];

  bookings.forEach((item) => {
    // eslint-disable-next-line max-len
    const prepareDate = `${item.year}-${item.month < 10 ? `0${item.month}` : item.month}-${item.day < 10 ? `0${item.day}` : item.day}`;
    if (moment(prepareDate).isSameOrAfter(startDate) && moment(prepareDate).isSameOrBefore(endDate)) {
      items.push(item);
    }
  });

  return items;
};

/**
 * statisticsGroupByMonth()
 * @param bookings
 */
export const statisticsGroupByMonth = (bookings: BookingStatistic[]): StatisticsGroupedByMonth[] => {
  let current = moment().subtract(12, 'month');
  let result: StatisticsGroupedByMonth[] = [];

  while (current.isBefore(moment().add(1, 'h'))) {
    const year = parseInt(moment(current).format('YYYY'));
    const month = parseInt(moment(current).format('M'));

    let count = 0;
    let size = 0;
    let time = 0;
    let incoming = 0;
    let outgoing = 0;
    let bank = 0;

    bookings.forEach((booking) => {
      if (parseInt(booking.year.toString()) === year && parseInt(booking.month.toString()) === month) {
        count += booking.overallCount;
        size += booking.fileSize | 0;
        time += booking.bookingTime;
        incoming += booking.incomingCount;
        outgoing += booking.outgoingCount;
        bank += booking.bankCount;
      }
    });

    result.push({
      year,
      month,
      overallCount: count,
      bookingTime: time,
      fileSize: size,
      incomingCount: incoming,
      outgoingCount: outgoing,
      bankCount: bank,
    });
    current = moment(current.add(1, 'month'));
  }

  return result;
};
