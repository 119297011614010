import React from 'react';
import { useAppSelector } from '../../../../Library/Hooks/ReduxHooks';
import ListView from '../../../../Components/Users/ListView';
import { useTranslation } from 'react-i18next';
import Container from '../../../../Components/Container/Container';
import { NavbarContext } from '../../../../Components/Layout/AuthedLayout';
import { useCheckAdminAccess } from '../../../../Library/Hooks/AccessHooks';
import CustomerRegisterSettings from '../../../../Components/Settings/Customer/CustomerRegisterSettings';
import { useDispatchUserGetByCustomer } from '../../../../Redux/Actions/UserActions';
import { useNavigate } from 'react-router';

/**
 * Dashboard()
 * @constructor
 */
export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { users, customer } = useAppSelector((state) => state.auth);
  const context = React.useContext(NavbarContext);
  useCheckAdminAccess();

  const dispatchUsersGetList = useDispatchUserGetByCustomer();

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    timer = setTimeout(() => dispatchUsersGetList(customer.customerId), 500);
    return () => clearTimeout(timer);
  }, [customer.customerId, dispatchUsersGetList]);

  React.useEffect(() => {
    context.setHeadline(`${t('settings')} / ${t('userAccounts')}`);
  }, [context, t]);

  return (
    <>
      <CustomerRegisterSettings />
      <Container noPadding>
        <ListView users={users} onPress={(user) => navigate(user.userId)} />
      </Container>

      <p className={'text-sm text-gray-500 text-center px-16 pt-6'}>{t('modules.users.addUserDescription')}</p>
    </>
  );
}
