import { Client } from '../../../Library/Types/ClientTypes';
import { Language, Salutation } from '../../../Library/Types/GeneralTypes';

export type ClientCreateUpdateState = {
  client: Client;
  errors: { [K in keyof Client]?: string };
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: Client }
  | { type: 'isCompany'; payload: boolean }
  | { type: 'companyName'; payload: string }
  | { type: 'salutation'; payload: Salutation }
  | { type: 'firstName'; payload: string }
  | { type: 'internalId'; payload: string }
  | { type: 'taxNumber'; payload: string }
  | { type: 'groupId'; payload: number }
  | { type: 'mail'; payload: string }
  | { type: 'lastName'; payload: string }
  | { type: 'language'; payload: Language }
  | { type: 'isActive'; payload: boolean }
  | { type: 'allowOutgoing'; payload: boolean }
  | { type: 'allowBank'; payload: boolean }
  | { type: 'registrationActive'; payload: boolean }
  | { type: 'assignedUserId'; payload: string };

export const clientCreateUpdateInitialState: ClientCreateUpdateState = {
  client: {
    clientId: '',
    customerId: '',
    groupId: 0,
    companyName: '',
    createdUserId: '',
    createdDate: '',
    firstName: '',
    internalId: '',
    lastName: '',
    mail: '',
    taxNumber: '',
    salutation: Salutation.male,
    assignedUserId: '',
    isActive: true,
    isConnected: false,
    registrationActive: true,
    language: Language.es,
    storageSize: 0,
    documentCount: 0,
    bookingTime: 0,
    allowBank: true,
    allowOutgoing: true,
    availableCreditors: [],
    settings: {
      bankAccounts: [],
    },
  },
  errors: {},
  hasChanges: false,
  initialized: false,
};

export const clientCreateUpdateReducer = (state: ClientCreateUpdateState, action: Action): ClientCreateUpdateState => {
  switch (action.type) {
    case 'isCompany':
      return {
        ...clientCreateUpdateInitialState,
        client: {
          ...state.client,
          companyName: !action.payload ? '' : state.client.companyName,
        },
        hasChanges: true,
      };
    case 'companyName':
      return { ...state, client: { ...state.client, companyName: action.payload }, hasChanges: true };
    case 'salutation':
      return { ...state, client: { ...state.client, salutation: action.payload }, hasChanges: true };
    case 'firstName':
      return { ...state, client: { ...state.client, firstName: action.payload }, hasChanges: true };
    case 'lastName':
      return { ...state, client: { ...state.client, lastName: action.payload }, hasChanges: true };
    case 'internalId':
      return { ...state, client: { ...state.client, internalId: action.payload }, hasChanges: true };
    case 'mail':
      return { ...state, client: { ...state.client, mail: action.payload }, hasChanges: true };
    case 'taxNumber':
      return { ...state, client: { ...state.client, taxNumber: action.payload }, hasChanges: true };
    case 'assignedUserId':
      return { ...state, client: { ...state.client, assignedUserId: action.payload }, hasChanges: true };
    case 'language':
      return { ...state, client: { ...state.client, language: action.payload }, hasChanges: true };
    case 'isActive':
      return { ...state, client: { ...state.client, isActive: action.payload }, hasChanges: true };
    case 'registrationActive':
      return { ...state, client: { ...state.client, registrationActive: action.payload }, hasChanges: true };
    case 'allowOutgoing':
      return { ...state, client: { ...state.client, allowOutgoing: action.payload }, hasChanges: true };
    case 'allowBank':
      return { ...state, client: { ...state.client, allowBank: action.payload }, hasChanges: true };
    case 'groupId':
      return { ...state, client: { ...state.client, groupId: action.payload }, hasChanges: true };
    case 'init':
      return { ...clientCreateUpdateInitialState, client: { ...action.payload }, initialized: true };
    default:
      throw new Error('Invalid type!');
  }
};
