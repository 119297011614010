import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatchClientGetList } from '../../../Redux/Actions/ClientActions';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ClientTable from '../../../Components/Client/ListView';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import TopNavbarItem from '../../../Components/Navigation/TopNavbarItem';
import { useNavigate } from 'react-router';
import { useCheckCustomerAccess } from '../../../Library/Hooks/AccessHooks';
import { Client } from '../../../Library/Types/ClientTypes';
import Filter from '../../../Components/Client/Filter';
import { useIsDemo } from '../../../Library/Hooks/GlobalHooks';
import DemoHintModal from '../../../Components/Modals/DemoHintModal';
import { useCustomerUseHasAccess } from '../../../Library/Hooks/CustomerHooks';

/**
 * Overview()
 * @constructor
 */
export default function Overview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customer, clients } = useAppSelector((state) => state.auth);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [filtered, setFiltered] = React.useState<Client[]>(clients);
  const [showDemoHint, setShowDemoHint] = React.useState<boolean>(false);
  const isDemo = useIsDemo();
  const navContext = React.useContext(NavbarContext);
  const hasCreateAccess = useCustomerUseHasAccess('mandant.create');
  useCheckCustomerAccess();

  const dispatchGetClients = useDispatchClientGetList();

  const handleNavigateCreate = React.useCallback(() => {
    if (isDemo && clients.length >= 1) {
      setShowDemoHint(true);
    } else {
      navigate('/client/create');
    }
  }, [clients.length, isDemo, navigate]);

  const setNavigation = React.useMemo(() => {
    return <TopNavbarItem visible={hasCreateAccess} icon={'fas fa-plus'} onPress={handleNavigateCreate} />;
  }, [handleNavigateCreate, hasCreateAccess]);

  React.useEffect(() => {
    navContext.setHeadline(t('clients'));
    navContext.setButton(setNavigation);
  }, [handleNavigateCreate, hasCreateAccess, navContext, navigate, setNavigation, t]);

  React.useEffect(() => {
    let timerRef: NodeJS.Timeout;

    if (!loaded) {
      timerRef = setTimeout(() => {
        dispatchGetClients(customer.customerId).finally(() => setLoaded(true));
      }, 200);
    }

    return () => clearTimeout(timerRef);
  }, [customer.customerId, dispatchGetClients, loaded]);

  return (
    <div className={''}>
      <Filter clients={clients} onChange={setFiltered} />
      <ClientTable clients={filtered || []} onPress={(client) => navigate(`/client/${client.clientId}`)} />

      <DemoHintModal visible={showDemoHint} onClose={() => setShowDemoHint(false)} />
    </div>
  );
}
