import React from 'react';
import { Endpoint } from '../ApiTypes';
import AbstractEndpoint from '../AbstractEndpoint';

export const DocumentGetOpenEndpoint: Endpoint = {
  name: 'Documents Get Open',
  description:
    'Returns all documents which are in state "open" for the given clientId. If you want to import all ' +
    'open documents to your software and process them there, use the query parameter "markDone" to set all ' +
    'documents to state "done" automatically. So there is no need to do this manually afterwards. Be aware, that ' +
    'if you import the documents, no statistic data can be provided or calculated!',
  method: 'get',
  path: '/document/open/:clientId',
  pathParams: [
    {
      type: 'string',
      name: 'clientId',
      description: 'The id of the client to get all open documents for',
      mandatory: true,
    },
  ],
  queryParams: [
    {
      type: 'boolean',
      name: 'markeDone',
      description:
        '(Optional) If parameter is "true" all documents, which will be returned by the request, will be set ' +
        'to state "done" automatically.',
      mandatory: false,
    },
  ],
  responseBody: {
    type: 'JSON-Object',
    data: [
      {
        key: 'Body',
        mandatory: true,
        type: 'Document[]',
        description: 'All open documents from the selected client',
      },
    ],
  },
};

/**
 * DocumentGetOpen()
 * @constructor
 */
export default function DocumentGetOpen() {
  return <AbstractEndpoint endpoint={DocumentGetOpenEndpoint} />;
}
