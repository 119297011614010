import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentState } from '../../../Library/Types/DocumentTypes';
import { pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import 'react-pdf/dist/Page/TextLayer.css';
import StatusBox from '../../../Components/Document/StatusBox';
import PdfPreview from '../../../Components/Document/PdfPreview';
import ImagePreview from '../../../Components/Document/ImagePreview';
import EditInformationForm from '../../../Components/Document/EditInformationForm';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useCheckCustomerAccess, useCheckDocumentAccess } from '../../../Library/Hooks/AccessHooks';
import ExcelPreview from '../../../Components/Document/ExcelPreview';
import TopNavbarItem from '../../../Components/Navigation/TopNavbarItem';
import { openDocument } from '../../../Library/Functions/HelperFunctions';
import 'pdfjs-dist/build/pdf.worker.entry';
import BookingTimer from '../../../Components/Document/BookingTimer';
import { DOCUMENT_SET_CURRENT, DOCUMENT_SET_CURRENT_BOOKING_TIME } from '../../../Redux/ActionTypes';
import ChangeStateButtons from '../../../Components/Document/ChangeStateButtons';
import classNames from 'classnames';
import Messages from '../../../Components/Document/Messages';
import { StateHistoryModal } from '../../../Components/Document/StateHistoryModal';
import { useContainerDimensions } from '../../../Library/Hooks/GlobalHooks';
import Container from '../../../Components/Container/Container';
import { useDispatchDocumentDelete, useDispatchDocumentDownloadBase64 } from '../../../Redux/Actions/DocumentActions';
import Button from '../../../Components/Forms/Button';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import moment from 'moment/moment';
import { documentUpdateInitialState, documentUpdateReducer } from '../../../Components/Document/DocumentUpdateReducer';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  img {
    border-radius: 10px;
    object-fit: contain;
  }
`;

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [state, dispatch] = React.useReducer(documentUpdateReducer, documentUpdateInitialState);
  const { documentId } = useParams();
  const { documents, currentDocument } = useAppSelector((state) => state.document);
  const { user } = useAppSelector((state) => state.auth);
  const [texts, setTexts] = React.useState<string[]>();
  const { t } = useTranslation();
  const navContext = React.useContext(NavbarContext);
  const divRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const [pdfFullScreen, setPdfFullScreen] = React.useState<boolean>(false);
  const [showHistory, setShowHistory] = React.useState<boolean>(false);
  const [downloading, setDownloading] = React.useState<boolean>(false);
  const [showAskDelete, setShowAskDelete] = React.useState<boolean>(false);
  const { width } = useContainerDimensions(divRef, pdfFullScreen);

  useCheckCustomerAccess();
  useCheckDocumentAccess(currentDocument);

  const dispatchDownload = useDispatchDocumentDownloadBase64(true);
  const dispatchDelete = useDispatchDocumentDelete();

  const handleDelete = React.useCallback(() => {
    if (currentDocument) {
      dispatchDelete(currentDocument).finally(() => {
        navigate('/documents');
      });
    }
  }, [currentDocument, dispatchDelete, navigate]);

  const handleDownload = React.useCallback(() => {
    if (currentDocument) {
      setDownloading(true);
      dispatchDownload(currentDocument).finally(() => {
        setDownloading(false);
      });
    }
  }, [currentDocument, dispatchDownload]);

  React.useEffect(() => {
    if (documents && documentId && (!currentDocument || currentDocument.documentId !== documentId)) {
      const found = documents.find((doc) => doc.documentId === documentId);
      if (found) {
        appDispatch(DOCUMENT_SET_CURRENT(found));
      }
    }
  }, [currentDocument, appDispatch, documentId, documents]);

  const setNavigationButtons = React.useMemo(() => {
    if (currentDocument) {
      return (
        <div className={'flex flex-row'}>
          <TopNavbarItem icon={'fa-kit fa-regular-file-clock'} onPress={() => setShowHistory(true)} />
          <TopNavbarItem icon={'fas fa-eye'} onPress={() => openDocument(currentDocument)} />
          <TopNavbarItem
            icon={classNames('fas', { 'fa-cloud-download': !downloading }, { 'fa-spin fa-spinner': downloading })}
            onPress={!downloading ? () => handleDownload() : () => {}}
          />
        </div>
      );
    }
    return null;
  }, [currentDocument, downloading, handleDownload]);

  React.useEffect(() => {
    navContext.setHeadline(t('modules.documents.information'));
    navContext.setShowBack(true);

    if (currentDocument) {
      navContext.setButton(setNavigationButtons);
    }
  }, [currentDocument, navContext, setNavigationButtons, t]);

  const prepareHighlightWords = () => {
    const data: string[] = [];

    if (currentDocument) {
      if (currentDocument.creditor) {
        data.push(currentDocument.creditor);
      }
      if (currentDocument.invoiceNumber) {
        data.push(currentDocument.invoiceNumber);
      }
      if (currentDocument.invoiceDate) {
        data.push(currentDocument.invoiceDate);
        data.push(moment(currentDocument.invoiceDate).format('DD.MM.YYYY'));
        data.push(moment(currentDocument.invoiceDate).format('DD.MM.YY'));
        data.push(moment(currentDocument.invoiceDate).format('DD/MM/YYYY'));
        data.push(moment(currentDocument.invoiceDate).format('DD/MM/YY'));
        data.push(moment(currentDocument.invoiceDate).format('YYYY/MM/DD'));
        data.push(moment(currentDocument.invoiceDate).format('YY/MM/DD'));
      }
    }
    return data;
  };

  if (currentDocument) {
    return (
      <>
        <Container>
          <div className={'flex flex-1 flex-row pl-4'}>
            <div className={'flex flex-1'}>
              <StatusBox document={currentDocument} withContainer={false} />
            </div>
            <div>
              <ChangeStateButtons document={currentDocument} />
            </div>
          </div>
        </Container>

        <InnerContainer>
          <div className={classNames({ 'flex flex-1 flex-col mr-4': !pdfFullScreen }, { hidden: pdfFullScreen })}>
            <BookingTimer
              document={currentDocument}
              onChange={(time) => appDispatch(DOCUMENT_SET_CURRENT_BOOKING_TIME(time))}
            />

            <EditInformationForm
              document={currentDocument}
              disabled={currentDocument.state === DocumentState.done}
              texts={texts}
              goBackAfterSave={false}
              state={state}
              dispatch={dispatch}
            />

            <Messages document={currentDocument} />
          </div>

          <div
            className={classNames(
              { 'w-2/6 lg:w-3/6 xl:w-7/12': !pdfFullScreen },
              { 'flex flex-1 w-auto': pdfFullScreen },
            )}
            ref={divRef}
          >
            <PdfPreview
              onFullScreen={setPdfFullScreen}
              width={width - 66}
              document={currentDocument}
              onTexts={setTexts}
              onStartLoading={() => navContext.setShowLoading(true)}
              onFinishedLoading={() => navContext.setShowLoading(false)}
              highlightWords={prepareHighlightWords()}
              onSetInvoiceNumber={() => {}}
              onSetInvoiceDate={() => {}}
              onSetCreditor={() => {}}
            />
            <ImagePreview
              document={currentDocument}
              onTexts={setTexts}
              onStartLoading={() => navContext.setShowLoading(true)}
              onFinishedLoading={() => navContext.setShowLoading(false)}
            />
            <ExcelPreview
              document={currentDocument}
              onTexts={setTexts}
              onStartLoading={() => navContext.setShowLoading(true)}
              onFinishedLoading={() => navContext.setShowLoading(false)}
            />

            <ShowHideContainer visible={currentDocument.createdUserId === user.userId}>
              <Container>
                <div className={'flex flex-row justify-end'}>
                  <div>
                    <Button onPress={() => setShowAskDelete(true)} colorStyle={'error'}>
                      {t('delete')}
                    </Button>
                  </div>
                </div>
              </Container>
            </ShowHideContainer>
          </div>
        </InnerContainer>

        <div className={'mt-4 text-xs text-gray-400 text-center'}>{`${documentId}`}</div>
        <StateHistoryModal document={currentDocument} visible={showHistory} onClose={() => setShowHistory(false)} />

        <AskDeleteModal
          headline={t('alert.deleteDocument.headline')}
          text={t('alert.deleteDocument.text', { name: currentDocument.originalName })}
          visible={showAskDelete}
          onClose={() => setShowAskDelete(false)}
          onDelete={handleDelete}
        />
      </>
    );
  }
  return null;
}
