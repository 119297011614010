import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import DisplayRow from '../Display/DisplayRow';
import ActionButton from '../Container/ActionButton';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);

  const renderButtons = () => {
    return (
      <ActionButton
        onPress={() => navigate('update')}
        icon={'fas fa-pencil'}
        className={classNames('hover:bg-blue-500 hover:text-white')}
        tooltip={t('edit')}
      />
    );
  };

  return (
    <Container headline={t('details')} actionButtons={renderButtons()}>
      <DisplayRow label={t('name')} value={customer.name} />
      <DisplayRow label={t('displayName')} value={customer.displayName} />
      <DisplayRow label={t('address')} value={customer.street} />
      <DisplayRow label={t(' ')} value={`${customer.zip} ${customer.city}`.trim()} />
      <DisplayRow label={t('phone')} value={customer.phone} showCopyIcon />
      <DisplayRow label={t('mail')} value={customer.mail} showCopyIcon />
      <DisplayRow label={t('website')} value={customer.web} showCopyIcon />
      <DisplayRow label={t('taxNumber')} value={customer.taxNumber} showCopyIcon />
    </Container>
  );
}
