import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ListView from '../../../Components/Document/ListView';
import { useNavigate } from 'react-router';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useCheckCustomerAccess } from '../../../Library/Hooks/AccessHooks';
import DocumentFilter from '../../../Components/Document/DocumentFilter';
import InputRow from '../../../Components/Forms/InputRow';
import { documentsSearch } from '../../../Library/Functions/DocumentFunctions';

/**
 * Dashboard()
 * @constructor
 */
export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { documents } = useAppSelector((state) => state.document);
  const [filtered, setFiltered] = React.useState<DocumentType[]>([]);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<DocumentType[] | null>(null);
  const navContext = React.useContext(NavbarContext);
  useCheckCustomerAccess();

  React.useEffect(() => {
    navContext.setHeadline(t('documentsOpen', { count: 0 }));
  }, [navContext, t]);

  const handleOnPress = (document: DocumentType) => {
    navigate(`/documents/${document.documentId}`);
  };

  React.useEffect(() => {
    if (searchValue && searchValue.length > 2) {
      const result = documentsSearch(filtered, searchValue);
      setSearchResult(result);
    } else {
      setSearchResult(null);
    }
  }, [filtered, searchValue]);

  return (
    <>
      <InputRow
        onChange={setSearchValue}
        value={searchValue}
        placeholder={t('searchDocument')}
        showClear={true}
        autoFocus
      />

      <div className={'mb-6'}>
        <DocumentFilter
          hideOwnerOnly={false}
          showOpenOnly={false}
          documents={[
            ...documents.filter((item) => item.state === DocumentState.open || item.state === DocumentState.question),
          ]}
          onChange={setFiltered}
        />
      </div>
      <ListView documents={searchResult || filtered || []} onPress={handleOnPress} showClient={true} />
    </>
  );
}
