import React from 'react';
import AbstractModal from './AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';
import { useNavigate } from 'react-router';

interface Props {
  visible: boolean;
  onClose: () => void;
}

/**
 * DemoHintModal()
 * @param props
 * @constructor
 */
export default function DemoHintModal(props: Props) {
  const { visible, onClose } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = React.useCallback(() => {
    onClose();
    navigate('/settings/subscription');
  }, [navigate, onClose]);

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'}>
        {t('close')}
      </Button>
      <Button onPress={handleNavigate} colorStyle={'success'}>
        {t('subscribeNow')}
      </Button>
    </div>
  );

  return (
    <AbstractModal
      visible={visible}
      onBackdrop={onClose}
      type={'success'}
      headline={t('demoVersion')}
      text={t('dashboardDemoHint')}
      buttons={renderButtons()}
      icon={'fad fa-face-smile-hearts'}
    />
  );
}
