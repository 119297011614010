import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Button from '../../../Components/Forms/Button';
import Container from '../../../Components/Container/Container';
import { useDispatchClientUpdate } from '../../../Redux/Actions/ClientActions';
import { Client } from '../../../Library/Types/ClientTypes';
import { Salutation } from '../../../Library/Types/GeneralTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useCheckCustomerAccess } from '../../../Library/Hooks/AccessHooks';
import validateClientCreate from '../../../Library/Validators/ClientCreate';
import InputRow from '../../../Components/Forms/InputRow';
import ListBoxSelectUser from '../../../Components/Forms/PredefinedListBox/ListBoxSelectUser';
import { useListBoxGetSalutationOptions } from '../../../Library/Hooks/FormHooks';
import ListBox from '../../../Components/Forms/ListBox';
import ListBoxSelectLanguage from '../../../Components/Forms/PredefinedListBox/ListBoxSelectLanguage';
import { clientCreateUpdateInitialState, clientCreateUpdateReducer } from './Reducer';
import ListBoxSelectMandantGroup from '../../../Components/Forms/PredefinedListBox/ListBoxSelectMandantGroup';

/**
 * Create()
 * @constructor
 */
export default function Update() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { users, clients } = useAppSelector((state) => state.auth);
  const [state, dispatch] = useReducer(clientCreateUpdateReducer, clientCreateUpdateInitialState);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);
  const [errors, setErrors] = React.useState<{ [K in keyof Client]?: boolean }>({});
  const salutationOptions = useListBoxGetSalutationOptions();
  useCheckCustomerAccess();

  const dispatchUpdate = useDispatchClientUpdate();

  const create = React.useCallback(() => {
    setErrors({});
    const validated = validateClientCreate(state.client);

    if (!validated.hasErrors) {
      setLoading(true);
      dispatchUpdate(state.client).finally(() => {
        setLoading(false);
        navigate(-1);
      });
    } else {
      setErrors(validated.errors);
    }
  }, [dispatchUpdate, navigate, state.client]);

  React.useEffect(() => {
    navContext.setHeadline(`${t('client')} / ${t('edit')}`);
  }, [navContext, t]);

  React.useEffect(() => {
    if (clientId && clients) {
      const foundClient = clients.find((client) => client.clientId === clientId);
      if (foundClient) {
        dispatch({ type: 'init', payload: foundClient });
      }
    }
  }, [clientId, clients]);

  const renderContent = () => {
    if (state.client.salutation === Salutation.company) {
      return (
        <>
          <InputRow
            onChange={(payload) => dispatch({ type: 'companyName', payload })}
            label={t('companyName')}
            value={state.client.companyName}
            disabled={loading}
            required
            hasError={errors && errors.companyName}
          />
        </>
      );
    } else {
      return (
        <div className={'flex flex-1 flex-row items-center'}>
          <div className={'w-3/12 text-gray-400 mb-4'}>{t('name')}*</div>
          <div className={'flex flex-1 flex-row'}>
            <div className={'w-1/2 mr-2'}>
              <InputRow
                onChange={(payload) => dispatch({ type: 'firstName', payload })}
                placeholder={t('firstName')}
                value={state.client.firstName}
                disabled={loading}
                required
                hasError={errors && errors.firstName}
              />
            </div>
            <div className={'w-1/2 ml-2'}>
              <InputRow
                onChange={(payload) => dispatch({ type: 'lastName', payload })}
                placeholder={t('lastName')}
                value={state.client.lastName}
                disabled={loading}
                required
                hasError={errors && errors.lastName}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Container headline={t('userInfos')}>
        <ListBox<Salutation>
          label={t('salutation')}
          options={salutationOptions}
          onChange={(payload) => dispatch({ type: 'salutation', payload })}
          selected={state.client.salutation}
          required
        />

        {renderContent()}

        <InputRow
          onChange={(payload) => dispatch({ type: 'mail', payload })}
          label={t('mail')}
          value={state.client.mail}
          disabled={loading}
        />
        <ListBoxSelectMandantGroup
          onChange={(payload) => dispatch({ type: 'groupId', payload })}
          selected={state.client.groupId}
          hasError={errors && errors.groupId}
          required
        />
        <InputRow
          onChange={(payload) => dispatch({ type: 'taxNumber', payload })}
          label={t('taxNumber')}
          value={state.client.taxNumber}
          disabled={loading}
        />
      </Container>

      <Container headline={t('additionalSettings')}>
        <ListBoxSelectUser
          onChange={(value) => dispatch({ type: 'assignedUserId', payload: value })}
          selected={state.client.assignedUserId}
          users={users}
          caption={t('responsibleUser')}
          required
          hasError={errors && errors.assignedUserId}
          hint={t('modules.client.form.hintAssignedUser')}
          direction={'row'}
        />
        <InputRow
          onChange={(payload) => dispatch({ type: 'internalId', payload })}
          label={t('internalClientId')}
          value={state.client.internalId}
          disabled={loading}
          hint={t('modules.client.form.hintInternalNumber')}
          direction={'row'}
        />
        <ListBoxSelectLanguage
          onChange={(payload) => dispatch({ type: 'language', payload })}
          selected={state.client.language}
          required
          hint={t('modules.client.form.hintLanguage')}
          direction={'row'}
        />
        <ListBox<boolean>
          onChange={(payload) => dispatch({ type: 'isActive', payload })}
          options={[
            { label: t('active'), value: true },
            { label: t('inactive'), value: false },
          ]}
          selected={state.client.isActive}
          label={t('state')}
          hint={t('modules.client.form.hintState')}
          direction={'row'}
        />
        <ListBox<boolean>
          onChange={(payload) => dispatch({ type: 'registrationActive', payload })}
          options={[
            { label: t('yes'), value: true },
            { label: t('no'), value: false },
          ]}
          selected={state.client.registrationActive}
          label={t('allowNewRegistrations')}
          hint={t('modules.client.form.hintRegistration')}
          direction={'row'}
        />
        <ListBox<boolean>
          onChange={(payload) => dispatch({ type: 'allowOutgoing', payload })}
          options={[
            { label: t('yes'), value: true },
            { label: t('no'), value: false },
          ]}
          selected={state.client.allowOutgoing}
          label={t('allowOutgoing')}
          hint={t('modules.client.form.hintAllowOutgoing')}
          direction={'row'}
          required
        />
        <ListBox<boolean>
          onChange={(payload) => dispatch({ type: 'allowBank', payload })}
          options={[
            { label: t('yes'), value: true },
            { label: t('no'), value: false },
          ]}
          selected={state.client.allowBank}
          label={t('allowBank')}
          hint={t('modules.client.form.hintAllowBank')}
          direction={'row'}
          required
        />
      </Container>

      <Container>
        <div className={'flex justify-end'}>
          <Button onPress={() => navigate(-1)} colorStyle={'cancel'} disabled={loading}>
            {t('cancel')}
          </Button>
          <Button onPress={create} colorStyle={'success'} disabled={loading}>
            {t('save')}
          </Button>
        </div>
      </Container>
    </>
  );
}
