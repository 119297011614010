import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AbstractFormContainer from './AbstractFormContainer';

export interface ListBoxItem<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

interface Props<T> {
  label?: string;
  options: ListBoxItem<T>[];
  onChange: (value: T) => void;
  selected: T;
  hasError: boolean;
  required?: boolean;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
  placeholder?: string;
}

export default function ListBox<T>(props: Props<T>) {
  const { options, direction, onChange, placeholder, selected, label, hasError, required, hint, containerClass } =
    props;
  const [selectedItem, setSelectedItem] = React.useState<ListBoxItem<T> | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (selected !== null) {
      const found = options.find((option) => option.value === selected);
      setSelectedItem(found || null);
    }
  }, [options, selected]);

  const renderOptions = () => {
    if (options && options.length > 0) {
      return options.map((item: ListBoxItem<T>) => (
        <Listbox.Option
          key={item.label}
          className={({ active }) =>
            classNames('relative select-none py-2 pl-10 pr-4 cursor-pointer', {
              'bg-gray-200 text-black': active,
              'text-gray-900': !active,
            })
          }
          value={item.value}
        >
          <span className={`block truncate font-normal`}>{item.label}</span>
        </Listbox.Option>
      ));
    }
    return null;
  };

  return (
    <AbstractFormContainer
      direction={direction}
      containerClass={containerClass}
      label={label}
      hint={hint}
      required={required}
    >
      <Listbox value={selected} onChange={(item) => onChange(item)}>
        <div className="relative flex-1">
          <Listbox.Button
            className={classNames(
              'relative',
              'flex, justify-center',
              'w-full',
              'cursor-default',
              'rounded-md',
              'bg-white',
              'px-4',
              'pr-10',
              'text-left',
              'border',
              'focus:outline-0',
              'border-gray-300',
              { 'border-red-500 border-2': hasError },
            )}
            style={{ height: 50 }}
          >
            <span className="block truncate">
              {selectedItem ? (
                selectedItem.label
              ) : (
                <div className={'font-light text-gray-400'}>{placeholder || t('pleaseSelect')}</div>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <i className="fas fa-chevron-down h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={classNames(
                'absolute',
                'z-10',
                'mt-1',
                'max-h-60',
                'w-full',
                'overflow-auto',
                'rounded-md',
                'bg-white',
                'py-1',
                'text-base',
                'shadow-lg',
                'ring-1',
                'ring-black/5',
                'focus:outline-none',
              )}
            >
              {renderOptions()}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </AbstractFormContainer>
  );
}

ListBox.defaultProps = {
  hasError: false,
  direction: 'row',
};
