import React from 'react';
import Container from '../../../Components/Container/Container';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useTranslation } from 'react-i18next';
import { useDispatchStorageGetList } from '../../../Redux/Actions/StorageActions';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ListView from '../../../Components/Storage/ListView';
import { Storage } from '../../../Library/Types/StorageTypes';
import { FolderHeader } from '../../../Components/Storage/FolderHeader';
import ActionBar from '../../../Components/Storage/ActionBar';

/**
 * Dashboard()
 * @constructor
 */
export default function Dashboard() {
  const { activeClient } = useAppSelector((state) => state.auth);
  const { all } = useAppSelector((state) => state.storage.storage);
  const { folders } = useAppSelector((state) => state.storage);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filtered, setFiltered] = React.useState<Storage[]>([]);
  const [searchResult, setSearchResult] = React.useState<Storage[] | null>(null);
  const [folder, setFolder] = React.useState<string>('/');
  const { t } = useTranslation();
  const navContext = React.useContext(NavbarContext);

  const dispatchGetList = useDispatchStorageGetList();

  const onHandleSearch = React.useCallback(
    (value: string) => {
      if (value && value.length >= 2) {
        const filtered = all.filter(
          (item) =>
            item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
            (item.description ? item.description.toLowerCase().indexOf(value.toLowerCase()) > -1 : false) ||
            item.fileType.toLowerCase().indexOf(value.toLowerCase()) > -1,
        );
        setSearchResult(filtered);
      } else {
        setSearchResult(null);
      }
    },
    [all],
  );

  React.useEffect(() => {
    if (folder) {
      setFiltered(all.filter((item) => item.folder === folder));
    }
  }, [all, folder]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (activeClient) {
      setLoading(true);
      timer = setTimeout(() => {
        dispatchGetList().finally(() => {
          setLoading(false);
        });
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [activeClient, dispatchGetList]);

  React.useEffect(() => {
    navContext.setHeadline(t('storage.documents'));
    navContext.setShowLoading(loading);
  }, [loading, navContext, t]);

  return (
    <>
      <FolderHeader folder={folder} onChange={setFolder} documents={filtered} />
      <ActionBar folder={folder} onSearch={onHandleSearch} onFolderCreated={setFolder} />

      <Container>
        <ListView
          documents={searchResult || filtered}
          folder={searchResult ? null : folder}
          availableFolders={folders}
          onPressFolder={setFolder}
        />
      </Container>
    </>
  );
}
