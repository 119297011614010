export enum FirebasePath {
  customers = 'customers',
  customerLogo = 'customerLogo',
  clients = 'clients',
  documents = 'documents',
  storage = 'storage',
  users = 'users',
  mailTemplates = 'mailTemplates',
  statistics = 'statistics',
  oauth = 'oauth',
}

export enum FirebaseFunctions {
  customerGet = 'customerGet',
  customerGenerateApiKey = 'customerGenerateApiKey',
  clientGet = 'clientGet',
  mailTemplateSend = 'mailTemplateSend',
  stripeCreateCheckout = 'stripeCreateCheckout',
  stripeCustomerGet = 'stripeCustomerGet',
  stripeGetCustomerPortalUrl = 'stripeGetCustomerPortalUrl',
  stripeApproveSession = 'stripeApproveSession',
  documentGetBase64 = 'documentGetBase64',
  oauthGet = 'oauthGet',
  oAuthGrandAccess = 'oAuthGrandAccess',
  documentsDownloadList = 'documentsDownloadList',
  documentsDownloadAllOpen = 'documentsDownloadAllOpen',
  documentGetInvoiceData = 'documentGetInvoiceData',
}
