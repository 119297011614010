import { ClientGroup, Language, MailTemplateType } from './GeneralTypes';

/**
 * Defines the access for users
 */
export interface CustomerUserAccess {
  mandant?: {
    create?: boolean;
    update?: boolean;
    sendMail?: boolean;
  };
}

export enum SubscriptionType {
  demo = 'demo',
  smallBusiness = 'smallBusiness',
  professional = 'professional',
  unlimited = 'unlimited',
}

export interface Customer {
  customerId: string;
  name: string;
  displayName: string;
  zip: string;
  city: string;
  street: string;
  country: Language;
  taxNumber: string;
  phone: string;
  mail: string;
  web: string;
  logo: string | null;
  isBeta: boolean;
  settings: {
    register: {
      isActive: boolean;
      allowedDomains: string[];
    };
    client: {
      groups: ClientGroup[];
    };
    api: {
      token?: string;
      active: boolean;
    };
  };
  stripeCustomerId?: string;
  subscriptionActive: boolean;
  subscriptionType: SubscriptionType;
}

export interface MailTemplate {
  type: MailTemplateType;
  subject: string;
  text: string;
  html: string;
}
