/* eslint-disable no-console */

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getApps, initializeApp } from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/remote-config';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { Environment } from '../Library/Types/GeneralTypes';
import Config from '../Library/Config';
import moment from 'moment';
import { getSelectedLanguage, momentConfiguration } from '../Library/Functions/LocalizationFunctions';
import AuthedLayout from '../Components/Layout/AuthedLayout';
import Login from '../Modules/Login';
import { initializeTranslations } from '../Library/Translations/i18n';
import { useAppSelector } from '../Library/Hooks/ReduxHooks';
import ClientOverview from '../Modules/Customer/Client/Overview';
import ClientCreate from '../Modules/Customer/Client/Create';
import ClientUpdate from '../Modules/Customer/Client/Update';
import UserDocumentsDashboard from '../Modules/Client/Documents/Dashboard';
import CustomerDocumentsDashboard from '../Modules/Customer/Documents/Dashboard';
import UserDashboard from '../Modules/Client/Dashboard/UserDashboard';
import CustomerDashboard from '../Modules/Customer/Dashboard/CustomerDashboard';
import UserDocumentDetails from '../Modules/Client/Documents/Details';
import CustomerDocumentDetails from '../Modules/Customer/Documents/Details';
import SettingsClients from '../Modules/Settings/Customers/Clients';
import FinishedDocuments from '../Modules/Customer/Client/FinishedDocuments';
import ClientDetails from '../Modules/Customer/Client/Details';
import UsersDashboard from '../Modules/Settings/Customers/Users/Dashboard';
import UsersDetails from '../Modules/Settings/Customers/Users/Details';
import RegisterUser from '../Modules/Register/RegisterUser';
import RegisterCustomer from '../Modules/Register/RegisterCustomer';
import RegisterClient from '../Modules/Register/RegisterClient';
import SettingsCustomer from '../Modules/Settings/Customers/Details';
import SettingsCustomerUpdate from '../Modules/Settings/Customers/Update';
import Checkout from '../Modules/Stripe/Checkout';
import CheckoutSuccess from '../Modules/Stripe/CheckoutSuccess';
import SettingsSubscription from '../Modules/Settings/Customers/Subscription';
import Profile from '../Modules/Settings/Profile';
import PasswordForgotten from '../Modules/PasswordForgotten';
import PasswordReset from '../Modules/PasswordReset';
import ApiDocumentation from '../Modules/ApiDocumentation';
import OAuth from '../Modules/OAuth';
import Startpage from '../Modules/Website/Startpage';
import Contact from '../Modules/Website/Contact';
import ExternalLogin from '../Modules/ExternalLogin';
import Cookies from '../Modules/Website/Cookies';
import Security from '../Modules/Website/Security';
import Legal from '../Modules/Website/Legal';
import Conditions from '../Modules/Website/Conditions';
import Finished from '../Modules/Client/Documents/Finished';
import StorageDashboard from '../Modules/Client/Storage/Dashboard';

/**
 * AppRouter()
 * @returns {*}
 * @constructor
 */
export default function AppRouter() {
  const [fbInitialized, setFbInitialized] = React.useState<boolean>(false);
  const [firebaseInitialized, setFirebaseInitialized] = React.useState<boolean>(false);
  const [initializedTranslations, setInitializedTranslations] = React.useState(false);
  const [isLocalDev] = React.useState(process.env.REACT_APP_STAGE === Environment.dev);
  const { user } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    try {
      moment.locale('de', {
        week: {
          dow: 1, // Monday is the first day of the week.
        },
      });
    } catch (e) {}

    if (getApps().length === 0) {
      initializeApp(Config.firebase.config);
      getFunctions().region = Config.firebase.region;
      setFirebaseInitialized(true);
    }
  }, []);

  React.useEffect(() => {
    if (isLocalDev && !fbInitialized) {
      connectFirestoreEmulator(getFirestore(), Config.localhostIp, 8080);
      connectAuthEmulator(getAuth(), `http://${Config.localhostIp}:9099`, { disableWarnings: true });
      connectStorageEmulator(getStorage(), Config.localhostIp, 9199);
      connectFunctionsEmulator(getFunctions(), Config.localhostIp, 5001);
      setFbInitialized(true);
      console.log('Firebase emulators setUp!');
    }
  }, [fbInitialized, isLocalDev]);

  React.useEffect(() => {
    if (!initializedTranslations) {
      moment.locale(getSelectedLanguage(false));
      moment.localeData(getSelectedLanguage(false)).set(momentConfiguration(getSelectedLanguage(true)));
      initializeTranslations(getSelectedLanguage(true));
      setInitializedTranslations(true);
    }
  }, [initializedTranslations]);

  React.useEffect(() => {
    if (Config.env !== Environment.dev) {
      // Initialize Analytics and get a reference to the service
      getAnalytics();
    }
  }, []);

  const renderRoutes = () => {
    return (
      <Routes>
        <Route path="" element={<Startpage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/security" element={<Security />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/conditions" element={<Conditions />} />

        <Route path={''} element={<AuthedLayout />}>
          <Route path="dashboard" element={user.customerId ? <CustomerDashboard /> : <UserDashboard />} />
          <Route
            path="documents"
            element={user.customerId ? <CustomerDocumentsDashboard /> : <UserDocumentsDashboard />}
          />
          <Route
            path="documents/:documentId"
            element={user.customerId ? <CustomerDocumentDetails /> : <UserDocumentDetails />}
          />

          <Route path={'/settings'}>
            <Route path="registration" element={<SettingsClients />} />
            <Route path="users" element={<UsersDashboard />} />
            <Route path="users/:userId" element={<UsersDetails />} />
            <Route path="customer" element={<SettingsCustomer />} />
            <Route path="customer/update" element={<SettingsCustomerUpdate />} />
            <Route path="subscription" element={<SettingsSubscription />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>

        <Route path={'/client'} element={<AuthedLayout />}>
          <Route path="" element={<ClientOverview />} />
          <Route path="create" element={<ClientCreate />} />
          <Route path="update/:clientId" element={<ClientUpdate />} />
          <Route path="invoices" element={<Finished />} />
          <Route path=":clientId" element={<ClientDetails />} />
          <Route path=":clientId/invoices" element={<FinishedDocuments />} />
          <Route path="storage" element={<StorageDashboard />} />
        </Route>

        <Route path="/register/user/:customerId" element={<RegisterUser />} />
        <Route path="/register/client/:customerId/:clientId" element={<RegisterClient />} />
        <Route path="/register" element={<RegisterCustomer />} />
        <Route path="/login" element={<Login />} />
        <Route path="/external/login/:customerId" element={<ExternalLogin />} />
        <Route path="/login/password" element={<PasswordForgotten />} />
        <Route path="/login/reset" element={<PasswordReset />} />
        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/checkout/success" element={<CheckoutSuccess />} />
        <Route path="/apidocumentation" element={<ApiDocumentation />} />
        <Route path="/oauth" element={<OAuth />} />
        <Route path="*" element={<Startpage />} />
      </Routes>
    );
  };

  if (initializedTranslations && firebaseInitialized) {
    return <BrowserRouter>{renderRoutes()}</BrowserRouter>;
  }
  return null;
}
