import React from 'react';
import { useIsDemo } from '../../Library/Hooks/GlobalHooks';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

/**
 * DemoHint()
 * @constructor
 */
export default function DemoHint() {
  const isDemo = useIsDemo();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isDemo) {
    return (
      <div
        className={classNames(
          'px-5 py-8 bg-orange-400 rounded-md mb-6',
          'flex flex-1 flex-col items-center',
          'text-white',
          '2xl:flex-row 2xl:py-6',
        )}
      >
        <i className={classNames('fad fa-face-smile-hearts mb-6 text-7xl', '2xl:mr-6 2xl:mb-0')} />
        <div className={classNames('flex-1 text-lg font-normal text-center mb-6', '2xl:text-left 2xl:mb-0')}>
          {t('dashboardDemoHint')}
        </div>
        <div
          className={classNames(
            'px-6 py-3 bg-orange-700 rounded-md',
            'cursor-pointer hover:shadow-2xl',
            'hover:bg-orange-600',
            '2xl:ml-8',
          )}
          onClick={() => navigate('/settings/subscription')}
        >
          {t('subscribeNow')}
        </div>
      </div>
    );
  }
  return null;
}
