import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-google-charts';
import { useDispatchClientStatisticsGet } from '../../Redux/Actions/Client/StatisticsActions';
import moment from 'moment';

/**
 * UserOverviewYear()
 * @constructor
 */
export default function UserOverviewYear() {
  const { t } = useTranslation();
  const { user, activeClient } = useAppSelector((state) => state.auth);
  const { lastYear } = useAppSelector((state) => state.client.statistics);
  const [chartData, setChartData] = React.useState<any>();
  const dispatchGetStatistics = useDispatchClientStatisticsGet();

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (activeClient) {
      timer = setTimeout(() => dispatchGetStatistics(activeClient.clientId), 500);
    }
    return () => clearTimeout(timer);
  }, [activeClient, dispatchGetStatistics, user.userId]);

  React.useEffect(() => {
    if (lastYear) {
      let data = [];

      let headerData = [
        { label: t('date'), type: 'string' },
        { label: t('overall'), type: 'number' },
        { label: t('invoiceIncoming'), type: 'number' },
      ];

      if (activeClient && activeClient.allowOutgoing) {
        headerData.push({ label: t('invoiceOutgoing'), type: 'number' });
      }
      if (activeClient && activeClient.allowBank) {
        headerData.push({ label: t('invoiceBank'), type: 'number' });
      }
      data.push(headerData);

      lastYear.forEach((item) => {
        let newData = [moment(item.month.toString(), 'M').format('MMM'), item.overallCount, item.incomingCount];

        if (activeClient && activeClient.allowOutgoing) {
          newData.push(item.outgoingCount);
        }
        if (activeClient && activeClient.allowBank) {
          newData.push(item.bankCount);
        }

        data.push(newData as any);
      });

      setChartData(data);
    }
  }, [t, lastYear, activeClient]);

  const options = {
    seriesType: 'bars',
    series: { 5: { type: 'line' } },
    legend: { position: 'bottom' },
    chartArea: { left: 50, top: 50, right: 20, bottom: 90 },
  };

  if (!user.isCustomerUser && chartData) {
    return (
      <Container headline={t('yearlyOverview')} noPadding closable identifier={'UserYearlyOverview'}>
        <Chart chartType="ComboChart" data={chartData} width="100%" height="400px" options={options} />
      </Container>
    );
  }
  return null;
}
