import { Customer, SubscriptionType } from '../Types/CustomerTypes';
import { ClientGroup, Language } from '../Types/GeneralTypes';

/**
 * customerBuildFromRegistration()
 * @param companyName
 * @param mail
 * @param taxNumber
 * @param language
 */
export const customerBuildFromRegistration = (
  companyName: string,
  mail: string,
  taxNumber: string,
  language: Language,
): Customer => {
  return {
    customerId: '',
    city: '',
    mail: mail,
    phone: '',
    street: '',
    web: '',
    zip: '',
    country: language,
    taxNumber: taxNumber,
    settings: {
      register: {
        isActive: true,
        allowedDomains: [],
      },
      client: {
        groups: [{ id: 1, name: 'Default' }],
      },
      api: {
        token: '',
        active: false,
      },
    },
    name: companyName,
    displayName: companyName,
    logo: '',
    subscriptionActive: false,
    subscriptionType: SubscriptionType.demo,
    isBeta: false,
  };
};

/**
 * getNextFreeClientGroupId()
 * @param groups
 */
export const getNextFreeClientGroupId = (groups: ClientGroup[]): number => {
  let number = 1;
  let freeNumber: number | null = null;

  while (!freeNumber) {
    // eslint-disable-next-line no-loop-func
    const found = groups.find((item) => item.id === number);
    if (found) {
      number++;
    } else {
      freeNumber = number;
    }
  }

  return freeNumber;
};
