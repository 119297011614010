import {
  AUTH_LOGOUT,
  STORAGE_CREATE_SUCCESS,
  STORAGE_DELETE_SUCCESS,
  STORAGE_FOLDER_CREATE,
  STORAGE_GETLIST_SUCCESS,
  STORAGE_UPDATE_SUCCESS,
} from '../ActionTypes';
import { createReducer } from '@reduxjs/toolkit';
import { Storage } from '../../Library/Types/StorageTypes';
import Lodash from 'lodash';

export interface StorageReducerInterface {
  storage: {
    all: Storage[];
    sortCreated: Storage[];
  };
  folders: string[];
}

const defaultValues: StorageReducerInterface = {
  storage: {
    all: [],
    sortCreated: [],
  },
  folders: [],
};

const sortByCreatedDate = (storage: Storage[]): Storage[] => {
  const sorted = storage.sort((a, b) => (a.firebaseCreatedDate > b.firebaseCreatedDate ? 1 : -1));
  return [...sorted];
};

const sortByName = (storage: Storage[]): Storage[] => {
  const sorted = [...storage].sort((a, b) => a.name.localeCompare(b.name));
  return [...sorted];
};

const getFolders = (storage: Storage[]): string[] => {
  return Lodash.uniq(storage.map((item) => item.folder)).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
};

export default createReducer(defaultValues, (builder) => {
  builder.addCase(STORAGE_GETLIST_SUCCESS, (state, action) => {
    state.storage.all = sortByName(action.payload);
    state.storage.sortCreated = sortByCreatedDate(action.payload);
    state.folders = getFolders(action.payload);
  });
  builder.addCase(STORAGE_CREATE_SUCCESS, (state, action) => {
    const newAll = [...state.storage.all, action.payload];
    state.storage.all = sortByName(newAll);
    state.storage.sortCreated = sortByCreatedDate(newAll);
  });
  builder.addCase(STORAGE_UPDATE_SUCCESS, (state, action) => {
    state.storage.all = state.storage.all.map((item) =>
      item.storageId === action.payload.storageId ? action.payload : item,
    );
    state.storage.sortCreated = state.storage.sortCreated.map((item) =>
      item.storageId === action.payload.storageId ? action.payload : item,
    );
  });
  builder.addCase(STORAGE_DELETE_SUCCESS, (state, action) => {
    const newAll = state.storage.all.filter((item) => item.storageId !== action.payload.storageId);
    state.storage.all = newAll;
    state.storage.sortCreated = state.storage.sortCreated.filter((item) => item.storageId !== action.payload.storageId);
    state.folders = getFolders(newAll);
  });
  builder.addCase(STORAGE_FOLDER_CREATE, (state, action) => {
    const newFolders = [...state.folders, action.payload];
    state.folders = newFolders.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  });

  builder.addCase(AUTH_LOGOUT, (state) => {
    state.storage = defaultValues.storage;
    state.folders = [];
  });
});
