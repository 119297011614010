import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { useCopyClipboard } from '../../Library/Hooks/GlobalHooks';
import classNames from 'classnames';

export enum InputType {
  text = 'text',
  password = 'password',
  textarea = 'textarea',
  number = 'number',
  email = 'email',
}

type InputProps = {
  onChange: (value: string) => void;
  onBlur?: Function;
  label: string;
  value: string | number;
  visible: boolean;
  type: InputType;
  required: boolean;
  hasError?: boolean;
  errorId?: string;
  errorMessage?: string | null;
  disabled: boolean;
  contextHelpKey: string;
  rows: number;
  maxLength?: number;
  showSkeleton: boolean;
  style: Object;
  autoFocus: boolean;
  removeBottomMargin: boolean;
  rightIcon?: React.ReactElement;
  showCopy: boolean;
  rightText?: string;
  loading?: boolean;
  onEnter?: () => void;
};

interface ContainerProps {
  $noMarginBottom: boolean;
  $isFocused: boolean;
  $hasError: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => (props.$noMarginBottom ? '0px' : '15px')};

  input,
  textarea {
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: ${(props) => (props.$isFocused ? '6px' : '16px')};
    padding-top: ${(props) => (props.$isFocused ? '26px' : '16px')};
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:focus {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: ${(props) => (props.$isFocused ? '4px' : '14px')};
      padding-top: ${(props) => (props.$isFocused ? '24px' : '14px')};
      border-width: 3px;
    }
  }

  .Label {
    font-weight: 300;
    position: absolute;
    left: 19px;
    top: ${(props) => (props.$isFocused ? '6px' : '17px')};
    font-size: ${(props) => (props.$isFocused ? '14px' : '16px')};
    color: ${(props) => (props.$hasError ? lighten(0.2, props.theme.colors.red) : props.theme.colors.textLight)};
    transition:
      top 200ms,
      font-size 200ms,
      font-weight 200ms;
  }

  .RightIconContainer {
    position: absolute;
    right: 15px;
    top: 17px;

    i {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ErrorIcon {
      color: ${(props) => lighten(0.2, props.theme.colors.red)};
    }
    .DisabledIcon {
      color: ${(props) => lighten(0.15, props.theme.colors.textLight)};
    }
  }
`;

/**
 * Input()
 * @param props
 * @constructor
 */
export default function Input(props: InputProps) {
  const { rightText, loading, onEnter } = props;
  const [focused, setFocused] = React.useState<boolean>(false);
  const [inputType, setInputType] = React.useState<InputType>(props.type);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (props.autoFocus && inputRef.current) {
      // @ts-ignore
      timer = setTimeout(() => inputRef.current.focus(), 350);
    }

    return () => clearTimeout(timer);
  }, [props.autoFocus]);

  const manualFocusInput = () => {
    if (inputRef.current && !focused) {
      inputRef.current.focus();
    }
  };

  const renderLabel = () => {
    const label = `${props.label}${props.required ? '*' : ''}`;
    return (
      <div className="Label" onClick={manualFocusInput}>
        {label}
      </div>
    );
  };

  const renderErrorIcon = () => {
    if (props.hasError) {
      return <i className="fas fa-exclamation-circle ErrorIcon" />;
    }
    return null;
  };

  const renderCopyIcon = () => {
    if (props.showCopy && props.value) {
      return (
        <i
          className="far fa-copy text-gray-300 hover:text-black cursor-pointer"
          onClick={() => copyToClipBoard(props.value as string)}
        />
      );
    }
    return null;
  };

  const renderDisabledIcon = () => {
    if (props.disabled) {
      return <i className="fas fa-lock DisabledIcon" />;
    }
    return null;
  };

  const renderPasswordVisible = () => {
    if (props.type === InputType.password && !props.disabled) {
      return (
        <i
          className="fas fa-eye"
          onMouseDown={() => setInputType(InputType.text)}
          onMouseUp={() => setInputType(InputType.password)}
          onMouseOut={() => setInputType(InputType.password)}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    return null;
  };

  const renderRightIcons = () => {
    const errorIcon = renderErrorIcon();
    const disabledIcon = renderDisabledIcon();
    const passwdVisible = renderPasswordVisible();
    const copyIcon = renderCopyIcon();
    const rightIcon = props.rightIcon || null;

    if (errorIcon || disabledIcon || passwdVisible || rightIcon || copyIcon) {
      return (
        <div className="RightIconContainer">
          {copyIcon}
          {rightIcon}
          {errorIcon}
          {passwdVisible}
          {disabledIcon}
        </div>
      );
    }
    return null;
  };

  const renderRightText = () => {
    if (props.rightText) {
      return (
        <div
          className={classNames(
            'bg-gray-200 border border-solid border-gray-300 rounded-r-lg',
            'justify-center items-center flex px-6',
          )}
        >
          {rightText}
        </div>
      );
    }
    return null;
  };

  const handleOnBlur = () => {
    setFocused(false);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const renderMaxLength = () => {
    if (props.maxLength && props.type === InputType.textarea) {
      return (
        <div className={classNames('absolute bottom-2 right-3 text-xs text-gray-400')}>
          <div>{`${(props.value as string).length} / ${props.maxLength}`}</div>
        </div>
      );
    }
    return null;
  };

  const renderInputElement = () => {
    if (props.type === InputType.textarea) {
      return (
        <>
          <textarea
            onFocus={() => setFocused(true)}
            onBlur={handleOnBlur}
            value={props.value}
            onChange={(e: any) => props.onChange(e.nativeEvent.target.value)}
            disabled={props.disabled}
            ref={textareaRef}
            rows={props.rows}
            autoFocus={props.autoFocus}
            maxLength={props.maxLength}
            className={classNames('w-full border border-solid border-gray-300 rounded-md')}
          />
          {renderMaxLength()}
        </>
      );
    }

    return (
      <input
        onFocus={() => setFocused(true)}
        onBlur={handleOnBlur}
        type={inputType}
        value={props.value}
        onChange={(e: any) => props.onChange(e.nativeEvent.target.value)}
        onKeyDown={(e) => (e.key === 'Enter' && onEnter ? onEnter() : null)}
        disabled={props.disabled}
        ref={inputRef}
        maxLength={props.maxLength}
        autoFocus={props.autoFocus}
        className={classNames(
          'w-full border border-solid border-gray-300',
          { 'rounded-l-md border-r-0': rightText },
          { 'rounded-md': !rightText },
        )}
      />
    );
  };

  const renderLoading = () => {
    if (loading) {
      return (
        <div className={'absolute text-gray-400 right-3 h-full flex items-center'}>
          <i className={'fas fa-spin fa-loader'} />
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (!props.showSkeleton) {
      return (
        <div className={'flex flex-row flex-1'}>
          <div className={'flex flex-row flex-1 relative'}>
            {renderInputElement()}
            {renderRightIcons()}
            {renderLoading()}
          </div>
          {renderRightText()}
          {renderLabel()}
        </div>
      );
    }
    return null;
  };

  if (props.visible) {
    return (
      <Container
        style={props.style}
        $isFocused={focused || !!props.value}
        $hasError={!!props.hasError}
        $noMarginBottom={props.removeBottomMargin}
        className={'border-r-0 rounded-r-none'}
      >
        {renderContent()}
        {clipboardCallout}
      </Container>
    );
  }
  return null;
}

Input.defaultProps = {
  visible: true,
  value: '',
  type: InputType.text,
  required: false,
  hasError: false,
  errorId: null,
  errorMessage: null,
  disabled: false,
  contextHelpKey: null,
  rows: 3,
  maxLength: null,
  onBlur: null,
  showSkeleton: false,
  style: {},
  autoFocus: false,
  removeBottomMargin: false,
  showCopy: false,
};
