import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/**
 * InfoBoxes()
 * @constructor
 */
export default function InfoBoxes() {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-1 py-16 bg-white')}>
      <div className={classNames('flex flex-1 flex-col px-8')}>
        <div className={classNames('flex flex-1 flex-col text-3xl', 'lg:text-5xl lg:font-light')}>
          <div className={classNames('text-center')}>{t('modules.website.info.headline1')}</div>
          <div className={classNames('text-center')}>{t('modules.website.info.headline2')}</div>
        </div>

        <div className={classNames('flex flex1 flex-col items-center my-20', 'lg:flex-row', 'first:ml-0')}>
          <div className={classNames('flex flex-col items-center my-20', 'lg:mx-14')}>
            <i className={'fad fa-files text-8xl text-green-700 mb-10'} />
            <div className={classNames('text-2xl font-bold mb-6 text-center')}>
              {t('modules.website.info.box1.headline')}
            </div>
            <div className={classNames('text-center text-xl')}>{t('modules.website.info.box1.text')}</div>
          </div>

          <div className={classNames('flex flex-col items-center my-20', 'lg:mx-14')}>
            <i className={'fad fa-globe text-8xl text-blue-700 mb-10'} />
            <div className={classNames('text-2xl font-bold mb-6 text-center')}>
              {t('modules.website.info.box2.headline')}
            </div>
            <div className={classNames('text-center text-xl')}>{t('modules.website.info.box2.text')}</div>
          </div>

          <div className={classNames('flex flex-col items-center my-20', 'lg:mx-14')}>
            <i className={'fad fa-stars text-8xl text-yellow-500 mb-10'} />
            <div className={classNames('text-2xl font-bold mb-6 text-center')}>
              {t('modules.website.info.box3.headline')}
            </div>
            <div className={classNames('text-center text-xl')}>{t('modules.website.info.box3.text')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
