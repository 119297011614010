import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const pehoma = require('../../Assets/Images/logo_pehoma.png');
const soto = require('../../Assets/Images/sotologo.png');

/**
 * Customers()
 * @constructor
 */
export default function Customers() {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-1 flex-col py-10 justify-center bg-white')}>
      <div className={classNames('text-5xl text-gray-700 py-6 text-center mb-16')}>
        {t('modules.website.ourCustomers')}
      </div>

      <div className={classNames('flex flex-col items-center justify-center mb-10', 'lg:flex-row')}>
        <a href={'https://www.pehoma.com'} target={'_blank'} rel="noreferrer">
          <img
            src={pehoma}
            alt={'Pehoma'}
            className={classNames('h-20 my-8 grayscale', 'hover:grayscale-0 transition-all delay-200', 'lg:mx-8')}
          />
        </a>
        <a href={'https://www.sotosoft.app'} target={'_blank'} rel="noreferrer">
          <img
            src={soto}
            alt={'SotoSoft'}
            className={classNames('h-20 my-8 grayscale', 'hover:grayscale-0 transition-all delay-200', 'lg:mx-8')}
          />
        </a>
      </div>
    </div>
  );
}
