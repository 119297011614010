import { SubscriptionType } from '../Types/CustomerTypes';

/**
 * subscriptionGetMaxFileSize()
 * @param subscription
 */
export const subscriptionGetMaxFileSize = (subscription: SubscriptionType) => {
  switch (subscription) {
    case SubscriptionType.unlimited:
      return 1024 * 1024 * 5;
    case SubscriptionType.smallBusiness:
      return 1024 * 1024;
    case SubscriptionType.professional:
      return 1024 * 1024 * 3;
    default:
      return 1024 * 244;
  }
};
