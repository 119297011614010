import React from 'react';
import AbstractProductBox from './AbstractProductBox';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Config from '../../../Library/Config';

interface Props {
  onPress: () => void;
  isMonthly: boolean;
  showBuyButton: boolean;
  headerClass?: string;
}

/**
 * ProductBoxProfessional()
 * @param props
 * @constructor
 */
export default function ProductBoxProfessional(props: Props) {
  const { isMonthly, showBuyButton, headerClass } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [priceId, setPriceId] = React.useState<string>('');

  React.useEffect(() => {
    if (isMonthly) {
      setPriceId(Config.stripe.pricePoints.professional.monthly);
    } else {
      setPriceId(Config.stripe.pricePoints.professional.yearly);
    }
  }, [isMonthly]);

  return (
    <AbstractProductBox
      headerClass={headerClass}
      showBuyButton={showBuyButton}
      caption={'Professional'}
      priceString={t('xPricePerMonth', { price: isMonthly ? '79,90€' : '63,90' })}
      rebateString={
        <div className={'line-through text-gray-400'}>
          {t('xPricePerMonth', { price: isMonthly ? '99,90€' : '79,90€' })}
        </div>
      }
      yearlyFull={
        !isMonthly ? <div className={'text-gray-400 text-sm'}>{t('xYearlyPrice', { price: '767,00€' })}*</div> : null
      }
      onPress={() => navigate(`/checkout/${priceId}`)}
      isMonthly={isMonthly}
      shadow={true}
    >
      <div className={'text-gray-500'}>
        <div className={'my-2'}>{t('subscription.professional.option1')}</div>
        <div className={'my-2'}>{t('subscription.professional.option2')}</div>
        <div className={'my-2'}>{t('subscription.professional.option3')}</div>
        <div className={'my-2'}>{t('subscription.professional.option4')}</div>
        <div className={'my-2'}>{t('subscription.professional.option5')}</div>
        <div className={'my-2'}>{t('subscription.professional.option6')}</div>
        <div className={'my-2'}>{t('subscription.professional.option7')}</div>
        <div className={'my-2'}>{t('subscription.professional.option8')}</div>
        <div className={'my-2'}>{t('subscription.professional.option9')}</div>
        <div className={'my-2'}>{t('subscription.professional.option10')}</div>
      </div>
    </AbstractProductBox>
  );
}
