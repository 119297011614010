import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import React from 'react';
import { Client } from '../../Library/Types/ClientTypes';
import {
  collection,
  CollectionReference,
  getFirestore,
  addDoc,
  query,
  where,
  getDocs,
  documentId,
  doc,
  DocumentReference,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { FirebasePath } from '../../Library/Firebase';
import {
  CLIENT_CREATE_ERROR,
  CLIENT_CREATE_START,
  CLIENT_CREATE_SUCCESS,
  CLIENT_DELETE_ERROR,
  CLIENT_DELETE_START,
  CLIENT_DELETE_SUCCESS,
  CLIENT_GET_USERS_ERROR,
  CLIENT_GET_USERS_START,
  CLIENT_GET_USERS_SUCCESS,
  CLIENT_GETLIST_BY_IDS_ERROR,
  CLIENT_GETLIST_BY_IDS_START,
  CLIENT_GETLIST_BY_IDS_SUCCESS,
  CLIENT_GETLIST_ERROR,
  CLIENT_GETLIST_START,
  CLIENT_GETLIST_SUCCESS,
  CLIENT_UPDATE_ERROR,
  CLIENT_UPDATE_START,
  CLIENT_UPDATE_SUCCESS,
} from '../ActionTypes';
import moment from 'moment';
import { User } from '../../Library/Types/UserTypes';
import { useDispatchClientDocumentGetList } from './Client/Documents';

/**
 * useDispatchClientCreate()
 */
export const useDispatchClientCreate = (): ((client: Client) => Promise<Client>) => {
  const dispatch = useAppDispatch();
  const { customer, user } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (client: Client) => {
      dispatch(CLIENT_CREATE_START(client));
      const collectionRef = collection(getFirestore(), FirebasePath.clients) as CollectionReference<Client>;

      const merged: Client = {
        ...client,
        customerId: customer.customerId,
        createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        createdUserId: user.userId,
      };

      return addDoc(collectionRef, merged)
        .then((response) => {
          const mergedClient: Client = { ...merged, clientId: response.id };
          dispatch(CLIENT_CREATE_SUCCESS(mergedClient));
          return Promise.resolve(mergedClient);
        })
        .catch((error) => {
          dispatch(CLIENT_CREATE_ERROR(error));
          return Promise.reject(error);
        });
    },
    [customer.customerId, dispatch, user.userId],
  );
};

/**
 * useDispatchClientUpdate()
 */
export const useDispatchClientUpdate = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (client: Client) => {
      dispatch(CLIENT_UPDATE_START(client));
      const docRef = doc(getFirestore(), FirebasePath.clients, client.clientId) as DocumentReference<Client>;

      return setDoc(docRef, client)
        .then(() => {
          dispatch(CLIENT_UPDATE_SUCCESS(client));
          return Promise.resolve(client);
        })
        .catch((error) => {
          dispatch(CLIENT_UPDATE_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientDelete()
 */
export const useDispatchClientDelete = () => {
  const dispatch = useAppDispatch();
  const dispatchGetDocuments = useDispatchClientDocumentGetList();

  return React.useCallback(
    (client: Client) => {
      dispatch(CLIENT_DELETE_START(client));
      const docRef = doc(getFirestore(), FirebasePath.clients, client.clientId) as DocumentReference<Client>;

      return dispatchGetDocuments(client.clientId, '2020-01-01', '2050-01-01').then((docs) => {
        if (docs && docs.length <= 0) {
          return deleteDoc(docRef)
            .then(() => {
              dispatch(CLIENT_DELETE_SUCCESS(client));
              return Promise.resolve(client);
            })
            .catch((error) => {
              dispatch(CLIENT_DELETE_ERROR(error));
              return Promise.reject(error);
            });
        } else {
          dispatch(CLIENT_DELETE_ERROR('CLIENT_HAS_DOCUMENTS'));
          return Promise.reject('CLIENT_HAS_DOCUMENTS');
        }
      });
    },
    [dispatch, dispatchGetDocuments],
  );
};

/**
 * useDispatchClientGetList()
 */
export const useDispatchClientGetList = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (customerId: string) => {
      dispatch(CLIENT_GETLIST_START(customerId));
      const collectionRef = collection(getFirestore(), FirebasePath.clients) as CollectionReference<Client>;
      const queryRef = query(collectionRef, where('customerId', '==', customerId));

      return getDocs(queryRef)
        .then((snapShot) => {
          if (!snapShot.empty) {
            const clients: Client[] = [];
            snapShot.forEach((item) => {
              clients.push({ ...item.data(), clientId: item.id });
            });
            dispatch(CLIENT_GETLIST_SUCCESS(clients));
            return Promise.resolve(clients);
          }
          dispatch(CLIENT_GETLIST_SUCCESS([]));
          return Promise.resolve([]);
        })
        .catch((error) => {
          dispatch(CLIENT_GETLIST_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientGetListByIds()
 * Only used to get all clients for a user / mandant
 */
export const useDispatchClientGetListByIds = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (clientIds: string[]) => {
      dispatch(CLIENT_GETLIST_BY_IDS_START(clientIds));
      const collectionRef = collection(getFirestore(), FirebasePath.clients) as CollectionReference<Client>;
      const queryRef = query(collectionRef, where(documentId(), 'in', clientIds));

      return getDocs(queryRef)
        .then((snapShot) => {
          if (!snapShot.empty) {
            const clients: Client[] = [];
            snapShot.forEach((item) => {
              clients.push({ ...item.data(), clientId: item.id });
            });
            dispatch(CLIENT_GETLIST_BY_IDS_SUCCESS(clients));
            return Promise.resolve(clients);
          }
          dispatch(CLIENT_GETLIST_BY_IDS_SUCCESS([]));
          return Promise.resolve([]);
        })
        .catch((error) => {
          dispatch(CLIENT_GETLIST_BY_IDS_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchClientGetUsers()
 * Get a list of all users assigned to the given clientId
 */
export const useDispatchClientGetUsers = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (clientId: string) => {
      dispatch(CLIENT_GET_USERS_START(clientId));
      const collectionRef = collection(getFirestore(), FirebasePath.users) as CollectionReference<User>;
      const queryRef = query(collectionRef, where('clients', 'array-contains', clientId));

      return getDocs(queryRef)
        .then((snapShot) => {
          const users: User[] = [];
          if (!snapShot.empty) {
            snapShot.forEach((item) => {
              users.push({ ...item.data(), userId: item.id });
            });
          }
          dispatch(CLIENT_GET_USERS_SUCCESS(users));
          return Promise.resolve(users);
        })
        .catch((error) => {
          dispatch(CLIENT_GET_USERS_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
