import React from 'react';
import classNames from 'classnames';
import Lodash from 'lodash';

interface Props {
  folder: string | null;
  availableFolders: string[];
  onPress: (item: string) => void;
}

const rowClass = classNames('py-3');

/**
 * RenderFolder()
 * @param props
 * @constructor
 */
export default function RenderFolder(props: Props) {
  const { folder, availableFolders, onPress } = props;
  const [foundFolders, setFoundFolders] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (folder) {
      const found = [...availableFolders.filter((item) => item.indexOf(folder) === 0 && item !== folder)];
      const filter1 = found.map((item) => item.substring(folder.length + (folder.length > 1 ? 1 : 0)));
      const filter2 = Lodash.uniq(
        filter1.map((item) => (item.indexOf('/') > -1 ? item.substring(0, item.indexOf('/')) : item)),
      );

      setFoundFolders(filter2.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())));
    }
  }, [folder, availableFolders]);

  const handleOnClick = (value: string) => {
    onPress(`${folder}/${value}`.replaceAll('//', '/'));
  };

  const render = () => {
    return foundFolders.map((item) => (
      <tr
        className={classNames('cursor-pointer border-b last:border-b-0 hover:bg-gray-200')}
        onClick={() => handleOnClick(item)}
        key={item}
      >
        <td className={classNames(rowClass, 'text-center rounded-l-xl')}>
          <i className={classNames('fas fa-folder-closed text-sky-500', 'text-2xl mr-2')} />
        </td>
        <td className={classNames(rowClass, 'overflow-ellipsis')}>{item.replaceAll('/', '')}</td>
        <td className={classNames(rowClass, 'text-sm text-gray-500')}>--</td>
        <td className={classNames(rowClass, 'text-sm text-gray-500')}>--</td>
        <td className={classNames(rowClass, 'text-sm text-gray-500')}>--</td>
        <td className={'text-right rounded-r-xl'} width={40} />
      </tr>
    ));
  };

  const renderBackFolder = () => {
    if (folder && folder !== '/') {
      return (
        <tr
          className={classNames('cursor-pointer border-b last:border-b-0 hover:bg-gray-200')}
          onClick={() =>
            onPress(
              folder.substring(0, folder.lastIndexOf('/')).length === 0
                ? '/'
                : folder.substring(0, folder.lastIndexOf('/')),
            )
          }
          key={'root'}
        >
          <td className={classNames(rowClass, 'text-center rounded-l-xl')}>
            <i className={classNames('fas fa-folder-closed text-sky-500', 'text-2xl mr-2')} />
          </td>
          <td className={classNames(rowClass, 'overflow-ellipsis')}>..</td>
          <td className={classNames(rowClass, 'text-sm text-gray-500')}>--</td>
          <td className={classNames(rowClass, 'text-sm text-gray-500')}>--</td>
          <td className={classNames(rowClass, 'text-sm text-gray-500')}>--</td>
          <td className={'text-right rounded-r-xl'} width={40} />
        </tr>
      );
    }
    return null;
  };

  if (folder) {
    return (
      <>
        {renderBackFolder()}
        {render()}
      </>
    );
  }
  return null;
}
