import React from 'react';
import { useTranslation } from 'react-i18next';
import ListBox, { ListBoxItem } from '../ListBox';
import moment from 'moment';

interface Props {
  onChange: (year: number) => void;
  selected: number;
  required?: boolean;
  hasError?: boolean;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
  startYear: number;
  endYear?: number;
}

/**
 * ListBoxSelectYear
 * @param props
 * @constructor
 */
export default function ListBoxSelectYear(props: Props) {
  const { onChange, direction, selected, required, hasError, hint, containerClass, startYear, endYear } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<ListBoxItem<number>[]>([]);

  React.useEffect(() => {
    if (!options || options.length <= 0) {
      const end = endYear || parseInt(moment().format('YYYY'));
      let currentYear = startYear;
      let data: ListBoxItem<number>[] = [];

      while (currentYear <= end) {
        data.push({ label: t('documentYear', { year: currentYear }), value: currentYear });
        currentYear = parseInt(moment(currentYear, 'YYYY').add(1, 'y').format('YYYY'));
      }

      setOptions(data);
    }
  }, [endYear, options, startYear, t]);

  if (options && options.length > 0) {
    return (
      <ListBox<number>
        onChange={onChange}
        options={options}
        selected={selected}
        required={required}
        hasError={hasError}
        hint={hint}
        containerClass={containerClass}
        direction={direction}
      />
    );
  }
  return null;
}

ListBoxSelectYear.defaultProps = {
  required: false,
  direction: 'row',
};
