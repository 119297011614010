import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Headline from '../Components/Headline';
import AuthenticationError from '../Components/AuthenticationError';
import Input, { InputType } from '../Components/Forms/Input';
import Button from '../Components/Forms/Button';
import RegisterAndLoginFooter from '../Components/RegisterAndLoginFooter';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatchAuth } from '../Redux/Actions/AuthActions';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions, FirebasePath } from '../Library/Firebase';
import { doc, getFirestore, deleteDoc, DocumentReference } from 'firebase/firestore';
import { OAuth as OAuthType } from '../Library/Types/GeneralTypes';
import Index from '../Components/Website/Navigation';
const sotoLogo = require('../Assets/Images/icon2.png');

const Box = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.templates.borderRadius};
  ${(props) => props.theme.templates.border};
`;

const LeftContainer = styled.div`
  width: 300px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 25px;
  background-color: #d1d5db;

  @media (max-width: 768px) {
    width: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1.5;
  padding: 30px 40px;
`;

/**
 * OAuth()
 * @constructor
 */
export default function OAuth() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mail, setMail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [authError, setAuthError] = React.useState<string | null>(null);
  const [oauthItem, setOauthItem] = React.useState<OAuthType>();
  const { search } = useLocation();
  const oauthId = new URLSearchParams(search).get('id');
  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [invalidOAuth, setInvalidOAuth] = React.useState<boolean>(false);

  const dispatchAuth = useDispatchAuth();

  const handleAuth = React.useCallback(async () => {
    setLoading(true);

    try {
      await dispatchAuth(mail, password, false);
      setLoading(false);
    } catch (error: any) {
      setAuthError(error.code);
      setLoading(false);
    }
  }, [dispatchAuth, mail, password]);

  const handleGrandAccess = React.useCallback(() => {
    setLoading(true);

    const callback = httpsCallable(getFunctions(), FirebaseFunctions.oAuthGrandAccess);
    callback({ oauthId })
      .then(() => {
        setSuccess(true);
        return Promise.resolve();
      })
      .finally(() => setLoading(false));
  }, [oauthId]);

  const handleCancel = React.useCallback(async () => {
    const docRef = doc(getFirestore(), FirebasePath.oauth, oauthId as string) as DocumentReference<OAuthType>;
    await deleteDoc(docRef);

    if (oauthItem && oauthItem.redirectUrl) {
      window.location.href = oauthItem.redirectUrl;
    } else {
      return (window.location.href = '/login');
    }
  }, [oauthId, oauthItem]);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (state) => {
      if (state && oauthId) {
        setLoggedIn(true);
        const callable = httpsCallable<{ oauthId: string }, OAuthType>(getFunctions(), FirebaseFunctions.oauthGet);
        return callable({ oauthId })
          .then((item) => {
            setOauthItem(item.data);
            return Promise.resolve();
          })
          .catch(() => {
            setInvalidOAuth(true);
          });
      }
    });
    return () => unsubscribe();
  }, [oauthId]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (success && oauthItem && oauthItem.redirectUrl) {
      timer = setTimeout(() => (window.location.href = oauthItem.redirectUrl as string), 4000);
    }

    return () => clearTimeout(timer);
  }, [oauthItem, success]);

  const renderLogin = () => {
    if (!loggedIn && !success) {
      return (
        <>
          <Headline size={'large'}>{t('oAuthLogin')}</Headline>
          <div className={'text-gray-400 mb-5'}>{t('oAuthLoginDescription')}</div>

          <AuthenticationError errorCode={authError} />

          <Input onChange={setMail} value={mail} label={t('eMailAddress')} disabled={loading} type={InputType.email} />
          <Input
            onChange={setPassword}
            value={password}
            label={t('password')}
            type={InputType.password}
            disabled={loading}
          />

          <div className={'flex flex-1 mt-8'}>
            <Button
              className={'flex flex-1 justify-center h-12 font-semibold'}
              onPress={handleAuth}
              colorStyle={'primary'}
              disabled={loading || !mail || !password || password.length < 6}
            >
              {loading ? <i className={'fa fa-spin fa-spinner'} /> : t('next')}
            </Button>
          </div>
        </>
      );
    }
    return null;
  };

  const renderAccess = () => {
    if (loggedIn && oauthItem && !success) {
      return (
        <>
          <Headline size={'large'}>{t('oAuthGrandHeadline')}</Headline>
          <div className={'text-gray-400 mb-5'}>{t('oAuthGrandDescription', { name: oauthItem.platform })}</div>

          <div className={'text-center text-3xl font-bold mt-8'}>{oauthItem.platform}</div>

          <div className={'text-center text-9xl my-20 text-purple-700'}>
            <i className={'fas fa-webhook'} />
          </div>

          <div className={'flex flex-1 mt-8'}>
            <Button
              className={'flex flex-1 justify-center h-12 font-semibold'}
              onPress={handleGrandAccess}
              colorStyle={'primary'}
            >
              {loading ? <i className={'fa fa-spin fa-spinner'} /> : t('oAuthGrandAccess')}
            </Button>
          </div>
          <div
            className={'mt-6 text-center text-gray-400 hover:text-black hover:underline cursor-pointer'}
            onClick={handleCancel}
          >
            {t('cancel')}
          </div>
        </>
      );
    }
    return null;
  };

  const renderSuccess = () => {
    if (loggedIn && success && oauthItem) {
      return (
        <>
          <Headline size={'large'}>{t('oAuthGranded')}</Headline>
          <div className={'text-gray-400 mb-5'}>
            {oauthItem.redirectUrl ? t('oAuthGrandedRedirect') : t('oAuthGrandedClose')}
          </div>

          <div className={'text-center text-9xl my-20 text-green-700'}>
            <i className={'fas fa-check-circle'} />
          </div>
        </>
      );
    }
    return null;
  };

  const renderInvalid = () => {
    if (loggedIn && !success && invalidOAuth) {
      return (
        <>
          <Headline size={'large'}>{t('oAuthInvalid')}</Headline>
          <div className={'text-gray-400 mb-5'}>{t('oAuthInvalidDescription')}</div>

          <div className={'text-center text-9xl my-20 text-red-700'}>
            <i className={'far fa-circle-exclamation'} />
          </div>

          <div
            className={'mt-6 text-center text-gray-400 hover:text-black hover:underline cursor-pointer'}
            onClick={() => navigate(-1)}
          >
            {t('back')}
          </div>
        </>
      );
    }
    return null;
  };

  if (oauthId) {
    return (
      <div className={'flex flex-1 flex-col'}>
        <Index showPrice={false} showStartPage={false} showRegister={false} showLogin={loggedIn && success} />

        <div className={'flex flex-1 flex-row'}>
          <div className={'basis-0 sm:basis-0 md:basis-1/6 lg:basis-1/6 xl:basis-1/6 2xl:basis-3/12'} />
          <div className={'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-6/12 p-4'}>
            <Box className={'shadow-2xl bg-white mt-10'}>
              <LeftContainer className={'hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex'}>
                <img src={sotoLogo} alt="logo" style={{ maxHeight: 300 }} />
              </LeftContainer>
              <RightContainer>
                {renderLogin()}
                {renderAccess()}
                {renderSuccess()}
                {renderInvalid()}
              </RightContainer>
            </Box>

            <RegisterAndLoginFooter />
          </div>
        </div>
      </div>
    );
  }
  return <Navigate to={'/login'} replace />;
}
