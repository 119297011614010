import React from 'react';
import classNames from 'classnames';

interface Props {
  type: 'string' | 'number' | 'object' | 'array' | 'boolean' | string;
}

/**
 * Type()
 * @param props
 * @constructor
 */
export default function Type(props: Props) {
  const { type } = props;

  return (
    <a
      className={classNames(
        'text-black py-2 px-2 rounded-md mr-2 cursor-default',
        { 'bg-red-300': type === 'string' },
        { 'bg-blue-400': type === 'boolean' },
        { 'bg-orange-200': type === 'number' },
        { 'cursor-pointer hover:underline bg-gray-300': type !== 'number' && type !== 'string' && type !== 'boolean' },
      )}
      href={type !== 'number' && type !== 'string' && type !== 'boolean' ? `#${type}` : ''}
    >
      {type}
    </a>
  );
}
