import {
  AUTH_LOGOUT,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_GETLIST_BY_CLIENTIDS_SUCCESS,
  DOCUMENT_GETLIST_BY_CUSTOMERID_SUCCESS,
  DOCUMENT_SET_BOOKED_SUCCESS,
  DOCUMENT_SET_CURRENT,
  DOCUMENT_SET_CURRENT_BOOKING_TIME,
  DOCUMENT_UPDATE_SUCCESS,
} from '../ActionTypes';
import { createReducer } from '@reduxjs/toolkit';
import { DocumentType } from '../../Library/Types/DocumentTypes';

export interface DocumentReducerInterface {
  currentDocument: DocumentType | null;
  currentDocumentBookingTime: number;
  documents: DocumentType[];
}

const defaultValues: DocumentReducerInterface = {
  currentDocument: null,
  currentDocumentBookingTime: 0,
  documents: [],
};

export default createReducer(defaultValues, (builder) => {
  builder.addCase(DOCUMENT_GETLIST_BY_CLIENTIDS_SUCCESS, (state, action) => {
    state.documents = action.payload;
  });
  builder.addCase(DOCUMENT_UPDATE_SUCCESS, (state, action) => {
    state.documents = state.documents.map((item) =>
      item.documentId !== action.payload.documentId ? item : action.payload,
    );
  });
  builder.addCase(DOCUMENT_DELETE_SUCCESS, (state, action) => {
    state.documents = state.documents.filter((item) => item.documentId !== action.payload.documentId);
  });
  builder.addCase(DOCUMENT_SET_BOOKED_SUCCESS, (state, action) => {
    state.documents = state.documents.filter((item) => item.documentId !== action.payload.documentId);
  });
  builder.addCase(DOCUMENT_SET_CURRENT, (state, action) => {
    state.currentDocument = action.payload;
  });
  builder.addCase(DOCUMENT_SET_CURRENT_BOOKING_TIME, (state, action) => {
    state.currentDocumentBookingTime = action.payload;
  });
  builder.addCase(DOCUMENT_GETLIST_BY_CUSTOMERID_SUCCESS, (state, action) => {
    state.documents = action.payload;
    if (state.currentDocument) {
      const found = action.payload.find((item) =>
        state.currentDocument ? item.documentId === state.currentDocument.documentId : false,
      );
      if (found) {
        state.currentDocument = found;
      }
    }
  });
  builder.addCase(AUTH_LOGOUT, (state) => {
    state.documents = [];
    state.currentDocument = null;
    state.currentDocumentBookingTime = 0;
  });
});
