import React from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../Library/Types/UserTypes';
import ListBox, { ListBoxItem } from '../ListBox';

interface Props {
  onChange: (item: string) => void;
  selected: string;
  users: User[];
  required?: boolean;
  caption?: string;
  hasError?: boolean;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
}

/**
 * ListBoxSelectUser
 * @param props
 * @constructor
 */
export default function ListBoxSelectUser(props: Props) {
  const { onChange, direction, selected, users, required, caption, hasError, hint, containerClass } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<ListBoxItem<string>[]>([]);

  React.useEffect(() => {
    if (users && users.length > 0) {
      setOptions(users.map((user) => ({ value: user.userId, label: `${user.lastName}, ${user.firstName}` })));
    }
  }, [users]);

  if (options && options.length > 0) {
    return (
      <ListBox<string>
        onChange={onChange}
        options={options}
        selected={selected}
        label={caption || t('users')}
        required={required}
        hasError={hasError}
        hint={hint}
        containerClass={containerClass}
        direction={direction}
      />
    );
  }
  return null;
}

ListBoxSelectUser.defaultProps = {
  required: false,
  direction: 'row',
};
