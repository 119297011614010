import React from 'react';
import AbstractModal from './AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';

interface Props {
  headline: string;
  text: string;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type: 'success' | 'error' | 'primary';
  icon?: string;
  confirmText?: string;
  cancelText?: string;
}

/**
 * ConfirmModal()
 * @param props
 * @constructor
 */
export default function ConfirmModal(props: Props) {
  const { visible, text, onClose, headline, onConfirm, type, icon, confirmText, cancelText } = props;
  const { t } = useTranslation();

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'}>
        {cancelText || t('cancel')}
      </Button>
      <Button onPress={onConfirm} colorStyle={type as any}>
        {confirmText || t('confirm')}
      </Button>
    </div>
  );

  return (
    <AbstractModal
      headline={headline}
      text={text}
      visible={visible}
      icon={icon || 'fas fa-question-circle'}
      buttons={renderButtons()}
      type={type}
    />
  );
}
