import React from 'react';
import AbstractModal from './AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';
import { StateMapping, useDocumentGetMapping } from '../../Library/Hooks/DocumentHooks';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';
import classNames from 'classnames';
import { STORAGE_DOCUMENT_FIRST_UPLOAD_HINT } from '../../Library/Types/Constants';
import SimpleCheckBox from '../Forms/SimpeCheckBox';

interface Props {
  document: DocumentType;
}

/**
 * FirstUploadModal()
 * @constructor
 */
export default function FirstUploadModal({ document }: Props) {
  const { t } = useTranslation();
  const getMapping = useDocumentGetMapping();
  const [open] = React.useState<StateMapping>(getMapping(DocumentState.open));
  const [uploaded] = React.useState<StateMapping>(getMapping(DocumentState.uploaded));
  const [visible, setVisible] = React.useState<boolean>(false);
  const [dontShowAgain, setDontShowAgain] = React.useState<boolean>(false);

  React.useEffect(() => {
    const item = localStorage.getItem(STORAGE_DOCUMENT_FIRST_UPLOAD_HINT);
    if (!item && document && document.state === DocumentState.uploaded) {
      setVisible(true);
    }
  }, [document]);

  const handleOnClose = () => {
    if (dontShowAgain) {
      localStorage.setItem(STORAGE_DOCUMENT_FIRST_UPLOAD_HINT, 'done');
    }
    setVisible(false);
  };

  const renderButtons = () => (
    <div className={'flex flex-1 flex-row items-center py-1'}>
      <div className={'flex-grow'}>
        <SimpleCheckBox
          caption={t('dontShowAgain')}
          checked={dontShowAgain}
          onChange={(value) => setDontShowAgain(value)}
          containerClass={'!mt-0 !mb-0 text-sm'}
        />
      </div>
      <div>
        <Button onPress={handleOnClose} colorStyle={'success'}>
          {t('understood')}
        </Button>
      </div>
    </div>
  );

  return (
    <AbstractModal
      visible={visible}
      type={'success'}
      headline={uploaded.text}
      buttons={renderButtons()}
      icon={'fad fa-input-text'}
    >
      <div className={'flex flex-col'}>
        <div>{t('modules.documents.firstUploadHint')}</div>

        <div className={'flex flex-col mt-8 items-center'}>
          <div className={classNames('px-3 py-1', uploaded.bgColorClass, 'rounded-md text-white')}>
            <i className={classNames(uploaded.icon, 'pr-2')} />
            {uploaded.text}
          </div>
          <div className={'py-3'}>
            <i className="fas fa-arrow-down text-2xl"></i>
          </div>
          <div className={classNames('px-3 py-1', open.bgColorClass, 'rounded-md text-white')}>
            <i className={classNames(open.icon, 'pr-2')} />
            {open.text}
          </div>
        </div>
      </div>
    </AbstractModal>
  );
}
