import React from 'react';
import { Endpoint } from '../ApiTypes';
import AbstractEndpoint from '../AbstractEndpoint';

export const ClientGetListEndpoint: Endpoint = {
  name: 'Client get list',
  description: 'Returns a list of all available clients for the customer',
  method: 'get',
  path: '/client',
  responseBody: {
    type: 'JSON-Object',
    data: [{ key: 'Body', mandatory: true, type: 'Client [...]', description: 'An array of clients' }],
  },
};

/**
 * ClientGetList()
 * @constructor
 */
export default function ClientGetList() {
  return <AbstractEndpoint endpoint={ClientGetListEndpoint} />;
}
