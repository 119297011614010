import React from 'react';
import { User } from '../../../Library/Types/UserTypes';
import classNames from 'classnames';

interface Props {
  user: User;
  onPress: (user: User) => void;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { user, onPress } = props;
  const [classes, setClasses] = React.useState<string>('');

  React.useEffect(() => {
    if (user.isActive) {
      setClasses(classNames('text-green-700 fas fa-circle'));
    } else {
      setClasses(classNames('text-red-500 fas fa-circle'));
    }
  }, [user.isActive]);

  return (
    <tr onClick={() => onPress(user)}>
      <td width={50}>
        <i className={classes} />
      </td>
      <td>{`${user.lastName}, ${user.firstName}${user.isCustomerAdmin ? '*' : ''}`.trim()}</td>
      <td>{user.mail}</td>
    </tr>
  );
}
