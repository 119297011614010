import React from 'react';
import { DocumentState, DocumentType, InvoiceType } from '../../../Library/Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import FilterButton from './FilterButton';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ShowHideContainer from '../../ShowHideContainer';
import { documentFilterInitialState, documentFilterReducer, FilterState } from './Reducer';
import Lodash from 'lodash';

const STORAGE_KEY = '@SotoDocsDocumentFilter';

interface Props {
  documents: DocumentType[];
  onChange: (documents: DocumentType[]) => void;
  hideOwnerOnly: boolean;
  showOpenOnly: boolean;
}

/**
 * DocumentFilter()
 * @param props
 * @constructor
 */
export default function DocumentFilter(props: Props) {
  const { documents, onChange, hideOwnerOnly, showOpenOnly } = props;
  const [state, dispatch] = React.useReducer(documentFilterReducer, documentFilterInitialState);
  const { assignedClientIds } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (state.initialized && !Lodash.isEqual(state.documents, documents)) {
      dispatch({ type: 'initDocuments', payload: { documents, userAssignedClientIds: assignedClientIds } });
    }
  }, [assignedClientIds, documents, state.documents, state.initialized]);

  React.useEffect(() => {
    if (!state.initialized) {
      const value = localStorage.getItem(STORAGE_KEY);
      if (value) {
        dispatch({ type: 'init', payload: JSON.parse(value) });
      } else {
        dispatch({ type: 'init', payload: null });
      }
    }
  }, [state.initialized]);

  React.useEffect(() => {
    if (state.initialized) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...state.filter }));
    }
  }, [state.filter, state.initialized]);

  const loadFilter = React.useCallback(
    (filterStateParam: FilterState) => {
      let filtered: DocumentType[] = [];

      if (
        !filterStateParam.incoming &&
        !filterStateParam.outgoing &&
        !filterStateParam.bank &&
        !filterStateParam.ownerOnly &&
        !filterStateParam.openOnly
      ) {
        filtered = documents.filter((document) => document.state === DocumentState.uploaded);
      } else {
        filtered = documents.filter(
          (document) =>
            ((filterStateParam.incoming && document.type === InvoiceType.incoming) ||
              (filterStateParam.outgoing && document.type === InvoiceType.outgoing) ||
              (filterStateParam.bank && document.type === InvoiceType.bank)) &&
            (filterStateParam.ownerOnly && !hideOwnerOnly
              ? assignedClientIds.indexOf(document.clientId as string) > -1
              : true),
        );

        if (showOpenOnly && filterStateParam.openOnly) {
          filtered = filtered.filter((item) => item.state === DocumentState.open);
        }
      }

      onChange(filtered);
    },
    [assignedClientIds, documents, hideOwnerOnly, onChange, showOpenOnly],
  );

  React.useEffect(() => {
    if (state.hasChanges) {
      dispatch({ type: 'resetChanged' });
      loadFilter(state.filter);
    }
  }, [loadFilter, state.filter, state.hasChanges]);

  if (state.initialized) {
    return (
      <div className={'flex flex-1 flex-row'}>
        <FilterButton
          caption={`${t('filterIncoming')} (${state.count.incoming})`}
          active={state.filter.incoming}
          onPress={() => dispatch({ type: 'incoming', payload: !state.filter.incoming })}
        />
        <FilterButton
          caption={`${t('filterOutgoing')} (${state.count.outgoing})`}
          active={state.filter.outgoing}
          onPress={() => dispatch({ type: 'outgoing', payload: !state.filter.outgoing })}
        />
        <FilterButton
          caption={`${t('filterBank')} (${state.count.bank})`}
          active={state.filter.bank}
          onPress={() => dispatch({ type: 'bank', payload: !state.filter.bank })}
        />
        <ShowHideContainer visible={!hideOwnerOnly}>
          <FilterButton
            caption={`${t('filterOnlyMine')} (${state.count.ownerOnly})`}
            active={state.filter.ownerOnly}
            onPress={() => dispatch({ type: 'ownerOnly', payload: !state.filter.ownerOnly })}
          />
        </ShowHideContainer>
        <ShowHideContainer visible={showOpenOnly}>
          <FilterButton
            caption={`${t('filterOpenOnly')} (${state.count.openOnly})`}
            active={state.filter.openOnly}
            onPress={() => dispatch({ type: 'openOnly', payload: !state.filter.openOnly })}
          />
        </ShowHideContainer>
      </div>
    );
  }
  return null;
}
