import React from 'react';
import { DocumentType, InvoiceType } from '../../Library/Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import ListView from '../../Components/Document/ListView';
import { Client } from '../../Library/Types/ClientTypes';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { clientBuildDisplayName } from '../../Library/Functions/ClientFunctions';
import DocumentFilter from '../../Components/Document/DocumentFilter';
import ListBoxSelectYear from '../../Components/Forms/PredefinedListBox/ListBoxSelectYear';
import moment from 'moment';
import InputRow from '../../Components/Forms/InputRow';
import classNames from 'classnames';
import { useDispatchDocumentsDownloadList } from '../../Redux/Actions/DocumentActions';
import { downloadBase64Pdf } from '../../Library/Functions/HelperFunctions';
import PreviewModal from '../../Components/Document/PreviewModal';
import { documentsSearch } from '../../Library/Functions/DocumentFunctions';

interface Props {
  documents: DocumentType[];
  client: Client;
  loading: boolean;
  onChangeYear: (year: number) => void;
  selectedYear: number;
}

/**
 * FinishedDocuments()
 * @constructor
 */
export default function FinishedDocuments(props: Props) {
  const { documents, client, loading, onChangeYear, selectedYear } = props;
  const { user } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const [filtered, setFiltered] = React.useState<DocumentType[]>([]);
  const [searchResult, setSearchResult] = React.useState<DocumentType[] | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedDocuments, setSelectedDocuments] = React.useState<DocumentType[]>([]);
  const [downloading, setDownloading] = React.useState<boolean>(false);
  const [selectActive, setSelectActive] = React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = React.useState<DocumentType | null>(null);

  const dispatchDownloadList = useDispatchDocumentsDownloadList();

  const handleDownload = React.useCallback(() => {
    if (selectedDocuments && selectedDocuments.length > 0 && client) {
      setDownloading(true);

      dispatchDownloadList(
        client.clientId,
        selectedDocuments.map((item) => item.documentId),
      )
        .then((base64Zip) => {
          selectedDocuments.sort((a, b) =>
            moment(a.firebaseInvoiceDate.toDate()).isAfter(moment(b.firebaseInvoiceDate.toDate())) ? 1 : -1,
          );
          const minDate = moment(selectedDocuments[0].firebaseInvoiceDate.toDate()).format('DDMMYYYY');
          const maxDate = moment(selectedDocuments[selectedDocuments.length - 1].firebaseInvoiceDate.toDate()).format(
            'DDMMYYYY',
          );
          // eslint-disable-next-line max-len
          const fileName = `${clientBuildDisplayName(client).replaceAll(',', '').replaceAll(' ', '')}_${minDate}_${maxDate}.zip`;
          downloadBase64Pdf(base64Zip.data, fileName);
          return Promise.resolve();
        })
        .finally(() => {
          setDownloading(false);
          setSelectActive(false);
        });
    }
  }, [client, dispatchDownloadList, selectedDocuments]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue && searchValue.length > 2) {
        const result = documentsSearch(filtered, searchValue);
        setSearchResult(result);
      } else {
        setSearchResult(null);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [filtered, searchValue]);

  const renderDownloadButton = () => {
    if (selectedDocuments && selectedDocuments.length > 0) {
      return (
        <div className={'flex mb-4'}>
          <div
            className={classNames(
              'flex flex-1 border border-gray-300',
              'rounded-md text-blue-700 ml-4 px-4 items-center bg-white',
              'cursor-pointer',
              'hover:bg-blue-700 hover:text-white',
            )}
            onClick={!downloading ? () => handleDownload() : () => {}}
          >
            <i
              className={classNames(
                'fas',
                { 'fa-cloud-download': !downloading },
                { 'fa-spin fa-spinner': downloading },
              )}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderSelectSwitch = () => {
    if (user.isCustomerUser && documents && documents.length > 0) {
      return (
        <div
          className={classNames('text-gray-400 cursor-pointer hover:text-blue-700 hover:underline ml-2')}
          onClick={() => setSelectActive(!selectActive)}
        >
          {selectActive ? t('selectDeactivate') : t('selectActivate')}
        </div>
      );
    }
    return null;
  };

  if (client) {
    return (
      <>
        <div className={'flex flex-1 flex-row'}>
          <div className={'flex-grow mr-4'}>
            <InputRow
              onChange={setSearchValue}
              value={searchValue}
              placeholder={t('searchDocument')}
              showClear={true}
            />
          </div>
          <div className={'w-3/12'}>
            <ListBoxSelectYear
              onChange={onChangeYear}
              startYear={parseInt(moment(client.createdDate).format('YYYY'))}
              selected={selectedYear}
            />
          </div>
          {renderDownloadButton()}
        </div>
        <div className={'mb-6'}>
          <DocumentFilter documents={documents || []} onChange={setFiltered} hideOwnerOnly showOpenOnly={false} />
        </div>

        <ListView
          loading={loading}
          documents={searchResult || filtered || []}
          onPress={(doc) => (doc.type !== InvoiceType.bank ? setSelectedDocument(doc) : {})}
          showClient={false}
          showFileName={false}
          showCreditor={true}
          emptyText={t('modules.documents.noFinishedDocuments')}
          showActions
          allowSelect={selectActive}
          onSelectionChange={!downloading ? (items) => setSelectedDocuments(items) : () => {}}
        />

        {renderSelectSwitch()}

        <PreviewModal document={selectedDocument} onClose={() => setSelectedDocument(null)} />
      </>
    );
  }
  return null;
}
