import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

interface Props {
  headline: string;
  text?: string;
  visible: boolean;
  onBackdrop?: () => void;
  icon?: string;
  buttons: React.ReactNode;
  type: 'success' | 'error' | 'primary';
  children?: React.ReactElement;
  widthClassName: string;
  hideIcon?: boolean;
}

/**
 * AbstractModal()
 * @param props
 * @constructor
 */
export default function AbstractModal(props: Props) {
  const { visible, text, onBackdrop, headline, icon, buttons, type, children, widthClassName, hideIcon } = props;

  const cancelButtonRef = useRef(null);

  const renderContent = () => {
    if (text) {
      return <p className="text-gray-500">{text}</p>;
    }
    return children;
  };

  const renderIcon = () => {
    if (!hideIcon) {
      return (
        <div className="items-center justify-center content-center rounded-full mr-10">
          <i
            className={classNames(`${icon || 'fas fa-question'}`, 'h-6 w-6 text-blue-600 text-5xl', {
              'text-blue-700': type === 'primary',
              'text-green-700': type === 'success',
              'text-red-700': type === 'error',
            })}
            aria-hidden="true"
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 overflow-y-hidden"
        initialFocus={cancelButtonRef}
        onClose={onBackdrop ? () => onBackdrop() : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-hidden">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  widthClassName,
                  'relative transform overflow-hidden',
                  'rounded-lg bg-white text-left shadow-xl transition-all',
                )}
              >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start p-2">
                    {renderIcon()}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex flex-1 flex-col">
                      <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-800">
                        {headline}
                      </Dialog.Title>
                      <div className="mt-2 mb-4 flex flex-1">{renderContent()}</div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">{buttons}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

AbstractModal.defaultProps = {
  widthClassName: 'w-2/5',
};
