import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { useDispatchStripeCustomerGet } from '../../../Redux/Actions/StripeActions';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useTranslation } from 'react-i18next';
import ChooseProduct from '../../../Components/Subscription/ChooseProduct';
import SubscriptionComponent from '../../../Components/Subscription/Subscription';
import { doc, DocumentReference, getFirestore, onSnapshot } from 'firebase/firestore';
import { FirebasePath } from '../../../Library/Firebase';
import { Customer } from '../../../Library/Types/CustomerTypes';
import { CUSTOMER_GET_SUCCESS } from '../../../Redux/ActionTypes';

/**
 * Subscription()
 * @constructor
 */
export default function Subscription() {
  const { customer } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);
  const [response, setResponse] = React.useState<any>();

  const dispatchCustomerGet = useDispatchStripeCustomerGet();

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const docRef = doc(getFirestore(), FirebasePath.customers, customer.customerId) as DocumentReference<Customer>;
    const unsubscribe = onSnapshot(docRef, (snapShot) => {
      if (snapShot.exists()) {
        const customerSnapShot = { ...snapShot.data(), customerId: snapShot.id };
        if (customerSnapShot) {
          if (customerSnapShot.subscriptionActive !== customer.subscriptionActive) {
            dispatch(CUSTOMER_GET_SUCCESS(customerSnapShot));
            setLoaded(false);
          }
        }
      }
    });

    return () => unsubscribe();
  }, [customer, dispatch]);

  React.useEffect(() => {
    navContext.setHeadline(`${t('settings')} / ${t('settlement')}`);
    navContext.setShowLoading(loading);
  }, [loading, navContext, t]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (customer.stripeCustomerId) {
      if (!loading && !loaded && customer) {
        timer = setTimeout(() => {
          setLoading(true);
          dispatchCustomerGet(customer.stripeCustomerId as string)
            .then((customerResponse) => {
              setResponse(customerResponse);
              return Promise.resolve();
            })
            .finally(() => {
              setLoaded(true);
              setLoading(false);
            });
        }, 200);
      }
    }

    return () => clearTimeout(timer);
  }, [customer, customer.stripeCustomerId, dispatchCustomerGet, loaded, loading, response]);

  return (
    <>
      <ChooseProduct hasSubscription={customer.subscriptionActive} />
      <SubscriptionComponent
        hasSubscription={customer.subscriptionActive}
        invoices={response ? response.invoices : []}
        subscription={response ? response.subscription : null}
      />
    </>
  );
}
