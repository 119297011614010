import { RegisterCustomerState } from '../../Modules/Register/RegisterCustomer';

export interface ValidateRegisterCustomerErrors {
  isValid: boolean;
  errorCode: string;
  companyName: boolean;
  taxNumber: boolean;
  firstName: boolean;
  lastName: boolean;
  mail: boolean;
  password: boolean;
  passwordRepeat: boolean;
  agb: boolean;
  legal: boolean;
}

const validateRegisterCustomer = (state: RegisterCustomerState): ValidateRegisterCustomerErrors => {
  let response: ValidateRegisterCustomerErrors = {
    isValid: true,
    errorCode: '',
    companyName: false,
    taxNumber: false,
    firstName: false,
    lastName: false,
    mail: false,
    password: false,
    passwordRepeat: false,
    agb: false,
    legal: false,
  };

  if (!state.data.companyName || state.data.companyName.length < 2) {
    response = { ...response, isValid: false, companyName: true };
  }

  if (!state.data.taxNumber || state.data.taxNumber.length < 5) {
    response = { ...response, isValid: false, taxNumber: true };
  }

  if (!state.data.lastName || state.data.lastName.length < 2) {
    response = { ...response, isValid: false, lastName: true };
  }

  if (!state.data.firstName || state.data.firstName.length < 2) {
    response = { ...response, isValid: false, firstName: true };
  }

  if (!state.data.mail || state.data.mail.indexOf('@') < 0 || state.data.mail.indexOf('.') < 0) {
    response = { ...response, isValid: false, mail: true };
  }

  if (!state.data.password) {
    response = { ...response, isValid: false, password: true };
  }

  if (!state.data.passwordRepeat) {
    response = { ...response, isValid: false, passwordRepeat: true };
  }

  if (state.data.passwordRepeat !== state.data.password) {
    response = { ...response, isValid: false, passwordRepeat: true, password: true };
  }

  if (!state.data.agb) {
    response = { ...response, isValid: false, agb: true };
  }
  if (!state.data.legal) {
    response = { ...response, isValid: false, legal: true };
  }

  return response;
};

export default validateRegisterCustomer;
