import { Client } from '../Types/ClientTypes';
import { Salutation } from '../Types/GeneralTypes';
import { isValidateEmail } from '../Functions/HelperFunctions';

export interface ValidateClientCreateErrors {
  hasErrors: boolean;
  errors: { [K in keyof Client]?: boolean };
}

const validateClientCreate = (client: Client): ValidateClientCreateErrors => {
  let response: ValidateClientCreateErrors = {
    hasErrors: false,
    errors: {},
  };

  if (client.salutation === Salutation.company) {
    if (!client.companyName) {
      response = { ...response, hasErrors: true, errors: { ...response.errors, companyName: true } };
    }
  } else {
    if (!client.firstName) {
      response = { ...response, hasErrors: true, errors: { ...response.errors, firstName: true } };
    }
    if (!client.lastName) {
      response = { ...response, hasErrors: true, errors: { ...response.errors, lastName: true } };
    }
  }

  if (!client.mail || !isValidateEmail(client.mail)) {
    response = { ...response, hasErrors: true, errors: { ...response.errors, mail: true } };
  }

  if (!client.assignedUserId) {
    response = { ...response, hasErrors: true, errors: { ...response.errors, assignedUserId: true } };
  }

  if (client.groupId <= 0) {
    response = { ...response, hasErrors: true, errors: { ...response.errors, groupId: true } };
  }

  return response;
};

export default validateClientCreate;
