import { useTranslation } from 'react-i18next';
import React from 'react';
import { useLoadCustomerDashboard } from './CustomerDashboardLoader';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import GestorDocumentOverview from '../../../Components/Dashboard/GestorDocumentOverview';
import GestorWeeklyStatistics from '../../../Components/Dashboard/GestorWeeklyStatistics';
import GestorOverallDocumentStatistics from '../../../Components/Dashboard/GestorOverallDocumentStatistics';
import Headline from '../../../Components/Dashboard/Headline';
import GestorClientOverview from '../../../Components/Dashboard/GestorClientOverview';
import AdminOpenDocumentsByUser from '../../../Components/Dashboard/AdminOpenDocumentsByUser';
import AdminOverview from '../../../Components/Dashboard/AdminOverview';
import NewMailButton from '../../../Components/Dashboard/NewMailButton/NewMailButton';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import UserInactiveHint from '../../../Components/Dashboard/UserInactiveHint';
import DemoHint from '../../../Components/Dashboard/DemoHint';

/**
 * CustomerDashboard()
 * @constructor
 */
export default function CustomerDashboard() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const load = useLoadCustomerDashboard();
  const navContext = React.useContext(NavbarContext);

  const setNavigationButtons = React.useMemo(() => {
    return <NewMailButton />;
  }, []);

  React.useEffect(() => {
    if (customer) {
      document.title = `${customer.displayName || customer.name} - SotoDocs - Sotosoft S.L.`;
    }
  }, [customer]);

  React.useEffect(() => {
    navContext.setHeadline(t('customerDashboard'));
    navContext.setButton(setNavigationButtons);
  }, [navContext, setNavigationButtons, t]);

  React.useEffect(() => {
    const timer = setTimeout(() => load(), 500);
    return () => clearTimeout(timer);
  }, [load]);

  return (
    <div>
      <Headline />

      <DemoHint />
      <UserInactiveHint />

      <div className={'w-full'}>
        <AdminOpenDocumentsByUser />
      </div>
      <div className={'flex flex-1 flex-row'}>
        <div className={'w-1/2 mr-2'}>
          <AdminOverview />
          <GestorDocumentOverview />
          <GestorClientOverview />
        </div>
        <div className={'w-1/2 ml-2 flex flex-col'}>
          <GestorWeeklyStatistics />
          <GestorOverallDocumentStatistics />
        </div>
      </div>
    </div>
  );
}
