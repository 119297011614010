// my-theme.ts
import { DefaultTheme } from 'styled-components';

const borderColor = 'rgb(209, 213, 219)';
const borderRadius = '0.35rem';

const appTheme: DefaultTheme = {
  templates: {
    borderRadius: `border-radius: ${borderRadius}`,
    border: `border: 1px solid ${borderColor}`,
    borderBottom: `border-bottom: 1px solid ${borderColor}`,
    borderRight: `border-right: 1px solid ${borderColor}`,
  },

  borderRadius: borderRadius,
  borderColor: borderColor,

  colors: {
    primary: '#2961a8',
    secondary: '#a4abc5',

    purple: '#7e22ce',
    red: '#b91c1c',
    green: '#166534',
    gray: '#d4d4d8',
    orange: '#ea580c',
    blue: '#3b82f6',

    text: '#2c3652',
    textLight: '#a4abc5',
  },
};

export { appTheme };
