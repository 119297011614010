import React from 'react';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';
import { useCustomerBuildUserRegistrationUrl } from '../../../Library/Hooks/CustomerHooks';
import { useDispatchCustomerUpdate } from '../../../Redux/Actions/CustomerActions';
import ShowHideContainer from '../../ShowHideContainer';
import { useCopyClipboard } from '../../../Library/Hooks/GlobalHooks';
import classNames from 'classnames';
import SwitchRow from '../../Display/SwitchRow';

/**
 * CustomerRegisterSettings()
 * @constructor
 */
export default function CustomerRegisterSettings() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const registerUrl = useCustomerBuildUserRegistrationUrl();
  const [domain, setDomain] = React.useState<string>('');
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();

  const dispatchCustomerUpdate = useDispatchCustomerUpdate();

  const addDomain = React.useCallback(() => {
    if (domain && domain.indexOf('.') > -1) {
      dispatchCustomerUpdate({
        ...customer,
        settings: {
          ...customer.settings,
          register: {
            ...customer.settings.register,
            allowedDomains: [...customer.settings.register.allowedDomains, domain.replace('@', '').toLowerCase()],
          },
        },
      }).finally(() => setDomain(''));
    }
  }, [customer, dispatchCustomerUpdate, domain]);

  const deleteDomain = React.useCallback(
    (domain: string) => {
      if (domain) {
        dispatchCustomerUpdate({
          ...customer,
          settings: {
            ...customer.settings,
            register: {
              ...customer.settings.register,
              allowedDomains: customer.settings.register.allowedDomains.filter((param) => param !== domain),
            },
          },
        }).finally(() => setDomain(''));
      }
    },
    [customer, dispatchCustomerUpdate],
  );

  const toggleActive = React.useCallback(() => {
    dispatchCustomerUpdate({
      ...customer,
      settings: {
        ...customer.settings,
        register: {
          ...customer.settings.register,
          isActive: !customer.settings.register.isActive,
        },
      },
    }).finally(() => setDomain(''));
  }, [customer, dispatchCustomerUpdate]);

  const renderDomains = () => {
    if (customer.settings.register.allowedDomains && customer.settings.register.allowedDomains.length > 0) {
      return customer.settings.register.allowedDomains.map((domain) => (
        <div
          className={'flex flex-row px-3 py-3 mb-2 bg-gray-100 rounded-md items-center ring-1 ring-gray-300'}
          key={domain}
        >
          <i className={'fal fa-globe mr-3'} />
          <div className="flex-grow">{`@${domain}`}</div>
          <i
            className={'fas fa-trash text-gray-300 cursor-pointer hover:text-red-500 pr-2 pl-5'}
            onClick={() => deleteDomain(domain)}
          />
        </div>
      ));
    }
    return null;
  };

  return (
    <>
      <Container headline={t('settings')} closable identifier={'userSettings'} initialOpen={false}>
        <h2 className={'text-lg'}>{t('modules.settings.accounts')}</h2>
        <p className={'text-gray-500 mb-6'}>{t('modules.settings.accountsDescriptionRegistration')}</p>

        <SwitchRow
          value={customer.settings.register.isActive}
          caption={t('modules.settings.registrationActive')}
          switchPosition={'right'}
          onChange={toggleActive}
        />

        <ShowHideContainer visible={customer.settings.register.isActive}>
          <div className={'border-b border-b-gray-300 my-12'} />

          <div className={'text-lg'}>{t('modules.settings.acceptedRegistrationDomains')}</div>
          <p className={'text-gray-500 mb-6'}>{t('modules.settings.accountsDescriptionDomains')}</p>
          {renderDomains()}
          <div className={'flex flex-row'}>
            <input
              type="text"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              className={classNames(
                'block w-full rounded-md border-0 py-3 pl-4 pr-20 text-gray-900 ring-1 ring-gray-300',
                'placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none',
              )}
              placeholder={t('addMailDomain')}
            />
            <div
              className={classNames(
                '"bg-gray-300 ring-1 ring-gray-400 rounded-md ml-2 flex',
                'justify-center items-center px-5 cursor-pointer hover:bg-gray-400',
              )}
              onClick={addDomain}
            >
              <i className={'fas fa-plus'} />
            </div>
          </div>

          <div className={'border-b border-b-gray-300 my-12'} />

          <div className={'text-lg'}>{t('modules.settings.registrationLinkHeadline')}</div>
          <p className={'text-gray-500 mb-6'}>
            {t('modules.settings.registrationLinkDescription', { name: customer.displayName || customer.name })}
          </p>

          <div className={'flex flex-row items-center ring-1 ring-gray-300 rounded-md p-3'}>
            <div className={'text-xl ml-2 text-gray-600 flex-grow'}>{registerUrl}</div>
            <i
              className={'fas fa-copy pr-3 pl-4 text-xl text-gray-300 hover:text-black cursor-pointer'}
              onClick={() => copyToClipBoard(registerUrl)}
            />
          </div>
        </ShowHideContainer>
      </Container>

      {clipboardCallout}
    </>
  );
}
