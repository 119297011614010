import React from 'react';
import { useTranslation } from 'react-i18next';
import AbstractCallout from './AbstractCallout';

interface Props {
  visible: boolean;
  onChange: (value: boolean) => void;
  text?: string;
}

/**
 * CalloutProgress()
 * @param props
 * @constructor
 */
export default function CalloutProgress(props: Props) {
  const { visible, onChange, text } = props;
  const { t } = useTranslation();

  return (
    <AbstractCallout
      visible={visible}
      onChange={onChange}
      icon={'fas fa-spinner fa-spin'}
      text={text || t('changesSaving')}
      className={'bg-gray-400 text-white'}
      duration={5000}
      transitionTime={250}
      showClose={false}
    />
  );
}
