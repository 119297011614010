import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { buttonClass } from '../Navigation/Item';

const de = require('../../../Assets/Images/de-DE.png');
const en = require('../../../Assets/Images/en-US.png');
const es = require('../../../Assets/Images/es-ES.png');

interface Props {
  onChange: (lng: string) => void;
  selected: string;
}

/**
 * LanguageSelectButton()
 * @param props
 * @constructor
 */
export default function LanguageSelectButton(props: Props) {
  const { selected, onChange } = props;
  const [t] = useTranslation();

  const getImage = () => {
    switch (selected) {
      case 'de-DE':
        return de;
      case 'en-EN':
        return en;
      default:
        return es;
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={classNames(buttonClass)}>
          <img src={getImage()} alt={'Language'} style={{ width: 30 }} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="
            absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1
            ring-black ring-opacity-5 focus:outline-none
          "
        >
          <div className="py-1">
            <Menu.Item key={'de'}>
              {({ active }) => (
                <div
                  onClick={() => onChange('de-DE')}
                  className={classNames(
                    { 'bg-gray-100 text-gray-900': active },
                    { 'text-gray-700': !active },
                    'block px-4 py-2 cursor-pointer',
                  )}
                >
                  <div className={classNames('flex flex-row items-center')}>
                    <img src={de} alt={'German'} width={16} className={'mr-2'} />
                    {t(`languages.de-DE`)}
                  </div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item key={'es'}>
              {({ active }) => (
                <div
                  onClick={() => onChange('es-ES')}
                  className={classNames(
                    { 'bg-gray-100 text-gray-900': active },
                    { 'text-gray-700': !active },
                    'block px-4 py-2 cursor-pointer',
                  )}
                >
                  <div className={classNames('flex flex-row items-center')}>
                    <img src={es} alt={'German'} width={16} className={'mr-2'} />
                    {t(`languages.es-ES`)}
                  </div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item key={'en'}>
              {({ active }) => (
                <div
                  onClick={() => onChange('en-EN')}
                  className={classNames(
                    { 'bg-gray-100 text-gray-900': active },
                    { 'text-gray-700': !active },
                    'block px-4 py-2 cursor-pointer',
                  )}
                >
                  <div className={classNames('flex flex-row items-center')}>
                    <img src={en} alt={'German'} width={16} className={'mr-2'} />
                    {t(`languages.en-EN`)}
                  </div>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
