import React from 'react';
import { Client } from '../../../Library/Types/ClientTypes';
import moment from 'moment';
import AlertModal from '../../Modals/AlertModal';
import { InvoiceType } from '../../../Library/Types/DocumentTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { useTranslation } from 'react-i18next';
import { useDispatchDocumentDoneAlreadyExisting } from '../../../Redux/Actions/DocumentActions';
import { clientFindBankInformation } from '../../../Library/Functions/ClientFunctions';
import { checkAndPrepareDate } from '../../../Library/Functions/LocalizationFunctions';
import { DocumentUpdateActions, DocumentUpdateState } from '../DocumentUpdateReducer';

/**
 * useCheckBankDates()
 * Checks if the startDAte for the current document is exactly one day after the last uploaded bank document. Also
 * checks already uploaded or open documents which are not done right now!
 */
export const useCheckBankDates = () => {
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertModal, setAlertModal] = React.useState<React.ReactElement | null>(null);
  const { documents } = useAppSelector((state) => state.document);
  const { t } = useTranslation();
  const [client, setClient] = React.useState<Client>();
  const [endDate, setEndDate] = React.useState<string>();

  const checkBankDate = React.useCallback(
    (documentId: string, documentType: InvoiceType, client: Client, startDate: string) => {
      setClient(client);
      const foundDocs = documents.filter(
        (item) =>
          item.type === InvoiceType.bank &&
          item.clientId === client.clientId &&
          item.documentId !== documentId &&
          item.bankEndDate,
      );
      let useDate: string = '';

      // Search for current open bank documents. If the highest bankStartDate from open documents is after
      // param startDate, use this date!
      if (foundDocs) {
        foundDocs.forEach((doc) => {
          if (!useDate || moment(useDate).isBefore(doc.bankEndDate)) {
            useDate = doc.bankEndDate;
          }
        });
      }

      useDate =
        useDate && moment(client.lastBankDocumentEndDate).isBefore(moment(useDate))
          ? useDate
          : client.lastBankDocumentEndDate || '';
      setEndDate(useDate);

      if (documentType === InvoiceType.bank) {
        if (useDate) {
          const result = moment(useDate).add(1, 'day').isSame(moment(startDate), 'date');

          setShowAlert(!result);
          return result;
        } else {
          return true;
        }
      }
      return true;
    },
    [documents],
  );

  React.useEffect(() => {
    if (showAlert && client) {
      setAlertModal(
        <AlertModal
          headline={t('alert.bankDateInvalid.headline')}
          text={t('alert.bankDateInvalid.text', {
            date: moment(endDate).format('LL'),
            nextDate: moment(endDate).add(1, 'day').format('LL'),
          })}
          visible={true}
          onClose={() => setShowAlert(false)}
          type={'error'}
        />,
      );
    } else {
      setAlertModal(null);
    }
  }, [client, endDate, showAlert, t]);

  return { checkBankDate, alertModal };
};

/**
 * useDocumentCheckExisting()
 * Checks if a document with the same creditor and same invoice number is already existing in done or open documents
 */
export const useDocumentCheckExisting = () => {
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [alertModal, setAlertModal] = React.useState<React.ReactElement | null>(null);
  const { t } = useTranslation();
  const { documents } = useAppSelector((state) => state.document);
  const [creditor, setCreditor] = React.useState<string>('');
  const [invoice, setInvoice] = React.useState<string>('');

  const dispatchCheckDoneExisting = useDispatchDocumentDoneAlreadyExisting();

  const checkDocumentExisting = React.useCallback(
    async (
      documentId: string,
      documentType: InvoiceType,
      clientId: string,
      creditor: string,
      invoiceNumber: string,
    ) => {
      if (documentType !== InvoiceType.bank) {
        setCreditor(creditor);
        setInvoice(invoiceNumber);

        const foundOpenDocs = documents.filter(
          (item) =>
            item.type !== InvoiceType.bank &&
            item.clientId === clientId &&
            item.documentId !== documentId &&
            (item.creditor ? item.creditor.toLowerCase() === creditor.toLowerCase() : false) &&
            (item.invoiceNumber ? item.invoiceNumber.toLowerCase() === invoiceNumber.toLowerCase() : false),
        );

        if (foundOpenDocs && foundOpenDocs.length > 0) {
          setShowAlert(true);
          return true;
        } else {
          const result = await dispatchCheckDoneExisting(clientId, creditor, invoiceNumber);
          if (result) {
            setShowAlert(true);
            return true;
          }
          return false;
        }
      }
      return false;
    },
    [dispatchCheckDoneExisting, documents],
  );

  React.useEffect(() => {
    if (showAlert && invoice && creditor) {
      setAlertModal(
        <AlertModal
          headline={t('alert.documentExisting.headline')}
          text={t('alert.documentExisting.text', { creditor, invoiceNumber: invoice })}
          visible={true}
          onClose={() => setShowAlert(false)}
          type={'error'}
        />,
      );
    } else {
      setAlertModal(null);
    }
  }, [creditor, invoice, showAlert, t]);

  return { checkDocumentExisting, alertModal };
};

/**
 * handleSetTextsForBankDocuments()
 * Prefill document fields for bank document types
 * @param texts
 * @param client
 * @param state
 * @param dispatch
 */
export const handleSetTextsForBankDocuments = (
  texts: string[],
  client: Client,
  state: DocumentUpdateState,
  dispatch: React.Dispatch<DocumentUpdateActions>,
) => {
  if (state.data.type === InvoiceType.bank) {
    if (texts && texts.length > 0) {
      const { lowestDate, highestDate, foundIban } = clientFindBankInformation(
        texts,
        client.settings && client.settings.bankAccounts ? client.settings.bankAccounts : [],
      );

      if (!state.data.bankIban && foundIban) {
        dispatch({ type: 'bankIban', payload: foundIban });
      }
      if (!state.data.bankStartDate && lowestDate) {
        dispatch({ type: 'bankStartDate', payload: lowestDate });
      }
      if (!state.data.bankEndDate && highestDate) {
        dispatch({ type: 'bankEndDate', payload: highestDate });
      }
    }
  }
};

/**
 * onPressTextHandler()
 * @param text
 * @param state
 * @param dispatch
 * @param copyToClipBoard
 */
export const onPressTextHandler = (
  text: string,
  state: DocumentUpdateState,
  dispatch: React.Dispatch<DocumentUpdateActions>,
  copyToClipBoard: (text: string) => void,
) => {
  if (state.data.type === InvoiceType.incoming || state.data.type === InvoiceType.outgoing) {
    if (!state.data.creditor && state.data.type === InvoiceType.incoming) {
      dispatch({ type: 'creditor', payload: text });
    } else if (!state.data.invoiceNumber) {
      dispatch({ type: 'invoiceNumber', payload: text });
    } else if (!state.data.invoiceDate) {
      const date = checkAndPrepareDate(text);
      if (date) {
        dispatch({ type: 'invoiceDate', payload: date });
      }
    } else {
      copyToClipBoard(text);
    }
  } else if (state.data.type === InvoiceType.bank) {
    if (!state.data.bankName) {
      dispatch({ type: 'bankName', payload: text });
    } else if (!state.data.bankIban) {
      dispatch({ type: 'bankIban', payload: text });
    } else if (!state.data.bankStartDate) {
      const date = checkAndPrepareDate(text);
      if (date) {
        dispatch({ type: 'bankStartDate', payload: date });
      } else {
        copyToClipBoard(text);
      }
    } else if (!state.data.bankEndDate) {
      const date = checkAndPrepareDate(text);
      if (date) {
        dispatch({ type: 'bankEndDate', payload: date });
      } else {
        copyToClipBoard(text);
      }
    } else {
      copyToClipBoard(text);
    }
  } else {
    copyToClipBoard(text);
  }
};
