import React from 'react';
import { Endpoint } from '../ApiTypes';
import AbstractEndpoint from '../AbstractEndpoint';

export const ClientCreateEndpoint: Endpoint = {
  name: 'Client create',
  description:
    'Create a new client for the customer. The default creator and default assigned responsible ' +
    'user of the client will be the customer admin user when creating a new client with the API.',
  method: 'post',
  path: '/client',
  requestBody: {
    type: 'JSON-Object',
    data: [
      { type: 'Salutation', key: 'salutation', mandatory: true },
      {
        type: 'string',
        key: 'firstName',
        mandatory: true,
        description: 'Can be optional if salutation is "c" for company',
      },
      {
        type: 'string',
        key: 'lastName',
        mandatory: true,
        description: 'Can be optional if salutation is "c" for company',
      },
      {
        type: 'string',
        key: 'companyName',
        mandatory: true,
        description: 'Can be optional if salutation is not like "c" for company',
      },
      {
        type: 'string',
        key: 'internalId',
        mandatory: false,
        description: 'An internalId to match the client with an external system',
      },
      { type: 'string', key: 'taxNumber', mandatory: false, description: 'The clients tax number' },
      {
        type: 'boolean',
        key: 'allowBank',
        mandatory: false,
        description: 'If the client is allowed to upload bank documents. Default: true',
      },
      {
        type: 'boolean',
        key: 'allowOutgoing',
        mandatory: false,
        description: 'If the client is allowed to upload outgoing documents. Default: true',
      },
      {
        type: 'Language',
        key: 'language',
        mandatory: false,
        description: 'The default client language. Default: es-ES',
      },
      {
        type: 'boolean',
        key: 'registrationActive',
        mandatory: false,
        description: 'If new users are allowed to be registered for the client. Default: true',
      },
    ],
  },
  responseBody: {
    type: 'JSON-Object',
    data: [
      { key: 'Body', mandatory: true, type: 'Client', description: 'The response body is a JSON-Object of the client' },
    ],
  },
};

/**
 * ClientCreate()
 * @constructor
 */
export default function ClientCreate() {
  return <AbstractEndpoint endpoint={ClientCreateEndpoint} />;
}
