import React from 'react';
import AbstractModal from './AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';

interface Props {
  headline: string;
  text: string;
  visible: boolean;
  onClose: () => void;
  type: 'success' | 'error' | 'primary';
  icon?: string;
}

/**
 * AlertModal()
 * @param props
 * @constructor
 */
export default function AlertModal(props: Props) {
  const { visible, text, onClose, headline, type, icon } = props;
  const { t } = useTranslation();

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={type as any}>
        {t('close')}
      </Button>
    </div>
  );

  return (
    <AbstractModal
      headline={headline}
      text={text}
      visible={visible}
      icon={icon || 'fas fa-exclamation-circle'}
      buttons={renderButtons()}
      type={type}
    />
  );
}
