import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';

const Container = styled.div<{ $active: boolean }>`
  padding: 10px 15px;
  ${(props) => props.theme.templates.borderRadius};
  margin: 0 10px 2px 10px;
  cursor: pointer;
  color: #4d5562;
  font-weight: 500;
  ${(props) => (props.$active ? 'background-color: #7e22ce;' : null)};
  ${(props) => (props.$active ? 'color: #ffffff;' : null)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: #7e22ce;
    color: #ffffff;

    i {
      color: #ffffff;
    }
  }
`;

interface Props {
  caption: string;
  icon: string;
  hasAccess: boolean;
  path?: string;
  onPress: () => void;
  smallMenu: boolean;
  onNavigate: () => void;
}

/**
 * MenuItem()
 * @constructor
 */
export default function MenuItem(props: Props) {
  const { caption, icon, hasAccess, path, smallMenu, onPress, onNavigate } = props;
  const navigate = useNavigate();
  const [active, setActive] = React.useState<boolean>(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location && path) {
      setActive(location.pathname.indexOf(path) >= 0);
    }
  }, [location, path]);

  const renderCaption = () => {
    if (!smallMenu) {
      return caption;
    }
    return null;
  };

  const handleNavigate = () => {
    if (path) {
      navigate(path);
      onNavigate();
    }
  };

  if (hasAccess) {
    return (
      <Container
        onClick={path ? () => handleNavigate() : () => onPress()}
        $active={active}
        className={classNames({ 'items-center mr-0 text-center': smallMenu })}
      >
        <i
          className={classNames(
            icon,
            { 'mr-4': !smallMenu },
            { 'py-2': smallMenu },
            { 'text-gray-400': !active },
            { 'text-white': active },
          )}
        />
        {renderCaption()}
      </Container>
    );
  }
  return null;
}

MenuItem.defaultProps = {
  onPress: () => {},
  smallMenu: false,
};
