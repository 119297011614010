import { Storage } from '../Types/StorageTypes';

const mapping = [
  {
    type: 'application/pdf',
    name: 'PDF Document',
    color: 'text-red-600',
    icon: 'fa-file-pdf',
  },
  {
    type: 'application/msword',
    name: 'Microsoft Word Document',
    color: 'text-blue-600',
    icon: 'fa-file-word',
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'Microsoft Word Document (OpenXML)',
    color: 'text-blue-600',
    icon: 'fa-file-word',
  },
  {
    type: 'application/vnd.ms-excel',
    name: 'Microsoft Excel Spreadsheet',
    color: 'text-green-600',
    icon: 'fa-file-excel',
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'Microsoft Excel Spreadsheet (OpenXML)',
    color: 'text-green-600',
    icon: 'fa-file-excel',
  },
  {
    type: 'application/vnd.ms-powerpoint',
    name: 'Microsoft PowerPoint Presentation',
    color: 'text-orange-600',
    icon: 'fa-file-powerpoint',
  },
  {
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'Microsoft PowerPoint Presentation (OpenXML)',
    color: 'text-orange-600',
    icon: 'fa-file-powerpoint',
  },
  {
    type: 'application/zip',
    name: 'ZIP Archive',
    color: 'text-gray-600',
    icon: 'fa-file-archive',
  },
  {
    type: 'application/x-rar-compressed',
    name: 'RAR Archive',
    color: 'text-gray-600',
    icon: 'fa-file-archive',
  },
  {
    type: 'application/json',
    name: 'JSON File',
    color: 'text-yellow-600',
    icon: 'fa-file-code',
  },
  {
    type: 'application/xml',
    name: 'XML File',
    color: 'text-purple-600',
    icon: 'fa-file-code',
  },
  {
    type: 'text/plain',
    name: 'Text File',
    color: 'text-gray-600',
    icon: 'fa-file-alt',
  },
  {
    type: 'text/html',
    name: 'HTML Document',
    color: 'text-red-600',
    icon: 'fa-file-code',
  },
  {
    type: 'text/css',
    name: 'CSS File',
    color: 'text-blue-600',
    icon: 'fa-file-code',
  },
  {
    type: 'text/javascript',
    name: 'JavaScript File',
    color: 'text-yellow-600',
    icon: 'fa-file-code',
  },
  {
    type: 'text/csv',
    name: 'CSV File',
    color: 'text-green-600',
    icon: 'fa-file-csv',
  },
  {
    type: 'image/jpeg',
    name: 'JPEG Image',
    color: 'text-gray-600',
    icon: 'fa-file-image',
  },
  {
    type: 'image/png',
    name: 'PNG Image',
    color: 'text-gray-600',
    icon: 'fa-file-image',
  },
  {
    type: 'image/gif',
    name: 'GIF Image',
    color: 'text-gray-600',
    icon: 'fa-file-image',
  },
  {
    type: 'image/bmp',
    name: 'BMP Image',
    color: 'text-gray-600',
    icon: 'fa-file-image',
  },
  {
    type: 'image/webp',
    name: 'WebP Image',
    color: 'text-gray-600',
    icon: 'fa-file-image',
  },
  {
    type: 'image/svg+xml',
    name: 'SVG Image',
    color: 'text-yellow-600',
    icon: 'fa-file-image',
  },
  {
    type: 'image/jpg',
    name: 'JPG Image',
    color: 'text-gray-600',
    icon: 'fa-file-image',
  },
  {
    type: 'audio/mpeg',
    name: 'MP3 Audio',
    color: 'text-orange-600',
    icon: 'fa-file-audio',
  },
  {
    type: 'audio/wav',
    name: 'WAV Audio',
    color: 'text-orange-600',
    icon: 'fa-file-audio',
  },
  {
    type: 'audio/ogg',
    name: 'OGG Audio',
    color: 'text-orange-600',
    icon: 'fa-file-audio',
  },
  {
    type: 'video/mp4',
    name: 'MP4 Video',
    color: 'text-blue-600',
    icon: 'fa-file-video',
  },
  {
    type: 'video/x-msvideo',
    name: 'AVI Video',
    color: 'text-blue-600',
    icon: 'fa-file-video',
  },
  {
    type: 'video/x-matroska',
    name: 'Matroska Video (MKV)',
    color: 'text-blue-600',
    icon: 'fa-file-video',
  },
  {
    type: 'video/webm',
    name: 'WebM Video',
    color: 'text-blue-600',
    icon: 'fa-file-video',
  },
];
/**
 * storageGetFileTypeIcon
 * @param document
 */
export const storageGetFileTypeIcon = (document: Storage): string => {
  const found = mapping.find((item) => item.type === document.fileType);

  if (found) {
    return `fas ${found.icon} ${found.color}`;
  }

  return 'far fa-memo text-gray-400';
};

/**
 * storageGetFileTypeIcon
 * @param document
 */
export const storageFileTypeToName = (document: Storage): string => {
  const found = mapping.find((item) => item.type === document.fileType);

  if (found) {
    return found.name;
  }

  return document.fileType;
};
