import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import Table from '../Table';
import { useDocumentGetMapping } from '../../Library/Hooks/DocumentHooks';
import classNames from 'classnames';

/**
 * GestorDocumentOverview()
 * @constructor
 */
export default function GestorDocumentOverview() {
  const { t } = useTranslation();
  const { documents } = useAppSelector((state) => state.document);
  const { user, assignedClientIds } = useAppSelector((state) => state.auth);
  const [myDocs, setMyDocs] = React.useState<DocumentType[]>();
  const [open, setOpen] = React.useState<DocumentType[]>();
  const [question, setQuestion] = React.useState<DocumentType[]>();
  const getMapping = useDocumentGetMapping();

  React.useEffect(() => {
    if (documents && documents.length > 0) {
      const docs = documents.filter(
        (item) =>
          assignedClientIds.indexOf(item.clientId) >= 0 &&
          (item.state === DocumentState.open || item.state === DocumentState.question),
      );
      setMyDocs(docs);
    } else {
      setMyDocs([]);
    }
  }, [assignedClientIds, documents]);

  React.useEffect(() => {
    if (myDocs) {
      setOpen(myDocs.filter((item) => item.state === DocumentState.open));
      setQuestion(myDocs.filter((item) => item.state === DocumentState.question));
    }
  }, [myDocs]);

  if (user.isCustomerUser && user.isActive && myDocs !== undefined && open !== undefined && question !== undefined) {
    return (
      <Container headline={t('overviewYour')}>
        <Table>
          <tbody>
            <tr>
              <td width={30}>
                <i
                  className={classNames(
                    getMapping(DocumentState.open).icon,
                    getMapping(DocumentState.open).textColorClass,
                  )}
                />
              </td>
              <td>{t('modules.documents.statusText.open')}</td>
              <td width={20}>{open.length}</td>
            </tr>
            <tr>
              <td width={30}>
                <i
                  className={classNames(
                    getMapping(DocumentState.question).icon,
                    getMapping(DocumentState.question).textColorClass,
                  )}
                />
              </td>
              <td>{t('modules.documents.statusText.question')}</td>
              <td width={20}>{question.length}</td>
            </tr>
            <tr>
              <td width={30}>
                <i className={'fas fa-sigma'} />
              </td>
              <td>{t('overall')}</td>
              <td width={20}>{myDocs.length}</td>
            </tr>
          </tbody>
        </Table>
      </Container>
    );
  }
  return null;
}
