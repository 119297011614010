import React from 'react';
import { useTranslation } from 'react-i18next';
import AbstractCallout from './AbstractCallout';

interface Props {
  visible: boolean;
  onChange: (value: boolean) => void;
  value?: string;
}

/**
 * CalloutCopiedToClipboard()
 * @param props
 * @constructor
 */
export default function CalloutCopiedToClipboard(props: Props) {
  const { visible, onChange, value } = props;
  const { t } = useTranslation();

  return (
    <AbstractCallout
      visible={visible}
      onChange={onChange}
      icon={'fas fa-copy'}
      text={`${t('copiedToClipboard')} (${value && value.length > 20 ? `${value.substring(0, 20)}...` : value})`}
      className={''}
      duration={2000}
      transitionTime={250}
      showClose
      closeBgClass={'hover:bg-blue-500'}
    />
  );
}
