import { ref, uploadBytesResumable } from '@firebase/storage';
import React from 'react';
import { getDownloadURL, getStorage } from 'firebase/storage';
import { Client } from '../../Library/Types/ClientTypes';
import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { FirebaseFunctions, FirebasePath } from '../../Library/Firebase';
import moment from 'moment';
import {
  DOCUMENT_CREATE_ERROR,
  DOCUMENT_CREATE_START,
  DOCUMENT_CREATE_SUCCESS,
  DOCUMENT_DELETE_ERROR,
  DOCUMENT_DELETE_START,
  DOCUMENT_DELETE_SUCCESS,
  DOCUMENT_DONE_ALREADY_EXISTING_SUCCESS,
  DOCUMENT_GETLIST_BY_CLIENTIDS_SUCCESS,
  DOCUMENT_GETLIST_BY_CUSTOMERID_SUCCESS,
  DOCUMENT_REOPEN_ERROR,
  DOCUMENT_REOPEN_START,
  DOCUMENT_REOPEN_SUCCESS,
  DOCUMENT_SET_BOOKED_ERROR,
  DOCUMENT_SET_BOOKED_START,
  DOCUMENT_SET_BOOKED_SUCCESS,
  DOCUMENT_UPDATE_ERROR,
  DOCUMENT_UPDATE_START,
  DOCUMENT_UPDATE_SUCCESS,
  DOCUMENT_UPLOAD_ERROR,
  DOCUMENT_UPLOAD_START,
  DOCUMENT_UPLOAD_SUCCESS,
  DOCUMENT_UPLOAD_UPDATE_ERROR,
  DOCUMENT_UPLOAD_UPDATE_START,
  DOCUMENT_UPLOAD_UPDATE_SUCCESS,
  DOCUMENTS_GET_INVOICE_INFORMATION_ERROR,
  DOCUMENTS_GET_INVOICE_INFORMATION_START,
  DOCUMENTS_GET_INVOICE_INFORMATION_SUCCESS,
} from '../ActionTypes';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { DocumentState, DocumentType, InvoiceType, StateHistory } from '../../Library/Types/DocumentTypes';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { downloadBase64Pdf } from '../../Library/Functions/HelperFunctions';

/**
 * useDispatchDocumentUpload()
 */
export const useDispatchDocumentUpload = (): ((
  file: File,
  client: Client,
  onProgress: (progress: number) => void,
) => Promise<DocumentType>) => {
  const dispatch = useAppDispatch();
  const dispatchDocumentCreate = useDispatchDocumentCreate();

  return React.useCallback(
    (file, client, onProgress) => {
      dispatch(DOCUMENT_UPLOAD_START(file));
      const random = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
      const fileName = `${moment().format('YYYYMMDDHHmmss')}_${client.clientId}_${random}`;
      const refPath = `${FirebasePath.clients}/${client.clientId}/${fileName}`;

      const uploadTask = uploadBytesResumable(ref(getStorage(), refPath), file, {
        customMetadata: { originalFilename: file.name, clientId: client.clientId },
      });

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            onProgress(progress);
          },
          (error) => {
            dispatch(DOCUMENT_UPLOAD_ERROR(error));
            return reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(ref(getStorage(), refPath)).then((downloadUrl) => {
              return dispatchDocumentCreate(fileName, file, refPath, downloadUrl, client).then((document) => {
                dispatch(DOCUMENT_UPLOAD_SUCCESS(document));
                return resolve(document);
              });
            });
          },
        );
      });
    },
    [dispatch, dispatchDocumentCreate],
  );
};

/**
 * useDispatchDocumentUploadUpdate()
 * Update the file of an existing document
 */
export const useDispatchDocumentUploadUpdate = (): ((
  file: File,
  document: DocumentType,
  onProgress: (progress: number) => void,
) => Promise<DocumentType>) => {
  const dispatch = useAppDispatch();
  const dispatchDocumentUpdate = useDispatchDocumentUpdate();

  return React.useCallback(
    (file, document, onProgress) => {
      dispatch(DOCUMENT_UPLOAD_UPDATE_START({ file, document }));

      const uploadTask = uploadBytesResumable(ref(getStorage(), document.refPath), file, {
        customMetadata: { originalFilename: file.name, clientId: document.clientId as string },
      });

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            onProgress(progress);
          },
          (error) => {
            dispatch(DOCUMENT_UPLOAD_UPDATE_ERROR(error));
            return reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(ref(getStorage(), document.refPath)).then((downloadUrl) => {
              const updatedDocument: DocumentType = {
                ...document,
                downloadUrl,
                originalName: file.name,
                state: DocumentState.open,
                fileType: file.type,
              };
              return dispatchDocumentUpdate(updatedDocument).then(() => {
                dispatch(DOCUMENT_UPLOAD_UPDATE_SUCCESS(updatedDocument));
                return resolve(updatedDocument);
              });
            });
          },
        );
      });
    },
    [dispatch, dispatchDocumentUpdate],
  );
};

/**
 * useDispatchDocumentCreate()
 */
export const useDispatchDocumentCreate = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (fileName: string, file: File, refPath: string, downloadUrl: string, client: Client) => {
      dispatch(DOCUMENT_CREATE_START(fileName));

      const document: DocumentType = {
        documentId: '',
        clientId: client.clientId,
        customerId: client.customerId,
        createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        state: DocumentState.uploaded,
        fileType: file.type,
        documentName: fileName,
        originalName: file.name,
        refPath,
        createdUserId: user.userId,
        downloadUrl,
        firebaseTimestamp: serverTimestamp(),
        bankName: '',
        bankIban: '',
        bankEndDate: '',
        bankStartDate: '',
        size: file.size,
        bookingTime: 0,
        messages: [],
        stateHistory: [],
        type: InvoiceType.unknown,
      };

      const collectionRef = collection(getFirestore(), FirebasePath.documents);
      return addDoc(collectionRef, document)
        .then((snapShot) => {
          dispatch(DOCUMENT_CREATE_SUCCESS({ ...document, documentId: snapShot.id }));
          return Promise.resolve({ ...document, documentId: snapShot.id });
        })
        .catch((error) => {
          dispatch(DOCUMENT_CREATE_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch, user.userId],
  );
};

/**
 * useDispatchDocumentUpdate()
 */
export const useDispatchDocumentUpdate = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (document: DocumentType) => {
      dispatch(DOCUMENT_UPDATE_START(document));

      const docRef = doc(
        getFirestore(),
        FirebasePath.documents,
        document.documentId,
      ) as DocumentReference<DocumentType>;

      const firebaseInvoiceDate =
        document.type === InvoiceType.bank
          ? document.bankStartDate
            ? Timestamp.fromDate(moment(document.bankStartDate).toDate())
            : ''
          : document.invoiceDate
            ? Timestamp.fromDate(moment(document.invoiceDate).toDate())
            : '';

      return updateDoc(docRef, {
        ...document,
        firebaseInvoiceDate,
        updatedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
        updatedUserId: user.userId,
      })
        .then(() => {
          dispatch(DOCUMENT_UPDATE_SUCCESS(document));
          return Promise.resolve(document);
        })
        .catch((error) => {
          dispatch(DOCUMENT_UPDATE_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch, user.userId],
  );
};

/**
 * useDispatchDocumentDelete()
 */
export const useDispatchDocumentDelete = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (document: DocumentType) => {
      dispatch(DOCUMENT_DELETE_START(document));

      if (document.state !== DocumentState.done) {
        const docRef = doc(
          getFirestore(),
          FirebasePath.documents,
          document.documentId,
        ) as DocumentReference<DocumentType>;

        return deleteDoc(docRef)
          .then(() => {
            dispatch(DOCUMENT_DELETE_SUCCESS(document));
            return Promise.resolve(document);
          })
          .catch((error) => {
            dispatch(DOCUMENT_DELETE_ERROR(error));
            return Promise.reject(error);
          });
      } else {
        return new Promise((resolve) => resolve(document));
      }
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentSetBooked()
 */
export const useDispatchDocumentSetBooked = () => {
  const dispatch = useAppDispatch();
  const { user, customer } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (document: DocumentType) => {
      dispatch(DOCUMENT_SET_BOOKED_START(document));

      if (document.clientId) {
        const docRef = doc(
          getFirestore(),
          FirebasePath.clients,
          document.clientId,
          FirebasePath.documents,
          document.documentId,
        ) as DocumentReference<DocumentType>;

        return setDoc(docRef, {
          ...document,
          state: DocumentState.done,
          customerId: customer.customerId,
          finishedDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          finishedUserId: user.userId,
        })
          .then(() => {
            dispatch(DOCUMENT_SET_BOOKED_SUCCESS(document));
            return Promise.resolve(document);
          })
          .catch((error) => {
            dispatch(DOCUMENT_SET_BOOKED_ERROR(error));
            return Promise.reject(error);
          });
      } else {
        return new Promise((reject) => reject(''));
      }
    },
    [customer.customerId, dispatch, user.userId],
  );
};

/**
 * useDispatchDocumentDownloadBase64()
 */
export const useDispatchDocumentDownloadBase64 = (downloadInBrowser: boolean = false) => {
  return React.useCallback(
    (document: DocumentType) => {
      const callable = httpsCallable<DocumentType, string>(getFunctions(), FirebaseFunctions.documentGetBase64);

      return callable(document).then((response) => {
        if (!downloadInBrowser) {
          return Promise.resolve(response.data);
        } else {
          downloadBase64Pdf(response.data as string, document.originalName);
          return Promise.resolve(response.data);
        }
      });
    },
    [downloadInBrowser],
  );
};

/**
 * useDispatchDocumentsOnListenCustomerChanges()
 */
export const useDispatchDocumentsOnListenCustomerChanges = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (customerId: string) => {
      const collectionRef = collection(getFirestore(), FirebasePath.documents) as CollectionReference<DocumentType>;
      const queryRef = query(collectionRef, where('customerId', '==', customerId));

      return onSnapshot(queryRef, (snapShot) => {
        let docs: DocumentType[] = [];
        if (!snapShot.empty) {
          snapShot.forEach((doc) => {
            docs.push({ ...doc.data(), documentId: doc.id });
          });
        }
        dispatch(DOCUMENT_GETLIST_BY_CUSTOMERID_SUCCESS(docs));
      });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentsOnListenCustomerChanges()
 */
export const useDispatchDocumentsOnListenClientChanges = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (clientIds: string[]) => {
      const collectionRef = collection(getFirestore(), FirebasePath.documents) as CollectionReference<DocumentType>;
      const queryRef = query(collectionRef, where('clientId', 'in', clientIds));

      return onSnapshot(queryRef, (snapShot) => {
        let docs: DocumentType[] = [];
        if (!snapShot.empty) {
          snapShot.forEach((doc) => {
            docs.push({ ...doc.data(), documentId: doc.id });
          });
        }
        dispatch(DOCUMENT_GETLIST_BY_CLIENTIDS_SUCCESS(docs));
      });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentsDownloadList()
 */
export const useDispatchDocumentsDownloadList = () => {
  return React.useCallback((clientId: string, documentIds: string[]) => {
    const callable = httpsCallable<{ clientId: string; documentIds: string[] }, string>(
      getFunctions(),
      FirebaseFunctions.documentsDownloadList,
    );
    return callable({ clientId, documentIds });
  }, []);
};

/**
 * useDispatchDocumentsDownloadAllOpen()
 */
export const useDispatchDocumentsDownloadAllOpen = () => {
  return React.useCallback((clientId: string) => {
    const callable = httpsCallable<{ clientId: string }, string>(
      getFunctions(),
      FirebaseFunctions.documentsDownloadAllOpen,
    );
    return callable({ clientId });
  }, []);
};

/**
 * useDispatchDocumentDoneAlreadyExisting()
 */
export const useDispatchDocumentDoneAlreadyExisting = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (clientId: string, creditor: string, invoiceNumber: string) => {
      const collectionRef = collection(
        getFirestore(),
        FirebasePath.clients,
        clientId,
        FirebasePath.documents,
      ) as CollectionReference<DocumentType>;
      const queryRef = query(
        collectionRef,
        where('creditor', '==', creditor),
        where('invoiceNumber', '==', invoiceNumber),
      );

      return getDocs(queryRef).then((snapShot) => {
        if (!snapShot.empty) {
          dispatch(DOCUMENT_DONE_ALREADY_EXISTING_SUCCESS(true));
          return Promise.resolve(true);
        }
        dispatch(DOCUMENT_DONE_ALREADY_EXISTING_SUCCESS(false));
        return Promise.resolve(false);
      });
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentGetInvoiceInformation()
 */
export const useDispatchDocumentGetInvoiceInformation = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (document: DocumentType, client: Client) => {
      if (document.fileType.indexOf('pdf') > -1 || document.fileType.indexOf('image') > -1) {
        dispatch(DOCUMENTS_GET_INVOICE_INFORMATION_START(document));
        const callable = httpsCallable<{ document: DocumentType; client: Client }, string>(
          getFunctions(),
          FirebaseFunctions.documentGetInvoiceData,
        );

        return callable({ document, client })
          .then((response: any) => {
            dispatch(DOCUMENTS_GET_INVOICE_INFORMATION_SUCCESS(response.data));
            return Promise.resolve(response.data);
          })
          .catch((error) => {
            dispatch(DOCUMENTS_GET_INVOICE_INFORMATION_ERROR(error));
            return Promise.reject(error);
          });
      } else {
        return Promise.resolve({});
      }
    },
    [dispatch],
  );
};

/**
 * useDispatchDocumentReopen()
 */
export const useDispatchDocumentReopen = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  return React.useCallback(
    (document: DocumentType) => {
      dispatch(DOCUMENT_REOPEN_START(document));

      const newDocRef = doc(
        getFirestore(),
        FirebasePath.documents,
        document.documentId,
      ) as DocumentReference<DocumentType>;

      const history: StateHistory = {
        dateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        state: DocumentState.open,
        userId: user.userId,
      };

      return setDoc(newDocRef, {
        ...document,
        state: DocumentState.open,
        stateHistory: [...document.stateHistory, history],
      })
        .then(() => {
          const docDeleteRef = doc(
            getFirestore(),
            FirebasePath.clients,
            document.clientId,
            FirebasePath.documents,
            document.documentId,
          );
          return deleteDoc(docDeleteRef).then(() => {
            dispatch(DOCUMENT_REOPEN_SUCCESS(document));
            return Promise.resolve();
          });
        })
        .catch((error) => {
          dispatch(DOCUMENT_REOPEN_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch, user.userId],
  );
};
