import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useCheckAdminAccess } from '../../../Library/Hooks/AccessHooks';
import ClientRegisterSettings from '../../../Components/Settings/Customer/ClientRegisterSettings';
import ClientGroups from '../../../Components/Settings/Customer/ClientGroups';

/**
 * Clients()
 * @constructor
 */
export default function Clients() {
  const { t } = useTranslation();
  const navContext = React.useContext(NavbarContext);
  useCheckAdminAccess();

  React.useEffect(() => {
    navContext.setHeadline(`${t('settings')} / ${t('clients')}`);
  }, [navContext, t]);

  return (
    <>
      <ClientRegisterSettings />
      <ClientGroups />
    </>
  );
}
