import { MailTemplate } from '../Types/CustomerTypes';
import { MailTemplateType } from '../Types/GeneralTypes';

const placeholderMappings = {
  [MailTemplateType.clientRegistration]: {
    name: 'name',
    registrationUrl: 'registrationUrl',
  },
};

/**
 * mailTemplatePrepare()
 * @param template
 * @param values
 */
export const mailTemplatePrepare = (template: MailTemplate, values: any): MailTemplate => {
  let replacedHtml = template.html;
  let replacedText = template.text;
  const mapping: any = placeholderMappings[template.type];

  Object.keys(mapping).forEach((key) => {
    replacedHtml = replacedHtml.replaceAll(`{{${key}}}`, values[mapping[key]]);
    replacedText = replacedText.replaceAll(`{{${key}}}`, values[mapping[key]]);
  });

  return { ...template, html: replacedHtml, text: replacedText };
};
