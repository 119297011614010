import React from 'react';
import { useCopyClipboard } from '../../Library/Hooks/GlobalHooks';
import { generateRandomString } from '../../Library/Functions/HelperFunctions';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  value: string | React.ReactElement;
  showOnEmptyValue: boolean;
  showCopyIcon: boolean;
  actionButton?: React.ReactElement;
}

/**
 * DisplayRow()
 * @param props
 * @constructor
 */
export default function DisplayRow(props: Props) {
  const { label, value, showOnEmptyValue, showCopyIcon, actionButton } = props;
  const { t } = useTranslation();
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();
  const [tooltipClass] = React.useState<string>(generateRandomString(20).toLowerCase());

  const renderTooltip = () => {
    return (
      <Tooltip anchorSelect={`.${tooltipClass}`} place={'bottom'} style={{ borderRadius: 6 }}>
        {t('copyToClipboard')}
      </Tooltip>
    );
  };

  const renderCopyIcon = () => {
    if (showCopyIcon) {
      return (
        <i
          className={`far fa-copy ml-6 text-gray-200 hover:text-black cursor-pointer ${tooltipClass}`}
          onClick={() => copyToClipBoard(value as string)}
        />
      );
    }
    return null;
  };

  const renderActionButton = () => {
    if (actionButton) {
      return actionButton;
    }
    return null;
  };

  if (value || showOnEmptyValue) {
    return (
      <>
        <div className={'flex flex-row hover:bg-gray-100 rounded-md p-2 justify-center'}>
          <div className={'font-light text-gray-500 w-1/3'}>{label}</div>
          <div className={'flex flex-row items-center flex-1'}>
            <div>{value}</div>
          </div>
          <div className={'pr-2'}>
            {renderActionButton()}
            {renderCopyIcon()}
          </div>
        </div>
        {clipboardCallout}
        {renderTooltip()}
      </>
    );
  }
  return null;
}

DisplayRow.defaultProps = {
  showOnEmptyValue: false,
  showCopyIcon: false,
};
