import { TextContent } from 'pdfjs-dist/types/src/display/api';
import { textsPrepare } from './TextFunctions';

/**
 * pdfPrepareTexts()
 * @param texts
 */
export const pdfPrepareTexts = (texts: TextContent): string[] => {
  const preparedTexts: string[] = texts.items.map((text: any) => text.str);
  return textsPrepare(preparedTexts, 50);
};
