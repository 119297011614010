import React from 'react';
import { Client } from '../../../Library/Types/ClientTypes';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';
import { User } from '../../../Library/Types/UserTypes';
import { useDispatchClientGetUsers } from '../../../Redux/Actions/ClientActions';
import DisplayRow from '../../Display/DisplayRow';
import { useCustomerBuildClientRegistrationUrl, useCustomerUseHasAccess } from '../../../Library/Hooks/CustomerHooks';
import { useCopyClipboard } from '../../../Library/Hooks/GlobalHooks';
import ConfirmModal from '../../Modals/ConfirmModal';
import ShowHideContainer from '../../ShowHideContainer';
import { useDispatchClientSendRegistrationMail } from '../../../Redux/Actions/Client/MailTemplateActions';
import { useDispatchCustomerMailTemplateGet } from '../../../Redux/Actions/Customer/MailTemplateActions';
import { MailTemplateType } from '../../../Library/Types/GeneralTypes';
import AlertModal from '../../Modals/AlertModal';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ActionButton from '../../Container/ActionButton';
import { Tooltip } from 'react-tooltip';
import { useSuccessCallout } from '../../../Library/Hooks/CalloutHooks';

interface Props {
  client: Client;
}

/**
 * ConnectedAccounts()
 * @param props
 * @constructor
 */
export default function ConnectedAccounts(props: Props) {
  const { client } = props;
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [clientUsers, setClientUsers] = React.useState<User[]>();
  const clientRegisterUrl = useCustomerBuildClientRegistrationUrl();
  const { clipboardCallout, copyToClipBoard } = useCopyClipboard();
  const [askSendMail, setAskSendMail] = React.useState<boolean>(false);
  const [showNoTemplate, setShowNoTemplate] = React.useState<boolean>(false);
  const { callout: successCallout, showCallout } = useSuccessCallout();
  const [loading, setLoading] = React.useState<boolean>(false);
  const hasAccess = useCustomerUseHasAccess('mandant.sendMail');

  const dispatchGetUsers = useDispatchClientGetUsers();
  const dispatchSendMail = useDispatchClientSendRegistrationMail();
  const dispatchGetMailTemplate = useDispatchCustomerMailTemplateGet();

  const handleSendMail = React.useCallback(() => {
    setAskSendMail(false);
    setLoading(true);
    dispatchGetMailTemplate(MailTemplateType.clientRegistration)
      .then((template) => {
        return dispatchSendMail(template, client).then(() => {
          showCallout(t('modules.client.registrationMailSuccessfullySend'));
          setLoading(false);
        });
      })
      .catch(() => {
        setShowNoTemplate(true);
        setLoading(false);
      });
  }, [client, dispatchGetMailTemplate, dispatchSendMail, showCallout, t]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (client && client.isConnected && !loading && !clientUsers && user.isCustomerAdmin) {
      timer = setTimeout(() => {
        setLoading(true);
        dispatchGetUsers(client.clientId)
          .then((users) => {
            setClientUsers(users);
            return Promise.resolve();
          })
          .finally(() => setLoading(false));
      }, 200);
    }

    return () => clearTimeout(timer);
  }, [client, clientUsers, dispatchGetUsers, loading, user.isCustomerAdmin]);

  const renderConnectedUsers = () => {
    if (clientUsers && clientUsers.length > 0) {
      return clientUsers.map((user) => (
        <DisplayRow
          key={user.userId}
          label={t('user')}
          value={`${user.firstName} ${user.lastName} (${user.mail})`}
          actionButton={
            <>
              <i
                className={`far fa-envelope text-gray-200 hover:text-gray-800 cursor-pointer ${user.userId}`}
                onClick={() => (window.location.href = `mailto:${user.mail}`)}
              />
              <Tooltip anchorSelect={`.${user.userId}`}>{t('sendMail')}</Tooltip>
            </>
          }
        />
      ));
    }

    return (
      <div className={'pb-2 px-4 text-justify text-gray-500 flex flex-row justify-center items-center'}>
        <div>
          <i className={'far fa-users-slash text-7xl mr-12 text-gray-300'} />
        </div>
        <div>{t('modules.client.noConnectedAccounts')}</div>
      </div>
    );
  };

  if (!client.registrationActive && !client.isConnected) {
    return null;
  }

  if (client && hasAccess) {
    return (
      <Container
        headline={t('connectedUsers')}
        closable
        identifier={'MandantDetailsConnectedAccounts'}
        initialOpen={false}
        showLoading={loading}
        actionButtons={
          <ActionButton
            icon={'far fa-envelope-circle-check'}
            onPress={() => setAskSendMail(true)}
            className={'hover:bg-green-800 hover:text-white'}
            visible={!!client.mail && client.registrationActive && hasAccess}
            tooltip={t('sendClientRegistrationMail')}
          />
        }
      >
        {renderConnectedUsers()}

        <ShowHideContainer visible={client.isActive}>
          <div className={'flex flex-col mt-6'}>
            <div className={'ml-1'}>{t('registrationUrl')}</div>
            <div className={'flex flex-row flex-grow items-center ring-1 ring-gray-300 rounded-md p-2 mt-2'}>
              <div className={'ml-2 text-gray-600 flex-grow'}>{clientRegisterUrl(client.clientId)}</div>
              <i
                className={'fas fa-copy pr-3 pl-4 text-xl text-gray-300 hover:text-black cursor-pointer'}
                onClick={() => copyToClipBoard(clientRegisterUrl(client.clientId))}
              />
            </div>
          </div>
        </ShowHideContainer>

        {clipboardCallout}
        {successCallout}

        <ConfirmModal
          headline={t('alert.askSendClientRegistrationMail.headline')}
          text={t('alert.askSendClientRegistrationMail.text', { mail: client.mail })}
          visible={askSendMail}
          onClose={() => setAskSendMail(false)}
          onConfirm={handleSendMail}
          type={'success'}
        />

        <AlertModal
          headline={t('alert.alertNoClientRegistrationTemplate.headline')}
          text={t('alert.alertNoClientRegistrationTemplate.text')}
          visible={showNoTemplate}
          onClose={() => setShowNoTemplate(false)}
          type={'error'}
        />
      </Container>
    );
  }
  return null;
}
