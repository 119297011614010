import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const Container = styled.table<{ $isClickable: boolean; $verticalBorder?: boolean }>`
  width: 100%;
  ${(props) => props.theme.templates.borderRadius};

  td,
  th {
    padding: 14px;
    ${(props) => (props.$verticalBorder ? 'border-right: 1px dashed #b6b6b6' : null)};

    &:last-child {
      border-right: none;
    }
  }

  th {
    background-color: #f1f1f1;

    &:last-child {
      border-top-right-radius: ${(props) => props.theme.borderRadius};
    }
    &:first-child {
      border-top-left-radius: ${(props) => props.theme.borderRadius};
    }
  }

  tbody {
    tr {
      ${(props) => props.theme.templates.borderBottom};
      cursor: ${(props) => (props.$isClickable ? 'pointer' : 'normal')};

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: ${(props) => lighten(0.12, props.theme.borderColor)};
      }
    }
  }

  thead {
    tr {
      ${(props) => props.theme.templates.borderBottom};
      text-align: left;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  showVerticalBorder?: boolean;
}

/**
 * Table()
 * @param props
 * @constructor
 */
export default function Table(props: Props) {
  const { children, showVerticalBorder } = props;

  return (
    <Container $isClickable={true} $verticalBorder={showVerticalBorder}>
      {children}
    </Container>
  );
}
