import { DocumentState, DocumentType, InvoiceType } from '../../../Library/Types/DocumentTypes';

export interface FilterState {
  incoming: boolean;
  outgoing: boolean;
  bank: boolean;
  ownerOnly: boolean;
  openOnly: boolean;
}

export type DocumentFilterState = {
  documents: DocumentType[];
  userAssignedCLinetIds: string[];
  filter: FilterState;
  count: {
    incoming: number;
    outgoing: number;
    bank: number;
    ownerOnly: number;
    openOnly: number;
  };
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'init'; payload: FilterState | null }
  | { type: 'initDocuments'; payload: { documents: DocumentType[]; userAssignedClientIds: string[] } }
  | { type: 'incoming'; payload: boolean }
  | { type: 'outgoing'; payload: boolean }
  | { type: 'bank'; payload: boolean }
  | { type: 'ownerOnly'; payload: boolean }
  | { type: 'openOnly'; payload: boolean }
  | { type: 'resetChanged' };

export const documentFilterInitialState: DocumentFilterState = {
  documents: [],
  userAssignedCLinetIds: [],
  filter: {
    incoming: true,
    outgoing: true,
    bank: true,
    ownerOnly: false,
    openOnly: false,
  },
  count: {
    incoming: 0,
    outgoing: 0,
    bank: 0,
    ownerOnly: 0,
    openOnly: 0,
  },
  hasChanges: false,
  initialized: false,
};

/**
 * calculateCount()
 * @param filterState
 * @param documents
 * @param userAssignedClientIds
 */
const calculateCount = (
  filterState: FilterState,
  documents: DocumentType[],
  userAssignedClientIds: string[],
): {
  incoming: number;
  outgoing: number;
  bank: number;
  ownerOnly: number;
  openOnly: number;
} => {
  const countIncoming = documents.filter(
    (document) =>
      document.type === InvoiceType.incoming &&
      (filterState.ownerOnly ? userAssignedClientIds.indexOf(document.clientId as string) > -1 : true),
  ).length;
  const countOutgoing = documents.filter(
    (document) =>
      document.type === InvoiceType.outgoing &&
      (filterState.ownerOnly ? userAssignedClientIds.indexOf(document.clientId as string) > -1 : true),
  ).length;
  const countBank = documents.filter(
    (document) =>
      document.type === InvoiceType.bank &&
      (filterState.ownerOnly ? userAssignedClientIds.indexOf(document.clientId as string) > -1 : true),
  ).length;
  const countOwn = documents.filter(
    (document) => userAssignedClientIds.indexOf(document.clientId as string) > -1,
  ).length;
  const countOpen = documents.filter(
    (document) =>
      document.state === DocumentState.open &&
      (filterState.ownerOnly ? userAssignedClientIds.indexOf(document.clientId as string) > -1 : true),
  ).length;

  return {
    bank: countBank,
    incoming: countIncoming,
    outgoing: countOutgoing,
    openOnly: countOpen,
    ownerOnly: countOwn,
  };
};

/**
 * documentFilterReducer()
 * @param state
 * @param action
 */
export const documentFilterReducer = (state: DocumentFilterState, action: Action): DocumentFilterState => {
  switch (action.type) {
    case 'incoming': {
      const filter: FilterState = { ...state.filter, incoming: action.payload };
      return {
        ...state,
        filter,
        count: calculateCount(filter, state.documents, state.userAssignedCLinetIds),
        hasChanges: true,
      };
    }
    case 'outgoing': {
      const filter: FilterState = { ...state.filter, outgoing: action.payload };
      return {
        ...state,
        filter,
        count: calculateCount(filter, state.documents, state.userAssignedCLinetIds),
        hasChanges: true,
      };
    }
    case 'bank': {
      const filter: FilterState = { ...state.filter, bank: action.payload };
      return {
        ...state,
        filter,
        count: calculateCount(filter, state.documents, state.userAssignedCLinetIds),
        hasChanges: true,
      };
    }
    case 'ownerOnly': {
      const filter: FilterState = { ...state.filter, ownerOnly: action.payload };
      return {
        ...state,
        filter,
        count: calculateCount(filter, state.documents, state.userAssignedCLinetIds),
        hasChanges: true,
      };
    }
    case 'openOnly': {
      const filter: FilterState = { ...state.filter, openOnly: action.payload };
      return {
        ...state,
        filter,
        count: calculateCount(filter, state.documents, state.userAssignedCLinetIds),
        hasChanges: true,
      };
    }
    case 'initDocuments':
      return {
        ...state,
        documents: action.payload.documents,
        userAssignedCLinetIds: action.payload.userAssignedClientIds,
        count: calculateCount(state.filter, action.payload.documents, action.payload.userAssignedClientIds),
        hasChanges: true,
      };
    case 'init':
      return {
        ...state,
        filter: action.payload || documentFilterInitialState.filter,
        initialized: true,
        hasChanges: false,
      };
    case 'resetChanged':
      return { ...state, hasChanges: false };
    default:
      throw new Error('Invalid type!');
  }
};
