import classNames from 'classnames';
import Item from './Item';
import { Storage } from '../../../Library/Types/StorageTypes';
import React from 'react';
import { formatSize } from '../../../Library/Functions/HelperFunctions';

interface Props {
  folder: string;
  onChange: (folder: string) => void;
  documents: Storage[];
}

/**
 * FolderHeader()
 * @param props
 * @constructor
 */
export function FolderHeader(props: Props) {
  const { folder, onChange, documents } = props;
  const [size, setSize] = React.useState<number>(0);

  React.useEffect(() => {
    if (documents) {
      let calc = 0;
      documents.forEach((item) => (calc += item.size));
      setSize(calc);
    } else {
      setSize(0);
    }
  }, [documents]);

  const renderFolders = () => {
    const split = [...folder.split('/').splice(1)];
    let folderPath = '/';

    const data = split
      .filter((item) => !!item)
      .map((item, index) => {
        folderPath = (folderPath + '/' + item).replaceAll('//', '/');
        const path = folderPath.repeat(1);

        return <Item path={path} name={item} onPress={onChange} showSeparator={true} key={path} />;
      });

    const root = <Item path={'/'} name={'SotoDocs'} onPress={() => onChange('/')} showSeparator={false} key={'/'} />;

    return [root, ...data];
  };

  return (
    <div className={classNames('flex flex-row items-center mb-8')}>
      <i className="far fa-cloud text-2xl text-purple-700 mr-4" style={{ fontSize: 50 }} />
      <div>
        <div className={classNames('flex flex-row text-2xl font-light')}>{renderFolders()}</div>
        <div className={classNames('flex flex-row font-light text-gray-500 text-sm')}>
          {`${documents.length} Objekte, ${formatSize(size)}`}
        </div>
      </div>
    </div>
  );
}
