import React from 'react';
import { DocumentMessage, DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import AbstractModal from '../../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../../Forms/Button';
import { useDispatchDocumentUpdate } from '../../../Redux/Actions/DocumentActions';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import moment from 'moment';
import Input, { InputType } from '../../Forms/Input';

interface Props {
  document: DocumentType;
  visible: boolean;
  onClose: () => void;
}

const MESSAGE_MAX_LENGTH = 1400;

/**
 * AddMessageModal()
 * @param props
 * @constructor
 */
export default function AddMessageModal(props: Props) {
  const { document, visible, onClose } = props;
  const { user, customer } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [message, setMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [internal, setInternal] = React.useState<boolean>(false);
  const dispatchUpdateDocument = useDispatchDocumentUpdate();

  const handleAdd = React.useCallback(() => {
    if (message) {
      setLoading(true);
      const messages: DocumentMessage[] = [
        ...(document.messages || []),
        {
          message,
          isCustomerMessage: user.isCustomerUser,
          isRead: false,
          createdUserId: user.userId,
          createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          createdDisplayName: user.isCustomerUser
            ? customer.displayName || customer.name
            : `${user.lastName}, ${user.firstName}`,
          isInternal: internal,
        },
      ];

      // If mandant answers always set document state back to open, otherwise leave state as before
      const newState = !user.isCustomerUser ? DocumentState.open : document.state;

      dispatchUpdateDocument({ ...document, messages, state: newState })
        .then(() => {
          onClose();
          return Promise.resolve();
        })
        .finally(() => setLoading(false));
    }
  }, [
    customer.displayName,
    customer.name,
    dispatchUpdateDocument,
    document,
    internal,
    message,
    onClose,
    user.firstName,
    user.isCustomerUser,
    user.lastName,
    user.userId,
  ]);

  React.useEffect(() => {
    if (!visible) {
      setMessage('');
    }
  }, [visible]);

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'} disabled={loading}>
        {t('cancel')}
      </Button>
      <Button onPress={handleAdd} colorStyle={'success'} disabled={!message || loading}>
        <div className={'flex justify-center items-center'}>
          {loading ? <i className={'fas fa-spin fa-spinner mr-2'} /> : null}
          {t('save')}
        </div>
      </Button>
    </div>
  );

  const renderInternal = () => {
    if (user.isCustomerUser) {
      return (
        <div className={'flex items-center mt-4 cursor-pointer'} onClick={() => setInternal(!internal)}>
          <input
            className={'mr-2 w-4 h-4 bg-gray-100 rounded'}
            type={'checkbox'}
            value={''}
            checked={internal}
            onChange={(e) => setInternal(e.target.checked)}
          />
          {t('messageInternalOnly', { name: customer.displayName || customer.name })}
        </div>
      );
    }
    return null;
  };

  return (
    <AbstractModal
      visible={visible}
      type={'success'}
      headline={t('messageCreate')}
      buttons={renderButtons()}
      onBackdrop={onClose}
      icon={'far fa-message-captions'}
    >
      <div className={'flex flex-1 mt-6 flex-col'}>
        <Input
          onChange={setMessage}
          label={t('messageCreate')}
          maxLength={MESSAGE_MAX_LENGTH}
          type={InputType.textarea}
          rows={10}
          disabled={loading}
          autoFocus
          value={message}
        />
        {renderInternal()}
      </div>
    </AbstractModal>
  );
}
