import React from 'react';
import styled from 'styled-components';
import Input, { InputType } from '../Components/Forms/Input';
import { useTranslation } from 'react-i18next';
import Button from '../Components/Forms/Button';
import Headline from '../Components/Headline';
import { useDispatchAuth, useDispatchAutoLogin } from '../Redux/Actions/AuthActions';
import { Navigate, useNavigate } from 'react-router';
import { useAppSelector } from '../Library/Hooks/ReduxHooks';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AuthenticationError from '../Components/AuthenticationError';
import { LOCALSTORAGE_PATH_CUSTOMER_LOGO } from '../Library/Types/GeneralTypes';
import ShowHideContainer from '../Components/ShowHideContainer';
import classNames from 'classnames';
import RegisterAndLoginFooter from '../Components/RegisterAndLoginFooter';
import { STORAGE_ADD_NEW_CLIENT } from '../Library/Types/Constants';
import Index from '../Components/Website/Navigation';
import { isMobile } from 'react-device-detect';
import UseAppHint from '../Components/Layout/UseAppHint';
import SimpleCheckBox from '../Components/Forms/SimpeCheckBox';
const sotoLogo = require('../Assets/Images/icon2.png');

const Box = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.templates.borderRadius};
  ${(props) => props.theme.templates.border};
`;

const LeftContainer = styled.div`
  width: 300px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 25px;
  background-color: #d1d5db;

  @media (max-width: 768px) {
    width: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1.5;
  padding: 30px 40px;
`;

/**
 * Login()
 * @constructor
 */
export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mail, setMail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [authError, setAuthError] = React.useState<string | null>(null);
  const [logoPath, setLogoPath] = React.useState<string | null>(null);
  const [autoLoginActive, setAutoLoginActive] = React.useState<boolean>(false);
  const [newClientId, setNewClientId] = React.useState<string>();
  const [stayLoggedIn, setStayLoggedIn] = React.useState<boolean>(false);

  const dispatchAuth = useDispatchAuth();
  const dispatchAuthAuto = useDispatchAutoLogin();

  const handleAuthStateChange = React.useCallback(
    (state: any) => {
      if (state && !loading) {
        setLoading(true);
        dispatchAuthAuto(state.uid)
          .then(() => {
            navigate('/dashboard');
          })
          .finally(() => setLoading(false));
      }
    },
    [dispatchAuthAuto, loading, navigate],
  );

  React.useEffect(() => {
    setTimeout(() => setAutoLoginActive(true), 250);
  }, []);

  React.useEffect(() => {
    const value = localStorage.getItem(STORAGE_ADD_NEW_CLIENT);
    if (value && !newClientId) {
      setNewClientId(value);
      localStorage.removeItem(STORAGE_ADD_NEW_CLIENT);
    }
  }, [newClientId]);

  React.useEffect(() => {
    const path = localStorage.getItem(LOCALSTORAGE_PATH_CUSTOMER_LOGO);

    if (path) {
      const request = new XMLHttpRequest();
      request.open('GET', path);
      request.send();
      request.onprogress = (event: any) => {
        if (event.target && event.target.status === 200) {
          setLogoPath(path);
        } else {
          setLogoPath(sotoLogo);
          localStorage.removeItem(LOCALSTORAGE_PATH_CUSTOMER_LOGO);
        }
      };
    } else {
      setLogoPath(sotoLogo);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  const handleAuth = React.useCallback(async () => {
    setLoading(true);

    try {
      await dispatchAuth(mail, password, stayLoggedIn, newClientId);
      navigate('/dashboard');
    } catch (error: any) {
      setAuthError(error.code);
      setLoading(false);
    }
  }, [dispatchAuth, mail, navigate, newClientId, password, stayLoggedIn]);

  if (!isLoggedIn) {
    if (!isMobile) {
      return (
        <div className={classNames('flex flex-1 flex-col')}>
          <Index showPrice={false} showStartPage={true} showRegister={true} showLogin={false} />

          <div className={'flex flex-1 flex-row justify-center'}>
            <div className={'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-6/12 p-4'}>
              <Box className={'shadow-2xl bg-white mt-10'}>
                <LeftContainer className={'hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex'}>
                  <ShowHideContainer visible={!!logoPath}>
                    <img src={logoPath as string} alt="logo" style={{ maxHeight: 300 }} />
                  </ShowHideContainer>
                </LeftContainer>
                <RightContainer>
                  <Headline size={'large'} className={'mb-4'}>
                    {t('login')}
                  </Headline>

                  <AuthenticationError errorCode={authError} />

                  <Input
                    onChange={setMail}
                    value={mail}
                    label={t('eMailAddress')}
                    disabled={loading}
                    autoFocus={autoLoginActive}
                    type={InputType.email}
                    onEnter={handleAuth}
                  />
                  <Input
                    onChange={setPassword}
                    value={password}
                    label={t('password')}
                    type={InputType.password}
                    disabled={loading}
                    onEnter={handleAuth}
                  />

                  <div>
                    <SimpleCheckBox caption={t('stayLoggedIn')} checked={stayLoggedIn} onChange={setStayLoggedIn} />
                  </div>

                  <div className={'flex justify-end'}>
                    <div
                      className={'text-gray-400 text-sm hover:text-blue-700 hover:underline cursor-pointer'}
                      onClick={() => navigate('/login/password')}
                    >
                      {t('passwordForgotten')}
                    </div>
                  </div>

                  <div className={'flex flex-1 mt-8'}>
                    <Button
                      className={'flex flex-1 justify-center h-12 font-semibold'}
                      onPress={handleAuth}
                      colorStyle={'primary'}
                      disabled={loading || !mail || !password || password.length < 6}
                    >
                      {loading ? <i className={'fa fa-spin fa-spinner'} /> : t('next')}
                    </Button>
                  </div>
                </RightContainer>
              </Box>

              <div className={'flex flex-row mt-12 text-sm text-gray-500'}>
                <div>{t('noAccountQuestion')}</div>
                <div
                  className={classNames('cursor-pointer text-blue-700 hover:underline', 'ml-2')}
                  onClick={() => navigate('/register')}
                >
                  {t('createNow')}
                </div>
              </div>
              <div className={'mt-8 text-sm text-gray-500'}>{t('modules.login.footerText')}</div>

              <RegisterAndLoginFooter />
            </div>
          </div>
        </div>
      );
    }
    return <UseAppHint />;
  }
  return <Navigate to={'/dashboard'} replace />;
}
