import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const login = require('../../Assets/Images/app_login.png');
const apple = require('../../Assets/Images/app_store.png');
const google = require('../../Assets/Images/play_store.png');

/**
 * AppTeaser()
 * @constructor
 */
export default function AppTeaser() {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'bg-orange-400 flex flex-1 flex-col p-10 items-center justify-center text-center',
        'lg:flex-row lg:items-start',
      )}
    >
      <div className={classNames('flex flex-1 justify-end', 'lg:mr-32')}>
        <img src={login} alt={'App Login'} className={'max-w-72 lg:max-w-96'} />
      </div>
      <div className={classNames('basis-1/2 mt-10 text-center', 'lg:text-left lg:pr-32')}>
        <div className={classNames('text-6xl font-bold mb-2 text-white')}>
          {t('modules.website.appTeaser.headline')}
        </div>
        <div className={classNames('text-4xl text-white font-light mb-6')}>{t('modules.website.appTeaser.text')}</div>
        <div className={classNames('text-2xl text-white')}>{t('modules.website.appTeaser.description')}</div>

        <div className={'flex flex-col items-center mt-8 lg:flex-row'}>
          <a href={'https://apps.apple.com/de/app/sotodocs/id6503434976'} target={'_blank'} rel="noreferrer">
            <img src={apple} alt="Apple" className={'w-48 lg:mr-3 mb-4'} />
          </a>
          <a href={'https://play.google.com/store/apps/details?id=com.sotodocs'} target={'_blank'} rel="noreferrer">
            <img src={google} alt="Google" className={'w-48 lg:ml-3 mb-4'} />
          </a>
        </div>
      </div>
    </div>
  );
}
