import { useAppDispatch } from '../../../Library/Hooks/ReduxHooks';
import React from 'react';
import {
  CLIENT_DOCUMENTS_GETLIST_ERROR,
  CLIENT_DOCUMENTS_GETLIST_START,
  CLIENT_DOCUMENTS_GETLIST_SUCCESS,
} from '../../ActionTypes';
import { collection, CollectionReference, getDocs, getFirestore, query, where, orderBy } from 'firebase/firestore';
import { FirebasePath } from '../../../Library/Firebase';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import moment from 'moment';

/**
 * useDispatchDocumentGetListByClientIds()
 */
export const useDispatchClientDocumentGetList = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (clientId: string, startDate: string, endDate: string) => {
      dispatch(CLIENT_DOCUMENTS_GETLIST_START(clientId));

      const collectionRef = collection(
        getFirestore(),
        FirebasePath.clients,
        clientId,
        FirebasePath.documents,
      ) as CollectionReference<DocumentType>;

      const queryRef = query(
        collectionRef,
        where('firebaseInvoiceDate', '>=', moment(startDate).toDate()),
        where('firebaseInvoiceDate', '<=', moment(endDate).toDate()),
        orderBy('firebaseInvoiceDate'),
      );

      return getDocs(queryRef)
        .then((snapShot) => {
          const documents: DocumentType[] = [];
          if (!snapShot.empty) {
            snapShot.forEach((item) => {
              documents.push({ ...item.data(), documentId: item.id });
            });
          }

          dispatch(CLIENT_DOCUMENTS_GETLIST_SUCCESS(documents));
          return Promise.resolve(documents);
        })
        .catch((error) => {
          dispatch(CLIENT_DOCUMENTS_GETLIST_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};
