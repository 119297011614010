import React from 'react';
import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { useTranslation } from 'react-i18next';
import { User } from '../../Library/Types/UserTypes';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { FirebasePath } from '../../Library/Firebase';
import { USER_UPDATE_SUCCESS } from '../../Redux/ActionTypes';

interface Props {}

/**
 * ()
 * @param props
 * @constructor
 */
export default function (props: Props) {
  const { t } = useTranslation();
  const { user, users } = useAppSelector((state) => state.auth);
  const [adminUser, setAdminUser] = React.useState<User>();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const docRef = doc(getFirestore(), FirebasePath.users, user.userId);
    const unsubscribe = onSnapshot(docRef, (snapShot) => {
      if (snapShot.exists()) {
        const user: User = { ...(snapShot.data() as User), userId: snapShot.id };
        if (user.isActive) {
          dispatch(USER_UPDATE_SUCCESS(user));
        }
      }
    });

    return () => unsubscribe();
  }, [dispatch, user.userId]);

  React.useEffect(() => {
    if (users) {
      const found = users.find((item) => item.isCustomerUser && item.isCustomerUser);
      if (found) {
        setAdminUser(found);
      }
    }
  }, [users]);

  if (!user.isActive) {
    return (
      <div
        className={
          'flex flex-1 flex-col bg-red-500 rounded mb-4 px-4 py-5 text-white border-2 border-red-700 text-center'
        }
      >
        <div className={'font-bold text-2xl'}>{t('userInactiveHeadline')}</div>
        <div>{t('userInactiveHint', { name: adminUser ? `${adminUser.firstName} ${adminUser.lastName}` : '' })}</div>
      </div>
    );
  }
  return null;
}
