import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Container from '../Container/Container';
import classNames from 'classnames';
import ShowHideContainer from '../ShowHideContainer';

interface Props {
  visible: boolean;
  isImage: boolean;
}

/**
 * ProcessingAi()
 * @param props
 * @constructor
 */
export default function ProcessingAi(props: Props) {
  const { visible, isImage } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [color, setColor] = React.useState<string>(theme.colors.purple);

  React.useEffect(() => {
    const interval = setInterval(() => {
      switch (color) {
        case theme.colors.purple:
          setColor(theme.colors.orange);
          break;
        case theme.colors.orange:
          setColor(theme.colors.green);
          break;
        case theme.colors.green:
          setColor(theme.colors.blue);
          break;
        case theme.colors.blue:
          setColor(theme.colors.red);
          break;
        default:
          setColor(theme.colors.purple);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [color, theme.colors.blue, theme.colors.green, theme.colors.orange, theme.colors.purple, theme.colors.red]);

  if (visible) {
    return (
      <Container noPadding>
        <div className={classNames('p-6 items-center flex')}>
          <i className={classNames('fa-solid fa-microchip-ai mr-4 text-3xl')} style={{ color: color }} />
          <div>
            <div className={'text-2xl'} style={{ color: color }}>
              {t('processingAi')}
            </div>
            <ShowHideContainer visible={isImage}>
              <div className={'text text-gray-700 font-light'}>{t('processingAiImageHint')}</div>
            </ShowHideContainer>
          </div>
        </div>
      </Container>
    );
  }
  return null;
}
