import React from 'react';
import { useAppSelector } from '../../../../Library/Hooks/ReduxHooks';
import AccountContainer from './AccountContainer';

/**
 * BankAccounts()
 * @constructor
 */
export default function BankAccounts() {
  const { user, clients } = useAppSelector((state) => state.auth);

  if (!user.isCustomerUser) {
    return (
      <>
        {clients.map((client) => (
          <AccountContainer client={client} key={client.clientId} />
        ))}
      </>
    );
  }
  return null;
}
