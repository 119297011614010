import React from 'react';
import classNames from 'classnames';
import { StateMapping, useDocumentStatusMappings } from '../../../Library/Hooks/DocumentHooks';
import { DocumentState } from '../../../Library/Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

interface Props {
  state: DocumentState;
  onPress: (state: DocumentState) => void;
  visible: boolean;
}

/**
 * Button()
 * @param props
 * @constructor
 */
export default function Button(props: Props) {
  const { state, onPress, visible } = props;
  const { t } = useTranslation();
  const [mapping, setMapping] = React.useState<StateMapping>();
  const mappings = useDocumentStatusMappings();

  React.useEffect(() => {
    setMapping(mappings.find((item) => item.state === state));
  }, [mappings, state]);

  if (mapping && visible) {
    return (
      <>
        <Tooltip place={'top-start'} anchorSelect={`.${state}`}>
          {t(`modules.documents.statusTextAction.${state}`)}
        </Tooltip>
        <div
          className={classNames(
            'px-4 py-4',
            'rounded',
            'flex',
            'items-center',
            'justify-center',
            'cursor-pointer',
            mapping.bgColorClass,
            'text-white',
            'mr-3',
            'last:mr-0',
            'px-5',
            state,
          )}
          onClick={() => onPress(state)}
        >
          <i className={classNames(mapping.icon)} />
        </div>
      </>
    );
  }
  return null;
}

Button.defaultProps = {
  visible: true,
};
