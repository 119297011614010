import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import React from 'react';
import { Client } from '../../Library/Types/ClientTypes';
import { generateDocumentIdentifier, splitFileToNameAndExtension } from '../../Library/Functions/HelperFunctions';
import { FirebasePath } from '../../Library/Firebase';
import { ref, uploadBytesResumable } from '@firebase/storage';
import { getDownloadURL, getStorage } from 'firebase/storage';
import {
  STORAGE_CREATE_ERROR,
  STORAGE_CREATE_START,
  STORAGE_CREATE_SUCCESS,
  STORAGE_DELETE_ERROR,
  STORAGE_DELETE_START,
  STORAGE_DELETE_SUCCESS,
  STORAGE_FOLDER_CREATE,
  STORAGE_GETLIST_ERROR,
  STORAGE_GETLIST_START,
  STORAGE_GETLIST_SUCCESS,
  STORAGE_UPDATE_ERROR,
  STORAGE_UPDATE_START,
  STORAGE_UPDATE_SUCCESS,
  STORAGE_UPLOAD_ERROR,
  STORAGE_UPLOAD_START,
  STORAGE_UPLOAD_SUCCESS,
} from '../ActionTypes';
import { Storage } from '../../Library/Types/StorageTypes';
import moment from 'moment';
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

/**
 * useDispatchStorageUpload()
 */
export const useDispatchStorageUpload = (): ((
  file: File,
  client: Client,
  onProgress: (progress: number) => void,
) => Promise<{ refPath: string; downloadUrl: string }>) => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (file, client, onProgress) => {
      dispatch(STORAGE_UPLOAD_START(file));
      const fileName = generateDocumentIdentifier(client.clientId);
      const refPath = `${FirebasePath.clients}/${client.clientId}/${FirebasePath.storage}/${fileName}`;

      const uploadTask = uploadBytesResumable(ref(getStorage(), refPath), file, {
        customMetadata: { originalFilename: file.name, clientId: client.clientId },
      });

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            onProgress(progress);
          },
          (error) => {
            dispatch(STORAGE_UPLOAD_ERROR(error));
            return reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(ref(getStorage(), refPath)).then((downloadUrl) => {
              dispatch(STORAGE_UPLOAD_SUCCESS({ refPath, downloadUrl }));
              return resolve({ refPath, downloadUrl });
            });
          },
        );
      });
    },
    [dispatch],
  );
};

/**
 * useDispatchStorageCreate()
 */
export const useDispatchStorageCreate = () => {
  const dispatch = useAppDispatch();
  const { activeClient, user } = useAppSelector((state) => state.auth);
  const dispatchUpload = useDispatchStorageUpload();

  return React.useCallback(
    (file: File, folder: string, callback: (progress: number) => void) => {
      dispatch(STORAGE_CREATE_START());

      if (activeClient) {
        return dispatchUpload(file, activeClient, callback).then(({ refPath, downloadUrl }) => {
          const { name, extension } = splitFileToNameAndExtension(file.name);

          const storage: Storage = {
            createdDate: moment().format('YYYY-MM-DD HH:mm:ss'),
            firebaseCreatedDate: serverTimestamp(),
            createdUserId: user.userId,
            downloadUrl,
            refPath,
            folder,
            fileType: file.type,
            size: file.size,
            name: name,
            extension,
          } as Storage;

          const collectionRef = collection(
            getFirestore(),
            FirebasePath.clients,
            activeClient.clientId,
            FirebasePath.storage,
          ) as CollectionReference<Storage>;

          return addDoc(collectionRef, storage).then((snapShot) => {
            const merged: Storage = { ...storage, storageId: snapShot.id };
            dispatch(STORAGE_CREATE_SUCCESS(merged));
            return Promise.resolve(merged);
          });
        });
      }

      dispatch(STORAGE_CREATE_ERROR(new Error('ACTIVE_CLIENT_MISSING')));
      return Promise.reject(new Error('ACTIVE_CLIENT_MISSING'));
    },
    [activeClient, dispatch, dispatchUpload, user.userId],
  );
};

/**
 * useDispatchStorageGetList()
 */
export const useDispatchStorageGetList = () => {
  const dispatch = useAppDispatch();
  const { activeClient } = useAppSelector((state) => state.auth);

  return React.useCallback(() => {
    dispatch(STORAGE_GETLIST_START());

    if (activeClient) {
      const collectionRef = collection(
        getFirestore(),
        FirebasePath.clients,
        activeClient.clientId,
        FirebasePath.storage,
      ) as CollectionReference<Storage>;

      return getDocs(collectionRef).then((snapShot) => {
        const storages: Storage[] = [];
        if (!snapShot.empty) {
          snapShot.forEach((item) => {
            storages.push({ ...item.data(), storageId: item.id });
          });
        }
        dispatch(STORAGE_GETLIST_SUCCESS(storages));
        return Promise.resolve(storages);
      });
    }

    dispatch(STORAGE_GETLIST_ERROR(new Error('ACTIVE_CLIENT_MISSING')));
    return Promise.reject(new Error('ACTIVE_CLIENT_MISSING'));
  }, [activeClient, dispatch]);
};

/**
 * useDispatchStorageUpdate()
 */
export const useDispatchStorageUpdate = () => {
  const dispatch = useAppDispatch();
  const { activeClient } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (storage: Storage) => {
      dispatch(STORAGE_UPDATE_START(storage));

      if (activeClient) {
        const documentRef = doc(
          getFirestore(),
          FirebasePath.clients,
          activeClient.clientId,
          FirebasePath.storage,
          storage.storageId,
        ) as DocumentReference<Storage>;

        return setDoc(documentRef, storage, { merge: true }).then(() => {
          dispatch(STORAGE_UPDATE_SUCCESS(storage));
          return Promise.resolve(storage);
        });
      }

      dispatch(STORAGE_UPDATE_ERROR(new Error('ACTIVE_CLIENT_MISSING')));
      return Promise.reject(new Error('ACTIVE_CLIENT_MISSING'));
    },
    [activeClient, dispatch],
  );
};

/**
 * useDispatchStorageDelete()
 */
export const useDispatchStorageDelete = () => {
  const dispatch = useAppDispatch();
  const { activeClient } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (storage: Storage) => {
      dispatch(STORAGE_DELETE_START(storage));

      if (activeClient) {
        const documentRef = doc(
          getFirestore(),
          FirebasePath.clients,
          activeClient.clientId,
          FirebasePath.storage,
          storage.storageId,
        ) as DocumentReference<Storage>;

        return deleteDoc(documentRef).then(() => {
          dispatch(STORAGE_DELETE_SUCCESS(storage));
          return Promise.resolve(storage);
        });
      }

      dispatch(STORAGE_DELETE_ERROR(new Error('ACTIVE_CLIENT_MISSING')));
      return Promise.reject(new Error('ACTIVE_CLIENT_MISSING'));
    },
    [activeClient, dispatch],
  );
};

/**
 * useDispatchStorageFolderCreate()
 */
export const useDispatchStorageFolderCreate = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (folder: string) => {
      dispatch(STORAGE_FOLDER_CREATE(folder));
    },
    [dispatch],
  );
};
