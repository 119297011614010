import React from 'react';
import { useTranslation } from 'react-i18next';
import Config from '../Library/Config';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

/**
 * RegisterAndLoginFooter()
 * @constructor
 */
export default function RegisterAndLoginFooter() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateWebsite = () => {
    window.location.href = Config.domain;
  };

  return (
    <div
      className={classNames(
        'mt-14 mb-14 flex flex-col justify-center items-center content-center text-gray-500 text-sm text-center',
        'md:flex-row',
      )}
    >
      <div className={'hover:text-gray-900 cursor-pointer hover:underline'} onClick={navigateWebsite}>
        &copy; 2024 by Sotosoft S.L
      </div>
      <div className={'mx-4 text-2xl'}>&#8727;</div>
      <div className={'hover:text-gray-900 cursor-pointer hover:underline'} onClick={() => navigate('/contact')}>
        {t('contact')}
      </div>
      <div className={'mx-4 text-2xl'}>&#8727;</div>
      <div className={'hover:text-gray-900 cursor-pointer hover:underline'} onClick={() => navigate('/security')}>
        {t('security')}
      </div>
      <div className={'mx-4 text-2xl'}>&#8727;</div>
      <div className={'hover:text-gray-900 cursor-pointer hover:underline'} onClick={() => navigate('/legal')}>
        {t('legalAdvice')}
      </div>
    </div>
  );
}
