import { Customer } from '../Types/CustomerTypes';
import { isValidateEmail, isValidateUrl, isValidPhoneNumber } from '../Functions/HelperFunctions';

export interface ValidateUpdateCustomerErrors {
  isValid: boolean;
  errors: { [K in keyof Customer]?: boolean };
}

const validateUpdateCustomer = (customer: Customer): ValidateUpdateCustomerErrors => {
  let response: ValidateUpdateCustomerErrors = {
    isValid: true,
    errors: {},
  };

  if (!customer.name || customer.name.length < 2) {
    response = { ...response, isValid: false, errors: { ...response.errors, name: true } };
  }
  if (!customer.displayName || customer.displayName.length < 2) {
    response = { ...response, isValid: false, errors: { ...response.errors, displayName: true } };
  }
  if (!customer.mail || !isValidateEmail(customer.mail)) {
    response = { ...response, isValid: false, errors: { ...response.errors, mail: true } };
  }

  if (customer.web && !isValidateUrl(customer.web)) {
    response = { ...response, isValid: false, errors: { ...response.errors, web: true } };
  }

  if (customer.phone && !isValidPhoneNumber(customer.phone)) {
    response = { ...response, isValid: false, errors: { ...response.errors, phone: true } };
  }

  return response;
};

export default validateUpdateCustomer;
