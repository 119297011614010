import { useAppSelector } from './ReduxHooks';
import React from 'react';
import Config from '../Config';
import Lodash from 'lodash';

/**
 * useCustomerBuildUserRegistrationUrl()
 */
export const useCustomerBuildUserRegistrationUrl = () => {
  const { customer } = useAppSelector((state) => state.auth);
  const [url, setUrl] = React.useState<string>('');

  React.useEffect(() => {
    if (customer) {
      setUrl(`${Config.domain}/register/user/${customer.customerId}`);
    }
  }, [customer]);

  return url;
};

/**
 * useCustomerBuildClientRegistrationUrl()
 */
export const useCustomerBuildClientRegistrationUrl = () => {
  const { customer } = useAppSelector((state) => state.auth);

  return React.useCallback(
    (clientId: string) => {
      if (customer) {
        return `${Config.domain}/register/client/${customer.customerId}/${clientId}`;
      }
      return '';
    },
    [customer],
  );
};

/**
 * useCustomerUseHasAccess()
 * @param accessTo
 */
export const useCustomerUseHasAccess = (accessTo: string): boolean => {
  const [hasAccess, setHasAccess] = React.useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (user.isCustomerAdmin) {
      setHasAccess(true);
    } else {
      if (accessTo && user.isCustomerUser) {
        if (user.customerAccess) {
          if (Lodash.has(user.customerAccess, accessTo)) {
            setHasAccess(Lodash.get(user.customerAccess, accessTo));
          } else {
            setHasAccess(false);
          }
        } else {
          setHasAccess(false);
        }
      }
    }
  }, [accessTo, user.customerAccess, user.isCustomerAdmin, user.isCustomerUser]);

  return hasAccess;
};
