import React from 'react';
import AbstractModal from '../../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../../Forms/Button';
import InputRow from '../../Forms/InputRow';
import { getAuth, updatePassword } from 'firebase/auth';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

/**
 * ChangePasswordModal()
 * @param props
 * @constructor
 */
export default function ChangePasswordModal(props: Props) {
  const { visible, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>('');
  const [repeat, setRepeat] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);

  const handleChange = React.useCallback(() => {
    setError(false);

    if (password === repeat) {
      setLoading(true);
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        updatePassword(currentUser, password)
          .then(() => {
            onSuccess();
            return Promise.resolve();
          })
          .finally(() => setLoading(false));
      }
    } else {
      setError(true);
    }
  }, [onSuccess, password, repeat]);

  React.useEffect(() => {
    if (!visible) {
      setRepeat('');
      setPassword('');
    }
  }, [visible]);

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'} disabled={loading}>
        {t('cancel')}
      </Button>
      <Button
        onPress={handleChange}
        colorStyle={'success'}
        disabled={loading || !password || !repeat || password.length < 6 || repeat.length < 6}
      >
        <div className={'flex justify-center items-center'}>
          {loading ? <i className={'fas fa-spin fa-spinner mr-2'} /> : null}
          {t('changePassword')}
        </div>
      </Button>
    </div>
  );

  return (
    <AbstractModal
      visible={visible}
      type={'success'}
      headline={t('changePassword')}
      buttons={renderButtons()}
      icon={'fas fa-key'}
    >
      <div className={'flex flex-1 flex-col'}>
        <div className={'text-gray-500 mb-8'}>{t('changePasswordDescription')}</div>

        <InputRow
          onChange={setPassword}
          value={password}
          type={'password'}
          placeholder={t('password')}
          containerClass={'-mb-0'}
          autoFocus
        />
        <InputRow
          onChange={setRepeat}
          value={repeat}
          type={'password'}
          placeholder={t('passwordRepeat')}
          containerClass={'-mb-0'}
          hasError={error}
        />
      </div>
    </AbstractModal>
  );
}
