import React from 'react';
import classNames from 'classnames';

interface Props {
  caption: string;
  active: boolean;
  onPress: () => void;
}

/**
 * FilterButton()
 * @param props
 * @constructor
 */
export default function FilterButton(props: Props) {
  const { caption, active, onPress } = props;

  return (
    <div
      className={classNames(
        'flex flex-1 justify-center items-center content-center p-3',
        { 'bg-white': !active },
        'border-t border-gray-300',
        'border-b',
        'first:border-l',
        'border-r',
        'first:rounded-l-md',
        'last:rounded-r-md',
        'cursor-pointer',
        { 'hover:bg-purple-100': !active },
        { 'bg-purple-700 text-white': active },
      )}
      onClick={onPress}
    >
      {caption}
    </div>
  );
}
