import React from 'react';
import DropDownButton, { DropDownItem } from '../../Forms/DropDownButton';
import classNames from 'classnames';
import AskDeleteModal from '../../Modals/AskDeleteModal';
import { useDispatchStorageDelete, useDispatchStorageUpdate } from '../../../Redux/Actions/StorageActions';
import { downloadBase64Pdf } from '../../../Library/Functions/HelperFunctions';
import { Storage } from '../../../Library/Types/StorageTypes';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import MoveToFolderModal from '../MoveToFolderModal';
import InformationModal from '../InformationModal';

interface Props {
  document: Storage;
  isActive: boolean;
}

/**
 * ActionButtons()
 * @param props
 * @constructor
 */
export default function ActionButtons(props: Props) {
  const { document, isActive } = props;
  const { t } = useTranslation();
  const { folders } = useAppSelector((state) => state.storage);
  const [showAskDelete, setShowAskDelete] = React.useState<boolean>(false);
  const [showMove, setShowMove] = React.useState<boolean>(false);
  const [showInformation, setShowInformation] = React.useState<boolean>(false);
  const [dropDownOptions, setDropDownOptions] = React.useState<DropDownItem[]>([]);

  const dispatchDelete = useDispatchStorageDelete();
  const dispatchUpdate = useDispatchStorageUpdate();

  const handleDelete = React.useCallback(() => {
    dispatchDelete(document);
  }, [dispatchDelete, document]);

  const handleOnMove = React.useCallback(
    (folder: string) => {
      dispatchUpdate({ ...document, folder }).then(() => {
        setShowMove(false);
      });
    },
    [dispatchUpdate, document],
  );

  React.useEffect(() => {
    let optionParams: DropDownItem[] = [
      {
        icon: 'far fa-cloud-download',
        caption: t('download'),
        onPress: () => downloadBase64Pdf(document.downloadUrl, document.name),
      },
      {
        icon: 'far fa-circle-info',
        caption: t('information'),
        onPress: () => setShowInformation(true),
        separatorBottom: !folders || folders.length <= 1,
      },
    ];

    if (folders && folders.length > 1) {
      optionParams.push({
        icon: 'far fa-folder-arrow-up',
        caption: t('moveFile'),
        onPress: () => setShowMove(true),
        separatorBottom: true,
      });
    }

    optionParams.push({
      icon: 'far fa-trash-alt',
      caption: t('delete'),
      onPress: () => setShowAskDelete(true),
      textColor: 'text-red-600',
    });

    setDropDownOptions(optionParams);
  }, [document.downloadUrl, document.fileType, document.name, folders, t]);

  return (
    <>
      <DropDownButton
        items={dropDownOptions}
        buttonClass={'border-0 !bg-transparent text-2xl text-white'}
        icon={classNames(
          'fas fa-circle-ellipsis text-white',
          { 'hover:text-gray-400': !isActive },
          { 'hover:text-purple-300': isActive },
        )}
      />
      <AskDeleteModal
        headline={t('alert.askDeleteStorageFile.headline')}
        text={t('alert.askDeleteStorageFile.text')}
        visible={showAskDelete}
        onClose={() => setShowAskDelete(false)}
        onDelete={handleDelete}
      />
      <MoveToFolderModal visible={showMove} onClose={() => setShowMove(false)} onSelect={handleOnMove} />
      <InformationModal document={document} visible={showInformation} onClose={() => setShowInformation(false)} />
    </>
  );
}
