import React from 'react';
import { secondsToMinuteString } from '../../Library/Functions/HelperFunctions';
import ShowHideContainer from '../ShowHideContainer';
import Container from '../Container/Container';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';

interface Props {
  document: DocumentType;
  onChange: (seconds: number) => void;
}

/**
 * BookingTimer()
 * @param props
 * @constructor
 */
export default function BookingTimer(props: Props) {
  const { onChange, document } = props;
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const [timer, setTimer] = React.useState<number>(0);
  const [timerStopped, setTimerStopped] = React.useState<boolean>(false);
  const intervalRef = React.useRef<NodeJS.Timer>();

  const handleChange = React.useCallback(
    (time: number) => {
      onChange(time);
    },
    [onChange],
  );

  React.useEffect(() => {
    if (!timerStopped && document.state !== DocumentState.uploaded) {
      intervalRef.current = setInterval(() => {
        setTimer(timer + 1);
        handleChange(timer + 1);
      }, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [document.state, handleChange, onChange, timer, timerStopped]);

  if (!timerStopped && document.state !== DocumentState.uploaded) {
    return (
      <Container>
        <div className={'flex flex-1 flex-row items-center -m-3'}>
          <div className={'flex-grow'}>{t('bookingTimer')}</div>
          <div>{secondsToMinuteString(timer)}</div>
          <ShowHideContainer visible={!timerStopped && user.isCustomerAdmin}>
            <i
              className={'fa fa-stop-circle text-xl ml-6 text-red-500 hover:text-red-700 cursor-pointer booking-timer'}
              onClick={() => {
                setTimerStopped(true);
                clearInterval(intervalRef.current);
                setTimer(0);
              }}
            />
            <Tooltip anchorSelect={'.booking-timer'}>{t('bookingTimerCancel')}</Tooltip>
          </ShowHideContainer>
        </div>
      </Container>
    );
  }
  return null;
}
