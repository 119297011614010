import React from 'react';
import { Endpoint, Type } from '../ApiTypes';

interface Props {
  item: Endpoint | Type;
}

/**
 * MenuItem()
 * @param props
 * @constructor
 */
export default function MenuItem(props: Props) {
  const { item } = props;

  return (
    <a href={`#${item.name}`} className={'flex hover:bg-gray-300 cursor-pointer px-4 py-3 rounded'}>
      {item.name}
    </a>
  );
}
