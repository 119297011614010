import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const aiLogo = require('../../Assets/Images/ai.png');

/**
 * AiTeaser()
 * @constructor
 */
export default function AiTeaser() {
  const { t } = useTranslation();

  return (
    <div className={classNames('bg-purple-700 flex flex-1 flex-col px-10 py-20 items-center')}>
      <img src={aiLogo} alt={'AI Logo'} className={'pb-20'} />

      <div className={classNames('text-5xl font-bold mb-2 text-center text-white')}>
        {t('modules.website.aiTeaser.headline')}
      </div>
      <div className={classNames('text-3xl text-center text-white font-light mb-10')}>
        {t('modules.website.aiTeaser.subHeadline')}
      </div>
      <div className={classNames('text-2xl text-white text-center', 'lg:px-32 2xl:px-96')}>
        {t('modules.website.aiTeaser.description')}
      </div>
    </div>
  );
}
