import { useAppDispatch } from '../../Library/Hooks/ReduxHooks';
import React from 'react';
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  AUTH_LOGIN_AUTO,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_START,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_SEND_PASSWORD_RESET_ERROR,
  AUTH_SEND_PASSWORD_RESET_START,
  AUTH_SEND_PASSWORD_RESET_SUCCESS,
} from '../ActionTypes';
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs,
  CollectionReference,
  setDoc,
  doc,
  DocumentReference,
} from 'firebase/firestore';
import { FirebasePath } from '../../Library/Firebase';
import { User } from '../../Library/Types/UserTypes';
import { useDispatchCustomerGet } from './CustomerActions';
import { Client } from '../../Library/Types/ClientTypes';

/**
 * useAddNewClient()
 */
const useAddNewClient = () => {
  return React.useCallback((user: User, newClientId: string): Promise<User> => {
    const found = user.clients.find((item) => item === newClientId);
    if (!found) {
      const userRef = doc(getFirestore(), FirebasePath.users, user.userId) as DocumentReference<User>;

      const clients = [...user.clients, newClientId];
      return setDoc(userRef, { clients }, { merge: true }).then(() => {
        const clientDocRef = doc(getFirestore(), FirebasePath.clients, newClientId) as DocumentReference<Client>;
        return setDoc(clientDocRef, { isConnected: true }, { merge: true }).then(() => {
          return Promise.resolve({ ...user, clients });
        });
      });
    }
    return new Promise((resolve) => resolve(user));
  }, []);
};

/**
 * useDispatchAuth()
 */
export const useDispatchAuth = () => {
  const dispatch = useAppDispatch();
  const dispatchGetCustomer = useDispatchCustomerGet();
  const addNewClient = useAddNewClient();

  return React.useCallback(
    (mail: string, password: string, stayLoggedIn: boolean, newClientId?: string) => {
      dispatch(AUTH_LOGIN_START({ mail, password }));

      return new Promise((resolve, reject) => {
        return setPersistence(getAuth(), stayLoggedIn ? browserLocalPersistence : browserSessionPersistence).then(
          () => {
            return signInWithEmailAndPassword(getAuth(), mail, password)
              .then((authResponse) => {
                const userCollection = collection(getFirestore(), FirebasePath.users) as CollectionReference<User>;
                const queryRef = query(userCollection, where('uuid', '==', authResponse.user.uid));

                return getDocs(queryRef).then((userDocs) => {
                  if (userDocs.docs.length === 1) {
                    let user = {
                      ...userDocs.docs[0].data(),
                      userId: userDocs.docs[0].id,
                    };

                    // If new clientId is set add it to users clients
                    let promise: Promise<User>;
                    if (newClientId && !user.isCustomerUser) {
                      promise = addNewClient(user, newClientId);
                    } else {
                      promise = new Promise((resolve) => resolve(user));
                    }

                    return promise.then((mergedUser) => {
                      if (mergedUser.customerId) {
                        return dispatchGetCustomer(mergedUser.customerId).then(() => {
                          dispatch(AUTH_LOGIN_SUCCESS({ user: mergedUser }));
                          resolve(mergedUser);
                        });
                      } else {
                        dispatch(AUTH_LOGIN_SUCCESS({ user: mergedUser }));
                        resolve(mergedUser);
                      }
                    });
                  }
                });
              })
              .catch((error) => {
                dispatch(AUTH_LOGIN_ERROR(error));
                reject(error);
              });
          },
        );
      });
    },
    [addNewClient, dispatch, dispatchGetCustomer],
  );
};

/**
 * useDispatchAutoLogin()
 */
export const useDispatchAutoLogin = () => {
  const dispatch = useAppDispatch();
  const dispatchGetCustomer = useDispatchCustomerGet();

  return React.useCallback(
    (uuid: string) => {
      const userCollection = collection(getFirestore(), FirebasePath.users) as CollectionReference<User>;
      const queryRef = query(userCollection, where('uuid', '==', uuid));

      return getDocs(queryRef).then((userDocs) => {
        if (userDocs.docs.length === 1) {
          const user = {
            ...userDocs.docs[0].data(),
            userId: userDocs.docs[0].id,
          };

          if (user.customerId) {
            return dispatchGetCustomer(user.customerId).then(() => {
              dispatch(AUTH_LOGIN_AUTO({ user }));
              return Promise.resolve(user);
            });
          } else {
            dispatch(AUTH_LOGIN_AUTO({ user }));
            return Promise.resolve(user);
          }
        }
      });
    },
    [dispatch, dispatchGetCustomer],
  );
};

/**
 * useDispatchLogOut()
 */
export const useDispatchAuthSendPasswordReset = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (mail: string) => {
      dispatch(AUTH_SEND_PASSWORD_RESET_START(mail));

      return sendPasswordResetEmail(getAuth(), mail)
        .then(() => {
          dispatch(AUTH_SEND_PASSWORD_RESET_SUCCESS(true));
          return Promise.resolve(true);
        })
        .catch((error) => {
          dispatch(AUTH_SEND_PASSWORD_RESET_ERROR(error));
          return Promise.reject(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchLogOut()
 */
export const useDispatchLogOut = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(() => {
    return signOut(getAuth()).then(() => {
      dispatch(AUTH_LOGOUT());
      return Promise.resolve(true);
    });
  }, [dispatch]);
};
