import React from 'react';
import ActionButton from '../../Container/ActionButton';
import { useTranslation } from 'react-i18next';
import { useDispatchDocumentUpload } from '../../../Redux/Actions/DocumentActions';
import classNames from 'classnames';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { Client } from '../../../Library/Types/ClientTypes';
import { useNavigate } from 'react-router';

interface Props {
  clientId: string;
}

/**
 * UploadDocumentButton()
 * @param props
 * @constructor
 */
export default function UploadDocumentButton(props: Props) {
  const { clientId } = props;
  const navigate = useNavigate();
  const { clients } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [client, setClient] = React.useState<Client>();

  const dispatchUploadFile = useDispatchDocumentUpload();

  const handleUpload = React.useCallback(
    (file: File) => {
      setLoading(true);

      if (client) {
        dispatchUploadFile(file, client, () => {}).then((response) => {
          navigate(`/documents/${response.documentId}`);
          setLoading(false);
        });
      }
    },
    [client, dispatchUploadFile, navigate],
  );

  React.useEffect(() => {
    if (clientId && !client) {
      setClient(clients.find((item) => item.clientId === clientId));
    }
  }, [client, clientId, clients]);

  return (
    <div style={{ display: 'flex' }}>
      <ActionButton
        icon={classNames({ 'fas fa-cloud-upload': !loading }, { 'fas fa-spin fa-spinner': loading })}
        onPress={() => inputRef.current?.click()}
        className={'hover:bg-blue-500 hover:text-white'}
        tooltip={t('modules.documents.uploadDocument')}
      />

      <input
        multiple={false}
        type={'file'}
        ref={inputRef}
        style={{ display: 'none' }}
        accept={'image/png,image/jpg,image/jpeg,image/bmp,application/pdf'}
        onChange={(e) => (e.target.files ? handleUpload(e.target.files[0]) : () => {})}
      />
    </div>
  );
}
