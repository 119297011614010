import AbstractModal from '../Modals/AbstractModal';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';
import React from 'react';
import Table from '../Table';
import moment from 'moment';
import { StateMapping, useDocumentStatusMappings } from '../../Library/Hooks/DocumentHooks';
import classNames from 'classnames';

interface Props {
  document: DocumentType;
  visible: boolean;
  onClose: () => void;
}

/**
 * StateHistoryModal()
 * @param props
 * @constructor
 */
export function StateHistoryModal(props: Props) {
  const { visible, document, onClose } = props;
  const { t } = useTranslation();
  const stateMappings = useDocumentStatusMappings();

  const getMapping = (state: DocumentState): StateMapping => {
    const found = stateMappings.find((item) => item.state === state);
    if (found) {
      return found;
    }
    return stateMappings[0];
  };

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'}>
        {t('close')}
      </Button>
    </div>
  );

  const renderUpload = () => {
    const mapping = getMapping(DocumentState.uploaded);

    return (
      <tr>
        <td width={60}>
          <i className={classNames(mapping.icon, mapping.textColorClass)} />
        </td>
        <td width={200}>
          {`${moment(document.createdDate).format('LL')} - ${moment(document.createdDate).format('LT')}`}
        </td>
        <td>{t(`modules.documents.statusTextHistory.${DocumentState.uploaded}`)}</td>
      </tr>
    );
  };

  const renderHistory = () => {
    if (document.stateHistory && document.stateHistory.length > 0) {
      return document.stateHistory.map((item) => {
        const mapping = getMapping(item.state);

        return (
          <tr key={item.dateTime}>
            <td width={60}>
              <i className={classNames(mapping.icon, mapping.textColorClass)} />
            </td>
            <td width={200}>{`${moment(item.dateTime).format('LL')} - ${moment(item.dateTime).format('LT')}`}</td>
            <td>{t(`modules.documents.statusTextHistory.${item.state}`)}</td>
          </tr>
        );
      });
    }
    return null;
  };

  return (
    <AbstractModal
      headline={t('changeHistory')}
      type={'success'}
      visible={visible}
      onBackdrop={onClose}
      buttons={renderButtons()}
      icon={'fa-kit fa-regular-file-clock'}
      widthClassName={'w-3/5'}
    >
      <div className={'mt-6 flex flex-1'}>
        <Table>
          <tbody>
            {renderUpload()}
            {renderHistory()}
          </tbody>
        </Table>
      </div>
    </AbstractModal>
  );
}
