import styled from 'styled-components';
import MenuItem from './MenuItem';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { DocumentState } from '../../Library/Types/DocumentTypes';
import LeftMenuHeader from './LeftMenuHeader';
import classNames from 'classnames';
import { menuWidthLarge, menuWidthSmall } from '../Layout/AuthedLayout';
import { clientBuildDisplayName } from '../../Library/Functions/ClientFunctions';
import BetaContainer from '../BetaContainer';

const Container = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  ${(props) => props.theme.templates.borderRight};

  .MenuHeader {
    text-transform: uppercase;
    font-weight: 400;
    color: #a19b9b;
    padding-left: 25px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

interface Props {
  smallMenu: boolean;
}

/**
 * LeftMenu()
 * @constructor
 */
export default function LeftMenu(props: Props) {
  const { smallMenu } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, customer, activeClient, clients } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const [openDocCount, setOpenDocCount] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(!smallMenu);
  const [width, setWidth] = React.useState<number>(300);

  React.useEffect(() => {
    setOpen(!smallMenu);
    setWidth(smallMenu ? menuWidthSmall : menuWidthLarge);
  }, [smallMenu]);

  React.useEffect(() => {
    if (documents) {
      if (user.isCustomerUser) {
        setOpenDocCount(
          documents.filter((item) => item.state === DocumentState.open || item.state === DocumentState.question).length,
        );
      } else {
        if (activeClient) {
          setOpenDocCount(documents.filter((item) => item.clientId === activeClient.clientId).length);
        }
      }
    }
  }, [activeClient, documents, user.isCustomerUser]);

  const handleNavigate = () => {
    if (smallMenu) {
      setWidth(menuWidthSmall);
      setOpen(false);
    }
  };

  const renderSettings = () => {
    if (user.isCustomerAdmin) {
      return (
        <>
          <div className={classNames('MenuHeader', { hidden: !open })}>{t('settings')}</div>
          <MenuItem
            hasAccess={user.isCustomerAdmin}
            caption={customer.name}
            icon={'far fa-building-circle-check'}
            path={'/settings/customer'}
            smallMenu={!open}
            onNavigate={handleNavigate}
          />
          <MenuItem
            hasAccess={user.isCustomerAdmin && user.isActive}
            caption={t('userAccounts')}
            icon={'far fa-users'}
            path={'/settings/users'}
            smallMenu={!open}
            onNavigate={handleNavigate}
          />
          <MenuItem
            hasAccess={user.isCustomerAdmin}
            caption={t('clients')}
            icon={'far fa-user-gear'}
            path={'/settings/registration'}
            smallMenu={!open}
            onNavigate={handleNavigate}
          />
          <MenuItem
            hasAccess={user.isCustomerAdmin}
            caption={t('settlement')}
            icon={'far fa-coin'}
            path={'/settings/subscription'}
            smallMenu={!open}
            onNavigate={handleNavigate}
          />
        </>
      );
    }
    return null;
  };

  const renderCustomerMenu = () => {
    if (user.isCustomerUser && user.isActive) {
      return (
        <>
          <div
            className={classNames('MenuHeader text-ellipsis overflow-hidden whitespace-nowrap pr-8', {
              hidden: !open,
            })}
          >
            {customer.name}
          </div>
          <MenuItem
            hasAccess={user.isActive}
            caption={t('clients')}
            icon={'far fa-people-arrows'}
            path={'/client'}
            smallMenu={!open}
            onNavigate={handleNavigate}
          />
          {renderSettings()}
        </>
      );
    }
    return null;
  };

  const renderUserMandant = () => {
    if (!user.isCustomerUser && activeClient) {
      return (
        <>
          <MenuItem
            hasAccess={user.isActive}
            caption={t('invoicesDone')}
            icon={'fas fa-check-circle'}
            path={`/client/invoices`}
            key={activeClient.clientId}
            smallMenu={!open}
            onNavigate={handleNavigate}
          />
          <BetaContainer>
            <MenuItem
              hasAccess={user.isActive}
              caption={t('storage.documents')}
              icon={'fas fa-folders'}
              path={`/client/storage`}
              key={activeClient.clientId}
              smallMenu={!open}
              onNavigate={handleNavigate}
            />
          </BetaContainer>
        </>
      );
    }
    return null;
  };

  const toggleMenu = () => {
    if (!open) {
      setWidth(menuWidthLarge);
      setOpen(true);
    } else {
      setWidth(menuWidthSmall);
      setOpen(false);
    }
  };

  const renderOpenMenu = () => {
    if (smallMenu) {
      return (
        <MenuItem
          hasAccess
          caption={!open ? t('menuOpen') : t('menuClose')}
          icon={classNames('far', { 'fa-chevrons-right': !open }, { 'fa-chevrons-left': open })}
          onPress={toggleMenu}
          smallMenu={!open}
          onNavigate={() => {}}
        />
      );
    }
    return null;
  };

  const getMenuCaption = () => {
    if (user.isCustomerUser) {
      return t('menu');
    } else {
      if (activeClient) {
        if (clients && clients.length > 1) {
          return clientBuildDisplayName(activeClient);
        }
      }
      return t('menu');
    }
  };

  return (
    <Container width={width} className={'z-10 transition-all'}>
      <LeftMenuHeader smallMenu={!open} />

      <div className={'flex flex-grow flex-col'}>
        <div className={classNames('MenuHeader', { hidden: !open })}>{getMenuCaption()}</div>
        <MenuItem
          hasAccess={true}
          caption={t('startPage')}
          icon={'far fa-home'}
          path={'/dashboard'}
          smallMenu={!open}
          onNavigate={handleNavigate}
        />
        <MenuItem
          hasAccess={user.isActive}
          caption={t('documentsOpen', { count: openDocCount })}
          icon={'far fa-folder-open'}
          path={'/documents'}
          smallMenu={!open}
          onNavigate={handleNavigate}
        />

        {renderCustomerMenu()}
        {renderUserMandant()}
      </div>
      <div className={'mb-4'}>
        <MenuItem
          hasAccess
          caption={t('myProfile')}
          icon={'far fa-user'}
          onPress={() => navigate('/settings/profile')}
          smallMenu={!open}
          onNavigate={handleNavigate}
        />
        {renderOpenMenu()}
      </div>
    </Container>
  );
}
