import React from 'react';
import AbstractModal from './AbstractModal';
import { useTranslation } from 'react-i18next';
import Button from '../Forms/Button';

interface Props {
  headline: string;
  text: string;
  visible: boolean;
  onClose: () => void;
  onDelete: () => void;
}

/**
 * AskDeleteModal()
 * @param props
 * @constructor
 */
export default function AskDeleteModal(props: Props) {
  const { visible, text, onClose, headline, onDelete } = props;
  const { t } = useTranslation();

  const renderButtons = () => (
    <div>
      <Button onPress={onClose} colorStyle={'cancel'}>
        {t('cancel')}
      </Button>
      <Button onPress={onDelete} colorStyle={'error'}>
        {t('delete')}
      </Button>
    </div>
  );

  return (
    <AbstractModal
      headline={headline}
      text={text}
      visible={visible}
      icon={'fas fa-trash-alt'}
      buttons={renderButtons()}
      type={'error'}
    />
  );
}
