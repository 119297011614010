import React from 'react';
import { InvoiceType } from '../../../Library/Types/DocumentTypes';
import Select from '../../Forms/Select';
import { useTranslation } from 'react-i18next';
import { Client } from '../../../Library/Types/ClientTypes';

interface Props {
  selected: InvoiceType;
  onChange: (type: InvoiceType) => void;
  disabled: boolean;
  client?: Client;
  hasError?: boolean;
}

/**
 * TypeSelect()
 * @param props
 * @constructor
 */
export default function TypeSelect(props: Props) {
  const { onChange, selected, disabled, client, hasError } = props;
  const { t } = useTranslation();

  const getTypeOptions = () => {
    const optionsParam: any[] = [
      { label: t('pleaseSelect'), value: InvoiceType.unknown },
      { label: t('invoiceIncoming'), value: InvoiceType.incoming },
    ];

    if (client?.allowOutgoing) {
      optionsParam.push({ label: t('invoiceOutgoing'), value: InvoiceType.outgoing });
    }
    if (client?.allowBank) {
      optionsParam.push({ label: t('invoiceBank'), value: InvoiceType.bank });
    }
    return optionsParam;
  };

  if (client) {
    return (
      <Select
        onChange={onChange}
        label={t('invoiceType')}
        options={getTypeOptions()}
        initialValue={selected}
        disabled={disabled}
        hasError={hasError}
      />
    );
  }
  return null;
}
