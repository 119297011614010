import React from 'react';
import { MailTemplate } from '../../../Library/Types/CustomerTypes';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../../../Library/Firebase';
import { useAppDispatch, useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import Config from '../../../Library/Config';
import { Environment, Salutation } from '../../../Library/Types/GeneralTypes';
import { Client } from '../../../Library/Types/ClientTypes';
import {
  CLIENT_MAILTEMPLATE_REGISTRATION_ERROR,
  CLIENT_MAILTEMPLATE_REGISTRATION_START,
  CLIENT_MAILTEMPLATE_REGISTRATION_SUCCESS,
} from '../../ActionTypes';
import { mailTemplatePrepare } from '../../../Library/Functions/MailTemplateFunctions';
import { useCustomerBuildClientRegistrationUrl } from '../../../Library/Hooks/CustomerHooks';
import { useDispatchClientUpdate } from '../ClientActions';
import moment from 'moment';

/**
 * useDispatchClientSendRegistrationMail()
 */
export const useDispatchClientSendRegistrationMail = (isTestmail: boolean = false) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const clientRegistrationUrl = useCustomerBuildClientRegistrationUrl();
  const dispatchUpdateClient = useDispatchClientUpdate();

  return React.useCallback(
    (template: MailTemplate, client: Client) => {
      dispatch(CLIENT_MAILTEMPLATE_REGISTRATION_START(client));

      if (client.mail) {
        // Only on production send to real mail address
        const mail = Config.env !== Environment.production ? user.mail : client.mail;
        const callableSendMail = httpsCallable(getFunctions(), FirebaseFunctions.mailTemplateSend);
        const preparedTemplate = mailTemplatePrepare(template, {
          name:
            client.salutation === Salutation.company ? client.companyName : `${client.firstName} ${client.lastName}`,
          registrationUrl: clientRegistrationUrl(client.clientId),
        });

        return callableSendMail({
          mail,
          subject: preparedTemplate.subject,
          text: preparedTemplate.text,
          html: preparedTemplate.html,
          customerId: client.customerId,
        })
          .then(() => {
            if (!isTestmail) {
              return dispatchUpdateClient({
                ...client,
                lastRegistrationMailSendDate: moment().format('YYYY-MM-DD HH:mm:ss'),
              }).then(() => {
                dispatch(CLIENT_MAILTEMPLATE_REGISTRATION_SUCCESS(true));
                return Promise.resolve(true);
              });
            } else {
              dispatch(CLIENT_MAILTEMPLATE_REGISTRATION_SUCCESS(true));
              return Promise.resolve(true);
            }
          })
          .catch((error) => {
            dispatch(CLIENT_MAILTEMPLATE_REGISTRATION_ERROR(error));
            return Promise.reject(error);
          });
      }

      return new Promise((resolve, reject) => reject());
    },
    [clientRegistrationUrl, dispatch, dispatchUpdateClient, isTestmail, user.mail],
  );
};
