import React from 'react';
import AbstractType from '../AbstractType';
import { Type } from '../ApiTypes';

export const ClientType: Type = {
  type: 'object',
  name: 'Client',
  fields: [
    { type: 'string', key: 'clientId', description: 'The internal primary key', mandatory: true },
    { type: 'string', key: 'customerId', description: 'The assigned customer', mandatory: true },
    { type: 'string', key: 'groupId', description: 'Id of the assigned group', mandatory: true },
    { type: 'Salutation', key: 'salutation', description: 'Client salutation', mandatory: true },
    { type: 'string', key: 'companyName', description: 'Only present if salutation is "c"', mandatory: true },
    { type: 'string', key: 'firstName', description: 'Only present if salutation is "m" or "f"', mandatory: true },
    { type: 'string', key: 'lastName', description: 'Only present if salutation is "m" or "f"', mandatory: true },
    { type: 'string', key: 'mail', description: 'The client mail', mandatory: true },
    { type: 'string', key: 'taxNumber', description: 'The client tax number', mandatory: false },
    {
      type: 'string',
      key: 'internalId',
      description: 'Internal Id for mapping with external systems',
      mandatory: false,
    },
    { type: 'string', key: 'assignedUserId', description: 'The assigned internal user id', mandatory: true },
    { type: 'string', key: 'createdUserId', description: 'The uer id created this record', mandatory: true },
    {
      type: 'string',
      key: 'createdDate',
      description: 'Date of creation in format YYYY-MM-DD HH:mm:ss',
      mandatory: true,
    },
    { type: 'boolean', key: 'isActive', description: 'If client is allowed to upload documents', mandatory: true },
    { type: 'boolean', key: 'isConnected', description: 'If client has connected user accounts', mandatory: true },
    {
      type: 'boolean',
      key: 'registrationActive',
      description: 'If new client users are allowed to connect to the client',
      mandatory: true,
    },
    {
      type: 'string',
      key: 'lastRegistrationMailSendDate',
      description: 'Last date of send registration mail in format YYYY-MM-DD HH:mm:ss',
      mandatory: false,
    },
    { type: 'Language', key: 'language', description: 'The clients language', mandatory: true },
    {
      type: 'boolean',
      key: 'allowOutgoing',
      description: 'If client is allowed to upload outgoing documents',
      mandatory: true,
    },
    {
      type: 'boolean',
      key: 'allowBank',
      description: 'If the client is allowed to upload bank documents',
      mandatory: true,
    },
  ],
};

/**
 * Client()
 * @constructor
 */
export default function Client() {
  return <AbstractType itemType={ClientType} />;
}
