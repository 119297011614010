import React from 'react';
import classNames from 'classnames';
import AbstractModal from '../../Modals/AbstractModal';
import { useTranslation } from 'react-i18next';
import InputRow from '../../Forms/InputRow';
import Button from '../../Forms/Button';
import { useDispatchStorageFolderCreate } from '../../../Redux/Actions/StorageActions';
import { Tooltip } from 'react-tooltip';

interface Props {
  folder: string;
  onCreated: (value: string) => void;
}

/**
 * CreateFolderButton()
 * @constructor
 */
export default function CreateFolderButton(props: Props) {
  const { folder, onCreated } = props;
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(false);
  const [folderName, setFolderName] = React.useState<string>('');

  const dispatchCreateFolder = useDispatchStorageFolderCreate();

  const createFolder = React.useCallback(() => {
    if (folderName && folderName.indexOf('/') < 0) {
      const name = `${folder}/${folderName}`.replace('//', '/');
      dispatchCreateFolder(name);
      onCreated(name);
      setShow(false);
    }
  }, [dispatchCreateFolder, folder, folderName, onCreated]);

  React.useEffect(() => {
    if (!show) {
      setFolderName('');
    }
  }, [show]);

  const renderButtons = () => (
    <div>
      <Button onPress={() => setShow(false)} colorStyle={'cancel'}>
        {t('cancel')}
      </Button>
      <Button onPress={createFolder} colorStyle={'success'} disabled={!folderName}>
        <div className={'flex justify-center items-center'}>{t('save')}</div>
      </Button>
    </div>
  );

  return (
    <>
      <div
        onClick={() => setShow(true)}
        className={classNames(
          'border-gray-300 border focus:outline-0 bg-white rounded-md py-3 px-4',
          'cursor-pointer ml-2 text-gray-500',
          'hover:bg-purple-700 hover:text-white',
          'createFolder',
        )}
      >
        <i className={'far fa-folder-plus'} />
      </div>
      <Tooltip className={'z-20'} anchorSelect={'.createFolder'}>
        {t(`createFolder`)}
      </Tooltip>

      <AbstractModal
        visible={show}
        buttons={renderButtons()}
        type={'success'}
        headline={t('createFolder')}
        icon={'far fa-folder-plus'}
        onBackdrop={() => setShow(false)}
      >
        <div className={'flex flex-row flex-grow mt-4 -mb-4'}>
          <InputRow
            onChange={setFolderName}
            value={folderName}
            placeholder={t('newFolderName')}
            containerClass={'flex-grow'}
            autoFocus
          />
        </div>
      </AbstractModal>
    </>
  );
}
