import styled from 'styled-components';
import React from 'react';
import { NavbarContext } from '../Layout/AuthedLayout';
import TopNavbarItem from './TopNavbarItem';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import SelectActiveClient from './SelectActiveClient';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 70px;
  align-items: center;
  align-content: center;
  background-color: #ffffff;
  ${(props) => props.theme.templates.borderBottom};
  padding-right: 15px;
`;

/**
 * Header()
 * @constructor
 */
export default function TopNavbar() {
  const navigate = useNavigate();
  const context = React.useContext(NavbarContext);

  const renderButtons = () => {
    if (context.showLoading) {
      return <TopNavbarItem onPress={() => {}} icon={'fas fa-spin fa-spinner'} className={'text-2xl'} />;
    }
    return context.button;
  };

  const renderBackButton = () => {
    if (context.showBack) {
      return <TopNavbarItem onPress={() => navigate(-1)} icon={'fas fa-arrow-left'} className={'ml-2'} />;
    }
    return null;
  };

  return (
    <Container>
      {renderBackButton()}
      <div className={'flex-grow'}>
        <div
          style={{ fontSize: 22 }}
          className={classNames(
            { 'ml-5': !context.showBack },
            { 'ml-2': context.showBack },
            'font-medium text-gray-900',
          )}
        >
          {context.headline}
        </div>
      </div>
      <div className={'flex flex-row items-center'}>
        <SelectActiveClient />
        {renderButtons()}
      </div>
    </Container>
  );
}
