import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContainerDimensions, useLoadingModal } from '../../../Library/Hooks/GlobalHooks';
import { useDispatchDocumentDelete } from '../../../Redux/Actions/DocumentActions';
import { DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import { pdfjs } from 'react-pdf';
import ShowHideContainer from '../../../Components/ShowHideContainer';
import Button from '../../../Components/Forms/Button';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import 'react-pdf/dist/Page/TextLayer.css';
import StatusBox from '../../../Components/Document/StatusBox';
import Container from '../../../Components/Container/Container';
import PdfPreview from '../../../Components/Document/PdfPreview';
import ImagePreview from '../../../Components/Document/ImagePreview';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';
import EditInformationForm from '../../../Components/Document/EditInformationForm';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useCheckDocumentAccess } from '../../../Library/Hooks/AccessHooks';
import UpdateDocumentButton from '../../../Components/Document/User/UpdateDocumentButton';
import ExcelPreview from '../../../Components/Document/ExcelPreview';
import 'pdfjs-dist/build/pdf.worker.entry';
import classNames from 'classnames';
import Messages from '../../../Components/Document/Messages';
import { useDispatchClientGetListByIds } from '../../../Redux/Actions/ClientActions';
import moment from 'moment';
import { documentUpdateInitialState, documentUpdateReducer } from '../../../Components/Document/DocumentUpdateReducer';
import FirstUploadModal from '../../../Components/Modals/FirstUploadModal';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  > .Left {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
  }

  img {
    border-radius: 10px;
    object-fit: contain;
  }
`;

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { documents } = useAppSelector((state) => state.document);
  const { user } = useAppSelector((state) => state.auth);
  const [state, dispatch] = React.useReducer(documentUpdateReducer, documentUpdateInitialState);
  const [document, setDocument] = React.useState<DocumentType | null>(null);
  const [texts, setTexts] = React.useState<string[]>();
  const { t } = useTranslation();
  const { loadingModal, setLoading } = useLoadingModal({ headline: t('modules.documents.deleting') });
  const [showAskDelete, setShowAskDelete] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);
  const divRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const [pdfFullScreen, setPdfFullScreen] = React.useState<boolean>(false);
  const { width } = useContainerDimensions(divRef, pdfFullScreen);
  useCheckDocumentAccess(document);

  const dispatchDelete = useDispatchDocumentDelete();
  const dispatchLoadClients = useDispatchClientGetListByIds();

  const handleDelete = React.useCallback(() => {
    if (document) {
      setLoading(true);
      dispatchDelete(document).finally(() => {
        setLoading(false);
        navigate(-1);
      });
    }
  }, [dispatchDelete, document, navigate, setLoading]);

  React.useEffect(() => {
    const timer = setTimeout(() => dispatchLoadClients(user.clients), 200);
    return () => clearTimeout(timer);
  }, [dispatchLoadClients, user.clients]);

  React.useEffect(() => {
    navContext.setHeadline(t('modules.documents.information'));
    navContext.setShowBack(true);
  }, [navContext, t]);

  React.useEffect(() => {
    if (documents && documentId) {
      const found = documents.find((doc) => doc.documentId === documentId);
      if (found) {
        setDocument(found);
      }
    }
  }, [documentId, documents]);

  const prepareHighlightWords = () => {
    const data: string[] = [];

    if (document) {
      if (document.creditor) {
        data.push(document.creditor);
      }
      if (document.invoiceNumber) {
        data.push(document.invoiceNumber);
      }
      if (document.invoiceDate) {
        data.push(document.invoiceDate);
        data.push(moment(document.invoiceDate).format('DD.MM.YYYY'));
        data.push(moment(document.invoiceDate).format('DD.MM.YY'));
        data.push(moment(document.invoiceDate).format('DD/MM/YYYY'));
        data.push(moment(document.invoiceDate).format('DD/MM/YY'));
        data.push(moment(document.invoiceDate).format('YYYY/MM/DD'));
        data.push(moment(document.invoiceDate).format('YY/MM/DD'));
      }
    }
    return data;
  };

  if (document) {
    return (
      <>
        <Container>
          <p className={'text-gray-500 text-lg'}>{t('modules.documents.addInformationDescription')}</p>
        </Container>

        <InnerContainer>
          <div className={classNames({ 'flex flex-1 flex-col mr-4': !pdfFullScreen }, { hidden: pdfFullScreen })}>
            <StatusBox document={document} withContainer />

            <EditInformationForm
              document={document}
              disabled={document.state === DocumentState.done}
              texts={texts}
              goBackAfterSave={true}
              state={state}
              dispatch={dispatch}
            />

            <Messages document={document} />
          </div>

          <div
            className={classNames(
              { 'w-2/6 lg:w-3/6 xl:w-7/12': !pdfFullScreen },
              { 'flex flex-1 w-auto': pdfFullScreen },
              'flex-col',
            )}
            ref={divRef}
          >
            <PdfPreview
              onFullScreen={setPdfFullScreen}
              width={width - 66}
              document={document}
              onTexts={setTexts}
              onStartLoading={() => navContext.setShowLoading(true)}
              onFinishedLoading={() => navContext.setShowLoading(false)}
              highlightWords={prepareHighlightWords()}
              onSetInvoiceNumber={(value) => dispatch({ type: 'invoiceNumber', payload: value })}
              onSetInvoiceDate={(value) => dispatch({ type: 'invoiceDate', payload: value })}
              onSetCreditor={(value) => dispatch({ type: 'creditor', payload: value })}
            />
            <ImagePreview
              document={document}
              onTexts={setTexts}
              onStartLoading={() => navContext.setShowLoading(true)}
              onFinishedLoading={() => navContext.setShowLoading(false)}
            />
            <ExcelPreview
              document={document}
              onTexts={setTexts}
              onStartLoading={() => navContext.setShowLoading(true)}
              onFinishedLoading={() => navContext.setShowLoading(false)}
            />

            <ShowHideContainer visible={document.state !== DocumentState.done}>
              <Container>
                <div className={'flex flex-row'}>
                  <div className={'flex-grow'}>
                    <UpdateDocumentButton document={document} onChange={setDocument} />
                  </div>
                  <div>
                    <Button onPress={() => setShowAskDelete(true)} colorStyle={'error'}>
                      {t('delete')}
                    </Button>
                  </div>
                </div>
              </Container>
            </ShowHideContainer>
          </div>

          <AskDeleteModal
            headline={t('alert.deleteDocument.headline')}
            text={t('alert.deleteDocument.text', { name: document.originalName })}
            visible={showAskDelete}
            onClose={() => setShowAskDelete(false)}
            onDelete={handleDelete}
          />

          {loadingModal}
        </InnerContainer>

        <div className={'mt-4 text-xs text-gray-400 text-center'}>{`${documentId}`}</div>

        <FirstUploadModal document={document} />
      </>
    );
  }
  return null;
}
