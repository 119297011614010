import React from 'react';
import InputRow from '../../Forms/InputRow';
import { useTranslation } from 'react-i18next';
import UploadButton from './UploadButton';
import CreateFolderButton from './CreateFolderButton';

interface Props {
  folder: string;
  onSearch: (value: string) => void;
  onFolderCreated: (value: string) => void;
}

/**
 * ActionBar()
 * @param props
 * @constructor
 */
export default function ActionBar(props: Props) {
  const { folder, onSearch, onFolderCreated } = props;
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>('');

  const handleSetValue = (d: string) => {
    setValue(d);
    onSearch(d);
  };

  return (
    <div className={'flex flex-1 flex-row mb-4'}>
      <div className={'flex-grow -mb-4'}>
        <InputRow onChange={handleSetValue} value={value} placeholder={t('search')} showClear={true} autoFocus />
      </div>

      <CreateFolderButton folder={folder} onCreated={onFolderCreated} />
      <UploadButton folder={folder} />
    </div>
  );
}
