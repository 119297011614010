import Container from '../../Components/Container/Container';
import Input, { InputType } from '../../Components/Forms/Input';
import React, { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import validateRegisterCustomer, { ValidateRegisterCustomerErrors } from '../../Library/Validators/RegisterCustomer';
import Button from '../../Components/Forms/Button';
import { useDispatchCustomerRegister } from '../../Redux/Actions/CustomerActions';
import { useNavigate } from 'react-router';
import { useLoadingModal } from '../../Library/Hooks/GlobalHooks';
import AuthenticationError from '../../Components/AuthenticationError';
import classNames from 'classnames';
import Index from '../../Components/Website/Navigation';
import { Language } from '../../Library/Types/GeneralTypes';
import Select from '../../Components/Forms/Select';
import SimpleCheckBox from '../../Components/Forms/SimpeCheckBox';
import Footer from '../../Components/Website/Footer';

const registerImage = require('../../Assets/Images/register.png');

export type RegisterCustomerState = {
  data: {
    companyName: string;
    taxNumber: string;
    firstName: string;
    lastName: string;
    mail: string;
    password: string;
    passwordRepeat: string;
    country: Language;
    agb: boolean;
    legal: boolean;
  };
  initialized: boolean;
  hasChanges: boolean;
};

type Action =
  | { type: 'clear' }
  | { type: 'companyName'; payload: string }
  | { type: 'firstName'; payload: string }
  | { type: 'taxNumber'; payload: string }
  | { type: 'lastName'; payload: string }
  | { type: 'country'; payload: Language }
  | { type: 'agb'; payload: boolean }
  | { type: 'legal'; payload: boolean }
  | { type: 'mail'; payload: string }
  | { type: 'password'; payload: string }
  | { type: 'passwordRepeat'; payload: string };

export const initialState: RegisterCustomerState = {
  data: {
    companyName: '',
    taxNumber: '',
    firstName: '',
    lastName: '',
    mail: '',
    password: '',
    passwordRepeat: '',
    country: Language.es,
    agb: false,
    legal: false,
  },
  hasChanges: false,
  initialized: false,
};

export const reducer = (state: RegisterCustomerState, action: Action): RegisterCustomerState => {
  switch (action.type) {
    case 'companyName':
      return { ...state, hasChanges: true, data: { ...state.data, companyName: action.payload } };
    case 'firstName':
      return { ...state, hasChanges: true, data: { ...state.data, firstName: action.payload } };
    case 'lastName':
      return { ...state, hasChanges: true, data: { ...state.data, lastName: action.payload } };
    case 'mail':
      return { ...state, hasChanges: true, data: { ...state.data, mail: action.payload } };
    case 'taxNumber':
      return { ...state, hasChanges: true, data: { ...state.data, taxNumber: action.payload } };
    case 'country':
      return { ...state, hasChanges: true, data: { ...state.data, country: action.payload } };
    case 'agb':
      return { ...state, hasChanges: true, data: { ...state.data, agb: action.payload } };
    case 'legal':
      return { ...state, hasChanges: true, data: { ...state.data, legal: action.payload } };
    case 'password':
      return { ...state, hasChanges: true, data: { ...state.data, password: action.payload } };
    case 'passwordRepeat':
      return { ...state, hasChanges: true, data: { ...state.data, passwordRepeat: action.payload } };
    default:
      throw new Error('Invalid type!');
  }
};

/**
 * RegisterCustomer()
 * @constructor
 */
export default function RegisterCustomer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setLoading, isLoading } = useLoadingModal();
  const [validateResult, setValidationResult] = React.useState<ValidateRegisterCustomerErrors | null>(null);
  const [errorCode, setErrorCode] = React.useState<string | null>(null);

  const dispatchRegister = useDispatchCustomerRegister();

  const handleCreate = useCallback(async () => {
    setValidationResult(null);
    const validated = validateRegisterCustomer(state);
    if (validated.isValid) {
      setLoading(true);

      try {
        await dispatchRegister(state);
        setLoading(false);
        navigate('/dashboard');
      } catch (error: any) {
        setLoading(false);
        setErrorCode(error.code);
      }
    } else {
      setValidationResult(validated);
    }
  }, [dispatchRegister, navigate, setLoading, state]);

  const getLanguageOptions = () => {
    return [
      {
        label: t(`countries.${Language.es}`),
        value: Language.es,
      },
      {
        label: t(`countries.${Language.de}`),
        value: Language.de,
      },
      {
        label: t(`countries.${Language.en}`),
        value: Language.en,
      },
    ];
  };

  return (
    <div className={'flex flex-1 flex-col justify-center'}>
      <Index showPrice={false} showStartPage={true} showRegister={false} showLogin={false} />

      <div className={classNames('flex flex-1 flex-row justify-center', 'bg-purple-700 text-white')}>
        <div
          className={classNames(
            'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-6/12',
            'justify-center',
            'py-10 px-4 text-xl lg:pb-20 lg:pt-6 lg:text-2xl',
          )}
        >
          <div className={classNames('flex justify-center text-6xl', 'mb-10')}>
            <img src={registerImage} alt={'Register'} />
          </div>
          <div
            className={classNames(
              'flex flex-1 flex-col items-center',
              'text-4xl font-bold text-center',
              'lg:text-4xl',
              'mb-6',
            )}
          >
            {t('createAccountHeadline')}
          </div>
          <div className={classNames('text-center font-light')}>{t('onlyForTaxman')}</div>
        </div>
      </div>

      <div className={'flex flex-1 flex-row justify-center'}>
        <div
          className={
            'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-6/12 p-2 md:pt-10 md:pb-10'
          }
        >
          <Container boxShadow>
            <div className={'mt-3 mb-8 text-gray-600'}>{t('createAccountDescription')}</div>
            <AuthenticationError errorCode={errorCode} />
            <Input
              onChange={(payload) => dispatch({ type: 'companyName', payload })}
              value={state.data.companyName}
              label={t('companyName')}
              disabled={isLoading}
              hasError={!!validateResult && validateResult.companyName}
              required
              autoFocus
            />
            <Input
              onChange={(payload) => dispatch({ type: 'taxNumber', payload })}
              value={state.data.taxNumber}
              label={t('taxNumber')}
              disabled={isLoading}
              hasError={!!validateResult && validateResult.taxNumber}
              required
            />
            <Select
              onChange={(payload) => dispatch({ type: 'country', payload })}
              label={t('country')}
              initialValue={state.data.country}
              options={getLanguageOptions()}
            />
            <div className={classNames('flex flex-col flex-1', 'md:flex-row')}>
              <div className={'mr-2 flex-1'}>
                <Input
                  onChange={(payload) => dispatch({ type: 'firstName', payload })}
                  value={state.data.firstName}
                  label={t('firstName')}
                  disabled={isLoading}
                  hasError={!!validateResult && validateResult.firstName}
                  required
                />
              </div>
              <div className={'flex-1 md:ml-2'}>
                <Input
                  onChange={(payload) => dispatch({ type: 'lastName', payload })}
                  value={state.data.lastName}
                  label={t('lastName')}
                  disabled={isLoading}
                  hasError={!!validateResult && validateResult.lastName}
                  required
                />
              </div>
            </div>
            <Input
              onChange={(payload) => dispatch({ type: 'mail', payload })}
              value={state.data.mail}
              label={t('eMailAddress')}
              disabled={isLoading}
              hasError={!!validateResult && validateResult.mail}
              type={InputType.email}
            />
            <Input
              onChange={(payload) => dispatch({ type: 'password', payload })}
              value={state.data.password}
              label={t('password')}
              type={InputType.password}
              disabled={isLoading}
              hasError={!!validateResult && validateResult.password}
              required
            />
            <Input
              onChange={(payload) => dispatch({ type: 'passwordRepeat', payload })}
              value={state.data.passwordRepeat}
              label={t('passwordRepeat')}
              type={InputType.password}
              disabled={isLoading}
              hasError={!!validateResult && validateResult.passwordRepeat}
              required
            />

            <SimpleCheckBox
              caption={t('registerConditionsAccepted')}
              checked={state.data.agb}
              onChange={(value) => dispatch({ type: 'agb', payload: value })}
              link={'/conditions'}
              hasError={validateResult?.agb}
            />

            <SimpleCheckBox
              caption={t('registerLegalAccepted')}
              checked={state.data.legal}
              onChange={(value) => dispatch({ type: 'legal', payload: value })}
              link={'/legal'}
              hasError={validateResult?.legal}
            />

            <div className={classNames('flex flex-1 flex-col-reverse items-center', 'md:flex-row')}>
              <div className={'flex-grow flex'}>
                <div
                  className={classNames('text-blue-500', 'hover:text-blue-700 hover:underline', 'cursor-pointer')}
                  onClick={() => navigate('/login')}
                >
                  {t('accountAlreadyExisting')}
                </div>
              </div>
              <div className={'flex justify-end mb-6 mt-6'}>
                <Button
                  className={'h-12 w-60 flex justify-center'}
                  onPress={handleCreate}
                  disabled={isLoading || !state.hasChanges}
                >
                  {isLoading ? <i className={'fas fa-spin fa-spinner'} /> : t('createAccount')}
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
}
