import React from 'react';
import AbstractType from '../AbstractType';
import { Type } from '../ApiTypes';

export const DocumentStateType: Type = {
  type: 'enum',
  name: 'DocumentState',
  fields: [
    {
      type: 'string',
      key: 'uploaded',
      description: 'Document uploaded and waiting to enter all information',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'question',
      description: 'Document has pending questions',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'unreadable',
      description: 'Document is unreadable and needs to be updated',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'notBookable',
      description: 'Document is not bookable',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'notDeductible',
      description: 'Document is not deductible',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'done',
      description: 'Document is done and can not be modified anymore',
      mandatory: false,
    },
  ],
};

/**
 * DocumentState()
 * @constructor
 */
export default function DocumentState() {
  return <AbstractType itemType={DocumentStateType} />;
}
