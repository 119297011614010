import React from 'react';
import moment from 'moment/moment';
import DatePicker from '../../Forms/DatePicker';
import Input from '../../Forms/Input';
import { useTranslation } from 'react-i18next';
import { InvoiceType } from '../../../Library/Types/DocumentTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { displayIban } from '../../../Library/Functions/HelperFunctions';
import Select from '../../Forms/Select';
import classNames from 'classnames';
import { Client } from '../../../Library/Types/ClientTypes';
import { DocumentUpdateActions, DocumentUpdateState } from '../DocumentUpdateReducer';

interface Props {
  dispatch: React.Dispatch<DocumentUpdateActions>;
  state: DocumentUpdateState;
  disabled: boolean;
  showErrors: boolean;
  client: Client;
}

/**
 * BankInformationForm()
 * @param props
 * @constructor
 */
export default function BankInformationForm(props: Props) {
  const { dispatch, state, disabled, showErrors, client } = props;
  const { user } = useAppSelector((state) => state.auth);
  const [manuall, setManuall] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const getTypeOptions = () => {
    if (client.settings.bankAccounts && client.settings.bankAccounts.length > 0) {
      return client.settings.bankAccounts.map((item) => ({
        label: `${item.bankName} (${displayIban(item.bankIban)})`,
        value: item.bankIban,
      }));
    }
    return [];
  };

  const handleSetBank = (iban: string) => {
    if (client.settings && client.settings.bankAccounts && client.settings.bankAccounts.length > 0) {
      const found = client.settings.bankAccounts.find((item) => item.bankIban === iban);
      if (found) {
        dispatch({ type: 'setBank', payload: { name: found.bankName, iban: found.bankIban } });
      }
    }
  };

  const renderBankAccount = () => {
    if (
      client.settings &&
      client.settings.bankAccounts &&
      client.settings.bankAccounts.length > 0 &&
      !user.isCustomerUser &&
      !manuall
    ) {
      return (
        <div>
          <Select
            onChange={handleSetBank}
            label={t('selectBankAccount')}
            options={getTypeOptions()}
            initialValue={state.data.bankIban}
          />
          <div
            className={classNames(
              'text-sm text-gray-400 mb-4 -mt-2 text-right',
              'cursor-pointer',
              'hover:underline',
              'hover:text-blue-700',
            )}
            onClick={() => setManuall(true)}
          >
            {t('insertBankManually')}
          </div>
        </div>
      );
    }
    return (
      <>
        <Input
          onChange={(payload) => dispatch({ type: 'bankName', payload })}
          value={state.data.bankName}
          label={t('bankName')}
          hasError={showErrors && !state.data.bankName}
          disabled={disabled}
          showCopy
        />
        <Input
          onChange={(payload) => dispatch({ type: 'bankIban', payload })}
          value={state.data.bankIban}
          label={t('bankIban')}
          hasError={showErrors && !state.data.bankIban}
          disabled={disabled}
          showCopy
        />
      </>
    );
  };

  if (state.data.type === InvoiceType.bank) {
    return (
      <>
        {renderBankAccount()}
        <DatePicker
          onChange={(payload) => dispatch({ type: 'bankStartDate', payload })}
          label={t('bankStartDate')}
          initialValue={state.data.bankStartDate ? moment(state.data.bankStartDate).toDate() : undefined}
          hasError={showErrors && !state.data.bankStartDate}
          disabled={disabled}
          maxDate={moment().toDate()}
          showCopy
        />
        <DatePicker
          onChange={(payload) => dispatch({ type: 'bankEndDate', payload })}
          label={t('bankEndDate')}
          initialValue={state.data.bankEndDate ? moment(state.data.bankEndDate).toDate() : undefined}
          hasError={showErrors && !state.data.bankEndDate}
          disabled={disabled}
          maxDate={moment().toDate()}
          showCopy
        />
      </>
    );
  }
  return null;
}
