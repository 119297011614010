import React from 'react';
import { User } from '../../../../../Library/Types/UserTypes';
import SwitchRow from '../../../../Display/SwitchRow';
import { useDispatchUserUpdate } from '../../../../../Redux/Actions/UserActions';
import { CustomerUserAccess } from '../../../../../Library/Types/CustomerTypes';
import { useTranslation } from 'react-i18next';

interface Props {
  user: User;
}

/**
 * MandantAccess()
 * @param props
 * @constructor
 */
export default function MandantAccess(props: Props) {
  const { user } = props;
  const { t } = useTranslation();
  const dispatchUpdate = useDispatchUserUpdate();
  const [allActive, setAllActive] = React.useState<boolean>(false);

  const updateUser = React.useCallback(
    (userParam: User) => {
      dispatchUpdate(userParam);
    },
    [dispatchUpdate],
  );

  const updateKey = React.useCallback(
    (key: string, value: boolean) => {
      const access: CustomerUserAccess = user.customerAccess || {};
      const merged: CustomerUserAccess = {
        ...access,
        mandant: { ...access.mandant, [key]: value },
      };

      updateUser({ ...user, customerAccess: merged });
    },
    [updateUser, user],
  );

  const handleSetAllActive = React.useCallback(
    (value: boolean) => {
      const access: CustomerUserAccess = user.customerAccess || {};
      const merged: CustomerUserAccess = {
        ...access,
        mandant: { create: value, sendMail: value, update: value },
      };

      updateUser({ ...user, customerAccess: merged });
    },
    [updateUser, user],
  );

  React.useEffect(() => {
    setAllActive(
      !!user.customerAccess &&
        !!user.customerAccess.mandant &&
        user.customerAccess.mandant.sendMail === true &&
        user.customerAccess.mandant.update === true &&
        user.customerAccess.mandant.create === true,
    );
  }, [setAllActive, user.customerAccess]);

  return (
    <>
      <SwitchRow value={allActive} caption={t('mandantManage')} onChange={handleSetAllActive} switchPosition={'left'} />
      <div className={'ml-16'}>
        <SwitchRow
          value={!!user.customerAccess && !!user.customerAccess.mandant && user.customerAccess.mandant.create === true}
          caption={t('create')}
          onChange={(value) => updateKey('create', value)}
          switchPosition={'left'}
        />
        <SwitchRow
          value={!!user.customerAccess && !!user.customerAccess.mandant && user.customerAccess.mandant.update === true}
          caption={t('update')}
          onChange={(value) => updateKey('update', value)}
          switchPosition={'left'}
        />
        <SwitchRow
          value={
            !!user.customerAccess && !!user.customerAccess.mandant && user.customerAccess.mandant.sendMail === true
          }
          caption={t('mandantSendRegistrationMail')}
          onChange={(value) => updateKey('sendMail', value)}
          switchPosition={'left'}
        />
      </div>
    </>
  );
}
