import React from 'react';
import AskDeleteModal from '../../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { displayIban } from '../../../../Library/Functions/HelperFunctions';
import { BankAccount, Client } from '../../../../Library/Types/ClientTypes';
import { useDispatchClientUpdate } from '../../../../Redux/Actions/ClientActions';

interface Props {
  item: BankAccount;
  client: Client;
}

/**
 * ()
 * @param props
 * @constructor
 */
export default function (props: Props) {
  const { item, client } = props;
  const { t } = useTranslation();
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatchUpdateClient = useDispatchClientUpdate();

  const deleteBankAccount = React.useCallback(() => {
    setLoading(true);
    const bankAccounts: BankAccount[] = client.settings.bankAccounts
      ? client.settings.bankAccounts.filter((param) => item.bankIban !== param.bankIban)
      : [];

    dispatchUpdateClient({ ...client, settings: { ...client.settings, bankAccounts } }).finally(() => {
      setLoading(false);
    });
  }, [dispatchUpdateClient, item.bankIban, client]);

  return (
    <tr className={classNames({ 'text-gray-300': loading })}>
      <td>{item.bankName}</td>
      <td>{displayIban(item.bankIban)}</td>
      <td width={30}>
        <i
          className={classNames(
            { 'fa fa-trash  hover:text-red-500': !loading },
            { 'fa fa-spin fa-spinner': loading },
            'text-gray-300',
          )}
          onClick={!loading ? () => setAskDelete(true) : () => {}}
        />
      </td>

      <AskDeleteModal
        headline={t('alert.askDeleteBankAccount.headline')}
        text={t('alert.askDeleteBankAccount.text')}
        visible={askDelete}
        onClose={() => setAskDelete(false)}
        onDelete={deleteBankAccount}
      />
    </tr>
  );
}
