import React from 'react';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ListBox, { ListBoxItem } from '../../Forms/ListBox';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange: (groupId: number) => void;
  selected: number;
}

/**
 * GroupFilter()
 * @param props
 * @constructor
 */
export default function GroupFilter(props: Props) {
  const { onChange, selected } = props;
  const { t } = useTranslation();
  const { settings } = useAppSelector((state) => state.auth.customer);
  const [options, setOptions] = React.useState<ListBoxItem<number>[]>([]);

  React.useEffect(() => {
    if (!selected) {
      onChange(0);
    }
  }, [onChange, selected, t]);

  React.useEffect(() => {
    if (settings && settings.client && settings.client.groups && settings.client.groups.length > 0) {
      const mapped: ListBoxItem<number>[] = settings.client.groups.map((group) => ({
        value: group.id,
        label: group.name,
      }));

      mapped.unshift({ value: 0, label: t('showAll') });
      setOptions(mapped);
    }
  }, [settings, t]);

  if (settings && settings.client && settings.client.groups && settings.client.groups.length > 1) {
    return (
      <div className={'w-96 ml-4'}>
        <ListBox<number> options={options} selected={selected} onChange={onChange} />
      </div>
    );
  }
  return null;
}
