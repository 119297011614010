/* eslint-disable max-len */
import { createAction } from '@reduxjs/toolkit';
import { User } from '../Library/Types/UserTypes';
import { Customer, MailTemplate } from '../Library/Types/CustomerTypes';
import { Client } from '../Library/Types/ClientTypes';
import { DocumentType } from '../Library/Types/DocumentTypes';
import { RegisterCustomerState } from '../Modules/Register/RegisterCustomer';
import { MailTemplateType } from '../Library/Types/GeneralTypes';
import { BookingStatistic } from '../Library/Types/StatisticTypes';
import { Storage } from '../Library/Types/StorageTypes';

export const AUTH_LOGIN_START = createAction<{ mail: string; password: string }>('AUTH_LOGIN_START');
export const AUTH_LOGIN_SUCCESS = createAction<{ user: User }>('AUTH_LOGIN_SUCCESS');
export const AUTH_LOGIN_ERROR = createAction<any>('AUTH_LOGIN_ERROR');
export const AUTH_LOGIN_AUTO = createAction<{ user: User }>('AUTH_LOGIN_AUTO');
export const AUTH_LOGOUT = createAction<void>('AUTH_LOGOUT');
export const AUTH_SEND_PASSWORD_RESET_START = createAction<string>('AUTH_SEND_PASSWORD_RESET_START');
export const AUTH_SEND_PASSWORD_RESET_SUCCESS = createAction<boolean>('AUTH_SEND_PASSWORD_RESET_SUCCESS');
export const AUTH_SEND_PASSWORD_RESET_ERROR = createAction<any>('AUTH_SEND_PASSWORD_RESET_ERROR');

export const CUSTOMER_GET_START = createAction<string>('CUSTOMER_GET_START');
export const CUSTOMER_GET_SUCCESS = createAction<Customer>('CUSTOMER_GET_SUCCESS');
export const CUSTOMER_GET_ERROR = createAction<any>('CUSTOMER_GET_ERROR');
export const CUSTOMER_GETLIST_START = createAction<string[]>('CUSTOMER_GETLIST_START');
export const CUSTOMER_GETLIST_SUCCESS = createAction<Customer[]>('CUSTOMER_GETLIST_SUCCESS');
export const CUSTOMER_GETLIST_ERROR = createAction<any>('CUSTOMER_GETLIST_ERROR');
export const CUSTOMER_UPDATE_START = createAction<Customer>('CUSTOMER_UPDATE_START');
export const CUSTOMER_UPDATE_SUCCESS = createAction<Customer>('CUSTOMER_UPDATE_SUCCESS');
export const CUSTOMER_UPDATE_ERROR = createAction<any>('CUSTOMER_UPDATE_ERROR');
export const CUSTOMER_REGISTER_START = createAction<RegisterCustomerState>('CUSTOMER_REGISTER_START');
export const CUSTOMER_REGISTER_SUCCESS = createAction<{ customer: Customer; user: User }>('CUSTOMER_REGISTER_SUCCESS');
export const CUSTOMER_REGISTER_ERROR = createAction<any>('CUSTOMER_REGISTER_ERROR');

export const CUSTOMER_STATISTICS_GETLIST_START = createAction<string>('CUSTOMER_STATISTICS_GETLIST_START');
export const CUSTOMER_STATISTICS_GETLIST_SUCCESS = createAction<BookingStatistic[]>(
  'CUSTOMER_STATISTICS_GETLIST_SUCCESS',
);
export const CUSTOMER_STATISTICS_GETLIST_ERROR = createAction<any>('CUSTOMER_STATISTICS_GETLIST_ERROR');

export const CUSTOMER_MAILTEMPLATE_SET_START = createAction<MailTemplateType>('CUSTOMER_MAILTEMPLATE_SET_START');
export const CUSTOMER_MAILTEMPLATE_SET_SUCCESS = createAction<MailTemplate>('CUSTOMER_MAILTEMPLATE_SET_SUCCESS');
export const CUSTOMER_MAILTEMPLATE_SET_ERROR = createAction<any>('CUSTOMER_MAILTEMPLATE_SET_ERROR');
export const CUSTOMER_MAILTEMPLATE_GET_START = createAction<MailTemplateType>('CUSTOMER_MAILTEMPLATE_GET_START');
export const CUSTOMER_MAILTEMPLATE_GET_SUCCESS = createAction<MailTemplate>('CUSTOMER_MAILTEMPLATE_GET_SUCCESS');
export const CUSTOMER_MAILTEMPLATE_GET_ERROR = createAction<any>('CUSTOMER_MAILTEMPLATE_GET_ERROR');

export const CLIENT_CREATE_START = createAction<Client>('CLIENT_CREATE_START');
export const CLIENT_CREATE_SUCCESS = createAction<Client>('CLIENT_CREATE_SUCCESS');
export const CLIENT_CREATE_ERROR = createAction<any>('CLIENT_CREATE_ERROR');
export const CLIENT_UPDATE_START = createAction<Client>('CLIENT_UPDATE_START');
export const CLIENT_UPDATE_SUCCESS = createAction<Client>('CLIENT_UPDATE_SUCCESS');
export const CLIENT_UPDATE_ERROR = createAction<any>('CLIENT_UPDATE_ERROR');
export const CLIENT_DELETE_START = createAction<Client>('CLIENT_DELETE_START');
export const CLIENT_DELETE_SUCCESS = createAction<Client>('CLIENT_DELETE_SUCCESS');
export const CLIENT_DELETE_ERROR = createAction<any>('CLIENT_DELETE_ERROR');
export const CLIENT_GETLIST_START = createAction<string>('CLIENT_GETLIST_START');
export const CLIENT_GETLIST_SUCCESS = createAction<Client[]>('CLIENT_GETLIST_SUCCESS');
export const CLIENT_GETLIST_ERROR = createAction<any>('CLIENT_GETLIST_ERROR');
export const CLIENT_GETLIST_BY_IDS_START = createAction<string[]>('CLIENT_GETLIST_BY_IDS_START');
export const CLIENT_GETLIST_BY_IDS_SUCCESS = createAction<Client[]>('CLIENT_GETLIST_BY_IDS_SUCCESS');
export const CLIENT_GETLIST_BY_IDS_ERROR = createAction<string[]>('CLIENT_GETLIST_BY_IDS_ERROR');
export const CLIENT_GET_USERS_START = createAction<string>('CLIENT_GET_USERS_START');
export const CLIENT_GET_USERS_SUCCESS = createAction<User[]>('CLIENT_GET_USERS_SUCCESS');
export const CLIENT_GET_USERS_ERROR = createAction<any>('CLIENT_GET_USERS_ERROR');
export const CLIENT_SET_ACTIVE = createAction<Client>('CLIENT_SET_ACTIVE');

export const CLIENT_STATISTICS_GETLIST_START = createAction<string>('CLIENT_STATISTICS_GETLIST_START');
export const CLIENT_STATISTICS_GETLIST_SUCCESS = createAction<BookingStatistic[]>('CLIENT_STATISTICS_GETLIST_SUCCESS');
export const CLIENT_STATISTICS_GETLIST_ERROR = createAction<any>('CLIENT_STATISTICS_GETLIST_ERROR');

export const CLIENT_DOCUMENTS_GETLIST_START = createAction<string>('CLIENT_DOCUMENTS_GETLIST_START');
export const CLIENT_DOCUMENTS_GETLIST_SUCCESS = createAction<DocumentType[]>('CLIENT_DOCUMENTS_GETLIST_SUCCESS');
export const CLIENT_DOCUMENTS_GETLIST_ERROR = createAction<any>('CLIENT_DOCUMENTS_GETLIST_ERROR');

export const CLIENT_MAILTEMPLATE_REGISTRATION_START = createAction<Client>('CLIENT_MAILTEMPLATE_REGISTRATION_START');
export const CLIENT_MAILTEMPLATE_REGISTRATION_SUCCESS = createAction<boolean>(
  'CLIENT_MAILTEMPLATE_REGISTRATION_SUCCESS',
);
export const CLIENT_MAILTEMPLATE_REGISTRATION_ERROR = createAction<any>('CLIENT_MAILTEMPLATE_REGISTRATION_ERROR');

export const DOCUMENT_UPLOAD_START = createAction<File>('DOCUMENT_UPLOAD_START');
export const DOCUMENT_UPLOAD_SUCCESS = createAction<DocumentType>('DOCUMENT_UPLOAD_SUCCESS');
export const DOCUMENT_UPLOAD_ERROR = createAction<any>('DOCUMENT_UPLOAD_ERROR');
export const DOCUMENT_UPLOAD_UPDATE_START = createAction<{ file: File; document: DocumentType }>(
  'DOCUMENT_UPLOAD_UPDATE_START',
);
export const DOCUMENT_UPLOAD_UPDATE_SUCCESS = createAction<DocumentType>('DOCUMENT_UPLOAD_UPDATE_SUCCESS');
export const DOCUMENT_UPLOAD_UPDATE_ERROR = createAction<any>('DOCUMENT_UPLOAD_UPDATE_ERROR');
export const DOCUMENT_CREATE_START = createAction<string>('DOCUMENT_CREATE_START');
export const DOCUMENT_CREATE_SUCCESS = createAction<DocumentType>('DOCUMENT_CREATE_SUCCESS');
export const DOCUMENT_CREATE_ERROR = createAction<any>('DOCUMENT_CREATE_ERROR');
export const DOCUMENT_UPDATE_START = createAction<DocumentType>('DOCUMENT_UPDATE_START');
export const DOCUMENT_UPDATE_SUCCESS = createAction<DocumentType>('DOCUMENT_UPDATE_SUCCESS');
export const DOCUMENT_UPDATE_ERROR = createAction<any>('DOCUMENT_UPDATE_ERROR');
export const DOCUMENT_DELETE_START = createAction<DocumentType>('DOCUMENT_DELETE_START');
export const DOCUMENT_DELETE_SUCCESS = createAction<DocumentType>('DOCUMENT_DELETE_SUCCESS');
export const DOCUMENT_DELETE_ERROR = createAction<any>('DOCUMENT_DELETE_ERROR');
export const DOCUMENT_REOPEN_START = createAction<DocumentType>('DOCUMENT_REOPEN_START');
export const DOCUMENT_REOPEN_SUCCESS = createAction<DocumentType>('DOCUMENT_REOPEN_SUCCESS');
export const DOCUMENT_REOPEN_ERROR = createAction<any>('DOCUMENT_REOPEN_ERROR');
export const DOCUMENT_SET_BOOKED_START = createAction<DocumentType>('DOCUMENT_SET_BOOKED_START');
export const DOCUMENT_SET_BOOKED_SUCCESS = createAction<DocumentType>('DOCUMENT_SET_BOOKED_SUCCESS');
export const DOCUMENT_SET_BOOKED_ERROR = createAction<any>('DOCUMENT_SET_BOOKED_ERROR');
export const DOCUMENT_SET_CURRENT = createAction<DocumentType | null>('DOCUMENT_SET_CURRENT');
export const DOCUMENT_SET_CURRENT_BOOKING_TIME = createAction<number>('DOCUMENT_SET_CURRENT_BOOKING_TIME');
export const DOCUMENT_GETLIST_BY_CLIENTIDS_SUCCESS = createAction<DocumentType[]>(
  'DOCUMENT_GETLIST_BY_CLIENTIDS_SUCCESS',
);
export const DOCUMENT_GETLIST_BY_CUSTOMERID_SUCCESS = createAction<DocumentType[]>(
  'DOCUMENT_GETLIST_BY_CUSTOMERID_SUCCESS',
);
export const DOCUMENT_DONE_ALREADY_EXISTING_SUCCESS = createAction<boolean>('DOCUMENT_DONE_ALREADY_EXISTING_SUCCESS');
export const DOCUMENTS_GET_INVOICE_INFORMATION_START = createAction<any>('DOCUMENTS_GET_INVOICE_INFORMATION_START');
export const DOCUMENTS_GET_INVOICE_INFORMATION_SUCCESS = createAction<object>(
  'DOCUMENTS_GET_INVOICE_INFORMATION_SUCCESS',
);
export const DOCUMENTS_GET_INVOICE_INFORMATION_ERROR = createAction<any>('DOCUMENTS_GET_INVOICE_INFORMATION_ERROR');

export const USER_GETLIST_START = createAction<string>('USER_GETLIST_START');
export const USER_GETLIST_SUCCESS = createAction<User[]>('USER_GETLIST_SUCCESS');
export const USER_GETLIST_ERROR = createAction<any>('USER_GETLIST_ERROR');
export const USER_UPDATE_START = createAction<User>('USER_UPDATE_START');
export const USER_UPDATE_SUCCESS = createAction<User>('USER_UPDATE_SUCCESS');
export const USER_UPDATE_ERROR = createAction<any>('USER_UPDATE_ERROR');

export const USER_STATISTICS_GETLIST_START = createAction<string>('USER_STATISTICS_GETLIST_START');
export const USER_STATISTICS_GETLIST_SUCCESS = createAction<BookingStatistic[]>('USER_STATISTICS_GETLIST_SUCCESS');
export const USER_STATISTICS_GETLIST_ERROR = createAction<any>('USER_STATISTICS_GETLIST_ERROR');

export const STRIPE_GET_CUSTOMER_START = createAction<string>('STRIPE_GET_CUSTOMER_START');
export const STRIPE_GET_CUSTOMER_SUCCESS = createAction<any>('STRIPE_GET_CUSTOMER_SUCCESS');
export const STRIPE_GET_CUSTOMER_ERROR = createAction<any>('STRIPE_GET_CUSTOMER_ERROR');
export const STRIPE_GET_CUSTOMER_PORTAL_URL_START = createAction<string>('STRIPE_GET_CUSTOMER_PORTAL_URL_START');
export const STRIPE_GET_CUSTOMER_PORTAL_URL_SUCCESS = createAction<string>('STRIPE_GET_CUSTOMER_PORTAL_URL_SUCCESS');
export const STRIPE_GET_CUSTOMER_PORTAL_URL_ERROR = createAction<any>('STRIPE_GET_CUSTOMER_PORTAL_URL_ERROR');
export const STRIPE_APPROVE_SESSION_START = createAction<string>('STRIPE_APPROVE_SESSION_START');
export const STRIPE_APPROVE_SESSION_SUCCESS = createAction<string>('STRIPE_APPROVE_SESSION_SUCCESS');
export const STRIPE_APPROVE_SESSION_ERROR = createAction<any>('STRIPE_APPROVE_SESSION_ERROR');

export const STORAGE_UPLOAD_START = createAction<File>('STORAGE_UPLOAD_START');
export const STORAGE_UPLOAD_SUCCESS = createAction<{ refPath: string; downloadUrl: string }>('STORAGE_UPLOAD_SUCCESS');
export const STORAGE_UPLOAD_ERROR = createAction<any>('STORAGE_UPLOAD_ERROR');
export const STORAGE_CREATE_START = createAction('STORAGE_CREATE_START');
export const STORAGE_CREATE_SUCCESS = createAction<Storage>('STORAGE_CREATE_SUCCESS');
export const STORAGE_CREATE_ERROR = createAction<any>('STORAGE_CREATE_ERROR');
export const STORAGE_GETLIST_START = createAction('STORAGE_GETLIST_START');
export const STORAGE_GETLIST_SUCCESS = createAction<Storage[]>('STORAGE_GETLIST_SUCCESS');
export const STORAGE_GETLIST_ERROR = createAction<any>('STORAGE_GETLIST_ERROR');
export const STORAGE_UPDATE_START = createAction<Storage>('STORAGE_UPDATE_START');
export const STORAGE_UPDATE_SUCCESS = createAction<Storage>('STORAGE_UPDATE_SUCCESS');
export const STORAGE_UPDATE_ERROR = createAction<any>('STORAGE_UPDATE_ERROR');
export const STORAGE_DELETE_START = createAction<Storage>('STORAGE_DELETE_START');
export const STORAGE_DELETE_SUCCESS = createAction<Storage>('STORAGE_DELETE_SUCCESS');
export const STORAGE_DELETE_ERROR = createAction<any>('STORAGE_DELETE_ERROR');
export const STORAGE_FOLDER_CREATE = createAction<string>('STORAGE_FOLDER_CREATE');
