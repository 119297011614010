import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

/**
 * Footer()
 * @constructor
 */
export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linkClasses = classNames('mb-3 ml-0 hover:underline cursor-pointer', 'md:ml-2');

  return (
    <div className={classNames('flex flex-1 flex-col px-10 py-10 bg-purple-700 text-white', 'lg:px-20 lg:pt-20')}>
      <div className={classNames('flex flex-1 flex-col', 'md:flex-row')}>
        <div className={classNames('flex flex-col flex-1 items-center mb-10', 'md:items-start')}>
          <div className={classNames('mb-4 font-bold text-xl uppercase bg-orange-400 px-4 py-2 rounded-md')}>
            Sotosoft S.L.
          </div>
          <div className={'ml-0 md:ml-2'}>Av. Ciro Gil, Soto Villa</div>
          <div className={'ml-0 md:ml-2'}>Edificio 2 - Oficina 23</div>
          <div className={'mb-6 ml-0 md:ml-2'}>11310 Sotogrande, Spain</div>
          <div className={'ml-0 md:ml-2'}>mail@sotodocs.com</div>
          <div className={'ml-0 md:ml-2'}>https://www.sotodocs.com</div>
        </div>
        <div className={classNames('flex flex-col flex-1 mb-10 items-center', 'md:items-start')}>
          <div className={classNames('mb-4 font-bold text-xl bg-orange-400 px-4 py-2 rounded-md uppercase')}>
            SotoDocs
          </div>
          <div className={linkClasses} onClick={() => navigate('register')}>
            {t('register')}
          </div>
          <div className={linkClasses} onClick={() => navigate('login')}>
            {t('modules.website.login')}
          </div>
          <div className={linkClasses} onClick={() => navigate('contact')}>
            {t('contact')}
          </div>
          <a className={linkClasses} href={'apidocumentation'} target={'_blank'} rel="noreferrer">
            Developer-API
          </a>
        </div>
        <div className={classNames('flex flex-col flex-1 items-center', 'md:items-start')}>
          <div className={classNames('mb-4 font-bold text-xl bg-orange-400 px-4 py-2 rounded-md')}>LINKS</div>
          <div className={linkClasses} onClick={() => navigate('/cookies')}>
            {t('cookiePolicy')}
          </div>
          <div className={linkClasses} onClick={() => navigate('/security')}>
            {t('security')}
          </div>
          <div className={linkClasses} onClick={() => navigate('/legal')}>
            {t('legalAdvice')}
          </div>
          <div className={linkClasses} onClick={() => navigate('/conditions')}>
            {t('conditions')}
          </div>
        </div>
      </div>
      <div className={'text-center mt-20 text-purple-400'}>
        &copy; Copyright 2024 by Sotosoft S.L. - All rights reserved
      </div>
    </div>
  );
}
