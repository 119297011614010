import React from 'react';
import classNames from 'classnames';
import Config from '../../../Library/Config';
import { Environment } from '../../../Library/Types/GeneralTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';

/**
 * ApiHeadline()
 * @constructor
 */
export default function ApiHeadline() {
  const { customer } = useAppSelector((state) => state.auth);

  const renderKey = () => {
    return (
      <div className={'flex flex-1 flex-row py-3 px-4 bg-gray-200 rounded-md font-bold text-xl mt-3 mb-10'}>
        <div className={'flex-1 font-normal'}>Authorization-Header:</div>
        <div>
          {customer && customer.settings && customer.settings.api && customer.settings.api.token ? (
            customer.settings.api.token
          ) : (
            <i>Your private API-Key</i>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classNames('text-4xl mb-6 font-bold text-purple-700')}>Overview</div>
      <div>
        In general all Endpoints needs to have an Authorization header with the private API-Key, which can be generated
        in the customer settings on SotoDocs Website or you can use OAuth for authentication and receiving the private
        key. Please be aware that the private key can change depending on the version and updates of the SotoDocs API.
        We will inform you as early as possible.
      </div>
      <div className={'flex flex-1 flex-row py-3 px-4 bg-gray-200 rounded-md font-bold text-xl mt-5'}>
        <div className={'flex-1 font-normal'}>Current Version:</div>
        <div>V1.0 (10.04.2024)</div>
      </div>
      <div className={'flex flex-1 flex-row py-3 px-4 bg-gray-200 rounded-md font-bold text-xl mt-3'}>
        <div className={'flex-1 font-normal'}>System:</div>
        <div>
          {Config.env === Environment.staging
            ? 'Demo'
            : Config.env === Environment.dev
              ? 'Local Development'
              : 'Production'}
        </div>
      </div>
      <div className={'flex flex-1 flex-row py-3 px-4 bg-gray-200 rounded-md font-bold text-xl mt-3'}>
        <div className={'flex-1 font-normal'}>Base-URL:</div>
        <div>{Config.apiEndpoint}</div>
      </div>
      {renderKey()}
    </>
  );
}
