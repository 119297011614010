import React from 'react';
import classNames from 'classnames';

interface Props {
  method: 'get' | 'post' | 'put' | 'delete';
}

/**
 * Method()
 * @param props
 * @constructor
 */
export default function Method(props: Props) {
  const { method } = props;

  return (
    <span
      className={classNames(
        'text-white py-1 px-2 rounded-md mr-2',
        { 'bg-blue-600': method === 'post' },
        { 'bg-green-600': method === 'get' },
        { 'bg-red-600': method === 'delete' },
        { 'bg-orange-600': method === 'put' },
      )}
    >
      {method.toUpperCase()}
    </span>
  );
}
