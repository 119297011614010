import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const step1 = require('../../Assets/Images/step1.png');
const step2 = require('../../Assets/Images/setp2.png');
const step3 = require('../../Assets/Images/step3.png');

/**
 * Process()
 * @constructor
 */
export default function Process() {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-1 flex-col p-10 bg-white', 'lg:px-32 lg:pt-20')}>
      <div className={classNames('pb-32')}>
        <div className={classNames('text-3xl text-center', 'lg:text-5xl')}>
          {t('modules.website.process.headline1')}
        </div>
        <div className={'text-center text-3xl pt-4 font-light'}>{t('modules.website.process.headline2')}</div>
      </div>

      <div>
        <div className={classNames('flex flex-1 flex-col-reverse mb-20', 'lg:flex-row lg:mb-0')}>
          <div className={classNames('w-full text-justify text-xl pr-0', 'lg:w-1/2 lg:pr-8')}>
            <div className={classNames('text-4xl font-light text-purple-700 mb-10')}>
              {t('modules.website.process.box1.headline')}
            </div>
            <div className={classNames('tracking-wide font-light')}>{t('modules.website.process.box1.text')}</div>
          </div>
          <div className={classNames('w-full mb-8 flex justify-center', 'lg:w-1/2')}>
            <img src={step1} alt={'Step 1'} />
          </div>
        </div>
      </div>

      <div>
        <div className={classNames('flex flex-1 flex-col mb-20', 'lg:flex-row lg:mb-0 lg:mt-32')}>
          <div className={classNames('w-full mb-8 flex justify-center', 'lg:w-1/2')}>
            <img src={step2} alt={'Step 2'} />
          </div>
          <div className={classNames('w-full text-justify text-xl pl-0', 'lg:w-1/2 lg:pl-8')}>
            <div className={classNames('text-4xl font-light text-purple-700 mb-10')}>
              {t('modules.website.process.box2.headline')}
            </div>
            <div className={classNames('tracking-wide font-light')}>{t('modules.website.process.box2.text')}</div>
          </div>
        </div>
      </div>

      <div>
        <div className={classNames('flex flex-1 flex-col-reverse mb-20', 'lg:flex-row lg:mt-32')}>
          <div className={classNames('w-full text-justify text-xl pr-0', 'lg:w-1/2 lg:pr-8')}>
            <div className={classNames('text-4xl font-light text-purple-700 mb-10')}>
              {t('modules.website.process.box3.headline')}
            </div>
            <div className={classNames('tracking-wide font-light')}>{t('modules.website.process.box3.text')}</div>
          </div>
          <div className={classNames('w-full mb-8 flex justify-center', 'lg:w-1/2')}>
            <img src={step3} alt={'Step 3'} />
          </div>
        </div>
      </div>
    </div>
  );
}
