import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const Inner = styled.div<{ $visible: boolean; $transitionTime: number }>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  height: ${(props) => (props.$visible ? '70px' : '70px')};
  margin-top: ${(props) => (props.$visible ? '0px' : '-120px')};
  transition: margin-top ${(props) => props.$transitionTime}ms ease-in-out;
  padding: 0 15px;
`;

interface Props {
  visible: boolean;
  onChange: (value: boolean) => void;
  icon: string;
  text: string;
  className: string;
  duration: number;
  transitionTime: number;
  showClose: boolean;
  closeBgClass?: string;
}

/**
 * AbstractCallout()
 * @param props
 * @constructor
 */
export default function AbstractCallout(props: Props) {
  const { visible, onChange, className, icon, text, duration, transitionTime, showClose, closeBgClass } = props;
  const [internalVisible, setInternalVisible] = React.useState<boolean>(false);

  const handleClose = React.useCallback(() => {
    setTimeout(() => onChange(false), 500);
    setInternalVisible(false);
  }, [onChange]);

  React.useEffect(() => {
    if (visible) {
      setInternalVisible(true);
      if (duration > 0) {
        setTimeout(() => handleClose(), duration);
      }
    }
  }, [duration, handleClose, visible]);

  const renderClose = () => {
    if (showClose) {
      return (
        <div className={classNames('px-4 py-3 cursor-pointer rounded-md', closeBgClass)} onClick={handleClose}>
          <i className={'fa fa-times'} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="fixed z-50 m-auto top-0 left-0 right-0 flex justify-center items-center">
      <Inner
        $transitionTime={transitionTime}
        $visible={internalVisible}
        className={`${className || 'bg-blue-700 text-white'} shadow-xl`}
      >
        <div className={'flex-grow pl-4'}>
          <i className={`${icon} mr-2`} />
          {text}
        </div>
        {renderClose()}
      </Inner>
    </div>
  );
}
