import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { DocumentState, DocumentType } from '../../Library/Types/DocumentTypes';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import Table from '../Table';
import { Client } from '../../Library/Types/ClientTypes';
import moment from 'moment';
import { clientBuildDisplayName } from '../../Library/Functions/ClientFunctions';
import { useNavigate } from 'react-router';
import classNames from 'classnames';

interface ClientGrouped {
  client: Client;
  count: number;
}

/**
 * GestorClientOverview()
 * @constructor
 */
export default function GestorClientOverview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { documents } = useAppSelector((state) => state.document);
  const { user, assignedClientIds, clients } = useAppSelector((state) => state.auth);
  const [myDocs, setMyDocs] = React.useState<DocumentType[]>();
  const [groupedByClient, setGroupedByClient] = React.useState<ClientGrouped[]>([]);

  React.useEffect(() => {
    if (documents && documents.length > 0) {
      const docs = documents.filter(
        (item) => assignedClientIds.indexOf(item.clientId) >= 0 && item.state === DocumentState.open,
      );
      setMyDocs(docs);
    }
  }, [assignedClientIds, documents]);

  React.useEffect(() => {
    if (myDocs) {
      const clientIds = new Set(
        myDocs
          .sort((a, b) => (moment(a.createdDate).isSameOrBefore(moment(b.createdDate)) ? 1 : -1))
          .map((item) => item.clientId),
      );

      const returnClients: Client[] = [];
      clientIds.forEach((item) => {
        const found = clients.find((client) => client.clientId === item);
        if (found) {
          returnClients.push(found);
        }
      });

      const grouped: ClientGrouped[] = [];
      returnClients.forEach((client) => {
        const filtered = myDocs.filter((item) => item.clientId === client.clientId);
        grouped.push({ client, count: filtered.length });
      });

      setGroupedByClient(grouped);
    }
  }, [clients, myDocs]);

  const renderRows = () => {
    if (groupedByClient && groupedByClient.length > 0) {
      return groupedByClient.map((group) => (
        <tr key={group.client.clientId} onClick={() => navigate(`/client/${group.client.clientId}`)}>
          <td>{clientBuildDisplayName(group.client)}</td>
          <td width={20}>{group.count}</td>
        </tr>
      ));
    }
    return null;
  };

  if (user.isCustomerUser && groupedByClient && groupedByClient.length > 0) {
    return (
      <Container headline={t('clientOpenDocuments')}>
        <div className={classNames('text-gray-500 text-sm', '-mt-4 mb-10 font-light')}>
          {t('clientOpenDocumentsDescription')}
        </div>
        <div className={'-m-4'}>
          <Table>
            <tbody>{renderRows()}</tbody>
          </Table>
        </div>
      </Container>
    );
  }
  return null;
}
