import React from 'react';
import CalloutSuccessfullySaved from '../../Components/Callouts/CalloutSuccessfullySaved';
import CalloutProgress from '../../Components/Callouts/CalloutProgress';
import CalloutInformation from '../../Components/Callouts/CalloutInformation';

/**
 * useSuccessCallout()
 */
export const useSuccessCallout = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [hide, setHide] = React.useState<boolean>(true);
  const [component, setComponent] = React.useState<React.ReactElement | null>();
  const [text, setText] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (!visible) {
      setHide(true);
    }
  }, [visible]);

  const showCallout = React.useCallback((calloutText?: string) => {
    setHide(false);
    setText(calloutText);
    setTimeout(() => setVisible(true), 100);
  }, []);

  React.useEffect(() => {
    if (hide) {
      setComponent(null);
    } else {
      setComponent(<CalloutSuccessfullySaved visible={visible} onChange={setVisible} text={text} />);
    }
  }, [hide, text, visible]);

  return { callout: component, showCallout };
};

/**
 * useProgressCallout()
 */
export const useProgressCallout = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [hide, setHide] = React.useState<boolean>(true);
  const [component, setComponent] = React.useState<React.ReactElement | null>();
  const [text, setText] = React.useState<string | undefined>(undefined);

  const showCallout = React.useCallback((calloutText?: string) => {
    setHide(false);
    setText(calloutText);
    setTimeout(() => setVisible(true), 100);
  }, []);

  const hideCallout = React.useCallback((calloutText?: string) => {
    setVisible(false);
    setText(calloutText);
    setTimeout(() => setHide(true), 500);
  }, []);

  React.useEffect(() => {
    if (hide) {
      setComponent(null);
    } else {
      setComponent(<CalloutProgress visible={visible} onChange={setVisible} text={text} />);
    }
  }, [hide, text, visible]);

  return { calloutProgress: component, showProgressCallout: showCallout, hideProgressCallout: hideCallout };
};

/**
 * useInformationCallout()
 */
export const useInformationCallout = () => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [hide, setHide] = React.useState<boolean>(true);
  const [component, setComponent] = React.useState<React.ReactElement | null>();
  const [text, setText] = React.useState<string>('');
  const [icon, setIcon] = React.useState<string | undefined>(undefined);

  const showCallout = React.useCallback((calloutText: string, icon?: string) => {
    setHide(false);
    setText(calloutText);
    setIcon(icon);
    setTimeout(() => setVisible(true), 100);
  }, []);

  const hideCallout = React.useCallback((calloutText: string, icon?: string) => {
    setVisible(false);
    setText(calloutText);
    setIcon(icon);
    setTimeout(() => setHide(true), 500);
  }, []);

  React.useEffect(() => {
    if (hide) {
      setComponent(null);
    } else {
      setComponent(<CalloutInformation visible={visible} onChange={setVisible} text={text} icon={icon} />);
    }
  }, [hide, icon, text, visible]);

  return { calloutInformation: component, showInformationCallout: showCallout, hideInformationCallout: hideCallout };
};
