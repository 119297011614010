import React from 'react';
import classNames from 'classnames';
import ProductBoxSmallBusiness from '../Subscription/ProductBox/ProductBoxSmallBusiness';
import ProductBoxProfessional from '../Subscription/ProductBox/ProductBoxProfessional';
import ProductBoxUnlimited from '../Subscription/ProductBox/ProductBoxUnlimited';
import { useTranslation } from 'react-i18next';
import Toggle from '../Forms/Toggle';

/**
 * PriceBox()
 * @constructor
 */
export default function PriceBox() {
  const { t } = useTranslation();
  const [monthly, setMonthly] = React.useState<boolean>(true);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a id={'price'} href={'/#'} content={''} />
      <div className={classNames('flex flex-1 flex-col px-6 py-20', 'lg:px-10 2xl:px-32')}>
        <div className={'text-center mb-20'}>
          <div className={classNames('text-5xl text-purple-700 font-bold')}>
            {t('modules.website.priceBox.headline')}
          </div>
          <div className={classNames('text-3xl pt-6 font-light')}>{t('modules.website.priceBox.text')}</div>
        </div>

        <div className={classNames('flex flex-1 flex-row mb-20 justify-center cursor-pointer')}>
          <Toggle onChange={() => setMonthly(!monthly)} value={!monthly} />
          <div className={classNames('ml-4 text-md', 'lg:text-2xl')} onClick={() => setMonthly(!monthly)}>
            {t('saveWithYearlySubscription')}
          </div>
        </div>

        <div className={classNames('flex flex-1 flex-col', 'lg:flex-row')}>
          <div className={classNames('basis-1/3 mb-8', 'lg:mr-4')}>
            <ProductBoxSmallBusiness
              onPress={() => {}}
              isMonthly={monthly}
              showBuyButton={false}
              headerClass={'bg-purple-400 text-white py-6'}
            />
          </div>
          <div className={classNames('basis-1/3 mb-8', 'lg:mr-4 lg:ml-4')}>
            <ProductBoxProfessional
              onPress={() => {}}
              isMonthly={monthly}
              showBuyButton={false}
              headerClass={'bg-orange-400 text-white py-6 font-bold'}
            />
          </div>
          <div className={classNames('basis-1/3 mb-0', 'lg:ml-4 lg:mb-8')}>
            <ProductBoxUnlimited
              onPress={() => {}}
              isMonthly={monthly}
              showBuyButton={false}
              headerClass={'bg-purple-400 text-white py-6'}
            />
          </div>
        </div>
      </div>
    </>
  );
}
