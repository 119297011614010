import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import ReactSelect, { OptionsOrGroups } from 'react-select';
import { useTranslation } from 'react-i18next';
import Lodash from 'lodash';

export type SelectOptionItem = { value: string | number | boolean; label: string };
export type SelectOptions = SelectOptionItem[];

interface SelectProps {
  onChange: (item: any) => void;
  label: string;
  initialValue: string | number | boolean | Array<string | number | boolean> | null;
  visible: boolean;
  options: OptionsOrGroups<any, any>;
  required: boolean;
  hasError: boolean;
  errorMessage?: string | null;
  disabled: boolean;
  contextHelpKey: string;
  showSkeleton: boolean;
  multiSelect: boolean;
  placeholder?: string;
  closeMenuOnSelect: boolean;
  optionAll?: SelectOptionItem;
  marginBottom: number;
  containerStyle?: object;
}

interface ContainerProps {
  $focused: boolean;
  $hasError: boolean;
  $marginBottom: number;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.$marginBottom}px;

  .skeleton {
    ${(props) => props.theme.templates.borderRadius};
    line-height: 56px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.borderColor};
    outline: none;
  }

  .CustomSelect__control {
    outline: none;
    border-radius: ${(props) => props.theme.borderRadius};
    padding-left: 18px;
    padding-right: 18px;

    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.borderColor};

    &:focus {
      background-color: ${() => lighten(0.47, 'gray')};
    }
  }

  .CustomSelect__value-container {
    padding: 0;
  }

  .CustomSelect__single-value {
    margin: 0;
    padding-left: 0;
    padding-top: 14px;
  }

  .CustomSelect__input-container {
    border: none;
    margin: 0;
    height: 56px;
    padding-bottom: 6px;
    padding-top: 22px;
  }

  .CustomSelect__placeholder {
    margin: 0;
    padding-top: 14px;
  }

  .Label {
    position: absolute;
    font-weight: 300;
    left: 19px;
    top: ${(props) => (props.$focused ? '6px' : '6px')};
    font-size: ${(props) => (props.$focused ? '14px' : '14px')};
    color: ${(props) => (props.$hasError ? lighten(0.15, props.theme.colors.red) : props.theme.colors.textLight)};
    transition:
      top 200ms,
      font-size 200ms,
      font-weight 200ms;
  }

  .RightIconContainer {
    position: absolute;
    right: 70px;
    top: 17px;

    i {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ErrorIcon {
      color: ${(props) => lighten(0.15, props.theme.colors.red)};
    }
    .DisabledIcon {
      color: ${(props) => lighten(0.15, props.theme.colors.textLight)};
    }
  }
`;

/**
 * Input()
 * @param props
 * @constructor
 */
export default function Select(props: SelectProps) {
  const {
    optionAll,
    options,
    initialValue,
    showSkeleton,
    multiSelect,
    placeholder,
    closeMenuOnSelect,
    marginBottom,
    containerStyle,
  } = props;
  const [currentValue, setCurrentValue] = React.useState<any>(null);
  const [optionsPrepared, setOptionsPrepared] = React.useState<OptionsOrGroups<any, any>>();
  const [t] = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (multiSelect && optionAll && options) {
      return setOptionsPrepared([optionAll, ...options]);
    }

    setOptionsPrepared(options);
  }, [multiSelect, optionAll, options]);

  React.useEffect(() => {
    if (initialValue !== undefined && initialValue !== null && options) {
      let receiversParam;
      if (multiSelect) {
        const listReceivers: Array<string> = initialValue as Array<string>;
        receiversParam =
          options && options.length > 0 ? options.filter((data) => listReceivers.indexOf(data.value) > -1) : null;
        if (receiversParam && receiversParam.length === 0) {
          receiversParam = null;
        }
      } else {
        receiversParam =
          options && options.length > 0 ? options.filter((data) => data.value === initialValue)[0] : null;
      }

      if (receiversParam) {
        setCurrentValue(receiversParam);
      }
    }
  }, [initialValue, multiSelect, options, setCurrentValue]);

  const manualFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const renderLabel = () => {
    if (multiSelect && currentValue && currentValue.length > 0) {
      return null;
    }

    const label = `${props.label}${props.required ? '*' : ''}`;
    return (
      <div className="Label" onClick={manualFocusInput}>
        {label}
      </div>
    );
  };

  const renderErrorIcon = () => {
    if (props.hasError) {
      return <i className="fas fa-exclamation-circle ErrorIcon" />;
    }
    return null;
  };

  const renderDisabledIcon = () => {
    if (props.disabled) {
      return <i className="fas fa-lock DisabledIcon" />;
    }
    return null;
  };

  const renderRightIcons = () => {
    const errorIcon = renderErrorIcon();
    const disabledIcon = renderDisabledIcon();

    if (errorIcon || disabledIcon) {
      return (
        <div className="RightIconContainer">
          {errorIcon}
          {disabledIcon}
        </div>
      );
    }
    return null;
  };

  const handleOnChange = (param: any) => {
    let currValue = param;
    if (multiSelect && optionAll) {
      if (Lodash.find(param, (p) => p.value === optionAll.value)) {
        currValue = Lodash.pullAll(options);
      }
    }
    setCurrentValue(currValue);

    if (multiSelect) {
      props.onChange(currValue.map((item: any) => item.value));
    } else {
      props.onChange(currValue.value);
    }
  };

  const renderContent = () => {
    if (!showSkeleton) {
      return (
        <>
          <ReactSelect
            onChange={handleOnChange}
            classNamePrefix="CustomSelect"
            placeholder={placeholder || t('pleaseSelect')}
            options={optionsPrepared}
            isDisabled={props.disabled}
            isMulti={multiSelect}
            closeMenuOnSelect={closeMenuOnSelect}
            value={currentValue}
          />
          {renderLabel()}
          {renderRightIcons()}
        </>
      );
    }
    return null;
  };

  if (props.visible) {
    return (
      <Container
        $focused={!!props.initialValue}
        $hasError={props.hasError}
        $marginBottom={marginBottom}
        style={containerStyle}
      >
        {renderContent()}
      </Container>
    );
  }
  return null;
}

Select.defaultProps = {
  visible: true,
  initialValue: null,
  required: false,
  hasError: false,
  errorMessage: null,
  disabled: false,
  contextHelpKey: null,
  showSkeleton: false,
  multiSelect: false,
  placeholder: null,
  closeMenuOnSelect: true,
  marginBottom: 15,
};
