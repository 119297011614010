import React from 'react';
import { useAppSelector } from '../Library/Hooks/ReduxHooks';

interface Props {
  children: React.ReactElement;
}

/**
 * BetaContainer()
 * @param props
 * @constructor
 */
export default function BetaContainer(props: Props) {
  const { children } = props;
  const { customer, user } = useAppSelector((state) => state.auth);

  if (user.isCustomerUser) {
    if (customer.isBeta) {
      return children;
    }
  } else {
    if (user.isBeta) {
      return children;
    }
  }
  return null;
}
