import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

export interface DropDownItem {
  caption: string;
  onPress: () => void;
  icon?: string;
  separatorBottom?: boolean;
  textColor?: string;
}

interface Props {
  caption?: string;
  icon?: string;
  items: DropDownItem[];
  buttonClass?: string;
}

/**
 * DropDownButton()
 * @constructor
 */
export default function DropDownButton(props: Props) {
  const { caption, icon, items, buttonClass } = props;

  const renderIcon = (item: DropDownItem) => {
    if (item.icon) {
      return <i className={classNames(item.icon, 'mr-3')} />;
    }
    return null;
  };

  const renderItems = () =>
    items.map((item) => (
      <Menu.Item key={item.caption}>
        {({ active }) => (
          <div
            onClick={item.onPress}
            className={classNames(
              { 'bg-gray-100 text-gray-900': active },
              { 'text-gray-700': !active },
              'block px-4 py-3 cursor-pointer',
              { 'border-b': item.separatorBottom },
              item.textColor,
            )}
          >
            {renderIcon(item)}
            {item.caption}
          </div>
        )}
      </Menu.Item>
    ));

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={classNames(
            'inline-flex w-full justify-center',
            'gap-x-1.5 rounded-md bg-white',
            'px-3 py-2 items-center',
            { 'ring-inset ring-gray-300 hover:bg-gray-50 hover:text-black shadow-sm ring-1': !buttonClass },
            buttonClass,
          )}
        >
          {caption || <i className={`${icon} py-1 px-1 text-gray-400`} aria-hidden="true" />}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="
            absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1
            ring-black ring-opacity-5 focus:outline-none
          "
        >
          <div className="py-1">{renderItems()}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
