export enum DocumentState {
  uploaded = 'uploaded',
  open = 'open',
  question = 'question',
  done = 'done',
  unreadable = 'unreadable',
  notBookable = 'notBookable',
  notDeductible = 'notDeductible',
}

export enum InvoiceType {
  unknown = 'unknown',
  incoming = 'incoming',
  outgoing = 'outgoing',
  bank = 'bank',
}

export interface DocumentMessage {
  createdDate: any;
  createdUserId: string;
  createdDisplayName: string;
  message: string;
  isRead: boolean;
  isCustomerMessage: boolean;
  isInternal: boolean;
}

export interface StateHistory {
  dateTime: string;
  userId: string;
  state: DocumentState;
}

export interface DocumentType {
  documentId: string;
  creditor?: string;
  clientId: string;
  customerId: string;
  type?: InvoiceType;
  bankName: string;
  bankIban: string;
  bankStartDate: string;
  bankEndDate: string;
  invoicingParty?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  additionalInformation?: string;
  fileType: string;
  size: number;
  state: DocumentState;
  documentName: string;
  originalName: string;
  refPath: string;
  downloadUrl: string;
  firebaseTimestamp: any; // For sorting and query
  firebaseInvoiceDate?: any; // For sorting and query
  createdUserId: string;
  createdDate: string;
  updatedUserId?: string;
  updatedDate?: string;
  finishedDate?: string;
  finishedUserId?: string;
  bookingTime: number;
  messages: DocumentMessage[];
  stateHistory: StateHistory[];
}
