import { useAppSelector } from './ReduxHooks';
import React from 'react';
import { DocumentType } from '../Types/DocumentTypes';
import { useDispatchLogOut } from '../../Redux/Actions/AuthActions';

/**
 * useCheckCustomerAccess()
 */
export const useCheckCustomerAccess = () => {
  const { user } = useAppSelector((state) => state.auth);
  const signOut = useDispatchLogOut();

  React.useEffect(() => {
    if (user && (!user.isCustomerUser || !user.isActive)) {
      signOut().then(() => {
        window.location.href = '/login';
      });
    }
  }, [signOut, user]);
};

/**
 * useCheckAdminAccess()
 */
export const useCheckAdminAccess = () => {
  const { user } = useAppSelector((state) => state.auth);
  const signOut = useDispatchLogOut();

  React.useEffect(() => {
    if (user && (!user.isCustomerAdmin || !user.isCustomerUser || !user.isActive)) {
      signOut().then(() => {
        window.location.href = '/login';
      });
    }
  }, [signOut, user]);
};

/**
 * useCheckDocumentAccess()
 */
export const useCheckDocumentAccess = (document: DocumentType | null) => {
  const { user, clients } = useAppSelector((state) => state.auth);
  const signOut = useDispatchLogOut();

  React.useEffect(() => {
    if (document && clients) {
      if (user.isCustomerUser) {
        const found = clients.find((item) => item.clientId === document.clientId);
        if (!found) {
          signOut().then(() => {
            window.location.href = '/login';
          });
        }
      } else {
        const found = clients.find((item) => item.clientId === document.clientId);
        if (!found) {
          signOut().then(() => {
            window.location.href = '/login';
          });
        }
      }
    }
  }, [clients, document, signOut, user]);
};

/**
 * useCheckDocumentAccess()
 */
export const useCheckHasClientAccess = (clientId: string | undefined) => {
  const { user, clients } = useAppSelector((state) => state.auth);
  const signOut = useDispatchLogOut();

  React.useEffect(() => {
    if (clientId && clients) {
      const found = clients.find((item) => item.clientId === clientId);
      if (!found) {
        signOut().then(() => {
          window.location.href = '/login';
        });
      }
    }
  }, [clientId, clients, signOut, user]);
};
