import React from 'react';
import { DocumentState, DocumentType } from '../../../Library/Types/DocumentTypes';
import Button from '../../Forms/Button';
import { useTranslation } from 'react-i18next';
import { useLoadingModal } from '../../../Library/Hooks/GlobalHooks';
import { useDispatchDocumentUploadUpdate } from '../../../Redux/Actions/DocumentActions';

interface Props {
  document: DocumentType;
  onChange: (document: DocumentType) => void;
}

/**
 * UpdateDocumentButton()
 * @param props
 * @constructor
 */
export default function UpdateDocumentButton(props: Props) {
  const { document, onChange } = props;
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const { setLoading, loadingModal, setTextLine1 } = useLoadingModal({ headline: t('updatingDocument') });

  const dispatchUploadUpdate = useDispatchDocumentUploadUpdate();

  const handleOnChange = React.useCallback(
    (file: File) => {
      setLoading(true);
      dispatchUploadUpdate(file, document, (percent) => setTextLine1(`${percent}%`))
        .then((docResponse) => {
          onChange(docResponse);
        })
        .finally(() => setLoading(false));
    },
    [dispatchUploadUpdate, document, onChange, setLoading, setTextLine1],
  );

  if (document.state === DocumentState.unreadable || document.state === DocumentState.notBookable) {
    return (
      <>
        <Button onPress={() => inputRef.current?.click()} colorStyle={'primary'} icon={'fas fa-cloud-upload'}>
          {t('updateDocument')}
        </Button>

        <input
          multiple={false}
          type={'file'}
          ref={inputRef}
          style={{ display: 'none' }}
          accept={'image/png,image/jpg,image/jpeg,image/bmp,application/pdf'}
          onChange={(e) => (e.target.files ? handleOnChange(e.target.files[0]) : () => {})}
        />

        {loadingModal}
      </>
    );
  }
  return null;
}
