import React from 'react';
import { useTranslation } from 'react-i18next';
import ListBox, { ListBoxItem } from '../ListBox';
import { Language } from '../../../Library/Types/GeneralTypes';

interface Props {
  onChange: (item: Language) => void;
  selected: Language;
  required?: boolean;
  caption?: string;
  hasError?: boolean;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
}

/**
 * ListBoxSelectLanguage
 * @param props
 * @constructor
 */
export default function ListBoxSelectLanguage(props: Props) {
  const { onChange, direction, selected, required, caption, hasError, hint, containerClass } = props;
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<ListBoxItem<Language>[]>([]);

  React.useEffect(() => {
    setOptions([
      { value: Language.de, label: t(`languages.${Language.de}`) },
      { value: Language.es, label: t(`languages.${Language.es}`) },
      { value: Language.en, label: t(`languages.${Language.en}`) },
    ]);
  }, [t]);

  if (options && options.length > 0) {
    return (
      <ListBox<Language>
        onChange={onChange}
        options={options}
        selected={selected}
        label={caption !== undefined ? caption : t('language')}
        required={required}
        hasError={hasError}
        hint={hint}
        containerClass={containerClass}
        direction={direction}
      />
    );
  }
  return null;
}

ListBoxSelectLanguage.defaultProps = {
  required: false,
  direction: 'row',
};
