/**
 * This is the configuration file for environment depending on settings for the project!
 * @type {{domain: string, apiGateway: {url: string}, env: string}}
 */
import { Environment } from './Types/GeneralTypes';

const dev = {
  env: Environment.dev,
  domain: 'http://localhost:3000',
  loginDomain: 'https://demologin.sotodocs.com',
  apiEndpoint: 'http://localhost:3000/api',
  localhostIp: '192.168.1.52',
  google: {
    apiKey: 'AIzaSyAISzej2mBC65NK9HFynagIPmP4D9TfiOI',
    geoCodingUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={{apiKey}}&address={{address}}',
  },
  whatsAppUrl: 'https://api.whatsapp.com/message/PZMZGGEULDBPJ1?autoload=1&app_absent=0',
  firebase: {
    region: 'europe-west3',
    config: {
      apiKey: 'AIzaSyBCVtQtr4c4PJDZ6gYoZGRvI6BanPUnYoU',
      authDomain: 'sotodocs-production.firebaseapp.com',
      projectId: 'sotodocs-production',
      storageBucket: 'sotodocs-production.appspot.com',
      messagingSenderId: '696923335897',
      appId: '1:696923335897:web:6bb7ce7a36c34022cfa2c8',
      measurementId: 'G-71TFJF284Q',
    },
  },
  stripe: {
    apikey:
      'pk_test_51OetqIDqRmi25w113lV9A2UlG83V7Pv4xJFP0uoF0VE4lXYZQJvLkW5ZNy65JRtPMORywdhAUaAxM2KrgonvKXuy00qvrp5YKd',
    pricePoints: {
      smallBusiness: {
        monthly: 'price_1PBw1ADqRmi25w11cDCwK2vC',
        yearly: 'price_1PD7ErDqRmi25w11RdC38fWk',
      },
      professional: {
        monthly: 'price_1PD7NgDqRmi25w11GtZLL3A2',
        yearly: 'price_1PD7O9DqRmi25w11R6UY75q3',
      },
      unlimited: {
        monthly: 'price_1PD7QCDqRmi25w115aTquaoA',
        yearly: 'price_1PD7QYDqRmi25w11zGJ9ZWSK',
      },
    },
  },
};

const staging = {
  env: Environment.staging,
  domain: 'https://demo.sotodocs.com',
  loginDomain: 'https://demologin.sotodocs.com',
  apiEndpoint: 'http://demoapi.sotodocs.com/api',
  localhostIp: null,
  google: {
    apiKey: 'AIzaSyAISzej2mBC65NK9HFynagIPmP4D9TfiOI',
    geoCodingUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={{apiKey}}&address={{address}}',
  },
  whatsAppUrl: 'https://api.whatsapp.com/message/PZMZGGEULDBPJ1?autoload=1&app_absent=0',
  firebase: {
    region: 'europe-west3',
    config: {
      apiKey: 'AIzaSyDildwcwaHGGmJNOiKzrFV-COub0qkI8jI',
      authDomain: 'sotodocs-staging.firebaseapp.com',
      projectId: 'sotodocs-staging',
      storageBucket: 'sotodocs-staging.appspot.com',
      messagingSenderId: '386603885341',
      appId: '1:386603885341:web:dc586989f2a09bf96bf7f4',
      measurementId: 'G-W2533BQBG4',
    },
  },
  stripe: {
    apikey:
      'pk_test_51OetqIDqRmi25w113lV9A2UlG83V7Pv4xJFP0uoF0VE4lXYZQJvLkW5ZNy65JRtPMORywdhAUaAxM2KrgonvKXuy00qvrp5YKd',
    pricePoints: {
      smallBusiness: {
        monthly: 'price_1PBw1ADqRmi25w11cDCwK2vC',
        yearly: 'price_1PD7ErDqRmi25w11RdC38fWk',
      },
      professional: {
        monthly: 'price_1PD7NgDqRmi25w11GtZLL3A2',
        yearly: 'price_1PD7O9DqRmi25w11R6UY75q3',
      },
      unlimited: {
        monthly: 'price_1PD7QCDqRmi25w115aTquaoA',
        yearly: 'price_1PD7QYDqRmi25w11zGJ9ZWSK',
      },
    },
  },
};

const production = {
  env: Environment.production,
  domain: 'https://www.sotodocs.com',
  loginDomain: 'https://login.sotodocs.com',
  apiEndpoint: 'http://api.sotodocs.com/api',
  localhostIp: null,
  google: {
    apiKey: 'AIzaSyAISzej2mBC65NK9HFynagIPmP4D9TfiOI',
    geoCodingUrl: 'https://maps.googleapis.com/maps/api/geocode/json?key={{apiKey}}&address={{address}}',
  },
  whatsAppUrl: 'https://api.whatsapp.com/message/PZMZGGEULDBPJ1?autoload=1&app_absent=0',
  firebase: {
    region: 'europe-west3',
    config: {
      apiKey: 'AIzaSyBCVtQtr4c4PJDZ6gYoZGRvI6BanPUnYoU',
      authDomain: 'sotodocs-production.firebaseapp.com',
      projectId: 'sotodocs-production',
      storageBucket: 'sotodocs-production.appspot.com',
      messagingSenderId: '696923335897',
      appId: '1:696923335897:web:6bb7ce7a36c34022cfa2c8',
      measurementId: 'G-71TFJF284Q',
    },
  },
  stripe: {
    apikey:
      'pk_live_51OetqIDqRmi25w11SXJRaPn6FgrEXcfJI2G0J7IIOgKD77WaAlH6aNh3POEOjNMjwriTO9jLW742Jpx8S2KIanEB00MoMYord2',
    pricePoints: {
      smallBusiness: {
        monthly: 'price_1PMTAtDqRmi25w11TdIqhMhx',
        yearly: 'price_1PMTAqDqRmi25w11qA9CTagd',
      },
      professional: {
        monthly: 'price_1PMTCnDqRmi25w11crJJNmg8',
        yearly: 'price_1PMTHpDqRmi25w11xqpAlxmf',
      },
      unlimited: {
        monthly: 'price_1PMTAkDqRmi25w11UE0JjsuO',
        yearly: 'price_1PMTAgDqRmi25w11sGU0GRVL',
      },
    },
  },
};

let config: any = production;
if (process.env.REACT_APP_STAGE === Environment.dev) {
  config = dev;
} else if (process.env.REACT_APP_STAGE === Environment.staging) {
  config = staging;
}

export default { ...config };
