export interface ValidateRegisterClientUserErrors {
  hasErrors: boolean;
  firstName: boolean;
  lastName: boolean;
  mail: boolean;
  password: boolean;
  passwordRepeat: boolean;
  legal: boolean;
  errorCode: string;
}

const validateRegisterClientUser = (
  firstName: string,
  lastName: string,
  mail: string,
  password: string,
  passwordRepeat: string,
  legal: boolean,
): ValidateRegisterClientUserErrors => {
  let response: ValidateRegisterClientUserErrors = {
    hasErrors: false,
    firstName: false,
    lastName: false,
    mail: false,
    password: false,
    passwordRepeat: false,
    legal: false,
    errorCode: '',
  };

  if (!lastName || lastName.length < 2) {
    response = { ...response, hasErrors: true, lastName: true };
  }

  if (!firstName || firstName.length < 2) {
    response = { ...response, hasErrors: true, firstName: true };
  }

  if (!mail || mail.indexOf('@') < 0 || mail.indexOf('.') < 0) {
    response = { ...response, hasErrors: true, mail: true };
  }

  if (!password) {
    response = { ...response, hasErrors: true, password: true };
  }

  if (!passwordRepeat) {
    response = { ...response, hasErrors: true, passwordRepeat: true };
  }

  if (passwordRepeat !== password) {
    response = { ...response, hasErrors: true, passwordRepeat: true, password: true };
  }

  if (!legal) {
    response = { ...response, hasErrors: true, legal: true };
  }

  return response;
};

export default validateRegisterClientUser;
