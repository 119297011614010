import { useDispatchClientGetListByIds } from '../../../Redux/Actions/ClientActions';
import React from 'react';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { useDispatchCustomerGetList } from '../../../Redux/Actions/CustomerActions';
import Lodash from 'lodash';

/**
 * useLoadUserDashboard()
 */
export const useLoadUserDashboard = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { customers } = useAppSelector((state) => state.client);

  const dispatchLoadClients = useDispatchClientGetListByIds();
  const dispatchCustomersGet = useDispatchCustomerGetList();

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatchLoadClients(user.clients).then((clients) => {
        if (!customers || customers.length <= 0) {
          const ids = Lodash.uniq(clients.map((item) => item.customerId));
          return dispatchCustomersGet(ids);
        }
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [customers, dispatchCustomersGet, dispatchLoadClients, user.clients]);
};
