import React from 'react';
import { useCheckCustomerAccess } from '../../../Library/Hooks/AccessHooks';
import { useNavigate, useParams } from 'react-router';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import { useTranslation } from 'react-i18next';
import { Client } from '../../../Library/Types/ClientTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { clientBuildDisplayName } from '../../../Library/Functions/ClientFunctions';
import Container from '../../../Components/Container/Container';
import DisplayRow from '../../../Components/Display/DisplayRow';
import moment from 'moment';
import { useDispatchClientDelete, useDispatchClientGetUsers } from '../../../Redux/Actions/ClientActions';
import { User } from '../../../Library/Types/UserTypes';
import ConnectedAccounts from '../../../Components/Client/Details/ConnectedAccounts';
import ActionButton from '../../../Components/Container/ActionButton';
import OpenDocuments from '../../../Components/Mandant/OpenDocuments';
import { useCustomerUseHasAccess } from '../../../Library/Hooks/CustomerHooks';
import AskDeleteModal from '../../../Components/Modals/AskDeleteModal';

/**
 * Details()
 * @constructor
 */
export default function Details() {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { t } = useTranslation();
  const { clients, users, user, customer } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const [client, setClient] = React.useState<Client>();
  const [clientUsers, setClientUsers] = React.useState<User[]>([]);
  const [askDelete, setAskDelete] = React.useState<boolean>(false);
  const navContext = React.useContext(NavbarContext);
  const hasAccess = useCustomerUseHasAccess('mandant.update');
  useCheckCustomerAccess();

  const dispatchGetUsers = useDispatchClientGetUsers();
  const dispatchDelete = useDispatchClientDelete();

  const handleDelete = React.useCallback(() => {
    if (client) {
      dispatchDelete(client).then(() => {
        navigate(`/client`);
      });
    }
  }, [client, dispatchDelete, navigate]);

  React.useEffect(() => {
    if (client && client.isConnected && !clientUsers) {
      dispatchGetUsers(client.clientId).then(setClientUsers);
    }
  }, [client, clientUsers, dispatchGetUsers]);

  React.useEffect(() => {
    if (clients && clientId && !client) {
      const found = clients.find((c) => c.clientId === clientId);
      if (found) {
        setClient(found);
      }
    }
  }, [client, clientId, clients]);

  React.useEffect(() => {
    if (client) {
      navContext.setShowBack(true);
      navContext.setHeadline(`${t('client')} / ${clientBuildDisplayName(client)}`);
    }
  }, [client, navContext, navigate, t, user.isCustomerAdmin]);

  const getAssignedUser = () => {
    if (client) {
      const user = users.find((user) => user.userId === client.assignedUserId);
      if (user) {
        return <DisplayRow label={t('responsibleUser')} value={`${user.firstName} ${user.lastName}`} />;
      }
    }
    return null;
  };

  const getCreatedUser = () => {
    if (client) {
      const user = users.find((user) => user.userId === client.createdUserId);
      if (user) {
        return <DisplayRow label={t('createdBy')} value={`${user.firstName} ${user.lastName}`} />;
      }
    }
    return null;
  };

  const renderGroup = () => {
    if (client && client.groupId) {
      if (customer.settings && customer.settings.client && customer.settings.client.groups) {
        const found = customer.settings.client.groups.find((item) => item.id === client.groupId);
        if (found) {
          return <DisplayRow label={t('clientGroup')} value={found.name} />;
        }
      }
    }
    return null;
  };

  const getStateElement = (state: boolean) => {
    return (
      <div className={'items-center'}>
        <i className={`fas fa-circle mr-2 ${state ? 'text-green-600' : 'text-red-600'}`} />
        {t(`${state ? 'active' : 'inactive'}`)}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <>
        <ActionButton
          icon={'fas fa-trash-alt'}
          onPress={() => setAskDelete(true)}
          className={'hover:bg-red-500 hover:text-white'}
          visible={user.isCustomerAdmin && !client?.isConnected}
          tooltip={t('edit')}
        />
        <ActionButton
          icon={'fas fa-pencil'}
          onPress={() => navigate(`/client/update/${client?.clientId}`)}
          className={'hover:bg-blue-500 hover:text-white'}
          visible={hasAccess}
          tooltip={t('edit')}
        />
      </>
    );
  };

  if (client) {
    return (
      <>
        <Container
          closable
          headline={t('details')}
          identifier={'MandantDetailsDetails'}
          actionButtons={renderButtons()}
        >
          <DisplayRow label={'SotoDoc ID'} value={client.clientId} showCopyIcon />
          <DisplayRow label={t('name')} value={clientBuildDisplayName(client)} />
          <DisplayRow label={t('taxNumber')} value={client.taxNumber} />
          <DisplayRow label={t('mail')} value={client.mail} />
          <DisplayRow label={t('internalClientId')} value={client.internalId} />
          {getAssignedUser()}
          <DisplayRow label={t('language')} value={t(`languages.${client.language}`)} />
          {renderGroup()}

          <div className={'mt-6'} />
          <DisplayRow label={t('allowOutgoing')} value={getStateElement(client.allowOutgoing)} />
          <DisplayRow label={t('allowBank')} value={getStateElement(client.allowBank)} />
          <div className={'mt-6'} />
          <DisplayRow label={t('state')} value={getStateElement(client.isActive)} />
          <DisplayRow label={t('allowNewRegistrations')} value={getStateElement(client.registrationActive)} />

          <div className={'mt-6'} />
          <DisplayRow label={t('createdAt')} value={moment(client.createdDate).format('LL')} />
          {getCreatedUser()}
        </Container>

        <ConnectedAccounts client={client} />

        <OpenDocuments documents={documents.filter((item) => item.clientId === clientId)} clientId={clientId} />

        <AskDeleteModal
          headline={t('alert.askDeleteClient.headline')}
          text={t('alert.askDeleteClient.text', { name: clientBuildDisplayName(client) })}
          visible={askDelete}
          onClose={() => setAskDelete(false)}
          onDelete={handleDelete}
        />
      </>
    );
  }
  return null;
}
