import React from 'react';
import Mobile from './Mobile';
import Responsive from './Responsive';
import { useLocation } from 'react-router';
interface Props {
  showPrice: boolean;
  showStartPage: boolean;
  showRegister: boolean;
  showLogin: boolean;
}

/**
 * Navigation()
 * @constructor
 */
export default function Navigation(props: Props) {
  const { showPrice, showStartPage, showRegister, showLogin } = props;
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Mobile showPrice={showPrice} showStartPage={showStartPage} showRegister={showRegister} showLogin={showLogin} />
      <Responsive
        showPrice={showPrice}
        showStartPage={showStartPage}
        showRegister={showRegister}
        showLogin={showLogin}
      />
    </>
  );
}
