import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from '../../Components/Forms/Button';
import { useLocation, useNavigate } from 'react-router';
import RegisterAndLoginFooter from '../../Components/RegisterAndLoginFooter';
import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { doc, DocumentReference, getFirestore, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { FirebasePath } from '../../Library/Firebase';
import { Customer } from '../../Library/Types/CustomerTypes';
import { CUSTOMER_GET_SUCCESS } from '../../Redux/ActionTypes';
import ShowHideContainer from '../../Components/ShowHideContainer';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatchStripeApproveSession } from '../../Redux/Actions/StripeActions';

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Box = styled.div`
  flex: 1;
  overflow: hidden;
  padding: 50px 0;
`;

/**
 * CheckoutSuccess()
 * @constructor
 */
export default function CheckoutSuccess() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [done, setDone] = React.useState<boolean>(false);
  const [authed, setAuthed] = React.useState<boolean>(false);
  const [countDown, setCountDown] = React.useState<number>(9);
  const [countDownStarted, setCountDownStarted] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const sessionId = new URLSearchParams(search).get('session_id');

  const dispatchApproveSession = useDispatchStripeApproveSession();

  const handleAuthStateChange = React.useCallback((state: any) => {
    if (state) {
      setAuthed(true);
    }
  }, []);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!customer.subscriptionActive || !customer.stripeCustomerId) {
      timer = setTimeout(() => {
        if (sessionId) {
          dispatchApproveSession(sessionId);
        }
      }, 10000);
    }

    return () => clearTimeout(timer);
  }, [customer.stripeCustomerId, customer.subscriptionActive, dispatchApproveSession, sessionId]);

  React.useEffect(() => {
    let interval: NodeJS.Timer;

    if (customer.subscriptionActive && countDown > 0) {
      interval = setInterval(() => {
        setCountDownStarted(true);
        const count = countDown - 1;

        if (count <= 0) {
          navigate('/settings/subscription');
        }

        setCountDown(count);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countDown, customer.subscriptionActive, navigate]);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  React.useEffect(() => {
    let unsubscribe: Unsubscribe;

    if (!done && customer.customerId && authed) {
      const docRef = doc(getFirestore(), FirebasePath.customers, customer.customerId) as DocumentReference<Customer>;

      unsubscribe = onSnapshot(docRef, (snapShot) => {
        if (snapShot.exists()) {
          const customer = { ...snapShot.data(), customerId: snapShot.id };
          if (customer) {
            if (customer.subscriptionActive) {
              setDone(true);
              setLoading(false);
              dispatch(CUSTOMER_GET_SUCCESS(customer));
            }
          }
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [authed, customer, dispatch, done]);

  const renderCountDown = () => {
    if (countDownStarted) {
      return (
        <div className={'mt-10 text-gray-400'}>
          <div>{t('redirectingIn', { countDown })}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={'flex flex-1'}>
      <div className={'basis-0 sm:basis-0 md:basis-1/12 lg:basis-1/12 xl:basis-1/6 2xl:basis-3/12'} />
      <div className={'basis-6/6 sm:basis-6/6 md:basis-10/12 lg:basis-10/12 xl:basis-4/6 2xl:basis-6/12'}>
        <MainContainer id="checkout" className={'bg-gray-100 mt-20 flex-col'}>
          <Box className={'shadow-2xl rounded-xl items-center bg-white flex flex-col'}>
            <i className={'fas fa-check-circle text-green-700'} style={{ fontSize: 80 }} />
            <div className={'text-4xl mt-6'}>{t('checkoutSuccessful')}</div>
            <div className={'mt-2 text-xl font-light'}>{t('checkoutSuccessfulDescription1')}</div>
            <div className={'text text font-light text-center mx-20 mt-8'}>{t('checkoutSuccessfulDescription2')}</div>

            <ShowHideContainer visible={loading}>
              <div className={'mt-20 bg-blue-500 px-8 py-5 rounded-xl text-white text-center text-xl'}>
                Bitte haben Sie noch einen Moment Geduld!
                <br /> Ihr Abonnement wird eingerichtet...
              </div>
            </ShowHideContainer>

            <Button
              onPress={() => navigate('/settings/subscription')}
              colorStyle={'success'}
              className={'mt-20 h-14'}
              disabled={loading}
            >
              <div className={'px-10 w-80'}>
                {loading ? <i className={'fas fa-spin fa-spinner'} /> : t('backToSotodocs')}
              </div>
            </Button>

            {renderCountDown()}
          </Box>

          <RegisterAndLoginFooter />
        </MainContainer>
      </div>
      <div className={'basis-0 sm:basis-0 md:basis-1/12 lg:basis-1/12 xl:basis-1/6 2xl:basis-3/12'} />
    </div>
  );
}
