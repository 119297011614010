import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { DocumentType } from '../../Library/Types/DocumentTypes';
import { useDispatchDocumentDownloadBase64 } from '../../Redux/Actions/DocumentActions';
import { Document, Page } from 'react-pdf';
import { useTranslation } from 'react-i18next';

interface Props {
  document: DocumentType | null;
  onClose: () => void;
}

/**
 * PreviewModal()
 * @param props
 * @constructor
 */
export default function PreviewModal(props: Props) {
  const { document, onClose } = props;
  const { t } = useTranslation();
  const [base64, setBase64] = React.useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatchDownload = useDispatchDocumentDownloadBase64();

  React.useEffect(() => {
    if (!document) {
      setBase64('');
    }
  }, [document]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!base64 && document && document.fileType.indexOf('pdf') >= 0) {
      timer = setTimeout(() => {
        setLoading(true);
        dispatchDownload(document)
          .then((response) => {
            setTimeout(() => setBase64(response), 500);
            return Promise.resolve();
          })
          .finally(() => setLoading(false));
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [base64, dispatchDownload, document]);

  const renderPdf = () => {
    if (document && document.fileType.indexOf('pdf') >= 0) {
      if (base64 && !loading) {
        return (
          <Document file={base64}>
            <Page
              key={`page_1`}
              pageNumber={1}
              height={window.innerHeight * 0.8}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              className={'d-none'}
            />
          </Document>
        );
      }
      return (
        <div className={'text-center p-10'}>
          <i className={'fad fa-spin fa-spinner-third text-4xl text-blue-700'} />
        </div>
      );
    }
    return null;
  };

  const renderImage = () => {
    if (document && document.fileType.indexOf('image') >= 0) {
      return <img src={document.downloadUrl} style={{ maxHeight: window.innerHeight * 0.8 }} alt={'Preview'} />;
    }
    return null;
  };

  return (
    <Transition appear show={!!document} as={Fragment}>
      <Dialog as="div" className="relative z-10 overflow-y-hidden" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'w-auto min-w-96 transform overflow-hidden rounded-2xl bg-white',
                  'p-6 text-left align-middle shadow-xl transition-all',
                )}
              >
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  <div className={'flex flex-1'}>
                    <div className={'flex-1'}>{t('preview')}</div>
                    <div>
                      <i
                        className={'fas fa-times-circle cursor-pointer text-gray-400 hover:text-black'}
                        onClick={onClose}
                      />
                    </div>
                  </div>
                </Dialog.Title>
                <div className="mt-2">
                  {renderPdf()}
                  {renderImage()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
