import React from 'react';

interface Props {
  caption?: string;
  onChange: (value: boolean) => void;
  value: boolean;
}

/**
 * Toggle()
 * @param props
 * @constructor
 */
export default function Toggle(props: Props) {
  const { caption, onChange, value } = props;

  const renderCaption = () => {
    if (caption) {
      return <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{caption}</span>;
    }
    return null;
  };

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input type="checkbox" checked={value} className="sr-only peer" onChange={(e) => onChange(e.target.checked)} />
      <div
        className="
          relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-1 rounded-full peer dark:bg-gray-500
          peer-checked:after:translate-x-full
          rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-['']
          after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border
          after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600
        "
      ></div>
      {renderCaption()}
    </label>
  );
}
