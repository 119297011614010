import React from 'react';
import classNames from 'classnames';

interface Props {
  caption: string;
  link?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  hasError?: boolean;
  containerClass?: string;
}

/**
 * SimpleCheckBox()
 * @param props
 * @constructor
 */
export default function SimpleCheckBox(props: Props) {
  const { checked, link, caption, onChange, hasError, containerClass } = props;

  const renderLink = () => {
    if (link) {
      return (
        <a href={link} target={'_blank'} rel="noreferrer" className={'text-gray-400 hover:text-black'}>
          <i className="fa-regular fa-arrow-up-right-from-square" />
        </a>
      );
    }
    return null;
  };

  return (
    <div className={classNames('flex flex-1 items-center mt-4 mb-6', containerClass)}>
      <input
        checked={checked}
        onChange={(value) => onChange(value.target.checked)}
        type={'checkbox'}
        className={classNames('w-5 h-5 text-blue-500 bg-gray-100', 'border-gray-300 mr-4')}
      />
      <div
        className={classNames('cursor-pointer flex-1 hover:underline', { 'text-red-700': hasError })}
        onClick={() => onChange(!checked)}
      >
        {caption}
      </div>
      {renderLink()}
    </div>
  );
}
