import moment from 'moment';
import { createWorker } from 'tesseract.js';
import { imagePrepareTexts } from './ImageFunctions';
import readXlsxFile from 'read-excel-file';
import { textPrepareCsvTexts, textPrepareExcelData } from './TextFunctions';
import { isValidIban } from './HelperFunctions';
import { DocumentType } from '../Types/DocumentTypes';

/**
 * documentFindHighestAndLowestDateInTexts()
 * @param texts
 */
export const documentFindHighestAndLowestDateInTexts = (texts: string[]) => {
  const regex1 = /^(0[1-9]|[12][0-9]|3[01])(\/)(0[1-9]|1[1,2])(\/)(19|20)\d{2}/gm;
  const regex2 = /^(0[1-9]|[12][0-9]|3[01])(-)(0[1-9]|1[1,2])(-)(19|20)\d{2}/gm;
  const regex3 = /^(0[1-9]|[12][0-9]|3[01])(.)(0[1-9]|1[1,2])(.)(19|20)\d{2}/gm;

  let highestDate: any = null;
  let lowestDate: any = null;

  if (texts && texts.length > 0) {
    texts.forEach((text) => {
      const match1 = text.match(regex1);
      if (match1 !== null) {
        if (!highestDate || highestDate.isBefore(moment(match1[0], 'DD/MM/YYYY'))) {
          highestDate = moment(match1[0], 'DD/MM/YYYY');
        }
        if (!lowestDate || lowestDate.isAfter(moment(match1[0], 'DD/MM/YYYY'))) {
          lowestDate = moment(match1[0], 'DD/MM/YYYY');
        }
      }
      const match2 = text.match(regex2);
      if (match2 !== null) {
        if (!highestDate || highestDate.isBefore(moment(match2[0], 'DD-MM-YYYY'))) {
          highestDate = moment(match2[0], 'DD-MM-YYYY');
        }
        if (!lowestDate || lowestDate.isAfter(moment(match2[0], 'DD-MM-YYYY'))) {
          lowestDate = moment(match2[0], 'DD-MM-YYYY');
        }
      }
      const match3 = text.match(regex3);
      if (match3 !== null) {
        if (!highestDate || highestDate.isBefore(moment(match3[0], 'DD.MM.YYYY'))) {
          highestDate = moment(match3[0], 'DD.MM.YYYY');
        }
        if (!lowestDate || lowestDate.isAfter(moment(match3[0], 'DD.MM.YYYY'))) {
          lowestDate = moment(match3[0], 'DD.MM.YYYY');
        }
      }
    });
  }

  return {
    highestDate: highestDate ? highestDate.format('YYYY-MM-DD') : null,
    lowestDate: lowestDate ? lowestDate.format('YYYY-MM-DD') : null,
  };
};

/**
 * documentFindFirstIban()
 * @param texts
 */
export const documentFindFirstIban = (texts: string[]): string => {
  let iban: string = '';

  if (texts && texts.length > 0) {
    texts.forEach((text) => {
      if (!iban && isValidIban(text)) {
        iban = text;
      }
    });
  }
  return iban;
};

/**
 * documentFindAllIban()
 * @param texts
 */
export const documentFindAllIban = (texts: string[]): string[] => {
  let iban: string[] = [];

  if (texts && texts.length > 0) {
    texts.forEach((text) => {
      if (isValidIban(text)) {
        iban.push(text);
      }
    });
  }
  return iban;
};

/**
 * documentGetTextFromImage()
 * @param imageBase64
 */
export const documentGetTextFromImage = (imageBase64: string): Promise<string[]> => {
  return createWorker(['eng', 'deu', 'spa']).then((worker) => {
    return worker.recognize(imageBase64).then((ret) => {
      return worker.terminate().then(() => {
        return Promise.resolve(imagePrepareTexts(ret));
      });
    });
  });
};

/**
 * documentGetTextFromExcelOrCsv()
 * Extracts all texts from csv or excel file
 * @param base64
 */
export const documentGetTextFromExcelOrCsv = (base64: string): Promise<string[]> => {
  if (base64.indexOf('text/csv') > -1) {
    return fetch(base64)
      .then((res) => res.text())
      .then((text) => textPrepareCsvTexts(text))
      .catch(() => Promise.resolve([]));
  } else {
    return fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        try {
          return readXlsxFile(new Uint8Array(buffer), { dateFormat: 'dd.mm.yy' })
            .then((rows) => {
              return textPrepareExcelData(rows);
            })
            .catch(() => Promise.resolve([]));
        } catch (e: any) {
          return Promise.resolve([]);
        }
      });
  }
};

/**
 * documentIsBankDocument()
 * Checks if the documents fileType is csv or excel
 * @param fileType
 */
export const documentIsBankDocument = (fileType: string): boolean => {
  return fileType.indexOf('text/csv') > -1 || fileType.indexOf('xml') > -1 || fileType.indexOf('vnd.') > -1;
};

/**
 * documentsSearch()
 * @param documents
 * @param searchValue
 */
export const documentsSearch = (documents: DocumentType[], searchValue: string): DocumentType[] => {
  if (documents && documents.length > 0) {
    return documents.filter(
      (item) =>
        (item.creditor ? item.creditor.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : false) ||
        (item.invoiceNumber ? item.invoiceNumber.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : false) ||
        (item.bankName ? item.bankName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : false) ||
        (item.originalName ? item.originalName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 : false) ||
        (item.invoiceDate ? moment(item.invoiceDate).format('LL').indexOf(searchValue.toLowerCase()) > -1 : false),
    );
  }
  return documents;
};
