import React from 'react';
import { useAppDispatch, useAppSelector } from '../../Library/Hooks/ReduxHooks';
import classNames from 'classnames';
import DropDownButton, { DropDownItem } from '../Forms/DropDownButton';
import { clientBuildDisplayName } from '../../Library/Functions/ClientFunctions';
import { CLIENT_SET_ACTIVE } from '../../Redux/ActionTypes';

/**
 * SelectActiveClient()
 * @constructor
 */
export default function SelectActiveClient() {
  const { user, clients, activeClient } = useAppSelector((state) => state.auth);
  const [options, setOptions] = React.useState<DropDownItem[]>([]);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (clients && clients.length > 1) {
      let merged: DropDownItem[] = [];
      clients.forEach((item) => {
        merged.push({
          caption: clientBuildDisplayName(item),
          onPress: () => dispatch(CLIENT_SET_ACTIVE(item)),
        });
      });

      setOptions(merged);
    }
  }, [clients, dispatch]);

  if (!user.isCustomerUser && clients && clients.length > 1 && activeClient) {
    return (
      <DropDownButton
        caption={clientBuildDisplayName(activeClient)}
        items={options}
        buttonClass={classNames(
          'text-lg px-4 py-2.4 ring-inset ring-gray-300 hover:bg-purple-700 hover:text-white rounded-md',
          'active:bg-purple-700',
        )}
      />
    );
  }
  return null;
}
