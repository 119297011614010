import React from 'react';
import styled from 'styled-components';
import Headline from '../Components/Headline';
import { useTranslation } from 'react-i18next';
import Input, { InputType } from '../Components/Forms/Input';
import RegisterAndLoginFooter from '../Components/RegisterAndLoginFooter';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router';
import Button from '../Components/Forms/Button';
import AuthenticationError from '../Components/AuthenticationError';
import ShowHideContainer from '../Components/ShowHideContainer';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import Index from '../Components/Website/Navigation';

const sotoLogo = require('../Assets/Images/icon2.png');

const Box = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.templates.borderRadius};
  ${(props) => props.theme.templates.border};
`;

const LeftContainer = styled.div`
  width: 300px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 25px;
  background-color: #d1d5db;

  @media (max-width: 768px) {
    width: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1.5;
  padding: 30px 40px;
`;

/**
 * PasswordReset()
 * @constructor
 */
export default function PasswordReset() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState<string>('');
  const [repeat, setRepeat] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const { search } = useLocation();
  const oobCode = new URLSearchParams(search).get('oobCode');

  const handleReset = React.useCallback(async () => {
    setErrorCode('');

    if (password === repeat && oobCode) {
      setLoading(true);

      try {
        await verifyPasswordResetCode(getAuth(), oobCode);
        await confirmPasswordReset(getAuth(), oobCode, password);
        setSuccess(true);
      } catch (error: any) {
        setErrorCode(error.code);
      }
      setLoading(false);
    } else {
      setErrorCode('invalid-password-repeat');
    }
  }, [oobCode, password, repeat]);

  const renderForm = () => {
    if (!success && oobCode) {
      return (
        <>
          <Headline size={'large'} className={'mb-4'}>
            {t('passwordReset')}
          </Headline>
          <div className={'-mt-3 mb-8 text-gray-600'}>{t('passwordResetDescription')}</div>

          <AuthenticationError errorCode={errorCode} />

          <Input
            onChange={setPassword}
            value={password}
            label={t('password')}
            disabled={loading}
            autoFocus
            type={InputType.password}
          />
          <Input
            onChange={setRepeat}
            value={repeat}
            label={t('passwordRepeat')}
            disabled={loading}
            type={InputType.password}
          />

          <div className={'flex flex-1 mt-8'}>
            <Button
              className={'flex flex-1 justify-center h-12 font-semibold'}
              onPress={handleReset}
              colorStyle={'primary'}
              disabled={!!password && !!repeat && password.length < 6}
            >
              {loading ? <i className={'fa fa-spin fa-spinner'} /> : t('next')}
            </Button>
          </div>
        </>
      );
    }
    return null;
  };

  const renderSuccess = () => {
    if (success && oobCode) {
      return (
        <>
          <div className={'flex flex-row items-center mb-4'}>
            <i className={'fas fa-check-circle text-3xl text-green-700 mr-4'} />
            <Headline size={'large'}>{t('passwordResetSuccess')}</Headline>
          </div>
          <div className={'mb-8 text-gray-600'}>{t('passwordResetSuccessDescription')}</div>

          <div className={'flex flex-1 mt-12'}>
            <Button
              className={'flex flex-1 justify-center h-12 font-semibold'}
              onPress={() => navigate('/login')}
              colorStyle={'success'}
            >
              {t('backToLogin')}
            </Button>
          </div>
        </>
      );
    }
    return null;
  };

  const renderInvalidCode = () => {
    if (!oobCode) {
      return (
        <>
          <div className={'flex flex-row items-center mb-4'}>
            <i className={'fas fa-times-circle text-3xl text-red-700 mr-4'} />
            <Headline size={'large'}>{t('passwordResetInvalid')}</Headline>
          </div>
          <div className={'mb-8 text-gray-600'}>{t('passwordResetInvalidDescription')}</div>

          <div className={'flex flex-1 mt-12'}>
            <Button
              className={'flex flex-1 justify-center h-12 font-semibold'}
              onPress={() => navigate('/login')}
              colorStyle={'success'}
            >
              {t('backToLogin')}
            </Button>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <div className={classNames('flex flex-1 flex-col')}>
      <Index showPrice={false} showStartPage showRegister={false} showLogin={true} />

      <div className={'flex flex-1 flex-row justify-center'}>
        <div className={'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-6/12 p-4'}>
          <Box className={'shadow-2xl bg-white mt-10'}>
            <LeftContainer className={'hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex'}>
              <img src={sotoLogo} alt="logo" style={{ maxHeight: 300 }} />
            </LeftContainer>
            <RightContainer>
              {renderForm()}
              {renderSuccess()}
              {renderInvalidCode()}
            </RightContainer>
          </Box>

          <ShowHideContainer visible={!success}>
            <div className={'flex flex-row justify-center mt-12 text-gray-500'}>
              <div
                className={classNames('cursor-pointer text-gray-500 hover:underline hover:text-blue-700', 'ml-2')}
                onClick={() => navigate('/login')}
              >
                {t('backToLogin')}
              </div>
            </div>
          </ShowHideContainer>

          <RegisterAndLoginFooter />
        </div>
      </div>
    </div>
  );
}
