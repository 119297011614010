import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { useDispatchUserStatisticsGet } from '../../Redux/Actions/User/StatisticsActions';
import { Chart } from 'react-google-charts';
import moment from 'moment';

/**
 * GestorWeeklyStatistics()
 * @constructor
 */
export default function GestorWeeklyStatistics() {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const { weekly } = useAppSelector((state) => state.user.statistics);
  const [chartData, setChartData] = React.useState<any>();
  const dispatchGetStatistics = useDispatchUserStatisticsGet();

  React.useEffect(() => {
    const timer = setTimeout(() => dispatchGetStatistics(user.userId), 500);
    return () => clearTimeout(timer);
  }, [dispatchGetStatistics, user.userId]);

  React.useEffect(() => {
    if (weekly) {
      let data = [
        [
          { label: t('date'), type: 'string' },
          { label: t('documents'), type: 'number' },
          { label: t('bookingTimeInMinutes'), type: 'number' },
        ],
      ];

      weekly.forEach((item) => {
        // eslint-disable-next-line max-len
        const prepareDate = `${item.year}-${item.month < 10 ? `0${item.month}` : item.month}-${item.day < 10 ? `0${item.day}` : item.day}`;
        const newData = [
          moment(prepareDate).format('L'),
          item.overallCount,
          parseInt((item.bookingTime / 60 / item.overallCount).toString()) <= 0
            ? 1
            : parseInt((item.bookingTime / 60 / item.overallCount).toString()),
        ];
        data.push(newData as any);
      });

      setChartData(data);
    }
  }, [t, weekly]);

  const options = {
    seriesType: 'bars',
    series: { 5: { type: 'line' } },
    legend: { position: 'bottom' },
    chartArea: { left: 50, top: 50, right: 20, bottom: 90 },
  };

  if (user.isCustomerUser && user.isActive && chartData) {
    return (
      <Container headline={t('yourWeeklyStatistics')} noPadding>
        <Chart chartType="ComboChart" data={chartData} width="100%" height="400px" options={options} />
      </Container>
    );
  }
  return null;
}
