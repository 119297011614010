import {
  AUTH_LOGOUT,
  CLIENT_DOCUMENTS_GETLIST_SUCCESS,
  CLIENT_STATISTICS_GETLIST_START,
  CLIENT_STATISTICS_GETLIST_SUCCESS,
  CUSTOMER_GETLIST_SUCCESS,
} from '../ActionTypes';
import { createReducer } from '@reduxjs/toolkit';
import { BookingStatistic, StatisticsGroupedByMonth } from '../../Library/Types/StatisticTypes';
import moment from 'moment';
import { statisticsGroupByMonth, statisticsPrepareWeeklyItems } from '../../Library/Functions/StatisticFunctions';
import { DocumentType } from '../../Library/Types/DocumentTypes';
import { Customer } from '../../Library/Types/CustomerTypes';

export interface ClientReducerInterface {
  statistics: {
    lastUsedClientId: string | null;
    all: BookingStatistic[];
    weekly: BookingStatistic[];
    lastYear: StatisticsGroupedByMonth[];
  };
  documents: {
    doneAll: DocumentType[];
  };
  customers: Customer[];
  lastDocumentsRefresh: string;
  lastStatisticRefresh: string;
}

const defaultValues: ClientReducerInterface = {
  statistics: {
    lastUsedClientId: null,
    all: [],
    weekly: [],
    lastYear: [],
  },
  documents: {
    doneAll: [],
  },
  customers: [],
  lastStatisticRefresh: '',
  lastDocumentsRefresh: '',
};

export default createReducer(defaultValues, (builder) => {
  builder.addCase(CLIENT_STATISTICS_GETLIST_START, (state, action) => {
    state.statistics.lastUsedClientId = action.payload;
  });
  builder.addCase(CLIENT_STATISTICS_GETLIST_SUCCESS, (state, action) => {
    state.statistics.all = action.payload;
    state.statistics.weekly = statisticsPrepareWeeklyItems(action.payload);
    state.statistics.lastYear = statisticsGroupByMonth(action.payload);
    state.lastStatisticRefresh = moment().format('YYYY-MM-DD HH:mm:ss');
  });

  builder.addCase(CLIENT_DOCUMENTS_GETLIST_SUCCESS, (state, action) => {
    state.documents.doneAll = action.payload || [];
    state.lastDocumentsRefresh = moment().format('YYYY-MM-DD HH:mm:ss');
  });

  builder.addCase(CUSTOMER_GETLIST_SUCCESS, (state, action) => {
    state.customers = action.payload;
  });

  builder.addCase(AUTH_LOGOUT, (state) => {
    state.statistics = defaultValues.statistics;
    state.lastStatisticRefresh = '';
    state.customers = [];
  });
});
