import React from 'react';
import { DocumentMessage, DocumentType } from '../../../Library/Types/DocumentTypes';
import Container from '../../Container/Container';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ActionButton from '../../Container/ActionButton';
import AddMessageModal from './AddMessageModal';
import Row from './Row';
import { useDispatchDocumentUpdate } from '../../../Redux/Actions/DocumentActions';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';

interface Props {
  document: DocumentType;
}

/**
 * Messages()
 * @param props
 * @constructor
 */
export default function Messages(props: Props) {
  const { document } = props;
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(false);

  const dispatchDocumentUpdate = useDispatchDocumentUpdate();

  const handleDelete = React.useCallback(
    (message: DocumentMessage) => {
      const messages: DocumentMessage[] = document.messages.filter((item) => item.createdDate !== message.createdDate);
      dispatchDocumentUpdate({ ...document, messages }).finally(() => {});
    },
    [dispatchDocumentUpdate, document],
  );

  const renderAdd = () => {
    return (
      <ActionButton
        icon={classNames('text-black', 'fas fa-plus')}
        className={classNames('hover:bg-blue-500 hover:text-white mr-0')}
        onPress={() => setShow(true)}
      />
    );
  };

  const renderRows = () => {
    if (document.messages && document.messages.length > 0) {
      return document.messages
        .filter((item) => (user.isCustomerUser ? true : !item.isInternal))
        .map((message) => <Row message={message} key={message.createdDate} onDelete={handleDelete} />);
    }
    return <div className={'text-gray-400 text-center p-4'}>{t('noMessages')}</div>;
  };

  return (
    <Container
      headline={t('messages', { count: document.messages ? document.messages.length : 0 })}
      closable
      identifier={'documentMessages'}
      initialOpen={true}
      actionButtons={renderAdd()}
      noPadding
    >
      <div className={'p-4'}>{renderRows()}</div>
      <AddMessageModal document={document} visible={show} onClose={() => setShow(false)} />
    </Container>
  );
}
