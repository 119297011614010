import React from 'react';
import AbstractType from '../AbstractType';
import { Type } from '../ApiTypes';

export const DocumentType: Type = {
  type: 'object',
  name: 'Document',
  fields: [
    { type: 'string', key: 'documentId', description: 'The internal primary key', mandatory: true },
    { type: 'string', key: 'clientId', description: 'The clientId owner of the document', mandatory: true },
    { type: 'DocumentType', key: 'type', description: 'Document type', mandatory: true },
    { type: 'DocumentState', key: 'state', description: 'The current document state', mandatory: true },
    {
      type: 'string',
      key: 'fileType',
      description: 'The file type of the document. For example "image/png" or "application/pdf"',
      mandatory: true,
    },
    {
      type: 'string',
      key: 'invoiceDate',
      description: 'The invoice date in format YYYY-MM-DD. Only available on document type "incoming" or "outgoing"',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'creditor',
      description: 'The invoice creditor. Only available on document type "incoming"',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'invoiceNumber',
      description: 'The invoice number. Only available on document type "incoming" or "outgoing"',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'bankStartDate',
      description: 'Only available for document type "bank". The start date of the bank statement. Format YYYY-MM-DD',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'bankEndDate',
      description: 'Only available for document type "bank". The end date of the bank statement. Format YYYY-MM-DD',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'bankIban',
      description: 'Only available for document type "bank". IBAN of the bank account',
      mandatory: false,
    },
    {
      type: 'string',
      key: 'bankName',
      description: 'Only available for document type "bank". Name of the bank',
      mandatory: false,
    },
    {
      type: 'number',
      key: 'size',
      description: 'The file size in byte',
      mandatory: true,
    },
    { type: 'string', key: 'originalName', description: 'The original filename of the document', mandatory: true },
    { type: 'string', key: 'downloadUrl', description: 'The url to download or show the file', mandatory: true },
  ],
};

/**
 * Client()
 * @constructor
 */
export default function Document() {
  return <AbstractType itemType={DocumentType} />;
}
