import React from 'react';
import classNames from 'classnames';
import Method from './Components/Method';
import Type from './Components/Type';
import ApiTable from './Components/ApiTable';
import { Endpoint } from './ApiTypes';
import Config from '../../Library/Config';

interface Props {
  endpoint: Endpoint;
}

/**
 * AbstractEndpoint()
 * @param props
 * @constructor
 */
export default function AbstractEndpoint(props: Props) {
  const { endpoint } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const renderPathParams = () => {
    if (endpoint.pathParams && endpoint.pathParams.length > 0) {
      const mapped = endpoint.pathParams.map((item) => (
        <tr key={item.name}>
          <td className={item.mandatory ? 'mandatory' : ''}>{item.name}</td>
          <td>
            <Type type={item.type} />
          </td>
          <td>{item.description}</td>
        </tr>
      ));

      return (
        <div className={'p-5'}>
          <div>
            <span className={'bg-gray-400 text-white py-2 px-2 rounded-md mr-2'}>Path parameter</span>
          </div>
          <div className={'mt-6'}>
            <ApiTable>{mapped}</ApiTable>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderQueryParams = () => {
    if (endpoint.queryParams && endpoint.queryParams.length > 0) {
      const mapped = endpoint.queryParams.map((item) => (
        <tr key={item.name}>
          <td className={item.mandatory ? 'mandatory' : ''}>{item.name}</td>
          <td>
            <Type type={item.type} />
          </td>
          <td>{item.description}</td>
        </tr>
      ));

      return (
        <div className={'p-5'}>
          <div>
            <span className={'bg-gray-400 text-white py-2 px-2 rounded-md mr-2'}>Query parameter</span>
          </div>
          <div className={'mt-6'}>
            <ApiTable>{mapped}</ApiTable>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderRequestBodyData = () => {
    if (endpoint.requestBody && endpoint.requestBody.data) {
      const sorted = endpoint.requestBody.data.sort((a, b) => a.key.localeCompare(b.key));
      const mapped = sorted.map((item) => (
        <tr key={item.key}>
          <td className={item.mandatory ? 'mandatory' : ''}>{item.key}</td>
          <td>
            <Type type={item.type} />
          </td>
          <td>{item.description}</td>
        </tr>
      ));

      return (
        <div className={'p-5'}>
          <div>
            <span className={'bg-gray-400 text-white py-2 px-2 rounded-md mr-2'}>Request-Body</span>
            <span className={'bg-gray-400 text-white py-2 px-2 rounded-md mr-2'}>{endpoint.requestBody.type}</span>
          </div>
          <div className={'mt-6'}>
            <ApiTable>{mapped}</ApiTable>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderResponseBody = () => {
    if (endpoint.responseBody) {
      const sorted = endpoint.responseBody.data.sort((a, b) => a.key.localeCompare(b.key));
      const mapped = sorted.map((item) => (
        <tr key={item.key}>
          <td className={item.mandatory ? 'mandatory' : ''}>{item.key}</td>
          <td>
            <Type type={item.type} />
          </td>
          <td>{item.description}</td>
        </tr>
      ));

      return (
        <div className={'p-5'}>
          <div>
            <span className={'bg-gray-400 text-white py-2 px-2 rounded-md mr-2'}>Response-Body</span>
            <span className={'bg-green-600 text-white py-2 px-2 rounded-md mr-2'}>200 OK</span>
            <span className={'bg-gray-400 text-white py-2 px-2 rounded-md mr-2'}>{endpoint.responseBody.type}</span>
          </div>
          <ApiTable>{mapped}</ApiTable>
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (open) {
      return (
        <>
          {renderPathParams()}
          {renderQueryParams()}
          {renderRequestBodyData()}
          {renderResponseBody()}
        </>
      );
    }
    return null;
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a id={endpoint.name} content={''} href={'/#'} />
      <div className={'flex flex-1 flex-col mb-6 border border-gray-200 rounded-xl'}>
        <div
          className={classNames(
            'p-4 bg-gray-200 flex flex-1 flex-row items-center',
            { 'rounded-t-xl': open },
            { 'rounded-xl': !open },
          )}
        >
          <div className={'flex flex-col flex-1'}>
            <div className={'flex flex-1 flex-row mb-4'}>
              <Method method={endpoint.method} />
              <div className={classNames('text-2xl font-bold')}>{endpoint.name}</div>
            </div>
            <div className={'font-light'}>{endpoint.description}</div>
            <div className={'mt-4 mb-2'}>
              <span className={'font-light text-xl text-gray-500'}>
                {Config.apiEndpoint}
                <span className={'font-semibold text-black'}>{endpoint.path}</span>
              </span>
            </div>
          </div>
          <div className={'ml-10'}>
            <div
              className={classNames(
                'bg-white rounded px-3 py-2 border border-gray-300',
                'cursor-pointer',
                'hover:bg-gray-100',
              )}
              onClick={() => setOpen(!open)}
            >
              <i
                className={classNames('fas', { 'fa-chevron-down': !open }, { 'fa-chevron-up': open }, 'text-gray-400')}
              />
            </div>
          </div>
        </div>

        {renderContent()}
      </div>
    </>
  );
}
