import React from 'react';
import { DocumentType } from '../../Library/Types/DocumentTypes';
import Container from '../Container/Container';
import StatusIcon from './StatusIcon';
import { useDocumentStatusMappings } from '../../Library/Hooks/DocumentHooks';

interface Props {
  document: DocumentType;
  withContainer: boolean;
}

/**
 * StatusBox()
 * @param props
 * @constructor
 */
export default function StatusBox(props: Props) {
  const { document, withContainer } = props;
  const mappings = useDocumentStatusMappings();

  const renderContent = () => {
    return (
      <div className={'flex row items-center -m-3'}>
        <StatusIcon document={document} />
        <div className={'ml-3'}>{mappings.find((item) => item.state === document.state)?.text}</div>
      </div>
    );
  };

  if (withContainer) {
    return <Container containerStyle={{ marginBottom: 15 }}>{renderContent()}</Container>;
  }
  return renderContent();
}
