import React from 'react';
import Headline from '../../Components/Headline';
import Input, { InputType } from '../../Components/Forms/Input';
import Button from '../../Components/Forms/Button';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../../Library/Firebase';
import { Customer } from '../../Library/Types/CustomerTypes';
import { useDispatchUserRegisterClient } from '../../Redux/Actions/UserActions';
import AuthenticationError from '../../Components/AuthenticationError';
import validateRegisterClientUser, {
  ValidateRegisterClientUserErrors,
} from '../../Library/Validators/RegisterClientUser';
import { useLoadingModal } from '../../Library/Hooks/GlobalHooks';
import { LOCALSTORAGE_PATH_CUSTOMER_LOGO } from '../../Library/Types/GeneralTypes';
import { Client } from '../../Library/Types/ClientTypes';
import { clientBuildDisplayName } from '../../Library/Functions/ClientFunctions';
import RegisterAndLoginFooter from '../../Components/RegisterAndLoginFooter';
import classNames from 'classnames';
import ConfirmModal from '../../Components/Modals/ConfirmModal';
import { STORAGE_ADD_NEW_CLIENT } from '../../Library/Types/Constants';
import Index from '../../Components/Website/Navigation';
import SimpleCheckBox from '../../Components/Forms/SimpeCheckBox';
const sotoLogo = require('../../Assets/Images/icon2.png');

const Box = styled.div`
  display: flex;
  flex: 1;
  ${(props) => props.theme.templates.borderRadius};
  ${(props) => props.theme.templates.border};
`;

const LeftContainer = styled.div`
  flex-direction: column;
  width: 300px;
  justify-content: center;
  background-color: #d1d5db;

  @media (max-width: 768px) {
    width: 0;
  }
`;

const RightContainer = styled.div`
  flex: 1.5;
  padding: 30px 40px;
`;

/**
 * RegisterClient()
 * @constructor
 */
export default function RegisterClient() {
  const { customerId, clientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState<Customer>();
  const [client, setClient] = React.useState<Client>();
  const [mail, setMail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [creating, setCreating] = React.useState<boolean>(false);
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [authErrorCode, setAuthErrorCode] = React.useState<string | null>(null);
  const [validateResult, setValidateResult] = React.useState<ValidateRegisterClientUserErrors | null>(null);
  const { setLoading, isLoading, loadingModal } = useLoadingModal({ headline: t('pageLoading') });
  const [showAccountExisting, setShowAccountExisting] = React.useState<boolean>(false);
  const [registrationActive, setRegistrationActive] = React.useState<boolean>(false);
  const [legalAccepted, setLegalAccepted] = React.useState<boolean>(false);

  const dispatchUserClientCreate = useDispatchUserRegisterClient();

  const handleCreate = React.useCallback(() => {
    setValidateResult(null);
    if (customer) {
      const validation = validateRegisterClientUser(firstName, lastName, mail, password, passwordRepeat, legalAccepted);

      if (clientId && !validation.hasErrors) {
        setCreating(true);
        dispatchUserClientCreate(firstName, lastName, mail, password, clientId)
          .then(() => {
            setCreating(false);
            navigate('/dashboard');
          })
          .catch((error) => {
            setCreating(false);
            setAuthErrorCode(error.code);
          });
      } else {
        setValidateResult(validation);
        setAuthErrorCode(validation.errorCode);
      }
    }
  }, [
    clientId,
    customer,
    dispatchUserClientCreate,
    firstName,
    lastName,
    legalAccepted,
    mail,
    navigate,
    password,
    passwordRepeat,
  ]);

  const handleAccountExisting = React.useCallback(() => {
    if (clientId) {
      localStorage.setItem(STORAGE_ADD_NEW_CLIENT, clientId);
      setShowAccountExisting(true);
    }
  }, [clientId]);

  React.useEffect(() => {
    setLoading(true);
    const customerGet = httpsCallable<any, Customer>(getFunctions(), FirebaseFunctions.customerGet);
    const clientGet = httpsCallable<any, Client>(getFunctions(), FirebaseFunctions.clientGet);

    customerGet({ customerId })
      .then((customerResponse) => {
        return clientGet({ clientId }).then((clientResponse) => {
          setCustomer(customerResponse.data);
          setClient(clientResponse.data);
          setRegistrationActive(clientResponse.data.registrationActive);

          if (customerResponse.data.logo) {
            localStorage.setItem(LOCALSTORAGE_PATH_CUSTOMER_LOGO, customerResponse.data.logo);
          }

          return Promise.resolve();
        });
      })
      .catch(() => {
        navigate('/login');
      })
      .finally(() => setLoading(false));
  }, [clientId, customerId, navigate, setLoading]);

  React.useEffect(() => {
    if (!registrationActive && !isLoading) {
      setAuthErrorCode('registration-inactive');
    } else {
      setAuthErrorCode('');
    }
  }, [isLoading, registrationActive]);

  if (!isLoggedIn) {
    if (customer && client && !isLoading) {
      return (
        <div className={classNames('flex flex-1 flex-col')}>
          <Index showPrice={false} showStartPage={false} showRegister={false} showLogin={false} />

          <div className={'flex flex-1 flex-row justify-center'}>
            <div className={'basis-6/6 sm:basis-6/6 md:basis-4/6 lg:basis-4/6 xl:basis-4/6 2xl:basis-7/12 p-4'}>
              <Box className={'shadow-2xl mt-10 bg-white flex-row'}>
                <LeftContainer className={'hidden sm:hidden md:hidden lg:flex xl:flex 2xl:flex sm:w-0 md:w-0'}>
                  <div className={'text-center mt-6'}>
                    <div className={'font-bold text-2xl'}>{customer ? customer.displayName || customer.name : ''}</div>
                    <div>{clientBuildDisplayName(client)}</div>
                  </div>
                  <div
                    className={
                      'flex flex-grow items-center justify-center text-center text-5xl font-bold text-gray-400 p-10'
                    }
                  >
                    <img src={customer.logo || sotoLogo} alt={'Logo'} style={{ maxHeight: 300 }} />
                  </div>
                </LeftContainer>
                <RightContainer>
                  <Headline size={'large'} style={{ marginBottom: 6 }}>
                    {t('registerUser')}
                  </Headline>
                  <p className={'text-gray-500 mb-6'}>{t('modules.register.clientDescription')}</p>

                  <AuthenticationError errorCode={authErrorCode} />

                  <div className={classNames('flex flex-col flex-grow', 'lg:flex-row')}>
                    <div className={classNames('flex-1', 'lg:mr-2')}>
                      <Input
                        onChange={setFirstName}
                        value={firstName}
                        label={t('firstName')}
                        disabled={isLoading || creating || !registrationActive}
                        autoFocus
                        hasError={!!validateResult && validateResult.firstName}
                      />
                    </div>
                    <div className={classNames('flex-1', 'lg:ml-2')}>
                      <Input
                        onChange={setLastName}
                        value={lastName}
                        label={t('lastName')}
                        disabled={isLoading || creating || !registrationActive}
                        hasError={!!validateResult && validateResult.lastName}
                      />
                    </div>
                  </div>

                  <Input
                    onChange={setMail}
                    value={mail}
                    label={t('eMailAddress')}
                    disabled={isLoading || creating || !registrationActive}
                    hasError={!!validateResult && validateResult.mail}
                    type={InputType.email}
                  />
                  <Input
                    onChange={setPassword}
                    value={password}
                    label={t('password')}
                    type={InputType.password}
                    disabled={isLoading || creating || !registrationActive}
                    hasError={!!validateResult && validateResult.password}
                  />
                  <Input
                    onChange={setPasswordRepeat}
                    value={passwordRepeat}
                    label={t('passwordRepeat')}
                    type={InputType.password}
                    disabled={isLoading || creating || !registrationActive}
                    hasError={!!validateResult && validateResult.passwordRepeat}
                  />

                  <SimpleCheckBox
                    caption={t('registerLegalAccepted')}
                    checked={legalAccepted}
                    onChange={setLegalAccepted}
                    hasError={validateResult?.legal}
                  />

                  <div className={'flex justify-end'}>
                    <Button
                      className={'h-12 flex-1 justify-center'}
                      onPress={handleCreate}
                      disabled={creating || !registrationActive}
                    >
                      {creating ? <i className={'fas fa-spin fa-spinner'} /> : t('createUserAccount')}
                    </Button>
                  </div>

                  <div
                    className={classNames(
                      'text-gray-400 text-center mt-8 mb-2 cursor-pointer',
                      'hover:text-blue-700 hover:underline',
                    )}
                    onClick={handleAccountExisting}
                  >
                    {t('accountAlreadyExisting')}
                  </div>
                </RightContainer>
              </Box>

              <div>
                <RegisterAndLoginFooter />
              </div>
            </div>

            <ConfirmModal
              headline={t('alert.clientAccountExisting.headline')}
              text={t('alert.clientAccountExisting.text', {
                name: client ? clientBuildDisplayName(client) : 'unknown',
              })}
              visible={showAccountExisting}
              onClose={() => setShowAccountExisting(false)}
              onConfirm={() => navigate('/login')}
              type={'primary'}
              confirmText={t('next')}
            />
          </div>
        </div>
      );
    }
    return <>{loadingModal}</>;
  }
  return <Navigate to={'/dashboard'} replace />;
}
