import React from 'react';
import { useDispatchStorageCreate } from '../../../Redux/Actions/StorageActions';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  folder: string;
}

/**
 * UploadButton()
 * @constructor
 */
export default function UploadButton(props: Props) {
  const { folder } = props;
  const { t } = useTranslation();
  const ref = React.useRef<HTMLInputElement>(null);

  const dispatchUpload = useDispatchStorageCreate();

  const handleUpload = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length === 1) {
        dispatchUpload(e.target.files[0], folder, () => {});
      }
    },
    [dispatchUpload, folder],
  );

  const handleOpen = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  return (
    <>
      <div
        onClick={handleOpen}
        className={classNames(
          'border-gray-300 border focus:outline-0 bg-white rounded-md py-3 px-4',
          'cursor-pointer ml-2 text-gray-500',
          'hover:bg-purple-700 hover:text-white',
          'uploadStorage',
        )}
      >
        <i className={'fas fa-cloud-upload'} />
      </div>

      <Tooltip className={'z-20'} anchorSelect={'.uploadStorage'}>
        {t(`uploadStorageFileToFolder`)}
      </Tooltip>
      <input type={'file'} onChange={handleUpload} style={{ display: 'none' }} ref={ref} />
    </>
  );
}
