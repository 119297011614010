import React from 'react';
import { Storage } from '../../../Library/Types/StorageTypes';
import moment from 'moment';
import { downloadBase64Pdf, formatSize, shortenFileName } from '../../../Library/Functions/HelperFunctions';
import classNames from 'classnames';
import { storageFileTypeToName, storageGetFileTypeIcon } from '../../../Library/Functions/StorageFunctions';
import ActionButtons from './ActionButtons';

interface Props {
  document: Storage;
  isActive: boolean;
  onClick: (document: Storage) => void;
  onUnselect: () => void;
}

const rowClass = classNames('py-3');

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { document, isActive, onClick, onUnselect } = props;
  const [doubleClickActive, setDoubleClickActive] = React.useState<boolean>(false);

  const handleOnClick = () => {
    setDoubleClickActive(true);
    setTimeout(() => setDoubleClickActive(false), 500);

    if (isActive) {
      if (!doubleClickActive) {
        onUnselect();
      } else {
        downloadBase64Pdf(document.downloadUrl, document.name);
        setDoubleClickActive(false);
      }
    } else {
      onClick(document);
    }
  };

  return (
    <>
      <tr
        className={classNames(
          'cursor-pointer border-b last:border-b-0',
          { 'bg-purple-700 text-white': isActive },
          { 'hover:bg-gray-200': !isActive },
        )}
      >
        <td className={classNames(rowClass, 'text-center rounded-l-xl')} onClick={handleOnClick}>
          <i className={classNames(storageGetFileTypeIcon(document), 'text-2xl mr-2', { '!text-white': isActive })} />
        </td>
        <td className={classNames(rowClass, 'overflow-ellipsis')} onClick={handleOnClick} title={document.name}>
          {shortenFileName(document.name, 70)}
        </td>
        <td className={classNames(rowClass, 'text-sm', { 'text-gray-500': !isActive })} onClick={handleOnClick}>
          {storageFileTypeToName(document)}
        </td>
        <td className={classNames(rowClass, 'text-sm', { 'text-gray-500': !isActive })} onClick={handleOnClick}>
          {formatSize(document.size)}
        </td>
        <td className={classNames(rowClass, 'text-sm', { 'text-gray-500': !isActive })} onClick={handleOnClick}>
          {`${moment(document.createdDate).format('LL')} - ${moment(document.createdDate).format('LT')}`}
        </td>
        <td className={'text-right rounded-r-xl'} width={40} onClick={!isActive ? () => onClick(document) : () => {}}>
          <ActionButtons document={document} isActive={isActive} />
        </td>
      </tr>
    </>
  );
}
