import React from 'react';
import Toggle from '../Forms/Toggle';
import classNames from 'classnames';

interface Props {
  value: boolean;
  caption: string;
  switchPosition: 'left' | 'right';
  onChange: (value: boolean) => void;
  className?: string;
}

/**
 * SwitchRow()
 * @param props
 * @constructor
 */
export default function SwitchRow(props: Props) {
  const { switchPosition, value, caption, onChange, className } = props;

  const renderSwitchLeft = () => {
    if (switchPosition === 'left') {
      return <Toggle onChange={onChange} value={value} />;
    }
    return null;
  };

  const renderSwitchRight = () => {
    if (switchPosition === 'right') {
      return <Toggle onChange={onChange} value={value} />;
    }
    return null;
  };

  return (
    <div
      className={classNames(
        'flex flex-row items-center ring-1 ring-gray-300 rounded-md p-4 cursor-pointer mb-4',
        className,
      )}
      onClick={() => onChange(!value)}
    >
      {renderSwitchLeft()}
      <div className={classNames('flex-grow', { 'ml-4': switchPosition === 'left' })}>{caption}</div>
      {renderSwitchRight()}
    </div>
  );
}
