import React from 'react';
import classNames from 'classnames';

interface Props {
  path: string;
  name: string;
  onPress: (item: string) => void;
  showSeparator: boolean;
}

/**
 * Item()
 * @param props
 * @constructor
 */
export default function Item(props: Props) {
  const { path, name, onPress, showSeparator } = props;

  return (
    <div data-path={path} key={path}>
      <span className={classNames('px-2', { hidden: !showSeparator })}>/</span>
      <span onClick={() => onPress(path)} className={classNames('hover:underline cursor-pointer')}>
        {name}
      </span>
    </div>
  );
}
