import React from 'react';
import { useAppSelector } from '../../../../Library/Hooks/ReduxHooks';
import Container from '../../../Container/Container';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ActionButton from '../../../Container/ActionButton';
import CreateOrUpdateModal from './CreateOrUpdateModal';
import Row from './Row';
import { ClientGroup } from '../../../../Library/Types/GeneralTypes';
import { useDispatchCustomerUpdate } from '../../../../Redux/Actions/CustomerActions';

/**
 * ClientGroups()
 * @constructor
 */
export default function ClientGroups() {
  const { t } = useTranslation();
  const { customer } = useAppSelector((state) => state.auth);
  const [show, setShow] = React.useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = React.useState<ClientGroup | null>(null);

  const dispatchUpdateCustomer = useDispatchCustomerUpdate();

  const handleDelete = React.useCallback(
    (group: ClientGroup) => {
      const groups = customer.settings.client.groups.filter((item) => item.id !== group.id);
      dispatchUpdateCustomer({
        ...customer,
        settings: { ...customer.settings, client: { ...customer.settings.client, groups } },
      });
    },
    [customer, dispatchUpdateCustomer],
  );

  const renderRow = () => {
    if (customer.settings.client && customer.settings.client.groups && customer.settings.client.groups.length > 0) {
      const groups = [...customer.settings.client.groups];
      return groups
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((group) => (
          <Row
            key={group.id}
            group={group}
            onEdit={(group) => {
              setSelectedGroup(group);
              setShow(true);
            }}
            onDelete={handleDelete}
            showDelete={groups.length > 1}
          />
        ));
    }
    return null;
  };

  return (
    <Container
      headline={t('clientGroups')}
      closable
      identifier={'ClientGroupSettings'}
      actionButtons={
        <ActionButton
          icon={'fas fa-plus'}
          className={classNames('hover:bg-blue-500 hover:text-white')}
          onPress={() => setShow(true)}
        />
      }
    >
      <div className={'text-gray-500'}>{t('clientGroupsDescription')}</div>

      <div>{renderRow()}</div>
      <CreateOrUpdateModal
        initialValue={selectedGroup}
        visible={show}
        onClose={() => {
          setShow(false);
          setSelectedGroup(null);
        }}
      />
    </Container>
  );
}
