import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { useDispatchDocumentUpload } from '../../../Redux/Actions/DocumentActions';
import { DocumentType } from '../../../Library/Types/DocumentTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import ListView from '../../../Components/Document/ListView';
import { useLoadingModal } from '../../../Library/Hooks/GlobalHooks';
import { useNavigate } from 'react-router';
import { NavbarContext } from '../../../Components/Layout/AuthedLayout';
import AlertModal from '../../../Components/Modals/AlertModal';
import classNames from 'classnames';
import { Customer, SubscriptionType } from '../../../Library/Types/CustomerTypes';
import { subscriptionGetMaxFileSize } from '../../../Library/Functions/SubscriptionFunctions';
import { formatSize } from '../../../Library/Functions/HelperFunctions';
import Container from '../../../Components/Container/Container';

const DropZone = styled.div`
  text-align: center;
  padding: 70px;
  border: 2px dashed #eeeeee;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 20px;

  &:hover {
    border-color: #000000;
  }
`;

/**
 * Dashboard()
 * @constructor
 */
export default function Dashboard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeClient } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const { customers } = useAppSelector((state) => state.client);
  const [selectedCustomer, setSelectedCustomer] = React.useState<Customer>();
  const { loadingModal, setLoading, setHeadline, setTextLine1 } = useLoadingModal({
    headline: t('modules.documents.uploading'),
  });
  const navContext = React.useContext(NavbarContext);
  const [showInvalidDocument, setShowInvalidDocument] = React.useState<boolean>(false);

  const upload = useDispatchDocumentUpload();

  const handleProgress = React.useCallback(
    (percent: number, count: number, overall: number) => {
      setTextLine1(t('uploadingDocumentProgress', { count, overallCount: overall, percent: percent.toFixed(0) }));
    },
    [setTextLine1, t],
  );

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      let isValid: boolean = true;

      // Check for file Size
      if (acceptedFiles && acceptedFiles.length > 0 && selectedCustomer) {
        acceptedFiles.forEach((file) => {
          if (file.size > subscriptionGetMaxFileSize(selectedCustomer.subscriptionType)) {
            isValid = false;
          }
        });
      }

      if (acceptedFiles && acceptedFiles.length > 0 && activeClient && isValid) {
        setLoading(true);
        setHeadline(t('uploadingDocument', { count: acceptedFiles.length }));
        let count = 1;

        let response: DocumentType;
        for (const acceptedFilesKey in acceptedFiles) {
          // eslint-disable-next-line no-loop-func
          response = await upload(acceptedFiles[acceptedFilesKey], activeClient, (progress) =>
            handleProgress(progress, count, acceptedFiles.length),
          );
          count++;
        }

        setLoading(false);

        if (acceptedFiles.length === 1) {
          // @ts-ignore
          navigate(`/documents/${response.documentId}`);
        }
      } else {
        setShowInvalidDocument(true);
      }
    },
    [handleProgress, navigate, activeClient, selectedCustomer, setHeadline, setLoading, t, upload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 5 * 1024 * 1024,
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
      'image/bmp': [],
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': [],
      'text/csv': [],
    },
  });

  React.useEffect(() => {
    navContext.setHeadline(t('documentsOpen', { count: 0 }));
  }, [navContext, t]);

  React.useEffect(() => {
    if (activeClient && customers) {
      const customer = customers.find((c) => c.customerId === activeClient.customerId);
      if (customer) {
        setSelectedCustomer(customer);
      }
    }
  }, [customers, activeClient]);

  const handleOnPress = (document: DocumentType) => {
    navigate(`/documents/${document.documentId}`);
  };

  const getDraDropSubDescription = () => {
    if (activeClient) {
      if (activeClient.allowBank && activeClient.allowOutgoing) {
        return t('modules.documents.dragAndDropDescriptionFilesAll');
      }
      if (!activeClient.allowBank && activeClient.allowOutgoing) {
        return t('modules.documents.dragAndDropDescriptionFilesWithoutBank');
      }
      if (activeClient.allowBank && !activeClient.allowOutgoing) {
        return t('modules.documents.dragAndDropDescriptionFilesWithoutOutgoing');
      }
      if (!activeClient.allowBank && !activeClient.allowOutgoing) {
        return t('modules.documents.dragAndDropDescriptionFilesIncomingOnly');
      }
    }
    return '';
  };

  const renderDropZone = () => {
    if (activeClient && activeClient.isActive) {
      return (
        <DropZone {...getRootProps()} className={classNames('text-gray-500 hover:text-black')}>
          <input {...getInputProps()} />
          <div className={'text-2xl'}>
            {isDragActive ? t('modules.documents.dropFileHere') : t('modules.documents.dragAndDropDescription')}
          </div>
          <div className={'mt-2 text-sm'}>{getDraDropSubDescription()}</div>
          <div className={'mt-2 text-sm'}>
            {t('maxFileSize', {
              size: formatSize(
                subscriptionGetMaxFileSize(
                  selectedCustomer ? selectedCustomer.subscriptionType : SubscriptionType.demo,
                ),
                0,
              ),
            })}
          </div>
        </DropZone>
      );
    }
    return (
      <Container>
        <div className={classNames('text-red-700 text-xl text-center')}>{t('clientInactiveHint')}</div>
      </Container>
    );
  };

  return (
    <>
      <Container>
        <p className={'text-gray-500 text-lg'}>{t('modules.documents.openDocumentsDescription')}</p>
      </Container>

      {renderDropZone()}

      <ListView
        documents={documents.filter((item) => item.clientId === activeClient?.clientId)}
        onPress={handleOnPress}
        showClient={false}
      />

      <AlertModal
        headline={t('alert.documentInvalid.headline')}
        text={t('alert.documentInvalid.text', {
          size: formatSize(
            subscriptionGetMaxFileSize(selectedCustomer ? selectedCustomer.subscriptionType : SubscriptionType.demo),
            0,
          ),
        })}
        visible={showInvalidDocument}
        onClose={() => setShowInvalidDocument(false)}
        type={'error'}
      />

      {loadingModal}
    </>
  );
}
