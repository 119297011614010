import { AvailableCreditor, BankAccount, Client } from '../Types/ClientTypes';
import { Salutation } from '../Types/GeneralTypes';
import {
  documentFindAllIban,
  documentFindFirstIban,
  documentFindHighestAndLowestDateInTexts,
} from './DocumentFunctions';

/**
 * clientBuildDisplayName()
 * @param client
 */
export const clientBuildDisplayName = (client: Client) => {
  if (client.salutation === Salutation.company) {
    return client.companyName;
  }
  return `${client.lastName}, ${client.firstName}`;
};

/**
 * clientFindAvailableCreditorAndInvoiceNumber()
 * @param texts
 * @param availableCreditors
 */
export const clientFindAvailableCreditorAndInvoiceNumber = (
  texts: string[],
  availableCreditors: AvailableCreditor[],
): any => {
  let foundCreditor: AvailableCreditor;
  let foundInvoiceNumber: string;

  if (texts && texts.length > 0 && availableCreditors && availableCreditors.length > 0) {
    availableCreditors.forEach((creditorParam) => {
      if (!foundCreditor) {
        texts.forEach((text) => {
          if (!foundCreditor && text.toLowerCase().indexOf(creditorParam.name.toLowerCase()) > -1) {
            foundCreditor = creditorParam;
          }
        });
      }
    });

    // @ts-ignore
    if (foundCreditor) {
      const invoiceLength = foundCreditor.lastInvoiceNumber.length;
      const invoiceStart = foundCreditor.lastInvoiceNumber.substring(0, 2);

      texts.forEach((text) => {
        if (
          !foundInvoiceNumber &&
          text.toLowerCase().indexOf(invoiceStart.toLowerCase()) === 0 &&
          text.length === invoiceLength
        ) {
          foundInvoiceNumber = text;
        }
      });

      // @ts-ignore
      return { creditor: foundCreditor.name, invoiceNumber: foundInvoiceNumber || '' };
    }
  }

  return null;
};

/**
 * clientFindBankInformation()
 * @param texts
 * @param bankAccounts
 */
export const clientFindBankInformation = (texts: string[], bankAccounts: BankAccount[]) => {
  const { highestDate, lowestDate } = documentFindHighestAndLowestDateInTexts(texts);
  const allIban = documentFindAllIban(texts);

  let foundIban: string = documentFindFirstIban(texts);

  if (allIban && allIban.length > 0) {
    if (bankAccounts && bankAccounts.length > 0) {
      bankAccounts.forEach((bankAccount) => {
        if (allIban.indexOf(bankAccount.bankIban) > -1) {
          foundIban = bankAccount.bankIban;
        }
      });
    }
  }

  return { highestDate, lowestDate, foundIban };
};
