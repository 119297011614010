import React from 'react';
import { useDispatchStripeCreateCheckout } from '../../Redux/Actions/StripeActions';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Config from '../../Library/Config';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../Components/Modals/ConfirmModal';
import { useNavigate, useParams } from 'react-router';
import { useLoadingModal } from '../../Library/Hooks/GlobalHooks';
import { Environment } from '../../Library/Types/GeneralTypes';
import Container from '../../Components/Container/Container';
import DisplayRow from '../../Components/Display/DisplayRow';
import RegisterAndLoginFooter from '../../Components/RegisterAndLoginFooter';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';

const stripePromise = loadStripe(Config.stripe.apikey);

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  width: 1000px;
  overflow: hidden;
  padding: 40px 0;
`;

/**
 * Checkout()
 * @constructor
 */
export default function Checkout() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { customer } = useAppSelector((state) => state.auth);
  const [session, setSession] = React.useState<any>(null);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [askCancel, setAskCancel] = React.useState<boolean>(false);
  const { loadingModal, setLoading } = useLoadingModal({ headline: t('checkoutLoading'), initialValue: true });

  const dispatchCheckout = useDispatchStripeCreateCheckout();

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!loaded && id) {
      setLoading(true);
      timer = setTimeout(
        () =>
          dispatchCheckout(customer.mail, customer.customerId, customer.stripeCustomerId || null, id)
            .then((data) => {
              setSession(data);
              setLoaded(true);
              setTimeout(() => {
                setLoading(false);
              }, 3000);
            })
            .catch(() => {
              window.location.href = '/login';
            }),
        250,
      );
    }

    return () => clearTimeout(timer);
  }, [customer.customerId, customer.mail, customer.stripeCustomerId, dispatchCheckout, id, loaded, setLoading]);

  const renderTestData = () => {
    if (Config.env !== Environment.production) {
      return (
        <Container headline={'Kreditkarten zum testen'} containerStyle={{ width: 800, marginTop: 50 }}>
          <p className={'text-gray-500 -mt-4 px-2 mb-6'}>Ablaufdatum und CVC können beliebig gewählt werden!</p>
          <DisplayRow label={'Mastercard'} value={'5555555555554444'} showCopyIcon />
          <DisplayRow label={'Visa'} value={'4242424242424242'} showCopyIcon />
          <DisplayRow label={'American Express'} value={'378282246310005'} showCopyIcon />
        </Container>
      );
    }
    return null;
  };

  if (session && customer && id) {
    return (
      <MainContainer id="checkout" className={'bg-gray-100 mt-10 flex flex-col'}>
        <Box className={'shadow-2xl rounded-xl bg-purple-700'}>
          <div style={{ flex: 1 }}>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret: session }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </Box>

        <RegisterAndLoginFooter />

        <div className={'mt-0'}>
          <div
            className={'text-gray-400 hover:text-blue-700 hover:underline cursor-pointer'}
            onClick={() => setAskCancel(true)}
          >
            {t('cancel')}
          </div>
        </div>

        {renderTestData()}

        <ConfirmModal
          headline={t('alert.askCancelCheckout.headline')}
          text={t('alert.askCancelCheckout.text')}
          visible={askCancel}
          onClose={() => setAskCancel(false)}
          onConfirm={() => navigate(-1)}
          type={'error'}
          confirmText={t('cancelCheckout')}
          cancelText={t('close')}
          icon={'far fa-credit-card'}
        />

        {loadingModal}
      </MainContainer>
    );
  }
  return loadingModal;
}
