import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Config from '../Config';
import { getMappedLanguageFromBrowser } from '../Functions/LocalizationFunctions';
import { Environment } from '../Types/GeneralTypes';
import de from './DefaultTranslations.json';
import es from './es_ES.json';
import en from './en_US.json';

/**
 * initializeTranslations()
 * @param selectedLng
 */
export const initializeTranslations = (selectedLng) => {
  i18n
    .use(initReactI18next)
    .init({
      debug: Config.env !== Environment.production,
      interpolation: {
        escapeValue: false,
      },

      fallbackLng: 'de-DE',
      lng: selectedLng || getMappedLanguageFromBrowser(),
      resources: {
        'de-DE': {
          translation: de,
        },
        'es-ES': {
          translation: es,
        },
        'en-EN': {
          translation: en,
        },
      },
      react: {
        suspend: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },
    })
    .then(() => {});
};
