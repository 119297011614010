import React from 'react';
import InputRow from '../Components/Forms/InputRow';
import { useTranslation } from 'react-i18next';
import Button from '../Components/Forms/Button';
import classNames from 'classnames';
import Config from '../Library/Config';
import { useLocation, useParams } from 'react-router';
import { Customer, SubscriptionType } from '../Library/Types/CustomerTypes';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../Library/Firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatchAuth } from '../Redux/Actions/AuthActions';

/**
 * ExternalLogin()
 * @constructor
 */
export default function ExternalLogin() {
  const { customerId } = useParams();
  const { t } = useTranslation();
  const [mail, setMail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [customer, setCustomer] = React.useState<Customer>();
  const [alreadyLoggedIn, setAlreadyLoggedIn] = React.useState<boolean>(false);
  const { search } = useLocation();
  const bgColorButton = new URLSearchParams(search).get('bgColorButton');
  const textColorButton = new URLSearchParams(search).get('textColorButton');
  const toPortalCaption = new URLSearchParams(search).get('toPortalCaption');

  const dispatchLogin = useDispatchAuth();

  const handleAuthStateChange = React.useCallback((state: any) => {
    if (state) {
      setAlreadyLoggedIn(true);
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), handleAuthStateChange);
    return () => unsubscribe();
  }, [handleAuthStateChange]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (customerId && !customer) {
      const customerGet = httpsCallable<any, Customer>(getFunctions(), FirebaseFunctions.customerGet);

      timer = setTimeout(() => {
        customerGet({ customerId })
          .then((response) => {
            setCustomer(response.data);
            setLoaded(true);
          })
          .catch(() => {});
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [customer, customerId]);

  const handleLogin = React.useCallback(() => {
    if (mail && password) {
      dispatchLogin(mail, password, false).then(() => {
        window.open(`${Config.domain}/dashboard`, '_blank');
      });
    }
  }, [dispatchLogin, mail, password]);

  if (customer && loaded) {
    if (
      customer.subscriptionType !== SubscriptionType.professional &&
      customer.subscriptionType !== SubscriptionType.unlimited
    ) {
      return (
        <div className={'flex flex-1 text-center justify-center items-center p-5 font-bold text-red-700'}>
          Your subscription is not valid to use the iFrame login feature of SotoDocs. Please update your subscription!
        </div>
      );
    }

    if (!alreadyLoggedIn) {
      return (
        <div className={'flex flex-1 flex-col overflow-hidden'}>
          <InputRow placeholder={t('mail')} onChange={setMail} value={mail} type={'email'} />
          <InputRow
            placeholder={t('password')}
            onChange={setPassword}
            value={password}
            type={'password'}
            containerClass={'-mt-4'}
          />

          <div className={'flex flex-1 flex-col'}>
            <Button
              onPress={handleLogin}
              colorStyle={!!bgColorButton ? 'error' : 'purple'}
              className={classNames('flex justify-center', { 'border-0': bgColorButton })}
              containerStyle={{ backgroundColor: bgColorButton, color: textColorButton }}
            >
              <div className={classNames('p-1')}>{t('login')}</div>
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className={'flex flex-1 flex-col'}>
        <Button
          onPress={() => window.open(`${Config.domain}/login`, '_blank')}
          colorStyle={!!bgColorButton ? 'error' : 'purple'}
          className={classNames('flex justify-center', { 'border-0': bgColorButton })}
          containerStyle={{ backgroundColor: bgColorButton, color: textColorButton }}
        >
          <div className={classNames('p-1')}>{toPortalCaption || t('toPortal')}</div>
        </Button>
      </div>
    );
  }
  return null;
}
