import styled from 'styled-components';

const Container = styled.div<{ size: 'small' | 'medium' | 'large'; $center: boolean }>`
  font-size: ${(props) => (props.size === 'small' ? '16px' : props.size === 'medium' ? '20px' : '28px')};
  font-weight: 500;
  color: ${(props) => props.theme.colors.text};
  ${(props) => (props.$center ? 'text-align: center;' : null)};
`;

interface Props {
  children: string;
  size: 'small' | 'medium' | 'large';
  center: boolean;
  style?: object;
  className?: string;
}

/**
 * Headline()
 * @param props
 * @constructor
 */
export default function Headline(props: Props) {
  const { children, size, center, style, className } = props;

  return (
    <Container size={size} $center={center} style={style} className={className}>
      {children}
    </Container>
  );
}

Headline.defaultProps = {
  size: 'medium',
  center: false,
};
