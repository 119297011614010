import React from 'react';
import Index from '../../Components/Website/Navigation';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import InputRow from '../../Components/Forms/InputRow';
import Button from '../../Components/Forms/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../../Library/Firebase';
import { useNavigate } from 'react-router';
import { isValidateEmail } from '../../Library/Functions/HelperFunctions';
import Footer from '../../Components/Website/Footer';

const image = require('../../Assets/Images/contact.png');

/**
 * Contact()
 * @constructor
 */
export default function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [mail, setMail] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const [sending, setSending] = React.useState<boolean>(false);
  const [send, setSend] = React.useState<boolean>(false);
  const [mailError, setMailError] = React.useState<boolean>(false);

  const sendMessage = React.useCallback(() => {
    setMailError(false);

    if (isValidateEmail(mail)) {
      setSending(true);
      const callable = httpsCallable(getFunctions(), FirebaseFunctions.mailTemplateSend);
      return callable({
        mail: 'thorsten.rutte@sotosoft.app',
        subject: 'Contact request',
        text: `${companyName} - ${firstName} - ${lastName} - ${mail} - ${phone || 'No phone number'} - \n\n ${message}`,
        // eslint-disable-next-line max-len
        html: `Firma: ${companyName} <br/> Vorname: ${firstName} <br/> Nachname: ${lastName} <br/> Mail: ${mail} <br/> Phone: ${phone || 'No phone number'} <br/><br/>Message:<br/><br/> ${message}`,
      }).then(() => {
        setSending(false);
        setSend(true);
      });
    } else {
      setMailError(true);
    }
  }, [companyName, firstName, lastName, mail, message, phone]);

  React.useEffect(() => {
    if (companyName && mail && firstName && message && lastName) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [companyName, firstName, lastName, mail, message]);

  const renderForm = () => {
    if (!send) {
      return (
        <div className={classNames('flex flex-1 flex-col items-center')}>
          <div className={classNames('w-full', 'md:w-2/3 lg:w-2/4')}>
            <div className={classNames('p-6 flex flex-1 flex-col')}>
              <div className={classNames('text-3xl mb-3 mt-6')}>{t('contact')}</div>
              <div className={classNames('text-xl font-light text-gray-500 mb-14')}>
                {t('modules.website.contactDescription')}
              </div>

              <InputRow
                autoFocus
                onChange={setCompanyName}
                placeholder={t('companyName')}
                type={'text'}
                value={companyName}
                required
                showClear
                containerClass={'-mb-1'}
              />

              <div className={classNames('flex flex-col -mb-2', 'md:flex-row')}>
                <div className={'flex flex-1 flex-col md:mr-3'}>
                  <InputRow
                    onChange={setFirstName}
                    placeholder={t('firstName')}
                    type={'text'}
                    value={firstName}
                    required
                    showClear
                    containerClass={'-mb-2'}
                  />
                </div>
                <div className={'flex flex-1 flex-col pb-3 md:ml-3'}>
                  <InputRow
                    onChange={setLastName}
                    placeholder={t('lastName')}
                    type={'text'}
                    value={lastName}
                    required
                    showClear
                    containerClass={'-mb-2'}
                  />
                </div>
              </div>

              <div className={classNames('flex flex-col -mb-2', 'md:flex-row')}>
                <div className={'flex flex-1 flex-col md:mr-3'}>
                  <InputRow
                    onChange={setMail}
                    placeholder={t('mail')}
                    type={'email'}
                    value={mail}
                    required
                    showClear
                    hasError={mailError}
                    containerClass={'-mb-1.5'}
                  />
                </div>
                <div className={'flex flex-1 flex-col md:ml-3'}>
                  <InputRow onChange={setPhone} placeholder={t('phone')} type={'tel'} value={phone} showClear />
                </div>
              </div>

              <textarea
                className={classNames(
                  'py-3 flex-1 px-4 flex-grow rounded-md border-gray-300 border focus:outline-0 placeholder:font-light',
                )}
                rows={10}
                placeholder={t('messageCreate')}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <div className={classNames('flex flex-col mt-6 text-center mb-20', 'md:block md:text-right')}>
                <Button onPress={sendMessage} colorStyle={'success'} disabled={!enabled || sending || send}>
                  <div className={'flex flex-1 p-2 justify-center items-center'}>
                    {sending ? <i className={'fas fa-spin fa-spinner mr-3'} /> : null}
                    {t('modules.website.sendContact')}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderSuccess = () => {
    if (send) {
      return (
        <div className={classNames('flex flex-1 justify-center items-center pt-20 flex-col mb-20 px-10')}>
          <i className={'fad fa-check-circle text-9xl text-green-700'} />
          <div className={classNames('text-3xl mt-10 text-center')}>{t('modules.website.contactSendSuccessful1')}</div>
          <div className={classNames('text-xl font-light mt-2 text-center')}>
            {t('modules.website.contactSendSuccessful2')}
          </div>

          <div className={'py-10'}>
            <Button onPress={() => navigate('/')} colorStyle={'success'}>
              <div className={'p-3'}>{t('modules.website.backToWebsite')}</div>
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={'flex flex-1 flex-col'}>
      <Index showPrice={false} showStartPage showRegister showLogin />

      <div className={classNames('flex flex-1 flex-col items-center', 'bg-purple-700', 'xl:flex-row')}>
        <div className={classNames('w-full flex justify-center p-16', 'xl:justify-end')}>
          <img src={image} alt={'HeaderLogo'} style={{ width: 500 }} />
        </div>
        <div className={classNames('flex flex-col items-center w-full text-white px-5 pt-14 pb-14', 'xl:items-start')}>
          <div className={classNames('text-6xl font-bold font-mono', 'xl:text-7xl')}>SotoDocs</div>
          <div className={classNames('text-4xl text-center', 'xl:text-5xl xl:text-left xl:pr-52')}>SotoSoft S.L.</div>
          <div className={classNames('text-2xl font-light text-center pt-6', 'xl:text-left xl:pr-52')}>
            Av. Ciro Gil, Soto Villa
          </div>
          <div className={classNames('text-2xl font-light text-center', 'xl:text-left xl:pr-52')}>
            Edificio 2 - Oficina 23
          </div>
          <div className={classNames('text-2xl font-light text-center', 'xl:text-left xl:pr-52')}>
            11310 Sotogrande, Spain
          </div>
          <div className={classNames('text-2xl font-light text-center', 'xl:text-left xl:pr-52')}>
            mail@sotodocs.com
          </div>
        </div>
      </div>

      {renderForm()}
      {renderSuccess()}

      <Footer />
    </div>
  );
}
