import React from 'react';
import Container from '../../../Container/Container';
import { useTranslation } from 'react-i18next';
import AddBankAccount from './AddBankAccount';
import ActionButton from '../../../Container/ActionButton';
import classNames from 'classnames';
import Table from '../../../Table';
import AccountRow from './AccountRow';
import { Client } from '../../../../Library/Types/ClientTypes';
import { clientBuildDisplayName } from '../../../../Library/Functions/ClientFunctions';

interface Props {
  client: Client;
}

/**
 * AccountContainer()
 * @constructor
 */
export default function AccountContainer(props: Props) {
  const { client } = props;
  const { t } = useTranslation();
  const [show, setShow] = React.useState<boolean>(false);

  const renderAccounts = () => {
    if (client.settings && client.settings.bankAccounts && client.settings.bankAccounts.length > 0) {
      return (
        <div className={'mt-10'}>
          <Table>
            <tbody>
              {client.settings.bankAccounts.map((bank) => (
                <AccountRow client={client} item={bank} key={bank.bankIban} />
              ))}
            </tbody>
          </Table>
        </div>
      );
    }
    return null;
  };

  return (
    <Container
      headline={`${t('bankAccounts')} (${clientBuildDisplayName(client)})`}
      actionButtons={
        <ActionButton
          icon={'fas fa-plus'}
          className={classNames('hover:bg-blue-500 hover:text-white')}
          onPress={() => setShow(true)}
        />
      }
    >
      <div className={'text-gray-500'}>{t('bankAccountsDescription')}</div>

      {renderAccounts()}

      <AddBankAccount client={client} visible={show} onClose={() => setShow(false)} />
    </Container>
  );
}
