import Tesseract from 'tesseract.js';
import { textsPrepare } from './TextFunctions';

/**
 * imagePrepareTexts()
 * @param data
 */
export const imagePrepareTexts = (data: Tesseract.RecognizeResult): string[] => {
  return textsPrepare(
    data.data.words.map((item) => item.text),
    50,
  );
};
