import React from 'react';
import { useTranslation } from 'react-i18next';
import ListBox, { ListBoxItem } from '../ListBox';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';

interface Props {
  onChange: (item: number) => void;
  selected: number;
  required?: boolean;
  caption?: string;
  hasError?: boolean;
  hint?: string;
  containerClass?: string;
  direction: 'row' | 'column';
}

/**
 * ListBoxSelectMandantGroup
 * @param props
 * @constructor
 */
export default function ListBoxSelectMandantGroup(props: Props) {
  const { onChange, direction, selected, required, caption, hasError, hint, containerClass } = props;
  const { customer } = useAppSelector((state) => state.auth);
  const [t] = useTranslation();
  const [options, setOptions] = React.useState<ListBoxItem<number>[]>([]);

  React.useEffect(() => {
    if (
      customer &&
      customer.settings &&
      customer.settings.client &&
      customer.settings.client.groups &&
      customer.settings.client.groups.length > 0
    ) {
      const groups = [...customer.settings.client.groups];

      setOptions(
        groups.sort((a, b) => a.name.localeCompare(b.name)).map((group) => ({ value: group.id, label: group.name })),
      );
    }
  }, [customer]);

  if (options && options.length > 0) {
    return (
      <ListBox<number>
        onChange={onChange}
        options={options}
        selected={selected}
        label={caption || t('clientGroup')}
        required={required}
        hasError={hasError}
        hint={hint}
        containerClass={containerClass}
        direction={direction}
      />
    );
  }
  return null;
}

ListBoxSelectMandantGroup.defaultProps = {
  required: false,
  direction: 'row',
};
