import React from 'react';
import { Storage } from '../../../Library/Types/StorageTypes';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import classNames from 'classnames';
import RenderFolder from './RenderFolder';

interface Props {
  documents: Storage[];
  folder: string | null;
  availableFolders: string[];
  onPressFolder: (value: string) => void;
}

const headerClass = classNames('text-left py-4 border-b text-gray-400 font-light');

/**
 * ListView()
 * @param props
 * @constructor
 */
export default function ListView(props: Props) {
  const { documents, folder, availableFolders, onPressFolder } = props;
  const [activeDocument, setActiveDocument] = React.useState<Storage | null>();
  const { t } = useTranslation();

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className={headerClass} style={{ width: 80 }}></th>
          <th className={headerClass}>{t('name')}</th>
          <th className={headerClass}>{t('type')}</th>
          <th className={headerClass}>{t('size')}</th>
          <th className={headerClass}>{t('createdAt')}</th>
          <th className={headerClass}></th>
        </tr>
      </thead>
    );
  };

  const renderDocuments = () => {
    return documents.map((doc) => (
      <Item
        document={doc}
        key={doc.storageId}
        isActive={!!activeDocument && activeDocument.storageId === doc.storageId}
        onClick={setActiveDocument}
        onUnselect={() => setActiveDocument(null)}
      />
    ));
  };

  return (
    <table className={classNames('w-full')}>
      {renderTableHeader()}
      <tbody>
        <RenderFolder folder={folder} availableFolders={availableFolders} onPress={onPressFolder} />
        {renderDocuments()}
      </tbody>
    </table>
  );
}
