import React from 'react';
import { DocumentState, DocumentType, InvoiceType } from '../../../Library/Types/DocumentTypes';
import StatusIcon from '../StatusIcon';
import moment from 'moment';
import { Client } from '../../../Library/Types/ClientTypes';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import { clientBuildDisplayName } from '../../../Library/Functions/ClientFunctions';
import { displayIban, generateRandomString, shortenFileName } from '../../../Library/Functions/HelperFunctions';
import { ActionDropDown } from './ActionDropDown';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface Props {
  document: DocumentType;
  onPress: (document: DocumentType) => void;
  showClient: boolean;
  showFileName: boolean;
  showActions: boolean;
  showCreditor: boolean;
  selectActive: boolean;
  onSelect?: (item: DocumentType) => void;
  onDeselect?: (item: DocumentType) => void;
  selected?: boolean;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const {
    document,
    onPress,
    showClient,
    showActions,
    showFileName,
    showCreditor,
    selectActive,
    onSelect,
    onDeselect,
    selected,
  } = props;
  const { t } = useTranslation();
  const { clients } = useAppSelector((state) => state.auth);
  const [client, setClient] = React.useState<Client | null>(null);

  React.useEffect(() => {
    const found = clients.find((client) => client.clientId === document.clientId);
    if (found) {
      setClient(found);
    }
  }, [clients, document.clientId, showClient]);

  const renderInvoiceTypeIcon = () => {
    let icon: string | null = null;
    let tooltip = '';
    const tooltipClass = generateRandomString(20);

    if (document.state !== DocumentState.uploaded) {
      if (document.type === InvoiceType.outgoing) {
        icon = 'fa-solid fa-hand-holding-circle-dollar text-blue-700 text-xl';
        tooltip = t('invoiceOutgoing');
      } else if (document.type === InvoiceType.incoming) {
        icon = 'fa-solid fa-file-invoice-dollar text-red-600 text-xl';
        tooltip = t('invoiceIncoming');
      } else {
        icon = 'far fa-money-bill-transfer text-xl';
        tooltip = t('invoiceBank');
      }
    }

    if (icon) {
      return (
        <div className={'text text-gray-500'}>
          <i className={classNames(icon, tooltipClass)} />
          <Tooltip anchorSelect={`.${tooltipClass}`}>{tooltip}</Tooltip>
        </div>
      );
    }
    return null;
  };

  const renderClient = () => {
    if (showClient && client) {
      return <td onClick={handleOnClickCol}>{clientBuildDisplayName(client)}</td>;
    }
    return null;
  };

  const renderDate = () => {
    if (document.type === InvoiceType.bank) {
      return `${moment(document.bankStartDate).format('LL')} - ${moment(document.bankEndDate).format('LL')}`;
    }
    return document.invoiceDate ? moment(document.invoiceDate).format('LL') : '';
  };

  const getInvoiceNumber = () => {
    if (document.state !== DocumentState.uploaded) {
      return document.invoiceNumber || `${document.bankName} (${displayIban(document.bankIban)})`;
    }
    return '';
  };

  const renderActionButtons = () => {
    if (showActions) {
      return (
        <td align={'right'}>
          <ActionDropDown document={document} />
        </td>
      );
    }
    return null;
  };

  const renderStatusIcon = () => {
    if (selectActive) {
      return (
        <i
          className={classNames('text-2xl', { 'fat fa-square': !selected }, { 'far fa-square-check': selected })}
          onClick={
            selected
              ? () => (onDeselect ? onDeselect(document) : () => {})
              : onSelect
                ? () => onSelect(document)
                : () => {}
          }
        />
      );
    }
    return <StatusIcon document={document} />;
  };

  const renderFileName = () => {
    if (showFileName) {
      return <td onClick={handleOnClickCol}>{shortenFileName(document.originalName, 40)}</td>;
    }
    return null;
  };

  const renderCreditor = () => {
    if (showCreditor) {
      if (document.type === InvoiceType.outgoing && client) {
        return <td onClick={handleOnClickCol}>{clientBuildDisplayName(client)}</td>;
      }
      return <td onClick={handleOnClickCol}>{document.creditor}</td>;
    }
    return null;
  };

  const handleOnClickCol = () => {
    if (!selectActive) {
      onPress(document);
    } else {
      if (selected) {
        if (onDeselect) {
          onDeselect(document);
        }
      } else {
        if (onSelect) {
          onSelect(document);
        }
      }
    }
  };

  return (
    <tr>
      <td width={50} onClick={handleOnClickCol}>
        {renderStatusIcon()}
      </td>
      <td width={80} style={{ textAlign: 'center' }} onClick={handleOnClickCol}>
        {renderInvoiceTypeIcon()}
      </td>
      {renderFileName()}
      {renderClient()}
      {renderCreditor()}
      <td onClick={handleOnClickCol}>{getInvoiceNumber()}</td>
      <td align={'right'} onClick={handleOnClickCol}>
        {renderDate()}
      </td>
      {renderActionButtons()}
    </tr>
  );
}
