import React from 'react';
import { DocumentMessage } from '../../../Library/Types/DocumentTypes';
import moment from 'moment';
import classNames from 'classnames';
import { useAppSelector } from '../../../Library/Hooks/ReduxHooks';
import AskDeleteModal from '../../Modals/AskDeleteModal';
import { useTranslation } from 'react-i18next';
import { generateRandomString } from '../../../Library/Functions/HelperFunctions';
import { Tooltip } from 'react-tooltip';

interface Props {
  message: DocumentMessage;
  onDelete?: (message: DocumentMessage) => void;
}

/**
 * Row()
 * @param props
 * @constructor
 */
export default function Row(props: Props) {
  const { message, onDelete } = props;
  const { user, customer } = useAppSelector((state) => state.auth);
  const [show, setShow] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const renderDelete = () => {
    const showDelete =
      (message.isCustomerMessage && user.userId === message.createdUserId) ||
      (!message.isCustomerMessage &&
        user.userId === message.createdUserId &&
        Math.abs(moment(message.createdDate).diff(moment(), 'minutes')) <= 5);

    if (showDelete && onDelete) {
      return (
        <i
          className={classNames('fas fa-trash-alt text-gray-300 py-2 px-4', 'hover:text-red-500', 'cursor-pointer')}
          onClick={() => setShow(true)}
        />
      );
    }
    return null;
  };

  const renderInternal = () => {
    if (message.isInternal) {
      const tooltipClass = generateRandomString(20);
      return (
        <>
          <i className={classNames('fas fa-lock text-orange-400 py-2 text-sm', tooltipClass)} />
          <Tooltip anchorSelect={`.${tooltipClass}`}>
            {t('messageInternalOnly', { name: customer.displayName || customer.name })}
          </Tooltip>{' '}
        </>
      );
    }
    return null;
  };

  return (
    <div className={'flex flex-1 flex-col mb-6 border rounded-md last:mb-0'}>
      <div className={'flex flex-row bg-gray-200 rounded-t-md p-3'}>
        <div className={'mr-3'}>
          <i
            className={classNames(
              { 'fas fa-user-circle': !message.isCustomerMessage },
              { 'fas fa-circle-star': message.isCustomerMessage },
              'text-gray-400 text-4xl',
            )}
          />
        </div>
        <div className={'flex-grow'}>
          <div>{message.createdDisplayName}</div>
          <div className={'text-sm text-gray-600 font-light -mt-1'}>
            {`${moment(message.createdDate).format('LLL')} - ${moment(message.createdDate).format('LT')}`}
          </div>
        </div>
        <div className={'flex justify-center items-center'}>
          {renderInternal()}
          {renderDelete()}
        </div>
      </div>
      <div className={classNames('p-4')} dangerouslySetInnerHTML={{ __html: message.message.replace('\n', '<br/>') }} />

      <AskDeleteModal
        headline={t('alert.askDeleteMessage.headline')}
        text={t('alert.askDeleteMessage.text')}
        visible={show}
        onClose={() => setShow(false)}
        onDelete={onDelete ? () => onDelete(message) : () => {}}
      />
    </div>
  );
}
