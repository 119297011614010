import React from 'react';
import AbstractType from '../AbstractType';
import { Type } from '../ApiTypes';

export const LanguageType: Type = {
  type: 'enum',
  name: 'Language',
  fields: [
    { type: 'string', key: 'de-DE', description: 'For german', mandatory: false },
    { type: 'string', key: 'es-ES', description: 'For spanish', mandatory: false },
    { type: 'string', key: 'en-EN', description: 'For englisch', mandatory: false },
  ],
};

/**
 * Language()
 * @constructor
 */
export default function Language() {
  return <AbstractType itemType={LanguageType} />;
}
