import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import classNames from 'classnames';
const logo = require('../../Assets/Images/icon2.png');

interface Props {
  smallMenu: boolean;
}

/**
 * LeftMenuHeader()
 * @constructor
 */
export default function LeftMenuHeader(props: Props) {
  const { smallMenu } = props;
  const { customer } = useAppSelector((state) => state.auth);

  const renderLogo = () => {
    if (customer && customer.logo) {
      return <img src={customer.logo} alt={customer.name} className={'px-8 max-h-48'} />;
    }
    return <img src={logo} alt={'SotoDocs Logo'} width={120} height={120} />;
  };

  const renderCaptionWithCustomer = () => {
    if (customer && customer.logo) {
      return (
        <div
          className={classNames('text-sm text-gray-500 font-light', 'text-center', 'pt-4', 'drop-shadow-xl', 'px-3')}
        >
          powered by SotoDocs
        </div>
      );
    }
    return null;
  };

  const renderCaptionWithoutCustomer = () => {
    if (!customer || !customer.logo) {
      return (
        <div className={classNames('text-center pt-4')}>
          <div className={classNames('text-3xl font-semibold text-gray-600 tracking-wider')}>SotoDocs</div>
          <div className={classNames('text-xs text-gray-400 font-light')}>powered by Sotosoft S.L.</div>
        </div>
      );
    }
    return null;
  };

  if (!smallMenu) {
    return (
      <div className={'pt-8'}>
        <div className={'flex justify-center'}>{renderLogo()}</div>
        {renderCaptionWithCustomer()}
        {renderCaptionWithoutCustomer()}
      </div>
    );
  }
  return (
    <div className={'px-2 py-3 mb-2'}>
      <div className={'flex justify-center'}>
        <img src={logo} alt={'SotoDocs Logo'} width={80} height={80} />
      </div>
    </div>
  );
}
