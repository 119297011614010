import React from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from '../../Library/Firebase';
import { useAppDispatch } from '../../Library/Hooks/ReduxHooks';
import {
  STRIPE_APPROVE_SESSION_ERROR,
  STRIPE_APPROVE_SESSION_START,
  STRIPE_APPROVE_SESSION_SUCCESS,
  STRIPE_GET_CUSTOMER_ERROR,
  STRIPE_GET_CUSTOMER_PORTAL_URL_ERROR,
  STRIPE_GET_CUSTOMER_PORTAL_URL_START,
  STRIPE_GET_CUSTOMER_PORTAL_URL_SUCCESS,
  STRIPE_GET_CUSTOMER_START,
  STRIPE_GET_CUSTOMER_SUCCESS,
} from '../ActionTypes';

/**
 * useDispatchStripeCreateCheckout()
 */
export const useDispatchStripeCreateCheckout = () => {
  return React.useCallback((mail: string, customerId: string, stripeCustomerId: string | null, priceId: string) => {
    const callable = httpsCallable<
      { mail: string; customerId: string; stripeCustomerId: string | null; priceId: string },
      { clientSecret: string }
    >(getFunctions(), FirebaseFunctions.stripeCreateCheckout);

    return callable({ mail, customerId, priceId, stripeCustomerId })
      .then((response) => {
        return Promise.resolve(response.data.clientSecret);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }, []);
};

/**
 * useDispatchStripeCustomerGet()
 */
export const useDispatchStripeCustomerGet = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (stripeCustomerId: string) => {
      dispatch(STRIPE_GET_CUSTOMER_START(stripeCustomerId));

      const callable = httpsCallable<{ stripeCustomerId: string }, any>(
        getFunctions(),
        FirebaseFunctions.stripeCustomerGet,
      );

      return callable({ stripeCustomerId })
        .then((response) => {
          dispatch(STRIPE_GET_CUSTOMER_SUCCESS(response.data));
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch(STRIPE_GET_CUSTOMER_ERROR(error));
          return Promise.resolve(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchStripeGetCustomerPortalUrl()
 */
export const useDispatchStripeGetCustomerPortalUrl = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (stripeCustomerId: string) => {
      dispatch(STRIPE_GET_CUSTOMER_PORTAL_URL_START(stripeCustomerId));

      const callable = httpsCallable<{ stripeCustomerId: string }, any>(
        getFunctions(),
        FirebaseFunctions.stripeGetCustomerPortalUrl,
      );

      return callable({ stripeCustomerId })
        .then((response) => {
          dispatch(STRIPE_GET_CUSTOMER_PORTAL_URL_SUCCESS(response.data.url));
          return Promise.resolve(response.data.url);
        })
        .catch((error) => {
          dispatch(STRIPE_GET_CUSTOMER_PORTAL_URL_ERROR(error));
          return Promise.resolve(error);
        });
    },
    [dispatch],
  );
};

/**
 * useDispatchStripeApproveSession()
 */
export const useDispatchStripeApproveSession = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (sessionId: string) => {
      dispatch(STRIPE_APPROVE_SESSION_START(sessionId));

      const callable = httpsCallable<{ sessionId: string }, any>(
        getFunctions(),
        FirebaseFunctions.stripeApproveSession,
      );

      return callable({ sessionId })
        .then((response) => {
          dispatch(STRIPE_APPROVE_SESSION_SUCCESS(response.data));
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          dispatch(STRIPE_APPROVE_SESSION_ERROR(error));
          return Promise.resolve(error);
        });
    },
    [dispatch],
  );
};
