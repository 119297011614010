import Lodash from 'lodash';
import { Row } from 'read-excel-file';

/**
 * textsPrepare()
 * @param texts
 * @param maxLength
 */
export const textsPrepare = (texts: string[], maxLength?: number): string[] => {
  const filtered = texts.filter((item) => {
    if (item) {
      const prepared = item.toString().replaceAll('undefined', '');
      return (
        prepared.length >= 5 &&
        prepared.trim().length > 0 &&
        prepared.length < 30 &&
        prepared.toLowerCase().indexOf(':') < 0 &&
        prepared.toLowerCase().indexOf(',') < 0 &&
        prepared.toLowerCase().indexOf('iban') < 0 &&
        prepared.toLowerCase().indexOf('bic') < 0 &&
        prepared.toLowerCase().indexOf('www') < 0 &&
        prepared.toLowerCase().indexOf('http') < 0 &&
        prepared.toLowerCase().indexOf('@') < 0 &&
        prepared.toLowerCase().indexOf('http') < 0 &&
        prepared.toLowerCase().indexOf('1x') !== 0 &&
        prepared.toLowerCase().indexOf('Beschreibung') < 0 &&
        prepared.toLowerCase().indexOf('Zwischensumme') < 0 &&
        prepared.toLowerCase().indexOf('Betrag') < 0
      );
    }
    return false;
  });

  const unique = Lodash.uniq(filtered);
  if (maxLength && unique.length > maxLength) {
    return unique.slice(0, maxLength);
  }
  return unique;
};

/**
 * detectCsvSeparator()
 * @param input
 */
const detectCsvSeparator = (input: string): string => {
  const separators = [',', ';', '|', '\t'];
  const idx = separators
    .map((separator) => input.indexOf(separator))
    .reduce((prev, cur) => (prev === -1 || (cur !== -1 && cur < prev) ? cur : prev));
  return input[idx] || ',';
};

/**
 * prepareCsvTexts()
 * @param csvData
 */
export const textPrepareCsvTexts = (csvData: string): string[] => {
  const separator = detectCsvSeparator(csvData);
  const split = csvData.replaceAll('\r\n', ';').replaceAll('\n', ';').split(separator);
  return textsPrepare(split);
};

/**
 * textPrepareExcelData()
 * @param excelData
 */
export const textPrepareExcelData = (excelData: Row[]): string[] => {
  const texts: string[] = [];

  excelData.forEach((row) => {
    row.forEach((cell) => {
      if (cell) {
        texts.push(cell.toString());
      }
    });
  });

  return textsPrepare(texts);
};
