import React from 'react';
import { useAppSelector } from '../../Library/Hooks/ReduxHooks';
import { User } from '../../Library/Types/UserTypes';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';
import { Chart } from 'react-google-charts';
import { DocumentState } from '../../Library/Types/DocumentTypes';

interface OpenPerUser {
  user: User;
  count: number;
}

/**
 * AdminOpenDocumentsByUser()
 * @constructor
 */
export default function AdminOpenDocumentsByUser() {
  const { t } = useTranslation();
  const { user, users, clients } = useAppSelector((state) => state.auth);
  const { documents } = useAppSelector((state) => state.document);
  const [openPerUser, setOpenPerUser] = React.useState<OpenPerUser[]>([]);
  const [chartData, setChartData] = React.useState<any[]>();

  React.useEffect(() => {
    if (users && documents && user.isCustomerAdmin) {
      let response: OpenPerUser[] = [];

      users.forEach((user) => {
        const clientIds = clients.filter((item) => item.assignedUserId === user.userId).map((item) => item.clientId);
        const filteredDocuments = documents.filter(
          (item) => clientIds.indexOf(item.clientId) > -1 && item.state === DocumentState.open,
        );
        response.push({ user, count: filteredDocuments.length });
      });

      setOpenPerUser(response);
    }
  }, [clients, documents, user.isCustomerAdmin, users]);

  React.useEffect(() => {
    if (openPerUser) {
      let data: any[] = [
        [
          { label: t('name'), type: 'string' },
          { label: t('amount'), type: 'number' },
        ],
      ];

      openPerUser.forEach((item) => {
        data.push([`${item.user.firstName.substring(0, 1).toUpperCase()}.${item.user.lastName}`, item.count]);
      });

      setChartData(data);
    }
  }, [openPerUser, t, user.firstName, user.lastName]);

  const options = {
    seriesType: 'bars',
    series: { 5: { type: 'line' } },
    legend: { position: 'none' },
    chartArea: { left: 50, top: 20, right: 20, bottom: 20 },
  };

  if (user.isCustomerAdmin && openPerUser && users.length > 1 && chartData) {
    return (
      <Container headline={t('openDocumentsByUser')} closable identifier={'DashboardOpenDocumentsByUser'}>
        <Chart chartType="ComboChart" data={chartData} width="100%" height="300px" options={options} />
      </Container>
    );
  }
  return null;
}
