import React from 'react';
import AbstractType from '../AbstractType';
import { Type } from '../ApiTypes';

export const SalutationType: Type = {
  type: 'enum',
  name: 'Salutation',
  fields: [
    { type: 'string', key: 'm', description: 'For male', mandatory: false },
    { type: 'string', key: 'f', description: 'For female', mandatory: false },
    { type: 'string', key: 'c', description: 'For company', mandatory: false },
  ],
};

/**
 * Salutation()
 * @constructor
 */
export default function Salutation() {
  return <AbstractType itemType={SalutationType} />;
}
